import React, { PureComponent } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Slider from 'react-slick';
import { colors } from 'common/assets/sharedUI';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type Props = {}

function NextArrow(props) {
  const {
    className, style, onClick,
  } = props;

  return (
    <ArrowForwardIcon
      className={className}
      style={{ ...style, display: 'block', color: colors.black }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const {
    className, style, onClick,
  } = props;

  return (
    <ArrowBackIcon
      className={className}
      style={{ ...style, display: 'block', color: colors.black }}
      onClick={onClick}
    />
  );
}

class Carousel extends PureComponent<Props> {
  render() {
    const {
      children, infinite, myBestSource, eventSource,
    } = this.props;

    const slides = (children || []);

    const responsive = [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ];

    const myBestSourceResponsive = [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ];

    const settings = {
      dots: false,
      infinite,
      speed: 0,
      slidesToShow: (slides.length < 6 ? slides.length : 6),
      slidesToScroll: 1,
      rows: 1,
      nextArrow: <NextArrow eventSource={eventSource} />,
      prevArrow: <PrevArrow eventSource={eventSource} />,
      responsive: myBestSource ? myBestSourceResponsive : responsive,
    };

    return (
      <Slider {...settings}>
        {children}
      </Slider>
    );
  }
}

export default Carousel;
