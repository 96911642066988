import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { add, format } from 'date-fns'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import MultiSelectQuestion from './MultiSelectQuestion';
import DialogTitle from './DialogTitle';
import { sharedStyles } from '../../resources';

const transformDuration = (duration) => duration > 1 ? `${duration} months` : `${duration} month`

const calculateRenewal = (date, numberOfMonths) => add(new Date(date), {
  months: numberOfMonths,
})

const Success = ({ handleClose, sharedClasses }) => (
  <React.Fragment>
    <DialogTitle onClose={handleClose}>Pause complete</DialogTitle>
    <DialogContent>
      <DialogContentText className={sharedClasses.body}>
        Your personalized supplement subscription has been successfully paused.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button className={sharedClasses.rebrandedPrimaryButton} onClick={handleClose}>
        Continue
      </Button>
    </DialogActions>
  </React.Fragment>
)

const pauseDurationsinMonths = [1, 3, 6];

const PauseForm = ({handleClose, handleCancel, handlePause, isCanceling, renewalDate, handleChange, pauseDurations, calculatedRenewal, duration}) => {
  const sharedClasses = sharedStyles();
  
  return (
  <React.Fragment>
    <DialogTitle onClose={handleClose}>Pause Subscription</DialogTitle>
    <DialogContent
      dividers="paper"
      style={{ height: isCanceling ? 275 : 250 }}
    >
      <MultiSelectQuestion
        questionText={isCanceling ? "Would you like to pause instead?" : ""}
        choices={pauseDurations}
        updateAnswer={handleChange}
        selectedAnswer={duration}
        style={{ marginBottom: 10 }}
      />
      <Typography
        className={sharedClasses.body}
      >Your upcoming renewal on {format(new Date(renewalDate), 'LLLL do')} will be rescheduled to:</Typography>
      <Typography
        className={sharedClasses.h6}
        style={{ textAlign: 'center' }}
      >{format(new Date(calculatedRenewal), 'LLLL do')}</Typography>
    </DialogContent>
    <DialogActions style={{ justifyContent: 'space-around', flexDirection: 'column' }}>
      <Button
        size="small"
        className={sharedClasses.rebrandedPrimaryButton}
        onClick={() => handlePause(calculatedRenewal)}
        style={{ margin: 5 }}
      >
        Pause Subscription
      </Button>
      {isCanceling && (
        <Button
          size="small"
          className={sharedClasses.rebrandedSecondaryButton}
          onClick={handleCancel}
          style={{ margin: 5 }}
        >
          Cancel Subscription
        </Button>
      )}
    </DialogActions>
  </React.Fragment>
)}

const PauseSubscriptionDialog = ({
  open, handleClose, handleCancel, handlePause, success, isCanceling, renewalDate
}) => {
  const [duration, setDuration] = useState(pauseDurationsinMonths[0]);
  const [calculatedRenewal, setCalculatedRenewal] = useState(calculateRenewal(renewalDate, duration));

  const pauseDurations = useMemo(() =>
    pauseDurationsinMonths.map(duration => {
      return {
        id: duration,
        choiceText: transformDuration(duration)
      }
    })
    , [pauseDurationsinMonths]);

  const sharedClasses = sharedStyles();

  const handleChange = useCallback(e => {
    const newDuration = parseInt(e.target.value, 10)
    setDuration(newDuration);
    setCalculatedRenewal(calculateRenewal(renewalDate, newDuration));
  })

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="pause-subscription-dialog"
      >
        {success ? (
          <Success handleClose={handleClose} sharedClasses={sharedClasses} />
        ) : (
            <PauseForm handleCancel={handleCancel} handleClose={handleClose} handlePause={handlePause} isCanceling={isCanceling} renewalDate={renewalDate} handleChange={handleChange} pauseDurations={pauseDurations} calculatedRenewal={calculatedRenewal} duration={duration}/>
        )}
      </Dialog>
    </div>
  );
};

PauseSubscriptionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  isCanceling: PropTypes.bool.isRequired,
  renewalDate: PropTypes.string.isRequired,
};

export default PauseSubscriptionDialog;
