import { consent } from 'services';
import actionTypes from '../actionTypes';
import { selectStatus } from './status';

export const getContractStarted = () => ({ type: actionTypes.GET_CONTRACT_STARTED });

export const getContractSuccess = payload => ({ type: actionTypes.GET_CONTRACT_SUCCESS, payload });

export const getContractFailed = payload => ({ type: actionTypes.GET_CONTRACT_ERROR, payload });

export const getContract = (name) => async dispatch => {
  dispatch(getContractStarted());
  try {
    const response = await consent.getContract(name);
    dispatch(getContractSuccess(response));
  } catch (error) {
    dispatch(getContractFailed(error));
  }
};

export const addConsentStarted = () => ({ type: actionTypes.ADD_CONSENT_STARTED });

export const addConsentSuccess = payload => ({ type: actionTypes.ADD_CONSENT_SUCCESS, payload });

export const addConsentFailed = payload => ({ type: actionTypes.ADD_CONSENT_ERROR, payload });

export const addConsent = (name) => async dispatch => {
  dispatch(addConsentStarted());
  try {
    const response = await consent.addConsent(name);
    dispatch(addConsentSuccess(response));
    dispatch(selectStatus());
  } catch (error) {
    dispatch(addConsentFailed(error));
  }
};

export const getAccountContractsStarted = () => ({ type: actionTypes.GET_ACCOUNT_CONTRACTS_STARTED });

export const getAccountContractsSuccess = payload => ({ type: actionTypes.GET_ACCOUNT_CONTRACTS_SUCCESS, payload });

export const getAccountContractsFailed = payload => ({ type: actionTypes.GET_ACCOUNT_CONTRACTS_ERROR, payload });

export const getAccountContracts = (name) => async dispatch => {
  dispatch(getAccountContractsStarted());
  try {
    const response = await consent.getAccountContracts(name);
    dispatch(getAccountContractsSuccess(response));
  } catch (error) {
    dispatch(getAccountContractsFailed(error));
  }
};
