const colors = {
  black: '#000',
  white: '#FFF',
  // Blue
  blue: '#095D7C',
  lightBlue: '#277E9F',
  lighterBlue: '#7CB9D0',
  highlightsBlue: '#277E9F',
  headerBlue: '#095D7C',
  buttonBlue: '#095D7C',
  buttonHoverBlue: '#277E9F',
  buttonHoverLightBlue: '#AADBED',
  buttonBorderBlue: '#277E9F',
  buttonTextBlue: '#277E9F',
  linkBlue: '#095D7C',
  lightestBlue: '#AADBED',
  darkBlue: '#09485F',
  // Green
  green: '#8AC441',
  menuGreen: '#8AC441',
  chartGreen: '#8AC441',
  lighterGreen: '#D4EDB5',
  filterLightGreen: '#D4EDB5',
  carouselGreen: '#D4EDB5',
  hoverGreen: '#65A418',
  darkestGreen: '#508313',
  darkestDarkGreen: '#385c0d',
  // Grey
  darkGrey: '#73767C',
  darkerGrey: '#45484E',
  darkestGrey: '#282C36',
  chartGrey: '#73767C',
  grey: '#C9CACD',
  txtBtnGrey: '#C9CACD',
  headerGrey: '#73767C',
  lightGrey: '#F4F4F5',
  foodDetailsGrey: '#45484E',
  subtextGrey: '#45484E',
  buttonDisabled: '#E9E9EA',
  buttonDisabledBorder: '#45484E',
  buttonDisabledText: '#45484E',
  // Yellow
  chartYellow: '#F0A92D',
  // Orange
  chartOrange: '#C64B2F',
  starOrange: '#C64B2F',
  // Red
  chartRed: '#B70000',
  // Gradient
  linearGradient: 'url(#colorUv)',
  // Other
  transparent: 'rgba(0,0,0,0)',

  gray_01: '#F4F4F5',
  gray_02: '#E9E9EA',
  gray_03: '#C9CACD',
  gray_04: '#73767C',
  gray_05: '#45484E',
  gray_06: '#282c36',

  green_01: '#d4edb5',
  green_02: '#b2de7b',
  green_03: '#8ac441',
  green_04: '#65a418',
  green_05: '#508313',

  blue_01: '#AADBED',
  blue_02: '#7CB9D0',
  blue_03: '#277e9f',
  blue_04: '#095d7c',
  blue_05: '#09485f',
  blue_06: '#0B5D7C',

  red: '#B70000',

  get primaryBlue() {
    return this.blue_04;
  },

  get primaryGreen() {
    return this.green_03;
  },

  get bodyText() {
    return this.gray_06;
  },
};

export const nutrientCategories = ['fats', 'protein', 'carbohydrates', 'minerals', 'vitamins', 'sensitivities', 'substances'];

export const carbohydrateTraitName = 'Total Carbohydrates';

export const proteinTraitName = 'Protein';

export const fatTraitName = 'Total Fat';

const generateRecommendation = (min, max) => ({ recommendationText: `${min}-${max}%`, value: (min + max) / 2 });

export const getMacronutrients = ({ fat, protein, carbs }) => ([
  {
    name: carbohydrateTraitName,
    color: colors.hoverGreen,
    copy: ' of your daily caloric intake should come from mainly complex carbohydrates such as fruits, vegetables, and starchy grains.',
    ...generateRecommendation(carbs.percent.min, carbs.percent.max),
  },
  {
    name: proteinTraitName,
    color: colors.chartOrange,
    copy: ' of your daily caloric intake should come from quality proteins such as those found in your meat, seafood, legumes, and other protein categories on your Optimal Foods page.',
    ...generateRecommendation(protein.percent.min, protein.percent.max),
  },
  {
    name: fatTraitName,
    color: colors.highlightsBlue,
    copy: ' of your daily caloric intake should come from healthy fats such as those found in the fats and oils on your Optimal Foods page.',
    ...generateRecommendation(fat.percent.min, fat.percent.max),
  },
]);
