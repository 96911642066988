import React from 'react';

import {
  Box,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useQueryParams } from 'hooks';
import { getDomainName, getIntake, getIntakeInCalories } from 'utils/dataTools';
import { isSensitivityOrSubstance } from 'hooks/useNutrients';
import {
  EventActions,
  EventCategories,
  EventLabels,
  registerGAClickEventEnhanced,
} from 'utils/googleanalytics';
import NutrientList from './NutrientList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts } from 'common/assets/sharedUI';
import {
  faChevronRight,
  faFingerprint,
} from '@fortawesome/free-solid-svg-icons';
import RecommendationText from './RecommendationText';
import { capitalize } from 'lodash';

const useStyles = makeStyles((theme) =>
  createStyles({
    legendIcon: {
      minWidth: '23px',
    },

    inline: {
      display: 'inline',
    },

    text: {
      fontSize: 16,
      fontFamily: fonts.Swiss721MD,
      color: colors.gray_06,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },

    blueText: {
      color: colors.blue_03,
    },
  }),
);

export const NutrientListItem = ({ isLastTrait, trait }) => {
  const classes = useStyles();
  const history = useHistory();
  const { queryParams } = useQueryParams();
  const conversionAmount = queryParams.get('amount');

  const title = getDomainName(trait.traitName);
  const geneticInsightName =
    trait.geneticInsightName || 'Genetic Result Not Read';
  const recommendationText = trait.isTraitMacroNutrient
    ? trait?.formattedRecommendationInGrams
    : trait?.formattedRecommendation || '';
  const recommendationTextCalorie =
    trait?.formattedRecommendationCalories || '';
  const isSensOrSubs = isSensitivityOrSubstance(trait.domainName);
  // const subtitle = getUnitSubtitle(trait.formattedRecommendation);
  const intakeValueOrRecommendation = getIntake(
    geneticInsightName,
    recommendationText,
    isSensOrSubs,
  );
  const intakeValueOrRecommendationInCalories = getIntakeInCalories(
    geneticInsightName,
    recommendationTextCalorie,
    trait.isTraitMacroNutrient,
    isSensitivityOrSubstance(trait.domainName),
  );
  const generalIntake = trait.formattedAverage;
  const isImpactFul = trait.impactful;
  const domainName = trait.domainName;

  const getRecommendationBlockURL = (domainName, traitId) =>
    `/nutrients/${domainName.toLowerCase()}/${traitId}`;

  const handleRecommendationBlockChange = (value, traitName) => {
    registerGAClickEventEnhanced(
      EventCategories.Nutrients,
      EventActions.NutrientsDetailsClicked,
      EventLabels.Trait(traitName),
    );
    history.push(value);
  };

  const renderTitle = (title, value) => {
    if (isSensOrSubs) {
      return (
        <>
          <Typography
            component='span'
            className={[classes.text, classes.blueText]}
          >{`${title}`}</Typography>
        </>
      );
    }

    return (
      <>
        <Typography
          component='span'
          className={[classes.text, classes.blueText]}
        >{`${title}`}</Typography>
        <Typography
          component='span'
          className={[classes.text]}
        >{` ${value}`}</Typography>
      </>
    );
  };


  const recommendationValue = conversionAmount === 'calories' ? intakeValueOrRecommendationInCalories : intakeValueOrRecommendation
  const recommendText = isSensitivityOrSubstance(trait.domainName) ? intakeValueOrRecommendation : <RecommendationText text={capitalize(trait.recommendationText)} />;

  return (
    <>
      <NutrientList.Item
        onClick={() =>
          handleRecommendationBlockChange(
            getRecommendationBlockURL(domainName, trait.traitId),
            title,
          )
        }
      >
        <ListItemIcon className={classes.legendIcon}>
          {isImpactFul ? (
            <FontAwesomeIcon
              icon={faFingerprint}
              size='xl'
              color={colors.blue_03}
            />
          ) : null}
        </ListItemIcon>

        <ListItemText
          primary={
            <React.Fragment>
              <Box
                component='span'
                className={[classes.inline]}
                style={{
                  paddingLeft:
                    trait.level !== 0 ? `${trait.level * 20}px` : '3px',
                }}
              >
                {renderTitle(title, recommendationValue)}
              </Box>
            </React.Fragment>
          }
        />

        <ListItemSecondaryAction>
          <Typography variant='subtitle1' className={classes.text}>
              {recommendText}
          </Typography>
        </ListItemSecondaryAction>
        <FontAwesomeIcon icon={faChevronRight} />
      </NutrientList.Item>
    </>
  );
};
