import React from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, Cell } from 'recharts'
import { colors } from 'common/assets/sharedUI'
import useStyles from './HighlightChart.styles'

export default function HighlightChart(props) {
  const classes = useStyles()
  const { data } = props
  const RADIAN = Math.PI / 180

  const renderLabel = (propsArray) => {
    const {
      cx, cy, outerRadius, innerRadius, midAngle, name, recommendationText,
    } = propsArray
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <g>
        <text x={x} y={y} fill={colors.white} fontWeight="800" textAnchor="middle" dominantBaseline="central">
          <tspan>{name}</tspan>
        </text>
        <text x={x} y={y} fill={colors.white} fontWeight="800" textAnchor="middle" dominantBaseline="central">
          <tspan dy="20">{recommendationText}</tspan>
        </text>
      </g>
    )
  }

  return (
    <div className={classes.pieChartContainer}>
      <PieChart height={400} width={400}>
        <Pie
          data={data}
          dataKey="value"
          labelLine={false}
          label={renderLabel}
          isAnimationActive={false}
          cx={200}
          cy={200}
        >
          {
            data.map((entry, index) => <Cell key={`cell-${entry.index}`} fill={entry.color} />)
          }
        </Pie>
      </PieChart>
    </div>
  )
}

HighlightChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
}
