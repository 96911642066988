import { axios } from 'utils';
import { constants } from 'resources';

export default async function assignMember(id, ownerName, ownerSurname) {
  const params = {
    id,
    ownerName,
    ownerSurname,
  };

  return axios.post(constants.API_ASSIGN_MEMBER, params);
}
