import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles((theme) => createStyles({
  banner: {
    width: '100%',
    position: 'fixed',
    top: 140,
    left: 0,
    zIndex: 1300,
    height: 50,
    [theme.breakpoints.down('sm')]: {
      top: 0,
      left: 0,
      zIndex: 1301,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  bannerContainer: {
    padding: '0 2.5vw 0 2.5vw',
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  gridContainer: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  callToActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
  },
  callToAction: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  bannerTxt: {
    marginTop: 2,
    marginLeft: 6,
    fontSize: '1.25rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '.9rem',
      minWidth: 123,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '.7rem',
      minWidth: 100,
    },
  },
  bannerImage: {
    height: 44,
    [theme.breakpoints.down('xs')]: {
      visibility: 'hidden',
      width: 0,
    },
  },
  learnMoreButton: {
    marginLeft: 20,
    minWidth: 246,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 10,
      minWidth: 206,
      padding: '3px 10px 3px 10px',
    },
  },
  closeButton: {
    color: colors.white,
    width: 64,
    [theme.breakpoints.down('xs')]: {
      width: 40,
    },
  },
}));

export default useStyles;
