// @flow
import React, { PureComponent } from 'react';
import { Form, Icon, Input, Button, Alert } from 'antd';
import { auth } from 'services';
import styles from './styles.css';

const FormItem = Form.Item;

type Props = {};
class ForgotPasswordForm extends PureComponent<Props> {
  state = { loading: false, message: null };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.onSubmit(values)
    });
  }

  async onSubmit(values) {
    this.setState({ loading: true });
    try {
    const result = await auth.forgotPassword(values)

    this.setState({ loading: false, sentSuccessfully: true });
    } catch ({ response }) {
      this.setState({ message: 'Something went wrong, please try again later', loading: false })
    }
  }

  renderSuccess() {
    return (
      <div styleName="success-container">
        <Icon styleName="icon-success" type="check-circle" />
        <div styleName="title-success">
            Success!
        </div>
        <div styleName="description-success">
        If an account with that email is found, we will send an email that is valid for 24 hours.<br /> Please change your password within this time.
        </div>
        <div className={styles.actions}>
          <a href="/">
            <Button size="large" type="primary" className={styles.form_button}>
              {('Go to login').toUpperCase()}
            </Button>
          </a>
        </div>
      </div>

    )
  }

  renderMessage() {
    if(!this.state.message) return null;

    return <Alert style={{ marginBottom: 24 }} message={this.state.message} type="error" showIcon />;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, sentSuccessfully } = this.state;

    if (sentSuccessfully) {
      return this.renderSuccess();
    }

    return (
      <div styleName="form">
        {this.renderMessage()}
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input your email!' },
                      { type: 'email', message: 'Please input a valid email address!' }],
            })(
              <Input size="large" placeholder="Email" />
            )}
          </FormItem>
          <FormItem style={{ textAlign: 'center' }}>
            <Button size="large" type="primary" htmlType="submit" styleName="form_button" loading={loading}>
              {('Submit').toUpperCase()}
            </Button>
            Go back to <a href="/">login!</a>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const WrappedForgotPasswordForm = Form.create()(ForgotPasswordForm);

export default WrappedForgotPasswordForm;