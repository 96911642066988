import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Grid, Button, Typography,
} from '@material-ui/core';
import {
  HighlightChart, HighlightDescription,
} from 'components/componentsV2';
import { sharedStyles } from 'resources';
import { getMacronutrients } from '../resources/hardcodedAssets';
import useStyles from './ReportHighlight.styles';

export default function ReportHighlight(props) {
  const { data, onClick } = props;
  const sharedClasses = sharedStyles();
  const classes = useStyles();

  const formattedData = getMacronutrients(data);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">Your Recommended Macronutrient Intake</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <HighlightChart data={formattedData} />
      </Grid>
      <Grid item xs={12} md={6}>
        {formattedData.map(({
          name, color, copy, recommendationText,
        }) => (<HighlightDescription title={name} recommendationText={recommendationText} color={color} copy={copy} />))}
        <div className={classes.buttonContainer}>
          <Button id="homeAllRecommendationsButton" variant="outlined" className={clsx(sharedClasses.secondaryButton, classes.reportHighlightsBtn)} onClick={e => onClick(e)}>All Recommendations</Button>
        </div>
      </Grid>
    </Grid>
  );
}

const macroPropType = PropTypes.shape({
  recommendation: PropTypes.number,
  percent: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  calories: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  grams: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
});

ReportHighlight.propTypes = {
  data: PropTypes.shape({
    fat: macroPropType,
    protein: macroPropType,
    carbs: macroPropType,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};
