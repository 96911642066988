import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles((theme) => createStyles({
  productWrapper: {
    [theme.breakpoints.down('md')]: {
      margin: '-10px -16px 0',
    },
  },
  productNavigation: {
    padding: 20,
    [theme.breakpoints.down('md')]: {
      backgroundColor: colors.orange_01,
    },
  },
  productHeader: {
    [theme.breakpoints.down('md')]: {
      backgroundColor: colors.orange_01,
      paddingBottom: 30,
      alignItems: 'center',
    },
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'flex-start',
    paddingLeft: 30,
    paddingRight: 30,
  },
  productDetails: {
    marginLeft: 15,
  },
  productTitle: {
    fontWeight: 400,
    lineHeight: '1.25em',
    fontSize: '1.55em',
  },
  productPrice: {
    fontWeight: 'bold',
    fontSize: '1.55em',
    marginBottom: '1em',
  },
  button: {
    padding: '3px 10px 3px 10px',
  },
  icon: {
    marginRight: 3,
  },
  productDescription: {
    padding: '30px',
    [theme.breakpoints.up('sm')]: {
      padding: 0,
      marginLeft: '190px',
    },
    '& p': {
      fontSize: '16px',
    },
    '& li': {
      fontSize: '16px',
      marginBottom: '8px',
    },
    '& h3': {
      fontSize: '20px',
    },
  },
}));

export default useStyles;
