import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles(() => createStyles({
  productCard: {
    boxShadow: '0 1px 3px 0px rgba(0, 0, 0, .3)',
    borderRadius: 4,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 2,
    width: '100%',
  },
  productDetails: {
    padding: 20,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  productTitle: {
    fontWeight: 'bold',
    fontSize: '16',
    marginTop: 10,
    textAlign: 'center',
  },
  productPrice: {
    fontSize: '16',
    fontWeight: '400',
    textAlign: 'center',
  },
  productLearnMore: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    color: colors.blue_04,
    '&:hover': {
      background: 'none',
    },
  },
  button: {
    width: '100%',
    borderRadius: '0 0 4px 4px',
    padding: '3px 10px 3px 10px',
  },
  plusIcon: {
    marginRight: 5,
  },
}));

export default useStyles;
