import { colors } from 'common/assets/sharedUI';

const styles = theme => ({
  foodImage: {
    height: 100,
    width: 100,
    [theme.breakpoints.up('md')]: {
      paddingRight: 10,
    },
  },
  foodTitleContainer: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
  },
  foodTrophyContainer: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  foodTrophy: {
    color: colors.green_03,
    fontSize: 16,
    marginRight: 10,
  },
  categoryContainer: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
      justifyContent: 'flex-start',
    },
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  foodCategory: {
    marginLeft: 10,
    marginRight: 10,
    '& span': {
      color: colors.gray_04,
    },
  },
  arrowButton: {
    border: `2px solid ${colors.blue_03}`,
    borderRadius: 4,
    padding: 6,
    minWidth: 'auto',
    '&:disabled': {
      border: `2px solid ${colors.gray_04}`,
    },
    '&:disabled svg': {
      color: colors.gray_04,
    },
    '&:hover': {
      backgroundColor: colors.blue_03,
    },
    '&:hover svg': {
      color: colors.white,
    },
  },
  arrowIcon: {
    display: 'block',
    color: colors.blue_03,
  },
  hideButton: {
    border: `2px solid ${colors.blue_03}`,
    borderRadius: 4,
    color: colors.blue_03,
    marginLeft: 10,
    '&:hover': {
      backgroundColor: colors.blue_03,
      color: colors.white,
    },
    '&:hover svg': {
      color: colors.white,
    },
  },
  eyeIcon: {
    marginRight: 5,
  },
  cancelButton: {
    border: `2px solid ${colors.blue_04}`,
    borderRadius: 4,
    color: colors.blue_04,
    marginRight: 10,
    '&:hover': {
      backgroundColor: colors.blue_03,
      color: colors.white,
    },
  },
  removeButton: {
    border: `2px solid ${colors.blue_04}`,
    borderRadius: 4,
    backgroundColor: colors.blue_04,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.blue_03,
      color: colors.white,
    },
  },
  exitIcon: {
    position: 'absolute',
    right: 15,
    top: 15,
    cursor: 'pointer',
  },
  modalButtonContainer: {
    marginBottom: 20,
  },
  individualNutrientDetails: {
    paddingTop: 30,
    [theme.breakpoints.up('md')]: {
      paddingRight: 60,
    },
    '& .MuiTypography-body1': {
      marginBottom: 15,
    },
  },
  nutrientValuesTables: {
    padding: 20,
    borderWidth: 1,
    borderColor: colors.gray_02,
    borderStyle: 'solid',
    [theme.breakpoints.up('md')]: {
      padding: 20,
    },
  },
  individualNutrientsHeader: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    paddingTop: 5,
    paddingBottom: 10,
    '& p': {
      fontSize: 12,
      paddingLeft: 10,
      flexGrow: 1,
    },
    '& p:first-of-type': {
      flexGrow: 3,
      paddingLeft: 0,
    },
    '& p:last-of-type': {
      width: 75,
    },
  },
  individualNutrientValues: {
    '& th, & td': {
      border: 0,
      padding: 0,
    },
    '& th': {
      fontSize: 12,
      color: colors.gray_04,
      paddingBottom: 5,
    },
    '& td': {
      fontSize: 16,
      paddingBottom: 10,
    },
    '& span': {
      color: colors.gray_04,
    },
  },
  checkIcon: {
    color: colors.green_03,
  },
  beneficialNutrients: {
    color: colors.green_05,
    borderBottom: `solid 2px ${colors.green_05}`,
    marginBottom: 2,
  },
  energyNeeds: {
    color: colors.blue_04,
    borderBottom: `solid 2px ${colors.blue_04}`,
    marginBottom: 6,
  },
  otherVitaminsMinerals: {
    color: colors.blue_04,
    borderBottom: `solid 2px ${colors.blue_04}`,
    marginBottom: 6,
  },
  link: {
    color: colors.blue_03,
    fontWeight: 700,
    cursor: 'pointer'
  },
});

export default styles;
