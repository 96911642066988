import React from 'react';
import PropTypes from 'prop-types';
import FreeResponseForm from 'components/FreeResponseForm';
import HeightForm from 'components/HeightForm';
import WeightForm from 'components/WeightForm';
import NumberForm from 'components/NumberForm';
import SingleChoiceForm from 'components/SingleChoiceForm';
import MultipleChoiceForm from 'components/MultipleChoiceForm';
import DateForm from 'components/DateForm';
import TimeForm from 'components/TimeForm';

export default function AnswerHandler(props) {
  const {
    questionTypeId, singleAnswer,
  } = props; // Maybe this should come from Redux instead of props?
  switch (questionTypeId) {
    // We will return the proper component depending on the answerType
    case 1: // choice
      return (
        singleAnswer ? <SingleChoiceForm {...props} /> : <MultipleChoiceForm {...props} />
      );
    case 2: // time
      return (
        <TimeForm {...props} />
      );
    case 3: // date
      return (
        <DateForm {...props} />
      );
    case 4: // number
      return (
        <NumberForm {...props} />
      );
    case 6: // height
      return (
        <HeightForm {...props} />
      );
    case 7: // weight
      return (
        <WeightForm {...props} />
      );
    case 9: // text
      return (
        <FreeResponseForm {...props} />
      );
    default: return <div />;
  }
}

AnswerHandler.propTypes = {
  id: PropTypes.number.isRequired,
  questionGroupId: PropTypes.number,
  questionText: PropTypes.string,
  singleAnswer: PropTypes.bool,
  maxAnswerCount: PropTypes.number,
  questionTypeId: PropTypes.number.isRequired,
  imageLocation: PropTypes.string,
  overlayLocation: PropTypes.string,
  isNoneChoice: PropTypes.bool,
  choices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    choiceText: PropTypes.string,
    choiceSubtext: PropTypes.string,
    imageLocation: PropTypes.string,
    overlayLocation: PropTypes.string,
    isNoneChoice: PropTypes.bool,
  })),
  answer: PropTypes.shape({
    id: PropTypes.number,
    answerChoices: PropTypes.arrayOf(PropTypes.shape({
      questionChoiceId: PropTypes.number,
    })),
    answerText: PropTypes.arrayOf(PropTypes.any),
    dateResponse: PropTypes.string,
    noneResponse: PropTypes.bool,
    numberResponse: PropTypes.number,
    timeResponse: PropTypes.string,
  }),
  isEighteenOrOlder: PropTypes.bool.isRequired,
  onDateChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any),
  touched: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

AnswerHandler.defaultProps = {
  questionGroupId: null,
  questionText: '',
  singleAnswer: null,
  maxAnswerCount: 1,
  imageLocation: null,
  overlayLocation: null,
  isNoneChoice: false,
  choices: [],
  answer: null,
  values: {},
  touched: {},
  errors: {},
};
