import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Box,
} from '@material-ui/core'

import CustomRadio from 'components/CustomRadio'
import { SupplyButton } from 'components/SupplementSubscription'
import { helpers } from 'utils'
import { colors, fonts } from 'common/assets/sharedUI'

const PurchaseTypeSelection = ({
  defaultValue,
  selected,
  onSelect,
  plans,
  activeSubscription,
  disabled,
  isMonthly,
}) => {
  const selectedType = defaultValue?.purchaseType
    ? defaultValue.purchaseType
    : selected.purchaseType
  const selectedPlan = defaultValue?.subscriptionDuration
    ? defaultValue.subscriptionDuration
    : selected.subscriptionDuration

  const handleChange = (key, value) => {
    if (disabled) return

    onSelect(key, value)
  }

  return (
    <FormControl component='fieldset' disabled={disabled}>
      {/* we don't need to show if subscription is active */}
      {!activeSubscription ? (
        <RadioGroup
          aria-label='subscriptionPlans'
          name='subscriptionPlans'
          value={selectedType}
          onChange={(e) => {
            handleChange('purchaseType', e.target.value)
            if (e.target.value === 'oneTime') {
              handleChange('subscriptionDuration', undefined)
            } else {
              handleChange('subscriptionDuration', 3)
            }
          }}
        >
          <FormControlLabel
            value='oneTime'
            control={<CustomRadio />}
            label={
              <Typography
                variant='body1'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontFamily: fonts.subHeading,
                  color: colors.gray_06,
                }}
              >
                {/* TODO: update price dynamically */}
                <span>{`One-Time Purchase ${helpers.formatNumber(297)}`}</span>
                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                  3-month supply ($99/mo)
                </span>
              </Typography>
            }
          />

          <FormControlLabel
            value='subscription'
            control={<CustomRadio />}
            label={
              <Typography
                style={{
                  fontFamily: fonts.subHeading,
                  color: colors.gray_06,
                }}
              >
                Subscribe & Save
              </Typography>
            }
          />
        </RadioGroup>
      ) : null}

      <Box style={{ marginTop: 10, marginBottom: 10 }}>
        <SupplyButton
          onClickPlan={(plan) => {
            handleChange('purchaseType', 'subscription')
            handleChange('subscriptionDuration', plan)
          }}
          activeSupplyPlan={selectedPlan}
          plans={plans}
          supplyButtonContainerStyle={{ marginTop: 0 }}
          disabled={disabled}
          isMonthly={isMonthly}
          activeSubscription={activeSubscription}
        />
      </Box>
    </FormControl>
  )
}

PurchaseTypeSelection.propTypes = {
  defaultValue: PropTypes.shape({
    purchaseType: PropTypes.oneOf(['oneTime', 'subscription']).isRequired,
    subscriptionDuration: PropTypes.oneOfType([PropTypes.string, null])
      .isRequired,
  }),
  selected: PropTypes.shape({
    purchaseType: PropTypes.oneOf(['oneTime', 'subscription']).isRequired,
    subscriptionDuration: PropTypes.oneOfType([PropTypes.string, null])
      .isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  activeSubscription: PropTypes.bool.isRequired,
  isMonthly: PropTypes.bool.isRequired,
}

const ProductPlans = ({
  plans,
  activeSupplyPlan,
  onClickPlan,
  activeSubscription,
  disabled,
  isMonthly
}) => {
  const defaultPurchaseType = activeSupplyPlan ? 'subscription' : 'oneTime'
  const defaultSubscriptionDuration = activeSupplyPlan
    ? activeSupplyPlan
    : undefined

  const [selection, setSelection] = useState({
    purchaseType: defaultPurchaseType,
    subscriptionDuration: defaultSubscriptionDuration,
  })

  const handleOptionChange = (field, value) => {
    setSelection((prevSelection) => ({
      ...prevSelection,
      [field]: value,
    }))

    onClickPlan({ ...selection, [field]: value })
  }

  return (
    <div>
      <PurchaseTypeSelection
        defaultValue={{
          purchaseType: defaultPurchaseType,
          subscriptionDuration: defaultSubscriptionDuration,
        }}
        selected={selection}
        onSelect={handleOptionChange}
        plans={plans}
        activeSubscription={activeSubscription}
        disabled={disabled}
        isMonthly={isMonthly}
      />
    </div>
  )
}

ProductPlans.propTypes = {
  plans: PropTypes.array.isRequired,
  activeSupplyPlan: PropTypes.number,
  onClickPlan: PropTypes.func.isRequired,
  activeSubscription: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  isMonthly: PropTypes.bool.isRequired,
}

export default ProductPlans
