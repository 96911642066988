import React, { useMemo } from 'react';

import {
    homeHeader,
    homeSubheading,
    homeLink,
} from 'resources/constants';
import CustomHeader from '../CustomHeader';
import useStyles from './HomeHeader.styles'
import { get } from 'lodash';
import { useSelector } from 'react-redux';

function HomeHeader() {
  const classes = useStyles();
  const { userProfile } = useSelector((state) => state.profile);
  const name = useMemo(() => (get(userProfile, 'data.name', '')), [userProfile]);

  return (
    <>
    <CustomHeader
      header={`${homeHeader} ${name}!`}
      subHeading={homeSubheading}
      linkText={homeLink}
      bannerStyles={classes.banner}
      headingStyles={classes.heading}
      subheadingStyles={classes.textStyles}
      linkStyles={classes.textStyles}
      hasImage
      isLink={false}
    />
    </>
  );
}

export default HomeHeader;
