import React from 'react'
import {
  Grid, Box, IconButton, Collapse,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import { colors } from 'common/assets/sharedUI'
import { images } from 'resources'
import useStyles from './WebBanner.styles'

const WebBanner = ({
  openBanner, onClose, topPosition, bannerPosition,
}) => {
  const classes = useStyles()

  return (
    <Collapse in={openBanner}>
      <Box pr={2} pl={5} bgcolor={colors.blue_04} color={colors.white} className={classes.banner} style={{ top: topPosition, position: bannerPosition }}>
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item xs={12} className={classes.bannerContainer}>
            <div className={classes.flex}>
              <div className={classes.firstColumn}>
                <span className={classes.bannerTxt} name="bannerTxt">Download our mobile app to review your results on the go!</span>
                <div className={classes.imgPhoneContainer}>
                  <img src={images.phone} alt="Phone Preview" className={classes.imgPhone} />
                </div>
              </div>
            </div>
            <div className={classes.secondColumn}>
              <a href="https://apps.apple.com/us/app/genopalate/id1468074158?ls=1">
                <img src={images.appStore_banner} alt="App Store" />
              </a>
              <a href="http://play.google.com/store/apps/details?id=com.genopalate.mobileapp">
                <img src={images.googlePlay_banner} alt="Google Play" className={classes.imgGooglePlay} />
              </a>
            </div>
            <IconButton id="webBannerCloseBtn" aria-label="close" onClick={onClose} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  )
}

WebBanner.propTypes = {
  openBanner: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  topPosition: PropTypes.number.isRequired,
  bannerPosition: PropTypes.oneOf(['fixed', 'absolute']).isRequired,
}

export default WebBanner
