import React, { Fragment } from 'react'
import { Dialog, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

const styles = {
  modal: {
    zIndex: '1301 !important',
    '& .MuiDialog-paper': {
      margin: 5,
    },
  },
}

function CloseableDialog(props) {
  const { onClose, children, classes } = props
  const dialogProps = {
    ...props,
    children: undefined,
    classes: undefined,
  }

  return (
    <Dialog {...dialogProps} className={classes.modal}>
      <Fragment>
        <div style={{
          width: '100%', textAlign: 'right', fontSize: 20,
        }}
        >
          <IconButton onClick={() => onClose()} style={{ backgroundColor: 'transparent', padding: '10px 10px 5px 0' }} disableRipple>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </div>
        { children }
      </Fragment>
    </Dialog>
  )
}

CloseableDialog.propTypes = Dialog.propTypes

export default withStyles(styles)(CloseableDialog)
