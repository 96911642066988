import actionTypes from '../actionTypes'
import { apiStatuses } from '../../resources/constants'

const initialPreferenceState = {
  allergies: [],
  dietaryPreferences: [],
  getPreferencesStatus: apiStatuses.IDLE,
  setPreferenceStatus: apiStatuses.IDLE,
  getPreferenceError: null,
  setPreferenceError: null,
};

const preferenceReducer = (state = initialPreferenceState, action) => {
  switch (action.type) {
    case actionTypes.GET_PREFERENCES_STARTED:
      return {
        ...state,
        getPreferencesStatus: apiStatuses.PENDING,
      };
    case actionTypes.GET_PREFERENCES_SUCCESS:
      return {
        ...state,
        allergies: action.preferences.data.allergies,
        dietaryPreferences: action.preferences.data.dietaryPreferences,
        getPreferencesStatus: apiStatuses.RESOLVED,
      };
    case actionTypes.GET_PREFERENCES_ERROR:
      return {
        ...state,
        getPreferencesStatus: apiStatuses.REJECTED,
        error: action.error,
      };
    case actionTypes.SET_PREFERENCE_STARTED:
      return {
        ...state,
        setPreferenceStatus: apiStatuses.PENDING,
      };
    case actionTypes.SET_PREFERENCE_SUCCESS:
      return {
        ...state,
        allergies: action.preferences.allergies,
        dietaryPreferences: action.preferences.dietaryPreferences,
        setPreferenceStatus: apiStatuses.RESOLVED,
      };
    case actionTypes.SET_PREFERENCE_ERROR:
      return {
        ...state,
        setPreferenceStatus: apiStatuses.REJECTED,
        error: action.error,
      };
    default:
      return state;
  }
};

export default preferenceReducer;