import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';
import { user } from 'services';
import Grid from '@material-ui/core/Grid';
import {
  Button, Icon, useMediaQuery,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BackButton from 'components/BackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog as outlineCog,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faFileExport,
  faCog,
} from '@fortawesome/pro-solid-svg-icons';
import ReactGA from 'react-ga4';
import {
  WelcomeMessage, Loading,
} from 'components/componentsV2';
import FoodDetailView from 'components/FoodDetailView';
import FoodExportView from 'components/FoodExportView';
import FoodGroupListItem from 'components/FoodGroupListItem';
import FoodListItem from 'components/FoodListItem';
import { getContentLegend } from 'utils/dataTools';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { sharedStyles } from 'resources';
import { apiStatuses } from 'resources/constants';
import { useWindowWidth } from '../utils/hooks';
import { setDisplayHeaderFooter } from '../store/actions/status';
import styles from './FoodPage.styles';
import {
  selectFoodDetail,
  getFoodDetails as getFoodDetailsFromService,
} from '../store/actions/food';
import { getEssentialNutrition } from '../store/actions/domain';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';

const { IS_PROD, IS_STAGING } = window.env;

const FoodPage = (props) => {
  const { detailView, classes } = props;
  const sharedClasses = sharedStyles();
  const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const expandedCategory = useSelector(state => state.food.expandedCategory);
  const essentialNutrition = useSelector(state => state.domain.essentialNutrition);
  const userTopFoodsStatus = useSelector(state => state.topFoods.status);
  const userTopFoods = useSelector(state => state.topFoods.userTopFoods);
  const foodDetailsFromService = useSelector(state => state.food.details);
  const [selectedFoodGroupIds, setSelectedFoodGroupIds] = useState([expandedCategory || 0]);
  const width = useWindowWidth();

  // Load top foods and apply filters if needed
  const [foodGroups, setFoodGroups] = useState(null);
  const [foodExportView, setFoodExportView] = useState(false);

  useEffect(() => {
    if (userTopFoods) {
      const topFoods = userTopFoods;
      setFoodGroups(topFoods);
    }
  }, [userTopFoods]);

  useEffect(() => {
    if (!essentialNutrition.data && !essentialNutrition.fetching) {
      dispatch(getEssentialNutrition());
    }
  }, [dispatch, essentialNutrition.data, essentialNutrition.fetching]);

  // Fetch food score
  const [loadingFoodScore, setLoadingFoodScore] = useState(false);
  const [foodScore, setFoodScore] = useState(null);
  const getFoodScore = async (id) => {
    setLoadingFoodScore(true);
    try {
      const result = await user.getFoodScores([id]);
      setFoodScore(result.data);
      setLoadingFoodScore(false);
    } catch ({ response }) {
      setLoadingFoodScore(false);
    }
  };

  // Find food to render data
  const getFoodById = useCallback((id) => {
    if (!userTopFoods || !foodGroups) {
      return undefined;
    }
    const allFoods = foodGroups.map(group => group.foods);
    const flatFoods = allFoods.flat();
    return flatFoods.find(f => f.id === parseInt(id, 10));
  }, [userTopFoods, foodGroups]);

  useEffect(() => {
    (async () => {
      if (detailView && (IS_PROD || IS_STAGING)) {
        const foodId = match.params.id;
        const curFood = getFoodById(foodId);
        if (curFood) {
          ReactGA.send({ hitType: "pageview", page: `/food/${snakeCase(curFood.name)}` });
        } else {
          ReactGA.send({ hitType: "pageview", page: match.url });
        }
      } else {
        ReactGA.send({ hitType: "pageview", page: match.url });
      }
    })();
  }, [match.params.id, detailView, getFoodById, match.url]);

  useEffect(() => {
    if (match.params.id) {
      getFoodScore(match.params.id);
      dispatch(getFoodDetailsFromService(match.params.id));
    }
  }, [match.params.id]);

  // Redux setFoodId
  const setFoodId = useCallback((foodId) => {
    dispatch(selectFoodDetail(foodId));
    dispatch(getFoodDetailsFromService(foodId));
  }, [dispatch]);

  const handleFoodClick = useCallback((id, url, foodName) => {
    setFoodId(id);
    registerGAClickEventEnhanced(EventCategories.OptimalFoods, EventActions.Open, EventLabels.Food(foodName));
    window.scrollTo(0, 0);
    history.push(url);
  }, [setFoodId, history]);

  const searchFoodGroup = (foodGroup, id, index) => {
    const foodGroupId = foodGroup.id;
    const foodIndex = foodGroup.foods.findIndex(f => parseInt(f.id, 10) === parseInt(id, 10));
    if (foodIndex > -1) {
      const filteredFoodGroup = foodGroup.foods.filter(food => !food.individuallyHidden && food.missingDietaryPreferences.length === 0);
      const filteredFoodIndex = filteredFoodGroup.findIndex(f => parseInt(f.id, 10) === parseInt(id, 10));
      return {
        foodGroupIndex: index, foodGroupId, foodIndex: filteredFoodIndex, foods: filteredFoodGroup,
      };
    }
    return null;
  };

  const getNextUrl = (foodSearchResults) => {
    const siblingFoodsCopy = Object.create(foodSearchResults.foods); // create a shallow copy so the original array isn't modified
    siblingFoodsCopy.push(foodSearchResults.foods[0]); // add first food to end of array so the index can loop
    const { id } = siblingFoodsCopy[foodSearchResults.foodIndex + 1];
    const nextUrl = `/food/${id}`;
    return { id, url: nextUrl };
  };

  const getPrevUrl = (foodSearchResults) => {
    const { id } = (parseInt(foodSearchResults.foodIndex, 10) !== 0
      ? foodSearchResults.foods[foodSearchResults.foodIndex - 1]
      : foodSearchResults.foods[foodSearchResults.foods.length - 1]);
    const prevUrl = `/food/${id}`;
    return { id, url: prevUrl };
  };

  const getFoodDetails = (id) => {
    if (foodGroups) {
      const userTopFoodsDetails = foodGroups;
      if (userTopFoodsDetails.length > 0) {
        const foodSearchResults = [];
        userTopFoodsDetails.forEach((foodGroup, index) => {
          foodSearchResults[index] = searchFoodGroup(foodGroup, id, index);
        });
        const foodSearchNonEmptyResults = foodSearchResults.find(result => result); // fetch first non-null result from food search
        if (foodSearchNonEmptyResults && foodSearchNonEmptyResults.foodIndex !== -1) {
          const foodGroup = userTopFoodsDetails[foodSearchNonEmptyResults.foodGroupIndex];
          const { trophy } = foodGroup.foods[foodSearchNonEmptyResults.foodIndex];
          const nextFood = getNextUrl(foodSearchNonEmptyResults);
          const prevFood = getPrevUrl(foodSearchNonEmptyResults);
          return {
            next: nextFood.url,
            prev: prevFood.url,
            nextId: nextFood.id,
            prevId: prevFood.id,
            foodGroup,
            trophy,
          };
        }
        return {
          next: null,
          prev: null,
          nextId: null,
          prevId: null,
          foodGroup: null,
          trophy: null,
        };
      }
    }
    return null;
  };

  const handleFoodExport = useCallback((e) => {
    e.preventDefault();
    setFoodExportView(true);
    dispatch(setDisplayHeaderFooter(false));
    registerGAClickEventEnhanced(EventCategories.OptimalFoods, EventActions.Open, EventLabels.PageOpened);
  }, [setFoodExportView, dispatch]);

  const handleAccordionGaEvent = (action, name) => registerGAClickEventEnhanced(EventCategories.MyFoods, action, EventLabels.FoodGroup(name));

  const handleFoodGroupClick = useCallback(
    (foodGroupId, name) => {
      if (isXsScreen) {
        if (!selectedFoodGroupIds.includes(foodGroupId)) {
          handleAccordionGaEvent(EventActions.Expand, name);
          setSelectedFoodGroupIds([...selectedFoodGroupIds, foodGroupId]);
        } else {
          handleAccordionGaEvent(EventActions.Collapse, name);
          setSelectedFoodGroupIds(selectedFoodGroupIds.filter(selectedFoodGroupId => foodGroupId !== selectedFoodGroupId));
        }
      } else {
        handleAccordionGaEvent(EventActions.Expand, name);
        setSelectedFoodGroupIds([foodGroupId]);
      }
    },
    [selectedFoodGroupIds, isXsScreen],
  );

  const handleSettingsClick = (url) => {
    registerGAClickEventEnhanced(EventCategories.FoodSettings, EventActions.Open, EventLabels.OpenFoodSettings);
    history.push(url);
  };

  const getFirstSelectedFoodGroup = useCallback(() => foodGroups.find(foodGroup => selectedFoodGroupIds.includes(foodGroup.id)), [foodGroups, selectedFoodGroupIds]);

  // Display foods
  const generateFoods = useMemo(() => {
    if (foodGroups) {
      const selectedFoodGroup = getFirstSelectedFoodGroup();
      if (selectedFoodGroup && selectedFoodGroup.foods) {
        const hiddenFoodsRemoved = selectedFoodGroup.foods.filter(food => !food.individuallyHidden);
        const filteredFoodsRemoved = hiddenFoodsRemoved.filter(food => food.missingDietaryPreferences.length === 0);
        if (filteredFoodsRemoved.length > 0) {
          return filteredFoodsRemoved.map((food) => {
            if (!food.individuallyHidden) {
              return (
                <FoodListItem
                  food={food}
                  foodGroup={selectedFoodGroup}
                  handleClick={handleFoodClick}
                  isXsScreen={isXsScreen}
                  key={food.id}
                />
              );
            }
          });
        }
        return (
          <div className={classes.hiddenFoodsText}>
            {'You have hidden all the foods in this category. You can adjust this in your '}
            <Icon className={classes.outlineCog}>
              <FontAwesomeIcon icon={outlineCog} />
            </Icon>
            {' settings.'}
          </div>
        );
      }
      return null;
    }
    return null;
  }, [foodGroups, getFirstSelectedFoodGroup, handleFoodClick, isXsScreen, classes.hiddenFoodsText, classes.outlineCog]);

  // on screen resize
  useEffect(() => {
    if (!isXsScreen && selectedFoodGroupIds.length > 1) {
      setSelectedFoodGroupIds([selectedFoodGroupIds[0]]);
    }
  }, [width, isXsScreen, selectedFoodGroupIds]);

  const foodDetails = (detailView ? getFoodDetails(match.params.id) : {});

  if (userTopFoodsStatus === apiStatuses.IDLE || userTopFoodsStatus === apiStatuses.PENDING || loadingFoodScore || !foodDetails || essentialNutrition.fetching || foodDetailsFromService.fetching) return <Loading />;

  return (
    foodExportView ? (
      <FoodExportView setFoodExportView={setFoodExportView} foodGroups={foodGroups} />
    )
      : (
        <Grid container spacing={0}>
          {detailView ? (
            <Grid item xs={12} className={classes.foodDetails}>
              <BackButton href="/food" text="Optimal Foods" />
              <FoodDetailView
                data={essentialNutrition.data}
                id={match.params.id}
                foodScore={foodScore}
                nextUrl={foodDetails.next}
                prevUrl={foodDetails.prev}
                nextId={foodDetails.nextId}
                prevId={foodDetails.prevId}
                setFoodId={setFoodId}
                foodGroup={foodDetails.foodGroup}
                trophy={foodDetails.trophy}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={5} className={classes.welcomeContainer}>
                </Grid>
                <Grid item xs={12} md={7}>
                  <div className={classes.foodAppBarContainer}>
                    <Button
                      className={sharedClasses.secondaryButton}
                      startIcon={(
                        <Icon>
                          <FontAwesomeIcon icon={faFileExport} />
                        </Icon>
                      )}
                      onClick={handleFoodExport}
                    >
                      EXPORT
                      {' '}
                      {!isXsScreen && ('FOODS ')}
                      LIST
                    </Button>
                    &nbsp;
                    <Button
                      className={sharedClasses.primaryButton}
                      startIcon={(
                        <Icon>
                          <FontAwesomeIcon icon={faCog} />
                        </Icon>
                      )}
                      onClick={() => handleSettingsClick('/food/settings')}
                    >
                      Settings
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <p className={classes.pageDescription}>
                    {'Based on your genetic insights, these are your '}
                    <b>optimal foods</b>
                    {'. The trophy icon indicates '}
                    <b>{'the best matches '}</b>
                    to your nutrition recommendations and they are ordered starting with the best match at the top.
                  </p>
                </Grid>
              </Grid>
              <Grid container style={{ padding: !isXsScreen ? '0px 20px' : '0px' }}>
                <Grid item xs={12} sm={3} className={classes.foodCategoriesWrapper}>
                  {foodGroups && foodGroups.map((foodGroup) => (
                    <FoodGroupListItem
                      handleClick={() => handleFoodGroupClick(foodGroup.id, foodGroup.name)}
                      handleFoodClick={handleFoodClick}
                      foodGroup={foodGroup}
                      showToggle={false}
                      width={width}
                      isXsScreen={isXsScreen}
                      expanded={selectedFoodGroupIds.includes(foodGroup.id)}
                    />
                  ))}
                </Grid>
                {
                  !isXsScreen && foodGroups && (
                    <Grid item sm={9}>
                      <div className={classes.foodContainer}>
                        <div>
                          {generateFoods}
                        </div>
                        {/* The non-small-screen view of the web app only displays one food group at a time,
                        so the array will only have one food group id */}
                        <div className={classes.foodContentLegend}>{getContentLegend(selectedFoodGroupIds[0])}</div>
                      </div>
                    </Grid>
                  )
                }

              </Grid>
            </Grid>
          )}
        </Grid>
      ));
};

export default withStyles(styles)(FoodPage);

FoodPage.propTypes = {
  detailView: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    pageDescription: PropTypes.string.isRequired,
    foodCategoriesWrapper: PropTypes.string.isRequired,
    foodContainer: PropTypes.string.isRequired,
    foodContentLegend: PropTypes.string.isRequired,
    welcomeContainer: PropTypes.string.isRequired,
    foodAppBarContainer: PropTypes.string.isRequired,
    hiddenFoodsText: PropTypes.string.isRequired,
    outlineCog: PropTypes.string.isRequired,
    foodDetails: PropTypes.string.isRequired,
  }).isRequired,
};
