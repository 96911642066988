// @flow
import React, { useState, useCallback } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  FormControl,
  Typography,
  ButtonBase,
  CircularProgress,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { auth } from 'services';
import { colors } from 'common/assets/sharedUI';
import * as flows from '../flows';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';

const emailRegex = /\S+@\S+\.\S+/;

const loginGAEvent = () => registerGAClickEventEnhanced(EventCategories.Login, EventActions.Click, EventLabels.Login);
const forgotPwdGAEvent = () => registerGAClickEventEnhanced(EventCategories.Login, EventActions.Click, EventLabels.ForgotPassword);

const LoginForm = ({ classes }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [loggedInUserId, setLoggedInUserId] = useState(null);

  const location = useLocation();
  const { state } = location; // state will be undefined if user was not redirected to the login page

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setLoading(true);

    const loginResponse = await auth.login({ email, password }).catch(({ response }) => {
      const newMessage = response && response.data && response.data.code
        && response.data.code === 'username_password_wrong'
        ? 'Invalid username or password.'
        : 'Something went wrong, please try again later';
      setMessage(newMessage);
      setLoading(false);
    });

    if (loginResponse && loginResponse.data) { // I'd like to move these side effects out of this if statement but not really sure how 🤷‍♂️
      const { data: { token, user, uuidCode } } = loginResponse;
      flows.login(token, user.id, uuidCode);
      setLoggedInUserId(user.id);
      setLoading(false);
    }
  }, [email, password]);

  const checkEmailValid = useCallback(() => {
    if (!email) {
      setEmailHelperText('');
      setEmailError(false);
    } else {
      const emailValid = emailRegex.test(email);
      if (!emailValid) {
        setEmailHelperText('Please enter a valid email address');
        setEmailError(true);
      } else {
        setEmailHelperText('');
        setEmailError(false);
      }
    }
  }, [email]);

  if (loggedInUserId) {
    return <Redirect to={state !== undefined && state.from ? state.from : '/home/'} />;
  }

  return (
    <div className={classes.wrapperColumn}>
      <form onSubmit={handleSubmit}>
        <div className={classes.headerContainer}>
          <Typography className={classes.header}>Sign In to Your Account</Typography>
          <Typography className={classes.caption} variant="body2">
            Don&apos;t have an account?&nbsp;
            <Link to="/register" id="loginCreateAccountLink"><b>Create one now</b></Link>
          </Typography>
        </div>
        {message ? (
          <div className={classes.errorWrapper}>{message}</div>
        ) : null}
        <FormControl style={{ width: '100%', marginTop: 20 }}>
          <Typography className={classes.inputText}>Email address</Typography>
          <TextField id="loginEmailInput" variant="outlined" error={emailError} className={classes.textInput} helperText={emailHelperText} onBlur={checkEmailValid} onChange={(e) => setEmail(e.target.value)} />
        </FormControl>
        <Typography className={classes.inputText}>Password</Typography>
        <OutlinedInput
          className={classes.textInput}
          variant="outlined"
          id="loginPasswordInput"
          type={passwordVisible ? 'text' : 'password'}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
          onChange={e => setPassword(e.target.value)}
        />
        <ButtonBase
          size="large"
          className={classes.button}
          disabled={loading}
          type="submit"
          id="loginSignInButton"
          onClick={loginGAEvent}
        >
          {loading ? <CircularProgress size={20} color="white" /> : ('Sign in').toUpperCase()}
        </ButtonBase>
        <Typography variant="body2" className={classes.bodyText}>
          Forgot your password?&nbsp;
          <a onClick={forgotPwdGAEvent} id="loginResetPasswordLink" href="/forgot_password"><b>Reset it here</b></a>
        </Typography>
      </form>
    </div>
  );
};

const styles = {
  wrapperColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    maxWidth: 350,
  },
  header: {
    fontSize: 24,
    color: colors.blue_03,
    fontWeight: 300,
    textAlign: 'center',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8,
  },
  caption: {
    textAlign: 'center',
    color: colors.gray_05,
  },
  inputText: {
    color: colors.gray_05,
    marginBottom: '3px',
  },
  button: {
    backgroundColor: colors.linkBlue,
    color: colors.white,
    borderColor: colors.linkBlue,
    width: '100%',
    padding: '8px 32px 8px 32px',
    height: '44px',
    alignSelf: 'center',
    borderRadius: 4,
    fontWeight: 600,
    fontSize: '16px',
    marginTop: 12,
    marginBottom: 15,
  },
  bodyText: {
    fontSize: 14,
    textAlign: 'center',
    color: colors.gray_05,
  },
  textInput: {
    width: '100%',
    marginBottom: 12,
    '& input': {
      padding: 10,
      fontSize: '16px',
    },
  },
  errorWrapper: {
    padding: 4,
    marginTop: 10,
    borderStyle: 'solid',
    borderRadius: 4,
    textAlign: 'center',
    backgroundColor: 'rgba(180,0,0,.15)',
    color: colors.red,
    borderColor: colors.red,
    marginBottom: 24,
  },
};

LoginForm.propTypes = {
  classes: PropTypes.shape({
    wrapperColumn: PropTypes.string,
    header: PropTypes.string,
    headerContainer: PropTypes.string,
    caption: PropTypes.string,
    button: PropTypes.string,
    bodyText: PropTypes.string,
    inputText: PropTypes.string,
    textInput: PropTypes.string,
    errorWrapper: PropTypes.string,
    footer: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(LoginForm);
