import { createStyles, makeStyles } from '@material-ui/core'
import { colors } from 'common/assets/sharedUI'
export const useStyles = makeStyles((theme) => createStyles({
    horizontalRule: {
        marginTop: 40,
        marginBottom: 40,
        borderTop: `1px solid ${colors.gray_03}`,
    },
    additionalProductWrapper: {
        marginBottom: 25,
        paddingRight: 25,
    },
}))