import { getAxiosInstance } from '../../utils/axios';

export default async (traitIds) => {
  const resp = await getAxiosInstance().get('/api/v1/genetics/userGeneDetails', {
    params: {
      traitIds,
    },
  });

  return resp.data;
};
