/* eslint-disable class-methods-use-this */
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Typography,
  Paper,
  Grid,
  ButtonBase,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook, faVideo, faUpload, faArrowLeft,
} from '@fortawesome/pro-light-svg-icons';
import {
  faExternalLink,
} from '@fortawesome/pro-regular-svg-icons';
import { colors, fontFamily } from 'common/assets/sharedUI';
import UploadDnaModal from './UploadDnaModal';
import StatusTrackerV2 from './StatusTrackerV2';
import ContactSupport from './ContactSupport';
import {
  openModal,
  closeModal,
  openInstructions,
  closeInstructions,
  selectSource,
  SOURCE_TYPES,
} from '../store/actions/dnaUpload';
import CloseableDialog from './CloseableDialog';
import PaperContainer from './PaperContainer';
import { registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels } from '../utils/googleanalytics';

const styles = {
  container: {
    maxWidth: 400,
    margin: 'auto',
    textAlign: 'center',
    fontFamily,
  },
  titleText: {
    fontSize: 22,
    color: colors.primaryBlue,
  },
  cardText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  cardTextSmall: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  cardIcon: {
    fontSize: 38,
    color: colors.primaryBlue,
    marginBottom: 15,
  },
  card: {
    backgroundColor: colors.gray_01,
    padding: 20,
    margin: 5,
    cursor: 'pointer',
    width: '100%',
  },
  button: {
    backgroundColor: colors.primaryBlue,
    flex: 1,
    margin: '15px 5px 0px 5px',
    color: 'white',
    borderRadius: 5,
    fontSize: 18,
    padding: 10,
    fontWeight: 700,
  },
  cardWrapper: {
    display: 'flex',
    alignItems: 'stretch',
  },
  instructionsModal: {
    maxWidth: 400,
    maxHeight: 'calc(100vh - 250px);',
    padding: '0 20px 20px 20px',
    '& p': {
      marginBottom: 20,
    },
  },
  switchText: {
    display: 'inline-block',
    cursor: 'pointer',
    marginBottom: '10px',
    color: colors.gray_05,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

const getCleanSource = (sourceType) => (sourceType === SOURCE_TYPES.ANCESTRY ? 'AncestryDNA' : '23andMe');
const getOtherCleanSource = (sourceType) => (sourceType === SOURCE_TYPES.ANCESTRY ? '23andMe' : 'AncestryDNA');

const handleSourceClick = (dispatchAction, sourceType) => {
  registerGAClickEventEnhanced(EventCategories.UploadDna, EventActions.Click, EventLabels.Source(getCleanSource(sourceType)));
  dispatchAction(sourceType);
};

const handleInstructionsClick = (handleInstructionsAction, source) => {
  registerGAClickEventEnhanced(EventCategories.UploadDnaSource(getCleanSource(source)), EventActions.Click, EventLabels.Guide);
  handleInstructionsAction();
};

const handlePrivacySecurityClickFromSource = (source) => {
  registerGAClickEventEnhanced(EventCategories.UploadDnaSource(getCleanSource(source)), EventActions.Click, EventLabels.PrivacyAndSecurityLink);
};

const handlePrivacySecurityClickUpload = () => {
  registerGAClickEventEnhanced(EventCategories.UploadDna, EventActions.Click, EventLabels.PrivacyAndSecurityLink);
};

class UploadDna extends Component {
  switchSource() {
    const { source, selectSource: dispatchSelectSource } = this.props;
    if (source === SOURCE_TYPES.ANCESTRY) {
      dispatchSelectSource(SOURCE_TYPES.MEAND23);
    } else {
      dispatchSelectSource(SOURCE_TYPES.ANCESTRY);
    }
  }

  renderSourceSelector() {
    const {
      classes,
      selectSource: dispatchSelectSource,
    } = this.props;

    return (
      <PaperContainer style={{ marginTop: '-65px' }}>
        <StatusTrackerV2 productType="edna" activeStep={2} />
        <Grid container className={classes.container}>
          <Grid item xs={12} id="dnaUploadServiceTitle">
            <Typography className={classes.titleText}>
              Which service are you uploading your DNA from?
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <ButtonBase className={classes.button} onClick={() => handleSourceClick(dispatchSelectSource, SOURCE_TYPES.MEAND23)}>23andMe</ButtonBase>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <ButtonBase className={classes.button} onClick={() => handleSourceClick(dispatchSelectSource, SOURCE_TYPES.ANCESTRY)}>AncestryDNA</ButtonBase>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', marginTop: 15, justifyContent: 'center' }}>
            <Typography>
              <a style={{ color: colors.darkestGrey, fontWeight: 700 }} onClick={handlePrivacySecurityClickUpload} href="https://genopalate.com/pages/privacy-and-security" target="_blank" rel="noopener noreferrer">Read our Privacy and Security Summary </a>
              <FontAwesomeIcon icon={faExternalLink} />
            </Typography>
          </Grid>
        </Grid>
      </PaperContainer>
    );
  }

  render23andMeInstructions() {
    return (
      <div>
        <ol>
          <li>
            Please visit
            {' '}
            <a href="https://www.23andme.com" target="_blank" rel="noopener noreferrer">www.23andme.com</a>
          </li>
          <li>
            Login to your personal account with 23andMe
          </li>
          <li>
            Click on your name in the upper right-hand corner of your account
          </li>
          <li>
            Go to BROWSE RAW DATA
          </li>
          <li>
            Click DOWNLOAD
          </li>
          <li>
            Click DOWNLOAD RAW DATA
          </li>
          <li>
            Save the .zip file to your computer or phone storage
          </li>
          <li>
            Login to your GenoPalate account and click on the upload file icon
          </li>
          <li>
            Select the 23andMe Raw Data ZIP File on your device to upload it to your GenoPalate account
          </li>
        </ol>
      </div>
    );
  }

  renderAncestryInstructions() {
    return (
      <div>
        <ol>
          <li>
            Please visit
            {' '}
            <a href="https://www.ancestry.com" target="_blank" rel="noopener noreferrer">www.ancestry.com</a>
          </li>
          <li>
            Login to your personal account with AncestryDNA
          </li>
          <li>
            Under the &quot;DNA&quot; tab, click YOUR DNA RESULTS SUMMARY
          </li>
          <li>
            Click SETTINGS
          </li>
          <li>
            On the right side click DOWNLOAD RAW DNA DATA
          </li>
          <li>
            Enter your account password and click confirm
          </li>
          <li>
            You will receive an email (within an hour) when your DNA download is complete
          </li>
          <li>
            Open the email and click CONFIRM DATA DOWNLOAD. This will take you to a new page, &quot;Download DNA Raw Data&quot;
          </li>
          <li>
            Click DOWNLOAD DNA RAW DATA
          </li>
          <li>
            Save the .zip file to your computer or phone storage
          </li>
          <li>
            Login to your GenoPalate account and click on the upload file icon
          </li>
          <li>
            Select the AncestryDNA Raw Data ZIP File on your device to upload it to your GenoPalate account
          </li>
        </ol>
      </div>
    );
  }

  renderInstructions(source) {
    switch (source) {
      case SOURCE_TYPES.ANCESTRY: return this.renderAncestryInstructions();
      case SOURCE_TYPES.MEAND23: return this.render23andMeInstructions();
      default: return null;
    }
  }

  render() {
    const {
      classes,
      displayModal,
      displayInstructions,
      source,
      closeModal: dispatchCloseModal,
      openModal: dispatchOpenModal,
      openInstructions: dispatchOpenInstructions,
      closeInstructions: dispatchCloseInstructions,
    } = this.props;

    if (!source) {
      return this.renderSourceSelector();
    }

    const videoSource = source === SOURCE_TYPES.MEAND23 ? 'https://www.youtube.com/watch?v=MsQK5UEvpHM' : 'https://www.youtube.com/watch?v=GF8GuB6ztRA';

    const handleVideoClick = () => {
      registerGAClickEventEnhanced(`Upload DNA-${getCleanSource(source)}`, 'Click', 'Video');
      return true;
    };

    return (
      <PaperContainer style={{ marginTop: '-65px' }}>
        <StatusTrackerV2 productType="edna" activeStep={2} />
        <Grid container className={classes.container}>
          <Grid item xs={12} id="uploadInstructionsTitle">
            <Typography className={classes.titleText}>
              {getCleanSource(source)}
              {' '}
              Upload instructions
            </Typography>
            <Typography className={classes.switchText} onClick={() => this.switchSource()}>
              <FontAwesomeIcon icon={faArrowLeft} />
              {' '}
              Switch to
              {' '}
              {getOtherCleanSource(source)}
              {' '}
              instructions
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.cardWrapper}>
            <Paper className={classes.card} elevation={0} onClick={() => handleInstructionsClick(dispatchOpenInstructions, source)}>
              <FontAwesomeIcon icon={faBook} className={classes.cardIcon} />
              <Typography className={classes.cardText}>
                Read our step-by-step guide
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={6} className={classes.cardWrapper}>
            <a href={videoSource} onClick={handleVideoClick} target="_blank" rel="noreferrer">
              <Paper
                className={classes.card}
                elevation={0}
              >
                <FontAwesomeIcon icon={faVideo} className={classes.cardIcon} />
                <Typography className={classes.cardText}>
                  Watch our YouTube walk-through video
                </Typography>
              </Paper>
            </a>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography style={{ display: 'block', width: '100%' }}>
              <a
                className={classes.card}
                style={{
                  display: 'block', borderRadius: '4px', fontWeight: 700, color: colors.darkestGrey,
                }}
                href="https://genopalate.com/pages/privacy-and-security"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handlePrivacySecurityClickFromSource}
              >
                Read our Privacy and Security Summary
                {' '}
                <FontAwesomeIcon icon={faExternalLink} />
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', marginBottom: 10 }}>
            <ButtonBase className={classes.button} onClick={() => dispatchOpenModal()}>
              <FontAwesomeIcon icon={faUpload} />
              &nbsp;UPLOAD DNA
            </ButtonBase>
          </Grid>
          <Grid item xs={12}>
            <ContactSupport gaEvent={registerGAClickEventEnhanced(`Upload DNA-${getCleanSource(source)}`, 'Click', 'Contact Support')} />
          </Grid>
        </Grid>
        <UploadDnaModal open={displayModal} onClose={() => dispatchCloseModal()} />
        <CloseableDialog open={displayInstructions} onClose={() => dispatchCloseInstructions()}>
          <div className={classes.instructionsModal} id="uploadDnaInstructionsModal">
            <Typography style={{ fontWeight: 'bold' }}>
              Upload Instructions
            </Typography>
            {this.renderInstructions(source)}
            <ContactSupport />
          </div>
        </CloseableDialog>
      </PaperContainer>
    );
  }
}

UploadDna.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    titleText: PropTypes.string,
    cardText: PropTypes.string,
    cardIcon: PropTypes.string,
    card: PropTypes.string,
    button: PropTypes.string,
    cardWrapper: PropTypes.string,
    instructionsModal: PropTypes.string,
    switchText: PropTypes.string,
  }).isRequired,
  displayModal: PropTypes.bool.isRequired,
  displayInstructions: PropTypes.bool.isRequired,
  source: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openInstructions: PropTypes.func.isRequired,
  closeInstructions: PropTypes.func.isRequired,
  selectSource: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    displayModal: state.dnaUpload.displayModal,
    displayInstructions: state.dnaUpload.displayInstructions,
    source: state.dnaUpload.source,
  }),
  {
    openModal,
    closeModal,
    openInstructions,
    closeInstructions,
    selectSource,
  },
)(withStyles(styles)(UploadDna));
