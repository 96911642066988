import { createStyles, makeStyles } from '@material-ui/core'
import { colors, fonts } from 'common/assets/sharedUI'
export const useStyles = makeStyles((theme) => createStyles({
    backLink: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '10 0 28 0px',
    },
    backLinkText: {
        fontFamily: fonts.raleway,
        color: colors.gray_05,
        width: 'fit-content',
        margin: '0 0 0 8px',
        fontSize: 16,
        fontWeight: 400,
    },
    icon: {
        color: colors.gray_05,
        width: 16,
    },
    productOuterWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
    },
    responsiveWrapper: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    subscriptionImage: {
        backgroundColor: colors.green_01,
        width: '100%',
        height: '100%',
        borderRadius: 3,
    },
    productDetailsWrapper: {
        maxWidth: 480,
        width: '100%',
        [theme.breakpoints.down('md')]: {
            margin: 0,
        },
    },
    detailsContainer: {
    },
    subscriptionTitle: {
        fontFamily: fonts.BaskervilleBold,
        color: colors.gray_06,
        fontSize: 32,
        lineHeight: '32px',
        marginBottom: 10,
    },
    subscriptionDescription: {
        fontFamily: fonts.raleway,
        color: colors.gray_06,
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        marginBottom: 18,
    },
    nonSubscriptionPrice: {
        fontFamily: fonts.raleway,
        color: colors.blue_03,
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '32px',
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        },
    },
    formControlOneTime: {
        paddingLeft: 8,
        paddingBottom: 15,
        paddingTop: 15,
        color: colors.gray_06,
        marginLeft: 0,
    },
    selectedRadio: {
        backgroundColor: colors.grey_01,
        width: '100%',
    },
    formControlSubscriptionContainer: {
        paddingTop: 16,
        paddingLeft: 19,
        paddingBottom: 30,
        marginBottom: 15
    },
    dropDownPicker: {
        marginLeft: 37,
        marginRight: 20,
        borderWidth: 1,
        border: `1px solid ${colors.gray_03}`,
        color: colors.green_07,
        paddingLeft: 10,
        fontSize: 14
    },
    dropDownItem: {
        color: colors.green_07,
        fontSize: 14
    },
    checkoutContainer: {
        marginTop: 45
    },
    counterContainer: {
        borderWidth: 1,
        border: `1px solid ${colors.gray_03}`,
        width: 91,
        height: 55,
        paddingLeft: 12, 
        paddingRight: 12,
    },
    counterButton: {
        display: 'inline-block',
        padding: 0,
        minHeight: 0,
        minWidth: 0,
        color: colors.green_07,
        fontSize: 20
    },
    addToCartButton: {
        backgroundColor: colors.green_07,
        color: colors.white,
        width: '100%',
        // height: 45,
        '&:hover': {
            backgroundColor: colors.green_05,
        },
        padding: 10
    },
    addedToCartButton: {
        backgroundColor: colors.green_05,
        color: colors.white,
        width: '100%',
        // height: 45,
        '&:disabled': {
            backgroundColor: colors.buttonDisabled,
            color: colors.buttonDisabledText,
            borderColor: colors.buttonDisabledBorder,
          },
          padding: 10,
        [theme.breakpoints.down('xs')]: {
            padding: '16px 30px',
        },
    },
    subHeading: {
        fontSize: 15,
        color: colors.gray_06,
        marginTop: 20,
        textTransform: 'uppercase',
        fontWeight: '600'
    },
    subheadingDescription: {
        fontSize: 11,
        color: colors.gray_07,
        marginTop: 5,
        width: '100%',
    },
    flavorDropDownPicker: {
        borderWidth: 1,
        border: `1px solid ${colors.gray_03}`,
        color: colors.green_07,
        paddingLeft: 10,
        fontSize: 14,
        width: '100%',
        // marginTop: 10,
        
          // Add styles for the error state
        '&.Mui-error': {
            borderWidth: 1,
            border: `1px solid ${colors.red}`,
      },
    },

    errorInput : {
        borderWidth: 1,
        border: `1px solid ${colors.red}`,
    },

    formControl: {
      width: '100%',
    },
    introductoryPriceBox: {
      width: '95%',
      background: `${colors.orange_02} 0% 0% no-repeat padding-box`,
      borderRadius: '3px',
      padding: '4px',
      marginBottom: '5px',
    },
    introductoryHeading: {
      fontFamily: fonts.paragraph2,
      fontSize: '16px',
      lineHeight: '19px',
      color: colors.gray_06,
      fontWeight: 600,
    },
    introductorySubtext: {
      fontFamily: fonts.paragraph2,
      fontSize: '13px',
      lineHeight: '16px',
      color: colors.gray_06,
      fontStyle: 'italic',
      fontWeight: 'lighter',
    },
  })
);
