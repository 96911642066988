import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid, Typography,
} from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import useStyles from './HighlightDescription.styles'

export default function HighlightDescription(props) {
  const {
    title, recommendationText, color, copy,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.descriptionContainer}>
      <FiberManualRecordIcon className={classes.icon} style={{ color }} />
      <div>
        <Typography className={classes.title}>{title}</Typography>
        <Typography variant="body2">
          <b>{recommendationText}</b>
          {copy}
        </Typography>
      </div>
    </div>
  )
}

HighlightDescription.propTypes = {
  title: PropTypes.string.isRequired,
  recommendationText: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
}
