import actionTypes from "../actionTypes"

// initial state
const initialState = {
  enabledFeatures: [],
}

const featureReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENABLE_FEATURE:
      return {
        ...state,
        enabledFeatures: [...state.enabledFeatures, action.payload],
      }
    case actionTypes.DISABLE_FEATURE:
      return {
        ...state,
        enabledFeatures: state.enabledFeatures.filter(
          (feature) => feature !== action.payload
        ),
      }
    default:
      return state
  }
}


export default featureReducer