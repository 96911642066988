import { axios } from 'utils';
import { constants } from 'resources';

export default async function uploadDnaResult(file, progress = () => {} ) {
  const headers = { 'Content-Type': 'multipart/form-data' };

  const params = new FormData();
  params.append('file', file);

  const onUploadProgress = progressEvent => {
    var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
    progress(percentCompleted);
  }

  return axios.post(constants.API_UPLOAD_DNA_RESULT, params, { headers, onUploadProgress });
}
