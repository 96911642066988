/* eslint-disable class-methods-use-this */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  ButtonBase, Typography,
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'
import { images } from 'resources'
import { selectFile, clearFile, uploadFile } from 'store/actions/dnaUpload'
import UploadDnaDropzone from './UploadDnaDropzone'
import CloseableDialog from './CloseableDialog'

const styles = {
  modalContainer: {
    padding: 20,
    width: 340,
    textAlign: 'center',
  },
  readyToUpload: {
    textAlign: 'center',
    width: '100%',
  },
  button: {
    width: 200,
    backgroundColor: colors.blue_05,
    borderRadius: 10,
    fontSize: 22,
    color: 'white',
    padding: 10,
  },
  uploadDnaImage: {
    width: 200,
    margin: '30px auto',
    display: 'block',
  },
  errorText: {
    color: colors.red,
    fontSize: 12,
  },
}

class UploadDnaModal extends Component {
  handleOpen(file) {
    const { selectFile: dispatchSelectFile } = this.props
    dispatchSelectFile(file)
  }

  onClose() {
    const { onClose, clearFile: dispatchClearFile } = this.props
    dispatchClearFile()
    onClose()
  }

  onUploadClick() {
    const { uploadFile: dispatchUploadFile, file } = this.props
    dispatchUploadFile(file)
  }

  getErrorMessage() {
    const { error, errorCode, errorParams } = this.props
    switch (errorCode) {
    case 433: return (
      <Fragment>
        The file you are attempting to upload is a
        {' '}
        <b>{errorParams}</b>
        {' '}
        file type.
        We only accept DNA files in the form of compressed
        {' '}
        <b>.zip</b>
        {' '}
        or
        {' '}
        <b>.txt</b>
        {' '}
        files. Please check to make sure you are uploading the correct file in the correct format. For more help,
        please visit our account registration
        {' '}
        <a href="https://www.genopalate.com/pages/faqs" target="_blank" rel="noreferrer">FAQs</a>
        .
      </Fragment>
    )
    case 437: return (
      <Fragment>
        DNA .zip file must be smaller than 15MB. Please check to make sure you are uploading the correct file in the correct format. For more help,
        please visit our account registration
        {' '}
        <a href="https://www.genopalate.com/pages/faqs" target="_blank" rel="noreferrer">FAQs</a>
        .
      </Fragment>
    )
    case 438: return (
      <Fragment>
        DNA .zip file must be greater than 1MB. Please check to make sure you are uploading the correct file in the correct format. For more help,
        please visit our account registration
        {' '}
        <a href="https://www.genopalate.com/pages/faqs" target="_blank" rel="noreferrer">FAQs</a>
        .
      </Fragment>
    )
    case 442: return (
      <Fragment>
        DNA .txt file must be smaller than 80MB. Please check to make sure you are uploading the correct file in the correct format. For more help,
        please visit our account registration
        {' '}
        <a href="https://www.genopalate.com/pages/faqs" target="_blank" rel="noreferrer">FAQs</a>
        .
      </Fragment>
    )
    case 443: return (
      <Fragment>
        DNA .txt file must be greater than 6MB. Please check to make sure you are uploading the correct file in the correct format. For more help,
        please visit our account registration
        {' '}
        <a href="https://www.genopalate.com/pages/faqs" target="_blank" rel="noreferrer">FAQs</a>
        .
      </Fragment>
    )
    case 439: return (
      <Fragment>
        The file you are attempting to upload is not a valid DNA file.
        Please check to make sure you are uploading the correct
        {' '}
        <b>.zip</b>
        {' '}
        or
        {' '}
        <b>.txt</b>
        {' '}
        file. For more help, please visit our account registration
        {' '}
        <a href="https://www.genopalate.com/pages/faqs" target="_blank" rel="noreferrer"> FAQs</a>
        .
      </Fragment>
    )
    default: return (
      <Fragment>{error}</Fragment>
    )
    }
  }

  renderReadyToUpload(file) {
    const { classes } = this.props
    return (
      <div className={classes.readyToUpload}>
        <Typography style={{ fontWeight: 'bold' }}>Ready to upload!</Typography>
        <Typography>You have selected:</Typography>
        <Typography style={{ fontWeight: 'bold' }}>{file.name}</Typography>
        <Typography style={{ margin: '20px 0' }}>Click the upload button to start the uploading process!</Typography>
        <ButtonBase className={classes.button} onClick={() => this.onUploadClick()}>UPLOAD</ButtonBase>
      </div>
    )
  }

  renderUploadProgress() {
    const { progress } = this.props
    return (
      <Fragment>
        <Typography>Uploading...</Typography>
        <LinearProgress variant="determinate" value={progress} />
      </Fragment>
    )
  }

  render() {
    const {
      open, classes, file, error, isUploading,
    } = this.props
    return (
      <CloseableDialog open={open} onClose={() => this.onClose()}>
        <div className={classes.modalContainer} id="uploadDnaModal">
          <img src={images.uploadDna} className={classes.uploadDnaImage} alt="" />
          {!file && !isUploading && (
            <Fragment>
              {error && <Typography className={classes.errorText}>{this.getErrorMessage()}</Typography>}
              <UploadDnaDropzone handleOpen={(newFile) => this.handleOpen(newFile)} />
            </Fragment>
          )}
          {file && !isUploading && this.renderReadyToUpload(file)}
          {isUploading && this.renderUploadProgress()}
        </div>
      </CloseableDialog>
    )
  }
}

UploadDnaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.any,
  error: PropTypes.string,
  errorCode: PropTypes.number,
  errorParams: PropTypes.string,
  isUploading: PropTypes.bool.isRequired,
  progress: PropTypes.number,
  selectFile: PropTypes.func.isRequired,
  clearFile: PropTypes.func.isRequired,
  uploadFile: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    modalContainer: PropTypes.string,
    readyToUpload: PropTypes.string,
    button: PropTypes.string,
    uploadDnaImage: PropTypes.string,
    errorText: PropTypes.string,
  }).isRequired,
}

UploadDnaModal.defaultProps = {
  error: null,
  errorCode: null,
  errorParams: null,
  file: null,
  progress: 0,
}

export default connect(
  state => ({
    file: state.dnaUpload.file,
    error: state.dnaUpload.error,
    errorCode: state.dnaUpload.errorCode,
    errorParams: state.dnaUpload.errorParams,
    isUploading: state.dnaUpload.isUploading,
    progress: state.dnaUpload.progress,
  }),
  {
    selectFile,
    clearFile,
    uploadFile,
  },
)(withStyles(styles)(UploadDnaModal))
