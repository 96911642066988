import { user } from 'services'
import types from '../actionTypes'

export const getProfileStarted = () => ({ type: types.GET_PROFILE_STARTED })

export const getProfileSuccess = payload => ({ type: types.GET_PROFILE_SUCCESS, payload })

export const getProfileFailure = payload => ({ type: types.GET_PROFILE_ERROR, payload })

export const getProfile = () => async dispatch => {
  dispatch(getProfileStarted())
  try {
    const response = await user.getProfile()
    dispatch(getProfileSuccess(response))
  } catch (error) {
    dispatch(getProfileFailure(error))
  }
}

export const updateProfileStarted = () => ({ type: types.UPDATE_PROFILE_STARTED })

export const updateProfileSuccess = payload => ({ type: types.UPDATE_PROFILE_SUCCESS, payload })

export const updateProfileFailure = payload => ({ type: types.UPDATE_PROFILE_ERROR, payload })

export const updateProfile = (email, name, surname) => async (dispatch) => {
  dispatch(updateProfileStarted())
  user.updateProfile(email, name, surname)
    .then(response => {
      dispatch(updateProfileSuccess(response))
    })
    .catch(error => {
      dispatch(updateProfileFailure(error))
    });
};