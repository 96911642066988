import { withStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import { colors } from 'common/assets/sharedUI'

const CustomLinearProgress = withStyles({
  barColorSecondary: {
    color: colors.chartRed,
  },
  bar1Buffer: {
    backgroundColor: colors.blue,
  },
})(LinearProgress)

export default CustomLinearProgress
