import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles({
  questionContainer: {
    display: 'flex',
    flexFlow: 'row',
  },
  questionLabel: {
    alignSelf: 'center',
    marginTop: 15,
    fontSize: '0.95rem',
  },
  labelContainer: {
    height: 50,
    display: 'flex',
    flexFlow: 'row',
  },
  centerSpan: {
    alignSelf: 'center',
    marginRight: 30,
    marginLeft: 5,
    color: colors.darkGrey,
    fontSize: '0.95rem',
  },
  feetInp: {
    width: 80,
    marginTop: 5,
  },
  inchesInp: {
    width: 80,
    marginTop: 5,
  },
})

export default useStyles
