import { user, auth } from '../../services';
import types from '../actionTypes';

export const login = (userId) => ({
  type: types.LOGIN,
  payload: userId,
});

export const clearStore = () => ({
  type: types.CLEAR_STORE,
});

export const updatePassword = (currentPassword, newPassword) => async (dispatch) => {
  try {
    dispatch({ type: types.UPDATE_PASSWORD_STARTED });
    await user.updatePassword(currentPassword, newPassword);
    dispatch({ type: types.UPDATE_PASSWORD_SUCCESS });
  } catch (error) {
    dispatch({ type: types.UPDATE_PASSWORD_ERROR, error });
  }
};

export const resetUpdatePasswordState = () => ({ type: types.UPDATE_PASSWORD_RESET });

export const resetPassword = (forgotKey, newPassword) => async (dispatch) => {
  try {
    dispatch({ type: types.RESET_PASSWORD_STARTED });
    await auth.changePassword(forgotKey, newPassword);
    dispatch({ type: types.RESET_PASSWORD_SUCCESS });
  } catch (error) {
    dispatch({ type: types.RESET_PASSWORD_ERROR, error });
  }
};

const getHealthieUrlStarted = () => ({ type: types.GET_HEALTHIE_URL_STARTED });
const getHealthieUrlSuccess = url => ({ type: types.GET_HEALTHIE_URL_SUCCESS, url });
const getHealthieUrlError = error => ({ type: types.GET_HEALTHIE_URL_ERROR, error });

export const getHealthieUrl = () => dispatch => {
  dispatch(getHealthieUrlStarted());
  user.getHealthieUrl()
    .then(response => {
      const url = response.data && response.data.url ? response.data.url : null;
      dispatch(getHealthieUrlSuccess(url));
    })
    .catch(error => {
      dispatch(getHealthieUrlError(error));
    });
};

export const deleteAccount = () => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_ACCOUNT_STARTED });
    await user.deleteAccount();
    dispatch({ type: types.DELETE_ACCOUNT_SUCCESS });
  } catch (error) {
    dispatch({ type: types.DELETE_ACCOUNT_ERROR, error });
  }
};
