import React from 'react';
import { Link } from 'react-router-dom';
import {
  Typography, Card, CardHeader, CardContent,
} from '@material-ui/core';
import useStyles from './TopFood.styles';
import { registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels } from '../utils/googleanalytics';

export default function TopFood(props) {
  const classes = useStyles();
  const {
    id, name, image, foodName, eventName,
  } = props;

  return (
    <Link to={`/food/${id}`} key={id} onClick={() => registerGAClickEventEnhanced(EventCategories.Home, EventActions.Click, EventLabels.TopFood(eventName))}>
      <Card className={classes.topFoodsCard}>
        <CardHeader
          titleTypographyProps={{ variant: 'button', noWrap: true, className: classes.title }}
          className={classes.topFoodsCardHeader}
          title={`Top ${name}`}
        />
        <CardContent>
          <div className={classes.topFoodsContainer}>
            <img className={classes.topFoodsImg} src={`https://geno-icons.s3.amazonaws.com/${image}`} alt={foodName} />
          </div>
          <Typography variant="caption" align="left">
            {foodName}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}
