import { axios } from '../../utils';
import { constants } from '../../resources';

export default async ({ token }) => {
  const params = { token };

  const { data } = await axios.get(constants.API_AUTH_ACCOUNT_SHELL, { params });

  return data;
};
