import React, {
  useState, useCallback, useRef,
} from 'react';
import { images } from 'resources';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as faUserSolid } from '@fortawesome/free-solid-svg-icons';
import { faUser as faUserOutlined } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { colors } from 'common/assets/sharedUI';
import { useDispatch, useSelector } from 'react-redux';
import { clearStore } from '../store/actions/user';
import * as flows from '../flows';
import UserStatusBanner from './UserStatusBanner';
import constants from '../resources/constants';

const { userStatuses } = constants;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
    position: 'sticky',
    width: '100%',
    backgroundColor: 'white',
    zIndex: 1000,
  },
  spacer: {
    width: 64,
  },
  menuButton: {
    color: colors.primaryBlue,
    height: 26,
  },
  menuIcon: {
    height: 24,
    flex: 1,
  },
};

const OnboardingToolbar = ({
  classes, topPosition, smallLogo, status,
}) => {
  const menuRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [menuVisible, setMenuVisible] = useState(false);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    setMenuVisible(true);
  }, [setMenuVisible]);

  const handleClose = useCallback(() => {
    setMenuVisible(false);
  }, [setMenuVisible]);

  const onAccountClick = useCallback((e) => {
    setMenuVisible(false);
    e.preventDefault();
    history.push('/account');
  }, [setMenuVisible, history]);

  const onLogout = useCallback(() => {
    setMenuVisible(false);
    flows.logout();
    dispatch(clearStore());
  }, [setMenuVisible, dispatch]);

  const registrationNextStepClicked = useSelector(state => state.registration.registrationNextStep);
  const registerBarcodeClicked = useSelector(state => state.registration.registerBarcodeClicked);
  const { currentQuestionId } = useSelector(state => state.questionnaire);

  let userBannerMessage = null;
  let userBannerId = null;
  if (status) {
    if (status.webDisplay === userStatuses.NEW_BARCODE_REPORT_IN_DEVELOPMENT) {
      userBannerMessage = registrationNextStepClicked ? 'All Set ' : 'Your Kit is registered ';
      userBannerId = registrationNextStepClicked ? 'onboardingStatusHeaderAllSet' : 'onboardingStatusHeaderKitRegistered';
    } else if (status.webDisplay === userStatuses.BEGIN_QUESTIONNAIRE_EXISTING && currentQuestionId < 1) {
      userBannerMessage = 'Your DNA has been uploaded ';
      userBannerId = 'onboardingStatusHeaderDnaUploadedSuccessfully';
    } else if ((status.webDisplay === userStatuses.SHOULD_UPLOAD_DNA
        || status.webDisplay === userStatuses.BEGIN_QUESTIONNAIRE_KIT) && currentQuestionId < 1) {
      userBannerMessage = 'Your account has been created! ';
      userBannerId = 'onboardingStatusHeaderAccountCreated';
    } else if (status.webDisplay === userStatuses.CONSENT_TO_RESEARCH_EXISTING
        || status.webDisplay === userStatuses.CONSENT_TO_RESEARCH_AND_BIOBANK_NEW) {
      userBannerMessage = 'Health questionnaire complete ';
      userBannerId = 'onboardingStatusHeaderHealthQuestionnaireComplete';
    } else if (status.webDisplay === userStatuses.REPORT_IN_DEVELOPMENT
        || (status.webDisplay === userStatuses.SHOULD_UPLOAD_BARCODE_NEW && !registerBarcodeClicked)) {
      userBannerMessage = 'Research consent complete ';
      userBannerId = 'onboardingStatusHeaderUserResearchComplete';
    }
  }
  const userBannerVisible = !!userBannerMessage;
  return (
    <div style={{ top: topPosition, position: 'sticky', zIndex: '1000' }}>
      {userBannerVisible
      && (
        <UserStatusBanner
          status={status}
          message={userBannerMessage}
          dataStatus={userBannerId}
        />
      )}
      <div className={classes.container}>

        <div className={classes.spacer} />
        <img src={images.logoNoTagline} alt="GenoPalate" style={{ width: smallLogo ? 100 : 120 }} />
        <Button onClick={handleClick} ref={menuRef} className={classes.menuButton}>
          <FontAwesomeIcon icon={menuVisible ? faUserSolid : faUserOutlined} className={classes.menuIcon} />
        </Button>
        <Menu
          id="onboarding-toolbar"
          anchorEl={menuRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          keepMounted
          open={menuVisible}
          onClose={handleClose}
          style={{ zIndex: 10000 }}
        >
          <MenuItem onClick={onAccountClick}>Account</MenuItem>
          <MenuItem onClick={onLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

OnboardingToolbar.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    spacer: PropTypes.string,
    logo: PropTypes.string,
    menuButton: PropTypes.string,
    menuIcon: PropTypes.string,
  }).isRequired,
  topPosition: PropTypes.number.isRequired,
  smallLogo: PropTypes.bool.isRequired,
  status: PropTypes.shape({
    webDisplay: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(OnboardingToolbar);
