import getProduct from './getProduct';
import updatePassword from './updatePassword';
import getProfile from './getProfile';
import updateProfile from './updateProfile';
import setFoodVisibility from './setFoodVisibility';
import runReport from './runReport';
import getTopFoods from './getTopFoods';
import getFoodScores from './getFoodScores';
import setTermsAgreement from './setTermsAgreement';
import getStatus from './getStatus';
import setBannerEvent from './setBannerEvent';
import getShowMobileDownloadBanner from './getShowMobileDownloadBanner';
import getShowSupplementBanner from './getShowSupplementBanner';
import getPreferences from './getPreferences';
import setPreference from './setPreference';
import getHealthieUrl from './getHealthieUrl';
import deleteAccountConfirmation from './deleteAccountConfirmation';
import deleteAccount from './deleteAccount';

export default {
  getProduct,
  updatePassword,
  getProfile,
  updateProfile,
  setTermsAgreement,
  runReport,
  getTopFoods,
  getFoodScores,
  setFoodVisibility,
  getStatus,
  setBannerEvent,
  getShowSupplementBanner,
  getShowMobileDownloadBanner,
  getPreferences,
  setPreference,
  getHealthieUrl,
  deleteAccountConfirmation,
  deleteAccount,
};
