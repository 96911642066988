import * as React from 'react'
import { Route } from 'react-router-dom'
import { BaseContainer } from 'components'

export default ({ component: Component, additionalProps, ...props }) => {
  return (
    <Route
      {...props}
      render={renderProps => (
        <BaseContainer>
          <Component {...renderProps} {...additionalProps} />
        </BaseContainer>
      )
      }
    />
  )
}
