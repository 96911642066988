import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: 560,
    padding: '40 20',
    margin: '100 0 50',
    background: `${colors.white} 0% 0% no-repeat padding-box`,
    boxShadow: '0px 3px 20px #00000029',
    borderRadius: 10,
    opacity: 1,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  description: {
    color: colors.darkGrey,
    marginTop: 10,
    marginBottom: 40,
  },
}))

export default useStyles
