import { combineReducers } from 'redux'
import types from '../actionTypes'
import getStatusReducer from './statusReducer'

function errorReducer(state = null, action) {
  switch (action.type) {
    case types.SELECT_ACTION_ITEMS_ERROR:
      return action.payload.error
    default:
      return state
  }
}

function domainActionItemsReducer(state = null, action) {
  switch (action.type) {
    case types.SELECT_ACTION_ITEMS_SUCCESS:
      // return action.payload?.data ?? [] // nullish coalescing and option chaining (edit: WOULD BE NICE!!!)
      return action.payload ? action.payload.data : []
    default:
      return state
  }
}

const actionTypes = {
  pending: types.SELECT_ACTION_ITEMS_STARTED,
  resolved: types.SELECT_ACTION_ITEMS_SUCCESS,
  error: types.SELECT_ACTION_ITEMS_ERROR,
}

export default combineReducers({
  status: getStatusReducer(actionTypes),
  domainActionItems: domainActionItemsReducer,
  error: errorReducer,
})
