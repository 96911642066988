const EssentialNutritionTraits = {
  TotalFat: 'Total Fat',
  Omega3: 'Omega-3 Fatty Acids',
  Omega6: 'Omega-6 Fatty Acids',
  MonounsaturatedFat: 'Monounsaturated Fatty Acids',
  SaturatedFat: 'Saturated Fat',
  TotalCarbohydrates: 'Total Carbohydrates',
  Sugar: 'Sugar',
  Fiber: 'Fiber',
  Protein: 'Protein',
  Calcium: 'Calcium',
  Iron: 'Iron',
  Magnesium: 'Magnesium',
  Potassium: 'Potassium',
  Selenium: 'Selenium',
  Sodium: 'Sodium',
  Zinc: 'Zinc',
  Folate: 'Folate',
  VitaminA: 'Vitamin A',
  VitaminB6: 'Vitamin B6',
  VitaminB12: 'Vitamin B12',
  VitaminC: 'Vitamin C',
  VitaminD: 'Vitamin D',
  VitaminE: 'Vitamin E',
  Alcohol: 'Alcohol',
  Gluten: 'Gluten',
  Lactose: 'Lactose',
  Caffeine: 'Caffeine',
}

export const formatMacroMixData = (data) => {
  if (!data) return []

  const macroObject = Object.values(data).reduce((acc, current) => {
    acc[current.traitName] = { ...current }
    return acc
  }, {})

  const nutritionTraits = [
    { ...macroObject[EssentialNutritionTraits.TotalFat], level: 0 },
    { ...macroObject[EssentialNutritionTraits.MonounsaturatedFat], level: 1 },
    { ...macroObject[EssentialNutritionTraits.Omega3], level: 1 },
    { ...macroObject[EssentialNutritionTraits.Omega6], level: 1 },
    { ...macroObject[EssentialNutritionTraits.SaturatedFat], level: 1 },

    { ...macroObject[EssentialNutritionTraits.TotalCarbohydrates], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Fiber], level: 1 },
    { ...macroObject[EssentialNutritionTraits.Sugar], level: 1 },

    { ...macroObject[EssentialNutritionTraits.Protein], level: 0 },
  ]

  return nutritionTraits
}

export const formatMicroMixData = (data) => {
  if (!data) return []

  const macroObject = Object.values(data).reduce((acc, current) => {
    acc[current.traitName] = { ...current }
    return acc
  }, {})

  const nutritionTraits = [
    { ...macroObject[EssentialNutritionTraits.Calcium], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Iron], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Magnesium], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Potassium], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Selenium], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Sodium], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Zinc], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Folate], level: 0 },
    { ...macroObject[EssentialNutritionTraits.VitaminA], level: 0 },
    { ...macroObject[EssentialNutritionTraits.VitaminB6], level: 0 },
    { ...macroObject[EssentialNutritionTraits.VitaminB12], level: 0 },
    { ...macroObject[EssentialNutritionTraits.VitaminC], level: 0 },
    { ...macroObject[EssentialNutritionTraits.VitaminD], level: 0 },
    { ...macroObject[EssentialNutritionTraits.VitaminE], level: 0 },
  ]

  return nutritionTraits
}

export const formatSensitivitiesAndSubstanceData = (data) => {
  if (!data) return []

  const macroObject = Object.values(data).reduce((acc, current) => {
    acc[current.traitName] = { ...current }
    return acc
  }, {})

  const nutritionTraits = [
    { ...macroObject[EssentialNutritionTraits.Alcohol], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Caffeine], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Gluten], level: 0 },
    { ...macroObject[EssentialNutritionTraits.Lactose], level: 0 },
  ]

  return nutritionTraits
}
