import get from 'lodash/get';
import types from '../actionTypes';
import { apiStatuses } from '../../resources/constants';

const defaultState = {
  userId: null,
  updatingPassword: false,
  passwordUpdated: false,
  updatePasswordError: '',
  resettingPassword: false,
  passwordReset: false,
  resetPasswordError: '',
  healthieUrl: null,
  healthieUrlStatus: apiStatuses.IDLE,
  healthieUrlError: null,
  deletingAccount: false,
  accountDeleted: false,
  accountDeletedError: '',
};

function userReducer(state = defaultState, action) {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        userId: action.payload,
      };
    case types.UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        updatePasswordError: get(action, 'error.response.data.message', 'An unknown error occurred.'),
        updatingPassword: false,
      };
    case types.UPDATE_PASSWORD_STARTED:
      return {
        ...state,
        updatingPassword: true,
        passwordUpdated: false,
        updatePasswordError: '',
      };
    case types.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatingPassword: false,
        passwordUpdated: true,
      };
    case types.UPDATE_PASSWORD_RESET:
      return {
        ...state,
        updatingPassword: false,
        passwordUpdated: false,
        updatePasswordError: '',
      };
    case types.RESET_PASSWORD_STARTED:
      return {
        ...state,
        resettingPassword: true,
        passwordReset: false,
        resetPasswordError: '',
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resettingPassword: false,
        passwordReset: true,
      };
    case types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resettingPassword: false,
        resetPasswordError: get(action, 'error.response.data.message', 'An unknown error occurred.'),
      };
    case types.GET_HEALTHIE_URL_STARTED:
      return {
        ...state,
        healthieUrlStatus: apiStatuses.PENDING,
      };
    case types.GET_HEALTHIE_URL_SUCCESS:
      return {
        ...state,
        healthieUrl: action.url,
        healthieUrlStatus: apiStatuses.RESOLVED,
      };
    case types.GET_HEALTHIE_URL_ERROR:
      return {
        ...state,
        healthieUrlStatus: apiStatuses.REJECTED,
        healthieUrlError: action.error,
      };
    case types.DELETE_ACCOUNT_STARTED:
      return {
        ...state,
        deletingAccount: true,
        accountDeleted: false,
        accountDeletedError: '',
      };
    case types.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deletingAccount: false,
        accountDeleted: true,
      };
    case types.DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        accountDeletedError: get(action, 'error.response.data.message', 'An unknown error occurred.'),
        deletingAccount: false,
      };
    default:
      return state;
  }
}

export default userReducer;
