import React, { PureComponent } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { capitalize } from 'lodash'

type Props = {}
class WelcomeMessage extends PureComponent<Props> {
  render() {
    const { name, title } = this.props
    const welcomeText = `Welcome, ${capitalize(name)}!`
    return (
      <Grid item xs={12}>
        <div style={{ padding: 20 }}>
          <Typography name="welcomeText" variant="h3" align="left" gutterBottom>
            {title || welcomeText}
          </Typography>
        </div>
      </Grid>
    )
  }
}

export default WelcomeMessage
