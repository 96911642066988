import React from 'react'
import {
  Grid, Typography,
} from '@material-ui/core'

const NotFoundPage = () => (
  <Grid container spacing={0}>
    <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
      <div style={{ padding: 20 }}>
        <Typography variant="h3" align="left" gutterBottom style={{ textAlign: 'center' }}>404</Typography>
        <Typography variant="h3" align="left" gutterBottom>Page not found</Typography>
      </div>
    </Grid>
  </Grid>
)

export default NotFoundPage
