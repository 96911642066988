import React, { PureComponent } from 'react';
import {
  Typography, Grid, Card, CardContent, CardHeader, Hidden, CardActionArea, Divider,
} from '@material-ui/core';
import { startCase } from 'lodash';
import { PieChart, Pie, Cell } from 'recharts';
import GeneModal from 'components/GeneModal';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';
import { registerGAClickEventEnhanced, EventActions } from '../utils/googleanalytics';

const styles = {
  bold: {
    fontWeight: 'bold',
  },
  card: {
    marginBottom: 20,
  },
  title: {
    fontSize: 20,
  },
  cardHeader: {
    color: colors.white,
    height: 60,
  },
  container: {
    padding: 20,
  },
  prevalenceLegend: {
    marginTop: 10,
  },
  geneDescription: {
    marginTop: 20,
  },
  variantTitle: {
    marginBottom: 20,
  },
  divider: {
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },
};

class GeneCard extends PureComponent<Props> {
  state = {
    open: false,
  }

  handleCloseModal = () => {
    this.setState({ open: false }, () => {
      this.setState({ open: false });
    });
  }

  handleOpenModal = (geneName) => {
    const { traitName } = this.props;
    registerGAClickEventEnhanced(`Nutrient-${startCase(traitName)}`, EventActions.Open, geneName);
    this.setState({ open: true });
  }

  render() {
    const { open } = this.state;
    const {
      classes, title, gene, markers, titleColor, traitName,
    } = this.props;
    const COLORS = [colors.green, colors.grey];
    const markersLength = markers.length;

    return (
      <Card className={classes.card}>
        <CardActionArea onClick={() => this.handleOpenModal(title)}>
          <CardHeader
            titleTypographyProps={{ variant: 'button', noWrap: true, className: classes.title }}
            className={classes.cardHeader}
            style={{ backgroundColor: titleColor }}
            title={`${title} GENE`}
          />
          <CardContent>
            <Grid container spacing={2} className={classes.container}>
              {!!markers && markers.length > 0 ? (
                markers.map((marker, index) => {
                  if (marker.genotype) {
                    const prevalence = Math.round(marker.prevalence);
                    const pieData = [{ value: prevalence / 10 }, { value: 10 - (prevalence / 10) }];

                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid container item xs={12} sm={12} md={5} lg={5} key={`${gene.geneName}-${index}`}>
                        {index > 0 ? <Hidden mdUp><Divider className={classes.divider} /></Hidden> : <div />}
                        {
                          markersLength > 1 ? (
                            <Grid item xs={12} className={classes.variantTitle}>
                              <Typography variant="h6" className={classes.bold}>{`Variant ${index + 1}`}</Typography>
                            </Grid>
                          ) : (
                            <div />
                          )
                        }
                        <Grid item xs={5} sm={7} md={7} lg={7}>
                          <Typography variant="subtitle1" className={classes.bold}>YOUR RESULT:</Typography>
                          <Typography variant="subtitle1" className={classes.bold} style={{ color: titleColor, fontSize: '1.25rem' }}>{`${marker.genotype} GENOTYPE`}</Typography>
                          <Typography variant="subtitle1" className={classes.prevalenceLegend}>{`${prevalence}% of the population shares your genotype`}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <PieChart height={150} width={150}>
                            <text x={83} y={74} dy={8} textAnchor="middle" fill={colors.black}>{marker.genotype}</text>
                            <text x={85} y={88} dy={8} textAnchor="middle" fill={colors.black}>{`${prevalence}%`}</text>
                            <Pie
                              data={pieData}
                              cx={80}
                              cy={80}
                              innerRadius={50}
                              outerRadius={60}
                            >
                              {
                                pieData.map((entry, pieIndex) => <Cell key={`gene-${gene.geneName}`} fill={COLORS[pieIndex % COLORS.length]} />)
                              }
                            </Pie>
                          </PieChart>
                        </Grid>
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      {index > 0 ? <Hidden mdUp><Divider className={classes.divider} /></Hidden> : <div />}
                      {
                        markersLength > 1 ? (
                          <Grid item xs={12} className={classes.variantTitle}>
                            <Typography variant="h6" className={classes.bold}>{`Variant ${index + 1}`}</Typography>
                          </Grid>
                        ) : (
                          <div />
                        )
                      }
                      <Typography variant="subtitle1" className={classes.bold}>YOUR RESULT: -- </Typography>
                    </Grid>
                  );
                })
              ) : (
                <div />
              )}
              <Hidden smDown>
                <Grid item xs={markersLength % 2 === 1 ? 7 : 12}>
                  <Typography variant="subtitle2" className={classes.geneDescription}>{gene.geneDescription}</Typography>
                </Grid>
              </Hidden>
            </Grid>
          </CardContent>
          <GeneModal
            gene={gene}
            markers={markers}
            open={open}
            titleColor={titleColor}
            onClose={this.handleCloseModal}
            traitName={traitName}
          />
        </CardActionArea>
      </Card>
    );
  }
}

export default withStyles(styles)(GeneCard);
