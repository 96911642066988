import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  consentContainer: {
    width: '60%',
    margin: 'auto',
    padding: '40px 20px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '80px 0',
    },
  },
  backButton: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}));

export default useStyles;
