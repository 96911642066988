import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

import { makeStyles, withStyles } from '@material-ui/core/styles'

import { sharedStyles } from 'resources'
import Seprator from './Seprator'
import { colors } from 'common/assets/sharedUI'
import { Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: (props) => {
    return {
      borderBottom: props?.divider ? `1px solid ${colors.gray_03}` : null,
    }
  },
}))


const DialogTitle = (props) => {
  const { children, divider, onClose, ...other } = props
  const classes = useStyles({ divider });

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6' className={classes.title}>
        {children}
      </Typography>

      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const CustomDialog = ({
  open,
  title,
  content,
  handleClose,
  handleContinue,
}) => {
  const sharedClasses = sharedStyles()
  const classes = useStyles()

  return (
    <Dialog maxWidth='sm' fullWidth={true} open={open} onClose={handleClose}>
      <>
        <DialogTitle id='dialog-title' divider onClose={handleClose}>
          {title}
        </DialogTitle>

        <DialogContent>
          <DialogContentText className={sharedClasses.body}>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {handleContinue ? (
            <Button
              className={sharedClasses.rebrandedPrimaryButton}
              onClick={handleClose}
            >
              Continue
            </Button>
          ) : null}
        </DialogActions>
      </>
    </Dialog>
  )
}

CustomDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  divider: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleContinue: PropTypes.func,
}

export default CustomDialog
