import {
  lowerCase, replace,
} from 'lodash';
import icons from '../resources/icons';

export function getUrlParam(param) {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  return searchParams.get(param);
}

export function getName(userProfile) {
  if (userProfile !== undefined && userProfile !== null) {
    return userProfile.data.name;
  }
  return '';
}

export function getNeedScore(needScore) {
  switch (needScore) {
    case 0:
    case 1:
    case 2:
      return 0;
    case 3:
    case 4:
    case 5:
      return 1;
    case 6:
    case 7:
    case 8:
      return 2;
    case 9:
    case 10:
      return 3;
    default:
      return 0;
  }
}

export function getContentScore(contentScore) {
  switch (contentScore) {
    case 0:
    case 1:
      return 0;
    case 2:
    case 3:
      return 1;
    case 4:
    case 5:
      return 2;
    case 6:
      return 3;
    default:
      return 0;
  }
}

export const addNoToFilterDisplayName = (filterDisplayName) => {
  return `No ` + filterDisplayName
}

// sort filters into correct order for UI
export const sortFilter = (filterArray) => {
  return filterArray.sort((a, b) => a.displayOrder - b.displayOrder)
}

// Get icon for allergies and diets
export function getIcon(iconTitle) {
  const removeSpaces = replace(lowerCase(iconTitle), / /g, '');
  const iconKey = replace(removeSpaces, /s+$/, ''); // remove trailing 's'

  if (icons[iconKey]) {
    return icons[iconKey];
  }

  return '';
}

export function getDomainName(traitName) {
  if (traitName === 'Lactose' || traitName === 'Gluten') {
    return traitName.concat(' Sensitivity');
  }

  if (traitName === 'Caffeine' || traitName === 'Alcohol') {
    return traitName.concat(' Metabolism');
  }

  return traitName;
}

// TODO: Improvement: move this API in domain controller in formatRecommendation
const replaceLessGreater = (str) => str.replace(/(?:less than|greater than)/gi, (match) => match.toLowerCase() === 'less than' ? '<' : '>');

// Improvement: move this into the recommendationText logic in the API
export function getIntake(name, recommendationText, sensitivitiesOrSubstances, traitName) {  
  if (sensitivitiesOrSubstances && (traitName === 'Caffeine' || traitName === 'Alcohol')) {
    const lowerCaseName = name.toLowerCase();
    if (lowerCaseName.includes('not likely')) { return 'Normal metabolism' }
    if (lowerCaseName.includes('likely')) { return 'Slow metabolism' }
    if (lowerCaseName.includes('normal')) { return 'Normal metabolism' }
    if (lowerCaseName.includes('slow')) { return 'Slow metabolism' }
    if (lowerCaseName.includes('fast')) { return 'Fast metabolism' }
    return name;
  }

  if (sensitivitiesOrSubstances) {
    const lowerCaseName = name.toLowerCase();
    if (lowerCaseName.includes('not likely')) { return 'Not likely sensitive' }
    if (lowerCaseName.includes('likely')) { return 'Likely sensitive' }
    if (lowerCaseName.includes('normal')) { return 'Normal metabolism' }
    if (lowerCaseName.includes('slow')) { return 'Slow metabolism' }
    if (lowerCaseName.includes('fast')) { return 'Fast metabolism' }
    return name;
  }
  return replaceLessGreater(recommendationText) || '';
}

export const getIntakeInCalories = (name, recommendationText, macroNutrientTrait, sensitivitiesOrSubstances, traitName) => {
  if (sensitivitiesOrSubstances) return getIntake(name, recommendationText, sensitivitiesOrSubstances, traitName);
  
  if (macroNutrientTrait) return getIntake(name, recommendationText, sensitivitiesOrSubstances, traitName) + ' Cals';

  return '-- Cals';
}
/**
 * 
 * @param {string} recText 
 * @returns {string}
 * 
 * @deprecated - Moved this logic in API in DomainController.ts formatRecommendation() method.
 */
export const cleanRecommendation = recText => {
  if (recText.includes('<')) return recText.replace('<', 'less than ');
  if (recText.includes('>')) return recText.replace('>', 'greater than ');
  return recText;
};

export const getContentLegend = (foodGroupId) => {
  switch (foodGroupId) {
    case 7:
    case 13:
      return '*Contains Gluten';
    case 9:
      return '*Low Lactose';
    case 12:
    case 14:
      return '*Low Lactose, **Lactose-Free';
    case 15:
      return '*Gluten-Free';
    default:
      return '';
  }
}