// @flow
import React, { PureComponent } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { images } from 'resources';
import styles from './styles.css';

type Props = {};
export default class DowntimeMessage extends PureComponent<Props> {
  render() {
    const {height} = this.props;
    //console.log(height)
    return (
      <div styleName="parent" style={{height: height, backgroundImage: `url(${images.account_bg})`}}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div styleName="centerContent">
                <img src={images.logo} styleName="logo" />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div styleName="centerContent">
                <Paper style={{width: 250, padding: 15}}>
                  <Typography>
                    We are performing routine maintenance to improve your experience. Check back soon.
                  </Typography>
                </Paper>
              </div>
            </Grid>
          </Grid>
      </div>
    );
  }
}
