import { colors, fonts } from 'common/assets/sharedUI'
import { createStyles, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => createStyles({
  image: {
    width: '120px',
    height: '115px',
    [theme.breakpoints.down('md')]: {
      width: '85px',
      height: '85px'
    },
  },
  table: {
    border: 'none !important', // Add !important to ensure the style takes precedence
    marginTop: "30px",
  },
  tableCell: {
    border: 'none !important', // Add !important to ensure the style takes precedence
  },
  marketingSection: {
    padding: "0px 20px 0 40px",
    [theme.breakpoints.down('md')]: {
      padding: "0px 10px 0 10px",
    },
  },
  marketingTitle: {
    color: colors.gray_06,
    fontSize: "40px",
    lineHeight: "47px",
    [theme.breakpoints.down('md')]: {
      fontSize: "25px",
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: '31px'
    },
    fontFamily: fonts.Baskerville,
    fontWeight: 'bold'
  },
  marketingDesc: {
    color: "#74767C",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    marginTop: "30px",
    [theme.breakpoints.down('md')]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '15px',
    },
    fontFamily: fonts.Swiss721MD,
  },
  marketingSubTitle: {
    lineHeight: "26px",
    fontSize: "18px",
    color: colors.green_07,
    fontWeight: 500,
    marginBottom: "10px",
    [theme.breakpoints.down('md')]: {
      marginBottom: "5px",
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: "14px",
    },
    fontFamily: fonts.Swiss721MD,
  },
  marketingSubDes: {
    lineHeight: "20px",
    fontSize: "18px",
    color: colors.gray_06,
    [theme.breakpoints.down('md')]: {
      fontSize: "14px",
    },
    fontFamily: fonts.Swiss721LT,
  },
  topAlignedElement: {
    [theme.breakpoints.down('md')]: {
      display: 'flex !important',
      alignItems: 'flex-start', // Align elements at the top
      padding: "16px 16px 0 8px !important"
    },
  },
  removePadding: {
    [theme.breakpoints.down('md')]: {
      padding: "16px 0 !important"
    },
  }
}));