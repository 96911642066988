import { createStyles, makeStyles } from '@material-ui/core'
import { colors, fonts } from 'common/assets/sharedUI'

export const useStyles = makeStyles((theme) =>
  createStyles({
    counterLabel: {
      marginRight: 4,
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.8rem'
      },
    },

    counterContainer: {
      border: `1px solid ${colors.gray_03}`,
      borderRadius: 3,
      padding: '10px 12px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: 80,
      [theme.breakpoints.down('xs')]: {},
    },

    counterButton: {
      padding: 0,
      minHeight: 0,
      minWidth: 0,
      color: colors.green_07,
      fontFamily: fonts.Swiss721MD,
    },

    counterText: {
      border: 'none',
      textAlign: 'center',
      padding: 0,
      minHeight: 0,
      minWidth: 0,
      color: colors.green_07,
      fontSize: 18,
      fontFamily: fonts.Swiss721MD,
      outline: 'none',

      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
  })
)
