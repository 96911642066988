import React from 'react'
import { Dialog, DialogTitle, Typography } from '@material-ui/core'
import { user } from 'services'
import TermsForm from './TermsForm'
import Terms from './Terms'
import useStyles from './TermsModal.styles'

export default function TermsModal(props) {
  const { open, handleClose } = props
  const [message, setMessage] = React.useState()
  const classes = useStyles()

  const onSubmit = async (values) => {
    try {
      await user.setTermsAgreement()
      handleClose()
    } catch ({ config, response }) {
      setMessage('Something went wrong, please try again later')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      className={classes.modal}
    >
      <DialogTitle id="form-dialog-title">Privacy Policy and Terms of Service</DialogTitle>
      <div style={{ height: 400, overflowY: 'auto', marginBottom: 30 }}>
        <Terms />
      </div>
      {message && <Typography className={classes.errorText} type="error" showIcon>{message}</Typography>}
      <TermsForm onSubmit={onSubmit} />
    </Dialog>
  )
}
