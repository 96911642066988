import { axios } from 'utils';
import { constants } from 'resources';

export default async function cancelSubscription(subscriptionId, reason, reasonComments) {
  return axios.post(constants.API_CANCEL_SUBSCRIPTION, {
    subscriptionId,
    reason,
    reasonComments,
  });
}
