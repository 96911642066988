import { axios } from 'utils';
import { constants } from 'resources';

export default async function showMobileDownloadBanner() {
  try {
    const { data } = await axios.get(constants.API_SHOW_BANNER);
    return !!data.show;
  } catch (e) {
    console.error({ error: e });
    throw new Error(e);
  }
}
