import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import useStyles from './SummaryCard.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function SummaryCard(props) {
  const { count = 0, heading, description1, description2, description3, onClick, colorTheme, iconName, iconColor } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container md={12} xs={12}
      className={`${isMatch ? classes.containerSmall : classes.container} ${colorTheme == 'light' ? classes.bgBlue : classes.bgGreen}`}>
      <Grid md={10} xs={10} >
        <Typography
          variant="subtitle1"
          className={`${isMatch ? classes.nameSmall : classes.name} ${colorTheme == 'light' ? classes.fontWhite : classes.fontDarkestGrey}`}
          style={classes.nameStyle}
        >
          {heading}
        </Typography>
        <Typography
          variant='body2'
          className={`${isMatch ? classes.descriptionSmall : classes.description} ${colorTheme == 'light' ? classes.fontWhite : classes.fontDarkestGrey}`}
          style={classes.descriptionStyle}>
          {description1}
        </Typography>
      </Grid>
      <Grid item md={2} xs={2} alignItems="center" >
        <FontAwesomeIcon icon={iconName}
          style={{
            "--fa-primary-color": iconColor[0],
            "--fa-secondary-color": iconColor[1],
            width: isMatch ? '40px' : '67px',
            height: isMatch ? '47px' : '76px',
            alignSelf: 'center',
            ...iconColor[1] == '#FFF' ? { "--fa-secondary-opacity": 1 }: null
          }} />
      </Grid>
      <Grid container>
        <Grid item
          md={count.toString().length >= 3 ? 3 : 2}
          sm={count.toString().length >= 3 ? 3 : 2}
          xs={count.toString().length >= 3 ? 3 : 2}
        >
        <Typography
          variant="subtitle1"
          className={`${isMatch ? classes.nutrientsSmall : classes.nutrients} ${colorTheme == 'light' ? classes.fontWhite : classes.fontDarkestGrey}`}
          style={isMatch ? classes.nutrientSmallStyle : classes.nutrientsStyle}>
          {count}
        </Typography>
      </Grid>
        <Grid
          container
          md={count.toString().length >= 3 ? 9 : 10}
          lg={count.toString().length >= 3 ? 9 : 10}
          sm={count.toString().length >= 3 ? 9 : 10}
          xs={count.toString().length >= 3 ? 9 : 10}
          alignItems="center"
          spacing={0}>
        <Grid item md={12} xs={12} sm={12} lg={12}>
          <Typography
            variant="subtitle1"
            className={`${isMatch ? classes.description2Small : classes.description2} ${colorTheme == 'light' ? classes.fontWhite : classes.fontDarkestGrey}`}
            style={isMatch ? classes.description2SmallStyle : classes.description2Style}>
            {description2}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} sm={12} lg={12}>
          <Typography
            variant="subtitle1"
            className={`${isMatch ? classes.description3Small : classes.description3} ${colorTheme == 'light' ? classes.fontWhite : classes.fontDarkestGrey}`}
            style={isMatch ? classes.description3SmallStyle : classes.description3Style}>
            {description3}
          </Typography>
        </Grid>
        </Grid>
      </Grid>
      <Grid container md={12} xs={12} justify="center" alignItems='flex-end' >
        <Button
          variant="contained"
          className={`${isMatch ? classes.buttonSmall: classes.button}`}
          onClick={onClick}
          >
            View All →
        </Button>
        </Grid>
    </Grid>
  );
}

SummaryCard.propTypes = {
  heading: PropTypes.string.isRequired,
  description1: PropTypes.string.isRequired,
  description2: PropTypes.string.isRequired,
  description3: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  colorTheme: PropTypes.string,
  iconName: PropTypes.object,
  iconColor: PropTypes.array,
  count: PropTypes.number,
};

SummaryCard.defaultProps = {
  noOfNutrients: 0,
};
