import React from 'react';

import {
  Typography,
  createStyles,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/free-solid-svg-icons';

import { colors } from 'common/assets/sharedUI';
import { constants } from 'resources';
import {
  EventActions,
  EventCategories,
  EventLabels,
  registerGAClickEventEnhanced,
} from 'utils/googleanalytics';

import { Banner } from 'components/shared';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    banner: {
      position: 'fixed',
      top: 90,
      left: 0
    },
    text: {
      color: colors.white,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
        textAlign: 'left',
      },
    },

    icon: {
      marginLeft: 10,
    },
  }),
);
const DietitianConsultBanner = ({ topPosition }) => {
  const classes = useStyles();

  return (
    <Banner bannerStyle={clsx(classes.banner)} style={{ top: topPosition }}>
      <IconButton
        href={constants.CONSULTATION_SESSION}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() =>
          registerGAClickEventEnhanced(
            EventCategories.DietitianConsultBanner,
            EventActions.Click,
            EventLabels.DietitianConsult,
          )
        }
        target='_blank'
        size='small'
      >
        <Typography className={classes.text}>
          Sign up for your free consultation with a GenoPalate registered
          dietitian!
          <FontAwesomeIcon
            icon={faLongArrowRight}
            color={colors.white}
            size='sm'
            className={classes.icon}
          />
        </Typography>
      </IconButton>
    </Banner>
  );
};

DietitianConsultBanner.propTypes = {};

export default DietitianConsultBanner;