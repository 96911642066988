import { combineReducers } from 'redux';
import unionBy from 'lodash/unionBy';
import basicReducer from './basicReducer';
import actionTypes from '../actionTypes';

const initialAggregateState = {
  data: [],
  fetching: false,
  error: null,
};

const initialState = {
  data: [],
  geneticInsights: [],
  fetching: false,
  error: null,
};

const essentialNutritionDetailsReducer = (state = initialAggregateState, action) => {
  switch (action.type) {
    case actionTypes.GET_ESSENTIAL_NUTRITION_DETAILS_STARTED:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_ESSENTIAL_NUTRITION_DETAILS_SUCCESS:
      return {
        ...state,
        data: unionBy(state.data, action.data, 'id'),
        geneticInsights: unionBy(state.geneticInsights, action.geneticInsights, 'id'),
        fetching: false,
      };
    case actionTypes.GET_ESSENTIAL_NUTRITION_DETAILS_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const essentialNutrition = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ESSENTIAL_NUTRITION_STARTED:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_ESSENTIAL_NUTRITION_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.data],
        geneticInsights: [...state.geneticInsights, ...action.geneticInsights],
        fetching: false,
      };
    case actionTypes.GET_ESSENTIAL_NUTRITION_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default combineReducers({
  macros: basicReducer(actionTypes.GET_MACROS_STARTED, actionTypes.GET_MACROS_SUCCESS, actionTypes.GET_MACROS_ERROR),
  essentialNutrition: essentialNutrition,
  essentialNutritionDetails: essentialNutritionDetailsReducer,
  traitRecommendation: basicReducer(actionTypes.GET_TRAIT_RECOMMENDATION_STARTED, actionTypes.GET_TRAIT_RECOMMENDATION_SUCCESS, actionTypes.GET_TRAIT_RECOMMENDATION_ERROR),
});
