import { createStyles, makeStyles } from "@material-ui/core/styles";
import { colors, fonts } from "common/assets/sharedUI";

const useStyles = makeStyles(() =>
  createStyles({
    productImage: {
      width: "282px",
      height: "268px",
    },
    featuredImage: {
      paddingTop: 69,
    },
    shopProductsHeader: {
      fontFamily: fonts.raleway,
      color: colors.blue_04,
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
    },
    headerDiv: {
      paddingTop: "60px",
      paddingBottom: "40px",
    },
    itemPadding: {
      paddingTop: "10px",
    },
    productPriceText: {
      color: colors.gray_04,
      fontFamily: fonts.raleway,
      paddingTop: "24px",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "24px",
      paddingBottom: "16px",
    },
    productNameText: {
      fontFamily: fonts.raleway,
      color: colors.gray_06,
      paddingTop: "8px",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "24px",
    },
    productDescriptionText: {
      fontFamily: fonts.raleway,
      color: colors.gray_06,
      paddingTop: "8px",
      fontWeight: 400,
      fontSize: "16px",
      width: "100%",
      maxWidth: "282px",
      lineHeight: "24px",
    },
    linkButton: {
      marginLeft: "-7px",
    },
    viewProductLink: {
      fontFamily: fonts.raleway,
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
      paddingTop: "16px",
      textDecoration: "underline",
      textTransform: "none",
      color: colors.green_05,
      "&:hover": {
        background: "none",
      },
    },
    additionalProductWrapper: {
      marginBottom: "25px",
      paddingRight: "25px",
    },
  })
);

export default useStyles;
