// @flow
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {
  Form, Input, Button, Checkbox, Alert, Popover, Progress, Row, Col,
} from 'antd'
import { withRouter } from 'react-router-dom'
import { setFirstTimeKitUser } from 'store/actions/status'
import { auth } from 'services'
import * as flows from '../../flows';
import { constants } from 'resources'
import { axios } from 'utils'
import styles from './styles.css'

const FormItem = Form.Item

/** TODO: REMOVE * */
export const isDnaKit = productId => productId === 1 || productId === 5

const passwordStatusMap = {
  ok: <div className={styles.success}>Strength：Strong</div>,
  pass: <div className={styles.warning}>Strength：Medium</div>,
  poor: <div className={styles.error}>Strength：Too short</div>,
}

const passwordProgressMap = {
  ok: 'success',
  pass: 'normal',
  poor: 'exception',
}

type FormType = {
  getFieldValue(fieldName: string): any,
  validateFields(fieldNames: Array<string>, options: Object): void,
  setFields(obj: Object): void,
  setFieldsValue(obj: Object, callback?: Function): void,
  getFieldDecorator(id: string, options?: Object): (node: React.ReactNode) => React.ReactNode,
}

type Props = {
  form: FormType,
  productId: number,
  productCode?: string,
}

class RegisterForm extends PureComponent<Props> {
  state = { loading: false, message: null }

  async onRegister({ confirm, ...values }) {
    this.setState({ loading: true })
    const { history, setKitUser } = this.props
    // console.log("values: " + JSON.stringify(values))

    try {
      this.setState({ loading: true })

      const {
        data: { token, userId, isNewKitUser },
      } = await auth.register({ ...values })
      flows.login(token, userId)

      setKitUser(isNewKitUser)
      this.setState({ loading: false, registeredSuccessfully: true })
      history.push('/home')
    } catch ({ response }) {
      if (response) this.setState({ message: response.data.code, loading: false })
      else {
        this.setState({ message: 'Something went wrong, please try again later', loading: false })
      }
    }
  }

  getPasswordStatus = () => {
    const { form } = this.props
    const value = form.getFieldValue('password')
    if (value && value.length > 9) {
      return 'ok'
    }
    if (value && value.length > 5) {
      return 'pass'
    }
    return 'poor'
  }

  checkConfirm = (rule, value, callback) => {
    const { form } = this.props
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords do not match!')
    } else {
      callback()
    }
  }

  checkPassword = (rule, value, callback) => {
    if (!value) {
      this.setState({ help: 'Please input your password！', visible: !!value })
      callback('error')
    } else {
      this.setState({ help: '' })
      const { visible, confirmDirty } = this.state
      if (!visible) {
        this.setState({ visible: !!value })
      }
      if (value.length < 6) {
        callback('error')
      } else {
        const { form } = this.props
        if (value && confirmDirty) {
          form.validateFields(['confirm_password'], { force: true })
        }
        callback()
      }
    }
  }

  agreementCheck = (rule, value, callback) => {
    if (value !== true) {
      callback('Please check the box to continue!')
    } else {
      callback()
    }
  }

  renderInfoPopover = () => {
    const { productId } = this.props
    const content = (
      <div style={{ width: '175px' }}>
        {isDnaKit(productId)
          ? 'If you received a 7-digit product code in the instructions email you received shortly after purchase, please use that product code to create your GenoPalate account. Otherwise, please enter the 9-digit barcode found on your saliva swab vial.'
          : 'Your product code is the 7-digit number emailed to you after your purchase.'}
      </div>
    )

    return (
      <Popover content={content} placement="right" trigger="hover">
        <div styleName="pc-info">
          <span style={{ fontSize: '20px', color: 'white' }}>?</span>
        </div>
      </Popover>
    )
  }

  /* ************* */

  componentDidMount = async () => {
    const { form, productCode } = this.props
    if (productCode) {
      form.setFieldsValue({ userProductCode: productCode })
    }
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { form } = this.props
    const productCode = form.getFieldValue('userProductCode')
    // console.log(productCode)
    const productCodeValid = await axios.get(
      `${constants.API_VALIDATE_USER_PRODUCT}?product-code=${productCode}`,
    )
    // console.log("Valid? " + JSON.stringify(productCodeValid))
    if (!productCodeValid.data) {
      form.setFields({
        userProductCode: {
          errors: [new Error('invalid productCode')],
        },
      })
    }
    form.validateFields((err, values) => {
      if (err) return
      this.onRegister(values)
    })
  }

  renderMessage() {
    const { message } = this.state
    if (!message) return null

    return <Alert style={{ marginBottom: 24 }} message={message} type="error" showIcon />
  }

  renderPasswordProgress = () => {
    const { form } = this.props
    const value = form.getFieldValue('password')
    const passwordStatus = this.getPasswordStatus()
    return value && value.length ? (
      <div className={styles[`progress-${passwordStatus}`]}>
        <Progress
          status={passwordProgressMap[passwordStatus]}
          className={styles.progress}
          strokeWidth={6}
          percent={value.length * 10 > 100 ? 100 : value.length * 10}
          showInfo={false}
        />
      </div>
    ) : null
  }

  renderPopoverContent = () => (
    <div style={{ padding: '4px 0' }}>
      {passwordStatusMap[this.getPasswordStatus()]}
      {this.renderPasswordProgress()}
      <div style={{ marginTop: 10 }}>
        Please enter at least 6 characters and do not use passwords that are easy to guess.
      </div>
    </div>
  )

  render() {
    const {
      form, productId, productCode, name, surname, email,
    } = this.props
    const { getFieldDecorator } = form
    const {
      help, visible, loading,
    } = this.state

    // TODO: VALIDATE PRODUCT CODE WITH DB

    return (
      <div styleName="register_form">
        {this.renderMessage()}
        <Form onSubmit={event => this.handleSubmit(event)}>
          <FormItem>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input your first name！' }],
              initialValue: name,
            })(<Input size="large" placeholder="First Name" />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('surname', {
              rules: [{ required: true, message: 'Please input your last name! ' }],
              initialValue: surname,
            })(<Input size="large" placeholder="Last Name" />)}
          </FormItem>
          <FormItem>
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Please input your email address！' },
                { type: 'email', message: 'Please input a valid email address！' },
              ],
              initialValue: email,
            })(<Input size="large" placeholder="Email" />)}
          </FormItem>
          <FormItem help={help}>
            <Popover
              content={this.renderPopoverContent()}
              overlayStyle={{ width: 240 }}
              placement="right"
              visible={visible}
            >
              {getFieldDecorator('password', {
                rules: [{ validator: this.checkPassword }],
              })(<Input size="large" type="password" placeholder="Password" />)}
            </Popover>
          </FormItem>
          <FormItem>
            {getFieldDecorator('confirm', {
              rules: [
                { required: true, message: 'Please confirm your password！' },
                { validator: this.checkConfirm },
              ],
            })(<Input size="large" type="password" placeholder="Confirm password" />)}
          </FormItem>
          <FormItem style={{ display: productCode ? 'none' : null }}>
            <Row gutter={8}>
              <Col span={21}>
                {getFieldDecorator('userProductCode', {
                  rules: [
                    {
                      required: true,
                      message: isDnaKit(productId)
                        ? 'Please enter a product code or barcode!'
                        : 'Please enter a valid product code!',
                    },
                  ],
                  initialValue: productCode,
                })(
                  <Input
                    size="large"
                    placeholder={
                      isDnaKit(productId) ? 'Product Code or Barcode' : 'Product code'
                    }
                  />,
                )}
              </Col>
              <Col span={3}>{this.renderInfoPopover()}</Col>
            </Row>
          </FormItem>
          <FormItem>
            {getFieldDecorator('userAgreement', {
              valuePropName: 'checked',
              rules: [{ validator: this.agreementCheck }],
            })(
              <Checkbox>
                By clicking here you agree to our
                {' '}
                <a
                  href="https://www.genopalate.com/privacy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                {' '}
                and
                {' '}
                <a
                  href="https://www.genopalate.com/terms-of-service/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </Checkbox>,
            )}
          </FormItem>
          <FormItem>
            <Row>
              <Col>
                <Button
                  size="large"
                  loading={loading}
                  styleName="register_button"
                  type="primary"
                  htmlType="submit"
                >
                  {('Register').toUpperCase()}
                </Button>
              </Col>
              <Col style={{ textAlign: 'center' }}>
                Already have an account?
                {' '}
                <a href="/">Login here!</a>
              </Col>
            </Row>
          </FormItem>
        </Form>
      </div>
    )
  }
}

RegisterForm.defaultProps = {
  productCode: null,
}

const WrappedRegisterForm = Form.create()(RegisterForm)

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  setKitUser: isNewKitUser => dispatch(setFirstTimeKitUser(isNewKitUser)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WrappedRegisterForm))
