import React from 'react';
import {
  Dialog, DialogContent, Grid, Typography, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { sharedStyles } from 'resources';
import useStyles from './WelcomeBackModal.styles';

const WelcomeBackModal = (props) => {
  const {
    open, onClose, name,
  } = props;
  const sharedClasses = sharedStyles();
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const body = "Let's start where you left off.";
  const buttonTxt = 'Continue';

  return (
    <Dialog
      open={open}
      style={{ zIndex: 1302 }}
      onClose={handleClose}
    >
      <DialogContent>
        <Grid container className={classes.dialogContainer}>
          <Grid item xs={12} className={classes.itemContainer}>
            <Typography variant="h4" className={classes.title}>{`Welcome back, ${capitalize(name)}!`}</Typography>
            <Typography variant="body1" className={classes.body}>{body}</Typography>
            <Button className={sharedClasses.primaryButton} onClick={handleClose}>{buttonTxt}</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeBackModal;

WelcomeBackModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  name: PropTypes.string,
};

WelcomeBackModal.defaultProps = {
  name: '',
};
