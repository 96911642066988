import React from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import get from 'lodash/get';
import { Formik, Form } from 'formik';
import {
  Button, Grid, Tooltip, Select, MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';
import { updateShippingAddress } from 'store/actions/subscription';
import { sharedStyles } from '../../resources';
import { StatesAndTerritories } from '../../resources/constants';
import AccountFormikInput from './AccountFormikInput';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../../utils/googleanalytics';

const useStyles = makeStyles(theme => ({
  checkIcon: {
    color: colors.primaryGreen,
    fontSize: '5rem',
    paddingBottom: 20,
  },
  successContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  successText: {
    paddingBottom: 20,
  },
  errorText: {
    textAlign: 'center',
    color: colors.red,
  },
  form: {
    maxWidth: 440,
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  saveWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

const TooltipWrapper = ({ disabled, children }) => (
  <Tooltip title={disabled ? 'GenoVit subscription required' : ''} followCursor>
    <span>
      {children}
    </span>
  </Tooltip>
);

TooltipWrapper.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const UpdateShippingForm = ({
  shipAddr1, shipAddr2, shipCity, shipState, shipZip, addressId, disabled,
}) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    shipAddr1: Yup.string()
      .matches(/^(?!\s+$)/, 'Please provide a valid address.') // catches strings with only whitespaces
      .required('Address is required'),
    shipAddr2: Yup.string(),
    shipCity: Yup.string()
      .matches(/^(?!\s+$)/, 'Please provide a valid city.') // catches strings with only whitespaces
      .required('City is required'),
    shipState: Yup.string()
      .required('State is required'),
    shipZip: Yup.string()
      .matches(/^[0-9]+$/, 'Only digits are permitted')
      .required('Zipcode is required'),
  });

  return (
    <Formik
      initialValues={{
        shipAddr1: disabled ? '' : shipAddr1,
        shipAddr2: disabled ? '' : shipAddr2,
        shipCity: disabled ? '' : shipCity,
        shipState: disabled ? '' : shipState,
        shipZip: disabled ? '' : shipZip,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        registerGAClickEventEnhanced(EventCategories.AccountShipping, EventActions.Update, EventLabels.UpdateShipping);
        const { shipAddr2: shipAddr2Value, ...otherValues } = values;
        dispatch(updateShippingAddress(
          {
            shipAddr2: shipAddr2Value && shipAddr2Value.length > 0 ? shipAddr2Value : null,
            ...otherValues,
          },
          addressId,
        ));
        resetForm();
      }}
      enableReinitialize
    >
      {({
        handleSubmit, isValid, dirty,
      }) => (
        <Form onSubmit={handleSubmit} className={classes.form}>
          <Grid item xs={12}>
            <TooltipWrapper disabled={disabled}>
              <AccountFormikInput
                name="shipAddr1"
                label="Address Line 1"
                type="text"
                placeholder="Address"
                disabled={disabled}
              />
            </TooltipWrapper>
            <TooltipWrapper disabled={disabled}>
              <AccountFormikInput
                name="shipAddr2"
                label="Address Line 2"
                type="text"
                placeholder="Address"
                disabled={disabled}
              />
            </TooltipWrapper>
            <TooltipWrapper disabled={disabled}>
              <AccountFormikInput
                name="shipCity"
                label="City"
                type="text"
                placeholder="City"
                disabled={disabled}
              />
            </TooltipWrapper>
            <TooltipWrapper disabled={disabled}>
              <Select
                style={{ padding: '4px 10px 3px 10px' }}
                input={(
                  <AccountFormikInput
                    name="shipState"
                    label="State"
                    type="text"
                    disabled={disabled}
                  />
                )}
                displayEmpty
              >
                <MenuItem disabled value="">State</MenuItem>
                {StatesAndTerritories.map(state => <MenuItem key={state.code} value={state.code}>{state.name}</MenuItem>)}
              </Select>
            </TooltipWrapper>
            <TooltipWrapper disabled={disabled}>
              <AccountFormikInput
                name="shipZip"
                label="Zip"
                type="text"
                placeholder="Zip"
                disabled={disabled}
              />
            </TooltipWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.saveWrapper}
          >
            <Button
              disabled={!dirty || !isValid || disabled}
              className={sharedClasses.rebrandedPrimaryButton}
              type="submit"
            >
              Save →
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  shipAddr1: get(state, 'subscription.shippingAddress.data.address.shipAddr1', ''),
  shipAddr2: get(state, 'subscription.shippingAddress.data.address.shipAddr2', ''),
  shipCity: get(state, 'subscription.shippingAddress.data.address.shipCity', ''),
  shipState: get(state, 'subscription.shippingAddress.data.address.shipState', ''),
  shipZip: get(state, 'subscription.shippingAddress.data.address.shipZip', ''),
  addressId: (!!state.subscription && !!state.subscription.shippingAddress && !!state.subscription.shippingAddress.data && state.subscription.shippingAddress.data.shippingAddressId) || null,
});

UpdateShippingForm.propTypes = {
  shipAddr1: PropTypes.string.isRequired,
  shipAddr2: PropTypes.string,
  shipCity: PropTypes.string.isRequired,
  shipState: PropTypes.string.isRequired,
  shipZip: PropTypes.string.isRequired,
  addressId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

UpdateShippingForm.defaultProps = {
  shipAddr2: '',
  disabled: false,
};

export default connect(mapStateToProps)(UpdateShippingForm);
