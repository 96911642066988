import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
// import { createMuiTheme } from '@material-ui/styles';
import useStyles from './AccountRow.styles';

export default function AccountRow(props) {
  const { label, description, children } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.label} style={classes.labelStyle}>{label}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.description}>{description}</Typography>
      </Grid>
      <Grid container item xs={12} justify="flex-end">
        {children}
      </Grid>
    </Grid>
  );
}

AccountRow.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.node,
};

AccountRow.defaultProps = {
  children: null,
};
