import React, {  useEffect, useState } from 'react';
import { CircularProgress, Container, Typography } from '@material-ui/core';
import { colors } from 'common/assets/sharedUI';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import { withStyles } from '@material-ui/core/styles';
import { user } from 'services';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import { deleteAccountConfirmationCodeExpired, deleteAccountConfirmationCodeInvalid, deleteAccountSuccess } from 'resources/constants';
import * as flows from '../flows';
import { clearStore } from '../store/actions/user';
import { useDispatch } from 'react-redux';
import { deleteAccountConfirmationErrorMessage } from '../resources/constants';

const styles = {
  bold: {
    fontWeight: 'bold',
    color: colors.white,
  },
  container: {
    marginTop: 20,
  },
  legendContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  descriptionContainer: {
    display: 'flex',
  },
  blueDescription: {
    color: blue[400],
    marginRight: 10,
  },
  greyDescription: {
    color: grey[400],
    marginRight: 10,
  },
  message: {
    textAlign: 'justify',
    wordWrap: "break-word",
    whiteSpace: "pre-line"
  }
};

const DeleteRequestConfirmation = ({ classes, token }) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

 
  const handleDeleteAccountConfirmation = async () => {
  try {
    const loginResponse = await deleteUserAccount(token);
    handleSuccessResponse(loginResponse);
  } catch (error) {
    handleErrorResponse(error);
  } finally {
    setLoading(false);
  }
};

const deleteUserAccount = async (token) => {
  return await user.deleteAccountConfirmation({ token });
};

  const handleSuccessResponse = (response) => {
  if (response && response.data.error) {
    return deleteAccountConfirmationErrorMessage;
  }
  registerGAClickEventEnhanced(EventCategories.Navbar, EventActions.Open, EventLabels.Logout);
  flows.logoutWithoutRedirect();
  dispatch(clearStore());
  setMessage(deleteAccountSuccess);
};

const handleErrorResponse = (error) => {
  
  const errorMessage = getErrorMessage(error);
  setMessage(errorMessage);
};

const getErrorMessage = (error) => {
  const response = error.response;
  return deleteAccountConfirmationErrorMessage;
};

  useEffect(() => {
    handleDeleteAccountConfirmation();
  }, []);

  return (
    <Container maxWidth='md'>
      <div className={classes.container}>
        <Typography className={classes.message}>
          {loading ? <CircularProgress size={20} color="black" /> : message}
        </Typography>
        
      </div>
    </Container>
  );
};

export default withStyles(styles)(DeleteRequestConfirmation);
