const styles = theme => ({
  logo: {
    position: 'absolute',
    top: '10px',
    width: '135',
    height: '70',
    [theme.breakpoints.down('sm')]: {
      left: '35%',
    },
    [theme.breakpoints.up('sm')]: {
      left: '45%',
    },
    '@media print': {
      height: '41px',
      width: '80px',
      left: '42%',
      position: 'relative',
      alignItems: 'center',
    },
  },
  noprint: {
    '@media print': {
      display: 'none',
    },
  },
  top: {
    '@media print': {
      paddingLeft: '0px',
      width: '100%',
      fontSize: '9px',
    },
  },
  mobilelogo: {
    position: 'absolute',
    top: '10px',
    left: '35%',
  },
  closeBtn: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'row',
    display: 'flex',
  },
  foodsList: {
    width: '70%',
    '@media print': {
      paddingLeft: '0px',
      marginTop: '30px',
      width: '100%',
      fontSize: '9px',
    },
  },
  foodsListKey: {
    '& span': {
      marginRight: '5px',
      display: 'inline-block',
      fontSize: '.75em',
    },
  },
  foodsListTitle: {
    fontSize: '1.25em',
    fontWeight: '300',
    marginBottom: '0',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: '1%',
  },

  foodGroup: {
    width: '30%',
    marginRight: '3%',
  },

  mobileFoodGroup: {
    marginRight: '3%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
    '@media print': {
      width: '30%',
    },
  },
  printLogo: {
    '@media print': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
  },
  foodGroupTitle: {
    fontWeight: '700',
    marginBottom: '0',
  },

});

export default styles;
