import { getAxiosInstance } from '../../utils/axios';

export default async (traitIds) => {
  const resp = await getAxiosInstance().get('/api/v1/nutrient/essentialNutritionDetails', {
    params: {
      traitIds,
    },
  });

  return resp.data;
};
