
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Grid, Button, CircularProgress } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { sharedStyles } from 'resources'
import { apiStatuses } from 'resources/constants'
import useStyles from './QuestionNavigation.styles'

export default function QuestionNavigation(props) {
  const {
    isValidated, onClickBackBtn, saveStatus, questionStatus,
  } = props
  const sharedClasses = sharedStyles()
  const classes = useStyles()

  const nextBody = saveStatus === apiStatuses.PENDING ? <CircularProgress size={30} color="inherit" /> : 'Next'
  return (
    <Grid container item xs={12} justify="flex-start" className={classes.buttonContainer}>
      <Button name="backBtn" variant="outlined" className={clsx(sharedClasses.secondaryButton, classes.backBtn)} onClick={onClickBackBtn}><FontAwesomeIcon icon={faChevronLeft} /> Back</Button>
      <Button name="nextBtn" disabled={!isValidated || saveStatus === apiStatuses.PENDING || questionStatus === apiStatuses.PENDING} className={clsx(sharedClasses.primaryButton, classes.nextBtn)} type="submit">{nextBody} <FontAwesomeIcon icon={faChevronRight} /></Button>
    </Grid>
  )
}

QuestionNavigation.propTypes = {
  isValidated: PropTypes.bool.isRequired,
  saveStatus: PropTypes.string.isRequired,
  questionStatus: PropTypes.string.isRequired,
  onClickBackBtn: PropTypes.func.isRequired,
}
