import React from 'react'
import cmp from 'semver-compare'
import { clearLocalStorage } from 'utils/storage'
import packageJson from '../../package.json'

global.appVersion = packageJson.version

// version from response - first param, local version - second param
// check if server version is greater than local version 
const semverGreaterThan = (versionA, versionB) => {
  return cmp(versionA, versionB) > 0
}

class CacheBuster extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...')
        // if this is not the latest version, cache will be cleared and hard reload will be forced
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then((names) => {
            for (const name of names) caches.delete(name)
          })
        }

        // delete user's local data
        clearLocalStorage()

        // delete browser cache and hard reload
        window.location.reload(true)
      },
    }
  }

  componentDidMount() {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version
        const currentVersion = global.appVersion

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`)
          // Force refresh when a new version is available
          this.setState({ loading: false, isLatestVersion: false })
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`)
          // Current version is the latest version. No cache refresh is needed 
          this.setState({ loading: false, isLatestVersion: true })
        }
      })
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload })
  }
}

export default CacheBuster
