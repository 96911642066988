import { user } from '../../services';
import types from '../actionTypes';

export const selectStatus = () => dispatch => {
  dispatch(statusStarted());
  user.getStatus()
    .then(response => {
      dispatch(statusSuccess(response));
    })
    .catch(error => {
      dispatch(statusFailure(error));
    });
};

const statusSuccess = status => ({
  type: types.SELECT_STATUS_SUCCESS,
  payload: {
    ...status,
  },
});

const statusStarted = () => ({
  type: types.SELECT_STATUS_STARTED,
});

const statusFailure = error => ({
  type: types.SELECT_STATUS_ERROR,
  payload: {
    error,
  },
});

export const setFirstTimeKitUser = (firstTimeKitUser) => ({
  type: types.SET_FIRST_TIME_KIT_USER,
  payload: firstTimeKitUser,
});

export const setDisplayHeaderFooter = (flag) => ({
  type: types.DISPLAY_HEADER_FOOTER,
  payload: flag,
})
