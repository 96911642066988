import { assign, get } from 'lodash';
import format from 'date-fns/format';
import * as Yup from 'yup';
import { parseISO } from 'date-fns';

// This function will handle the schema validation for the questionnaire
// Including optional vs mandatory logic
export const getYupObject = (questionItem) => {
  const {
    id, questionTypeId, singleAnswer, maxAnswerCount, mandatory,
    minAnswerCount, questionText,
  } = questionItem;

  Yup.setLocale({
    string: {
      matches: 'Please provide 5 digits.',
    },
  });

  switch (questionTypeId) {
    case 1: { // choice
      // single choice
      if (singleAnswer) {
        const singleChoiceSchema = Yup.string().nullable();
        if (mandatory) {
          return {
            [`radioGroup_${id}`]: singleChoiceSchema
              .when('isRadioGroupMandatory', (isRadioGroupMandatory, passSchema) => (isRadioGroupMandatory ? passSchema.required('An option is required') : passSchema)),
          };
        }
        return {
          [`radioGroup_${id}`]: singleChoiceSchema,
        };
      }
      // multiple choice
      const multipleChoiceSchema = Yup.array().max(maxAnswerCount, `Please select ${maxAnswerCount} options`);
      if (mandatory) {
        return {
          [`checkboxGroup_${id}`]: multipleChoiceSchema
            .when('isCheckboxGroupMandatory', (isCheckboxGroupMandatory, passSchema) => (isCheckboxGroupMandatory ? passSchema.required('At least one option is required').nullable() : passSchema)),
        };
      }
      return {
        [`checkboxGroup_${id}`]: multipleChoiceSchema,
      };
    }
    case 2: { // time
      const timeSchema = Yup.date().typeError('Please specify a valid time.').nullable();
      if (mandatory) {
        return {
          [`time_${id}`]: timeSchema
            .when('isTimeMandatory', (isTimeMandatory, passSchema) => (isTimeMandatory ? passSchema.required('Required') : passSchema)),
        };
      }
      return {
        [`time_${id}`]: timeSchema,
      };
    }
    case 3: { // date
      const dateSchema = Yup.date().typeError('Please specify a valid date.').nullable();
      if (mandatory) {
        return {
          [`date_${id}`]: dateSchema
            .when('isDateMandatory', (isDateMandatory, passSchema) => (isDateMandatory ? passSchema.required('Required') : passSchema)),
        };
      }
      return {
        [`date_${id}`]: dateSchema,
      };
    }
    case 4: { // number
      const numberSchema = Yup.number()
        .integer('Please specify an integer.')
        .typeError('Please specify a number.')
        .nullable();
      if (mandatory) {
        return {
          [`number_${id}`]: numberSchema
            .when('isNumberMandatory', (isNumberMandatory, passSchema) => (isNumberMandatory ? passSchema.required('Required') : passSchema)),
        };
      }
      return {
        [`number_${id}`]: numberSchema,
      };
    }
    case 6: { // height
      const feetSchema = Yup.number()
        .integer('Please specify an integer.')
        .typeError('Please specify a number.')
        .nullable()
        .min(2, 'Please provide a minimum height of 2\'')
        .max(8, 'Please provide a maximum height of 8\'');
      const inchesSchema = Yup.number()
        .integer('Please specify an integer.')
        .typeError('Please specify a number.')
        .nullable()
        .max(11, 'Please provide a maximum of 11 inches')
        .when((`feet_${id}`), (feet, schema) => (feet === 2 ? schema.min(0, 'Please provide a minimum height of 2\'')
          : (feet === 8 ? schema.max(0, 'Please provide a maximum height of 8\'') : schema)));
      if (mandatory) {
        return {
          [`feet_${id}`]: feetSchema
            .when('isHeightMandatory', (isHeightMandatory, passSchema) => (isHeightMandatory ? passSchema.required('Required') : passSchema)),
          [`inches_${id}`]: inchesSchema
            .when('isHeightMandatory', (isHeightMandatory, passSchema) => (isHeightMandatory ? passSchema.required('Required') : passSchema)),
        };
      }
      return {
        [`feet_${id}`]: feetSchema,
        [`inches_${id}`]: inchesSchema,
      };
    }
    case 7: { // weight
      const weightSchema = Yup.number()
        .integer('Please specify an integer.')
        .typeError('Please specify a number.')
        .nullable()
        .min(65, 'Please provide a minimum weight of 65lbs')
        .max(999, 'Please provide a maximum weight of 999lbs');
      if (mandatory) {
        return {
          [`pounds_${id}`]: weightSchema
            .when('isWeightMandatory', (isWeightMandatory, passSchema) => (isWeightMandatory ? passSchema.required('Required') : passSchema)),
        };
      }
      return {
        [`pounds_${id}`]: weightSchema,
      };
    }
    case 9: { // text
      let textSchema = Yup.string().nullable();
      const freeResponseSchema = {};
      for (let i = 0; i < maxAnswerCount; i += 1) {
        if (questionText === 'What is your zip code?') {
          textSchema = textSchema.matches(/^[0-9]{5}$/);
        }
        if (i < minAnswerCount && mandatory) {
          assign(freeResponseSchema, {
            [`textResponse_${id}_${i}`]: textSchema
              .when('isResponseMandatory', (isResponseMandatory, passSchema) => (isResponseMandatory ? passSchema.required('Required').min(1, 'Please enter a response.') : passSchema)),
          });
        } else {
          assign(freeResponseSchema, {
            [`textResponse_${id}_${i}`]: textSchema,
          });
        }
      }
      return freeResponseSchema;
    }
    default: return {};
  }
};

// This function will load the initial values or the previous response, if it exists
export const getInitialValues = ({
  id,
  questionTypeId,
  singleAnswer,
  maxAnswerCount,
  mandatory,
  answer,
  questionChoices,
}) => {
  let array = [];

  switch (questionTypeId) {
    case 1: { // choice
      let selectedChoice;
      // Get noneResponse value
      const isNoneChoice = get(answer, 'noneResponse', false);
      // If noneResponse value is true, search none choice in question choices
      if (isNoneChoice) {
        selectedChoice = questionChoices.find(choice => choice.isNoneChoice);
      } else if (singleAnswer) {
        // single choice - search selected choice in questionChoices array
        const answerQuestionId = get(answer, 'answerChoices[0].questionChoiceId', -1);
        selectedChoice = questionChoices.find(({ id }) => id === answerQuestionId);
      } else {
        // multiple choice - generate array using questionChoiceId
        const answerChoices = get(answer, 'answerChoices', null);
        if (answerChoices) {
          array = answerChoices.map(answerChoiceItem => answerChoiceItem.questionChoiceId);
        }
      }

      // single choice
      if (singleAnswer) {
        return {
          [`radioGroup_${id}`]: selectedChoice ? selectedChoice.choiceText : null,
          isRadioGroupMandatory: mandatory,
        };
      }
      // multiple choice
      return {
        [`checkboxGroup_${id}`]: selectedChoice ? [selectedChoice.id] : (array.length > 0 ? array : []),
        isCheckboxGroupMandatory: mandatory,
      };
    }
    case 2: { // time
      const time = new Date();
      array = get(answer, 'timeResponse', null) ? get(answer, 'timeResponse', null).split(':') : null;
      if (array) {
        time.setHours(array[0]);
        time.setMinutes(array[1]);
      }
      return {
        [`time_${id}`]: array ? time : null,
        isTimeMandatory: mandatory,
      };
    }
    case 3: // date
      return {
        [`date_${id}`]: get(answer, 'dateResponse', null),
        isDateMandatory: mandatory,
      };
    case 4: // number
      return {
        [`number_${id}`]: get(answer, 'numberResponse', null),
        isNumberMandatory: mandatory,
      };
    case 6: { // height
      const feet = get(answer, 'numberResponse', null) ? Math.trunc(parseInt(get(answer, 'numberResponse', null), 10) / 12) : null;
      const inches = get(answer, 'numberResponse', null) ? parseInt(get(answer, 'numberResponse', null), 10) % 12 : null;
      return {
        [`feet_${id}`]: feet,
        [`inches_${id}`]: inches,
        isHeightMandatory: mandatory,
      };
    }
    case 7: // weight
      return {
        [`pounds_${id}`]: get(answer, 'numberResponse', null),
        isWeightMandatory: mandatory,
      };
    case 9: { // text
      const freeResponse = {};
      for (let i = 0; i < maxAnswerCount; i += 1) {
        if (get(answer, `answerText[${i}].response`, null)) {
          assign(freeResponse, {
            [`textResponse_${id}_${i}`]: get(answer, `answerText[${i}].response`, null),
          });
        }
      }
      assign(freeResponse, { isResponseMandatory: mandatory });
      return freeResponse;
    }
    default: return {};
  }
};

// This function will check if the answer is empty
export const isAnswerEmpty = (formValues, questionElem) => {
  if (questionElem.mandatory) {
    return false;
  }
  switch (questionElem.questionTypeId) {
    case 1: // choice
      // single choice test
      if (questionElem.singleAnswer) {
        return !formValues[`radioGroup_${questionElem.id}`];
      }
      // multiple choice
      return formValues[`checkboxGroup_${questionElem.id}`].length === 0;
    case 2: // time test
      return !formValues[`time_${questionElem.id}`];
    case 3: // date test
      return !formValues[`date_${questionElem.id}`];
    case 4: // number test
      return !formValues[`number_${questionElem.id}`];
    case 6: // height test
      return !formValues[`feet_${questionElem.id}`] && !formValues[`inches_${questionElem.id}`];
    case 7: // weight test
      return !formValues[`pounds_${questionElem.id}`];
    case 9: // text test
      for (let i = 0; i < questionElem.maxAnswerCount; i += 1) {
        if (formValues[`textResponse_${questionElem.id}_${i}`]) {
          return false;
        }
      }
      return true;
    default: return false;
  }
};

// This function will generate the answer Array that will be passed to the save call
export const getAnswerObject = (formValues, questionElem) => {
  const textAnswer = [];
  let selectedChoice;
  let selectedNoneChoice;
  let noneChoice;
  const id = get(questionElem, 'answer.id', null);
  switch (questionElem.questionTypeId) {
    case 1: // choice
      // single choice functionality
      if (questionElem.singleAnswer) {
        // find selected choice
        selectedChoice = questionElem.questionChoices.find(choice => choice.choiceText === formValues[`radioGroup_${questionElem.id}`]);
        // return noneResponse if selected choice isNoneChoice
        if (selectedChoice && selectedChoice.isNoneChoice) {
          return {
            id,
            noneResponse: true,
            questionId: questionElem.id,
          };
        }

        // return selected choice
        if (selectedChoice) {
          return {
            id,
            questionChoiceId: [selectedChoice.id],
            questionId: questionElem.id,
          };
        }
      }
      // multiple choice functionality
      // find none choice
      noneChoice = questionElem.questionChoices.find(choice => choice.isNoneChoice);
      // check if the values contain a none choice
      selectedNoneChoice = noneChoice ? formValues[`checkboxGroup_${questionElem.id}`].find(value => noneChoice.id === value) : null;
      return {
        id,
        noneResponse: selectedNoneChoice ? true : undefined,
        questionChoiceId: selectedNoneChoice || formValues[`checkboxGroup_${questionElem.id}`],
        questionId: questionElem.id,
      }
    case 2: // time
      return {
        id,
        timeResponse: formValues[`time_${questionElem.id}`] ? `${formValues[`time_${questionElem.id}`].getHours()}:${formValues[`time_${questionElem.id}`].getMinutes()}:00` : null,
        questionId: questionElem.id,
      };
    case 3: // date
      // if the date is coming from the DB, it is a string, otherwise it's a Date. handle type conv here
      return {
        id,
        dateResponse: formValues[`date_${questionElem.id}`]
          ? typeof formValues[`date_${questionElem.id}`] === 'string'
            ? format(parseISO(formValues[`date_${questionElem.id}`]), 'yyyy-MM-dd')
            : format(formValues[`date_${questionElem.id}`], 'yyyy-MM-dd')
          : null,
        questionId: questionElem.id,
      };
    case 4: // number
      return {
        id,
        numberResponse: formValues[`number_${questionElem.id}`],
        questionId: questionElem.id,
      };
    case 6: // height
      // convert feet to inches
      return {
        id,
        numberResponse: (parseInt(formValues[`feet_${questionElem.id}`], 10) * 12) + parseInt(formValues[`inches_${questionElem.id}`], 10),
        questionId: questionElem.id,
      };
    case 7: // weight
      return {
        id,
        numberResponse: formValues[`pounds_${questionElem.id}`],
        questionId: questionElem.id,
      };
    case 9: // text
      for (let i = 0; i < questionElem.maxAnswerCount; i += 1) {
        if (formValues[`textResponse_${questionElem.id}_${i}`]) {
          textAnswer.push(formValues[`textResponse_${questionElem.id}_${i}`]);
        }
      }
      return {
        id,
        textResponse: textAnswer.length === 1 ? textAnswer[0] : textAnswer,
        questionId: questionElem.id,
      };
    default: return {};
  }
};
