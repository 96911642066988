import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDna,
  faEnvelope,
  faEnvelopeOpenText,
  faLaptop,
} from '@fortawesome/free-solid-svg-icons';
import images from 'resources/images';
import { colors } from 'common/assets/sharedUI';
import ContactSupport from './ContactSupport';
import icons from '../resources/icons';

import { registerBarcodeButtonClicked } from '../store/actions/registration';

const styles = theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '-65px',
  },
  contentWrapper: {
    maxWidth: 450,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    fontSize: 24,
    color: colors.blue_03,
    fontWeight: 300,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  stepListWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '1em 0',
  },
  stepWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  stepIconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    width: 44,
    borderRadius: '50%',
    border: '2px solid',
    borderColor: colors.gray_03,
  },
  stepIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 4px white',
    background: colors.primaryBlue,
    height: 36,
    width: 38,
    borderRadius: '50%',
    textAlign: 'center',
    color: 'white',
  },
  stepText: {
    paddingLeft: 12,
  },
  separator: {
    border: '1px solid',
    borderColor: colors.gray_03,
    height: '1.75em',
    width: 0,
    marginLeft: 22,
  },
  actionWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: colors.gray_02,
    padding: '1em',
    [theme.breakpoints.down('xs')]: {
      margin: '0 -20px',
      width: '100vw',
    },
  },
  button: {
    backgroundColor: colors.primaryBlue,
    borderRadius: 4,
    fontWeight: 700,
    fontSize: 16,
    color: colors.white,
    margin: '0.5em 0',
    padding: '.75em 5em',
    '&:hover': {
      background: colors.blue_05,
      color: colors.white,
    },
  },
  contactSupportWrapper: {
    padding: '1em',
  },
  customIcon: {
    fontSize: '2rem',
  },
  customImg: {
    height: '100%',
    width: '100%',
    padding: 8,
    filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%)',
  },
});

const WhatToExpectKitList = ({ classes, setBarcodeReceived }) => {
  const dispatch = useDispatch();
  const handleRegisterBarcodeClicked = useCallback(() => {
    setBarcodeReceived();
    dispatch(registerBarcodeButtonClicked());
  }, [setBarcodeReceived, dispatch]);

  const { hasConsult } = useSelector(state => state.profile.userProfile.data);

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <img src={images.whatToExpectNext} alt="What to expect next" width={225} />
        <div className={classes.headerTextWrapper}>
          <Typography variant="h1" name="header" className={classes.header}>That&apos;s it!</Typography>
          <Typography variant="h2" name="subtitle" className={classes.subtitle}>Here&apos;s what to expect next</Typography>
        </div>
        <div className={classes.stepListWrapper}>
          <div className={classes.stepWrapper}>
            <div className={classes.stepIconWrapper}>
              <div className={classes.stepIcon}>
                <FontAwesomeIcon icon={faDna} />
              </div>
            </div>
            <Typography name="step1" className={classes.stepText}>
              We will email you as soon as your kit has shipped, along with tracking information.
            </Typography>
          </div>
          <div className={classes.separator} />
          <div className={classes.stepWrapper}>
            <div className={classes.stepIconWrapper}>
              <div className={classes.stepIcon}>
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            </div>
            <Typography name="step2" className={classes.stepText}>
              It takes about 5 minutes to collect your sample and drop it in your mailbox.
            </Typography>
          </div>
          <div className={classes.separator} />
          <div className={classes.stepWrapper}>
            <div className={classes.stepIconWrapper}>
              <div className={classes.stepIcon}>
                <FontAwesomeIcon icon={faEnvelopeOpenText} />
              </div>
            </div>
            <Typography className={classes.stepText}>
              When your sample reaches the lab, we will notify you via email.
            </Typography>
          </div>
          <div className={classes.separator} />
          <div className={classes.stepWrapper}>
            <div className={classes.stepIconWrapper}>
              <div className={classes.stepIcon}>
                <FontAwesomeIcon icon={faLaptop} />
              </div>
            </div>
            <Typography className={classes.stepText}>
              After your sample arrives at the lab, you will get your analysis in up to 4 weeks.
            </Typography>
          </div>
          {hasConsult && (
            <React.Fragment>
              <div className={classes.separator} />
              <div className={classes.stepWrapper}>
                <div className={classes.stepIconWrapper}>
                  <div className={classes.stepIcon}>
                    <Icon className={classes.customIcon}>
                      <img src={icons['consult']} className={classes.customImg} alt="" />
                    </Icon>
                  </div>
                </div>
                <Typography className={classes.stepText}>
                  We'll show you where you can sign up for your 1-on-1 consultation when your results are delivered.
                </Typography>
              </div>
            </React.Fragment>
          )}
        </div>
        <div className={classes.actionWrapper}>
          <Typography variant="h1" className={classes.header}>Received your kit?</Typography>
          <Button className={classes.button} onClick={handleRegisterBarcodeClicked}>REGISTER BARCODE</Button>
        </div>
        <div className={classes.contactSupportWrapper}>
          <ContactSupport />
        </div>
      </div>
    </div>
  );
};

WhatToExpectKitList.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    contentWrapper: PropTypes.string,
    headerTextWrapper: PropTypes.string,
    header: PropTypes.string,
    subtitle: PropTypes.string,
    stepListWrapper: PropTypes.string,
    stepWrapper: PropTypes.string,
    stepIconWrapper: PropTypes.string,
    stepIcon: PropTypes.string,
    stepText: PropTypes.string,
    separator: PropTypes.string,
    actionWrapper: PropTypes.string,
    button: PropTypes.string,
    contactSupportWrapper: PropTypes.string,
    customIcon: PropTypes.string,
    customImg: PropTypes.string,
  }).isRequired,
  setBarcodeReceived: PropTypes.func.isRequired,
};

export default withStyles(styles)(WhatToExpectKitList);
