import React from 'react'
import PropTypes from 'prop-types'
import { Typography, ButtonBase } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'
import PaperContainer from './PaperContainer'
import ContactSupport from './ContactSupport'

const styles = {
  title: {
    fontSize: 22,
    color: colors.blue_03,
    marginBottom: 20,
    textAlign: 'left',
    lineHeight: '1em',
  },
  bold: {
    fontWeight: 'bold',
  },
  description: {
    marginBottom: 30,
  },
  questionsContainer: {
    display: 'flex',
  },
  questionsTxt: {
    fontSize: '1.15rem',
  },
  link: {
    fontSize: '1.15rem',
    marginLeft: 10,
  },
  button: {
    borderRadius: 5,
    backgroundColor: colors.primaryBlue,
    color: 'white',
    width: '90%',
    fontSize: 18,
    margin: '30px auto 15px auto',
    display: 'block',
    padding: 10,
  },
}

const BioBankingResearchInfo = (props) => {
  const { isBioBank, onClickContinue, classes } = props

  return (
    <PaperContainer style={{ textAlign: 'left', marginTop: '-65px' }}>
      <Typography className={classes.title}>We invite you to participate in GenoPalate&apos;s Research Program</Typography>
      <Typography variant="body1" className={classes.bold}>What is GenoPalate&apos;s Research Program?</Typography>
      <Typography variant="body1" className={classes.description}>GenoPalate is committed to advancing the science of how food and nutrition impact our health. The information that GenoPalate is able to bring to you today through our app and reports is the result of decades of scientific research supported by health organizations such as the National Institute of Health and the National Science Foundation and many other society sponsors that continue investing in research. Those studies are only made possible by the participation of individuals who are willing to be a partner in those studies. Today GenoPalate launches its own research initiative where we will aim to make new discoveries about nutrition and our health. We invite you to participate in this initiative.</Typography>
      <Typography variant="body1" className={classes.bold}>Why should I participate?</Typography>
      <Typography variant="body1" className={classes.description}>By participating in GenoPalate&apos;s research study, you can contribute to the science of discovering how diet, lifestyle, environment, and genetics play a crucial role in health. This study will eventually help us better predict health outcomes and assist in preventing and controlling health risks. Your participation is completely optional and you can opt-out at any time. The discoveries we make through this study will be used to improve the insights we can deliver to you, as well as being published in reputable scientific journals. Your data will always be de-identified and protected using the highest data privacy and security standards.</Typography>
      { isBioBank ? (
        <React.Fragment>
          <Typography variant="body1" className={classes.bold}>What is biobanking?</Typography>
          <Typography variant="body1" className={classes.description}>A biobank is a repository where DNA samples are stored and then used later for analysis using advanced technologies for research purposes such as Whole Genome Sequencing, Whole Exome Sequencing and Epigenomic Profiling.</Typography>
          <Typography variant="body1" className={classes.bold}>Why should I biobank my sample?</Typography>
          <Typography variant="body1" className={classes.description}>By biobanking your sample with us, we will have access to your DNA for a longer period of time in which we may use new techniques to further analyze your sample to develop innovative solutions for nutrition related chronic diseases that are not possible today.</Typography>
        </React.Fragment>
      ) : null }
      <div className={classes.questionsContainer}>
        <Typography variant="body1" className={classes.questionsTxt}>Still have questions?</Typography>
        <a href="https://www.genopalate.com/pages/research" target="_blank" rel="noopener noreferrer" className={classes.link}>Learn more</a>
      </div>
      <ButtonBase className={classes.button} onClick={onClickContinue}>View Consent Options</ButtonBase>
      <ContactSupport style={{ textAlign: 'center' }} />
    </PaperContainer>
  )
}

BioBankingResearchInfo.propTypes = {
  isBioBank: PropTypes.bool.isRequired,
  onClickContinue: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    title: PropTypes.string,
    bold: PropTypes.string,
    description: PropTypes.string,
    questionsContainer: PropTypes.string,
    questionsTxt: PropTypes.string,
    link: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(BioBankingResearchInfo)
