import { axios } from 'utils';
import { constants } from 'resources';

export default async function getProgress(questionnaireId) {
  try {
    const params = {
      questionnaireId,
    };
    const { data } = await axios.post(constants.API_GET_PROGRESS_QUESTIONNAIRE, params);
    return data;
  } catch (e) {
    console.log({ e });
    return { e };
  }
}
