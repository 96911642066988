import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    marginBottom: 10,
    maxWidth: 550,
  },
  text: {
    color: colors.blue,
  },
}));

export default useStyles;
