import './utils/setEnv'; // a polyfill of sorts to set the ENV config
import React, { useEffect, useCallback } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  Router,
} from 'react-router-dom';

import CacheBuster from 'components/CacheBuster';

import { getUrlParam } from 'utils/dataTools';

import { init, RouteTracker } from 'utils/googleanalytics';
import ScrollToTop from './components/ScrollToTop';
import Routes from './routes';
import store from './store/configureStore';
import { history } from './routes/history';
import { allowedUsers, fetchFeatures } from './services/featureFlags';
import { disableFeature, enableFeature } from './store/actions/featureFlag';
import { get } from 'lodash';

const { IS_PROD, IS_STAGING } = window.env;

if (IS_PROD) {
  Sentry.init({
    dsn: 'https://7235fe74c8564ecf9577f4e4840a5a42@sentry.io/2104190',
  });
}

if (IS_STAGING) {
  Sentry.init({
    dsn: 'https://0939f3d9a40149c48fcf981ce75b9b8a@sentry.io/2076723',
  });
}

const productCode = getUrlParam('product-code') ? getUrlParam('product-code') : null;

const App = () => {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.profile);
  
  const processFeatures = useCallback(async () => {
    try {
      // only run when get the user's profile
      if (profile?.status === 'resolved') {
        const features = await fetchFeatures();
        const email = get(profile, 'userProfile.data.email');
        features.forEach(([featureName, isEnabled]) => {
          // add this usersToSeeFeature when we restrict this feature for specific users
          // const usersToSeeFeature = [...allowedUsers].includes(email);
          isEnabled ? dispatch(enableFeature(featureName)) : dispatch(disableFeature(featureName))
        }
        );
      }
    } catch (error) {
      console.log('error', error)
    }

  }, [dispatch, profile?.status]);

  useEffect(() => {
    processFeatures();
  }, [dispatch, profile?.status]);

  return (
    <Router history={history}>
      <ScrollToTop>
        {init() && <RouteTracker />}
        <Routes productCode={productCode} />
      </ScrollToTop>
    </Router>
)};

let theme = createMuiTheme({
  typography: {
    fontFamily: ['"Swiss721-MD-BT", "Baskerville", "Swiss721-Roman"'].join(','),
  },
  overrides: {
    MuiContainer: {
      root: {
        '@media print': {
          padding: '0 0 0 0 !important',
          '& @page': {
            margin: '0',
          },
        },
      },
    },

  },
});

theme = responsiveFontSizes(theme);

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Provider store={store}>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // Reload will be forced when current version is not the latest version
            refreshCacheAndReload();
          }
          return (
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          );
        }}
      </CacheBuster>
    </Provider>
  </MuiPickersUtilsProvider>,
  document.getElementById('root'),
);
