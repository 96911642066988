import { axios } from 'utils';
import { constants } from 'resources';

export default async function assignBarcode(barcode) {
  const params = {
    barcode
  };

  return axios.post(constants.API_ASSIGN_BARCODE, params);
}
