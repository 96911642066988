import { axios } from 'utils'
import { constants } from 'resources'
import UserProduct from 'models/UserProduct'

export default async function getProduct() {
  try {
    const { data: { product } } = await axios.get(constants.API_USER_PRODUCT)
    //console.log(product)
    return {product}
  } catch (e) { console.log({ e }) }
}
