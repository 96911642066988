import { axios } from 'utils';
import { constants } from 'resources';

export default async function setBannerEvent(banner) {
  try {
    return axios.post(constants.API_SET_BANNER, { banner });
  } catch (e) {
    return null;
  }
}
