import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const maxWidth = 120;

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {
    width: '100%',
    maxWidth,
  },
  label: {
    color: colors.white,
    maxWidth,
  },
});

export default useStyles;
