import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  FormControl, FormGroup, Grid, FormControlLabel, Paper, Typography,
  IconButton, Icon,
} from '@material-ui/core'
import { cloneDeep } from 'lodash'
import { getIcon } from 'utils/dataTools'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CustomCheckbox from 'components/CustomCheckbox'
import PropTypes from 'prop-types'
import useStyles from './MultipleChoiceForm.styles'

export default function MultipleChoiceForm(props) {
  const {
    id,
    questionText,
    values,
    errors,
    setFieldValue,
    choices,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const handleChange = (choice) => {
    // If noneChoice is selected, and it was not selected previously, set selected choice to the noneChoice
    // If an existing choice is selected, undo the selection
    if (choice.isNoneChoice && !values[`checkboxGroup_${id}`].includes(choice.id)) {
      setFieldValue(`checkboxGroup_${id}`, [choice.id])
    } else if (values[`checkboxGroup_${id}`].includes(choice.id)) {
      const nextValue = values[`checkboxGroup_${id}`].filter(value => value !== choice.id)
      setFieldValue(`checkboxGroup_${id}`, nextValue)
    } else {
      const noneOpt = choices.find((choiceOpt) => choiceOpt.isNoneChoice) || { id: '' }
      const nextValue = values[`checkboxGroup_${id}`].includes(noneOpt.id) ? values[`checkboxGroup_${id}`].concat(choice.id).filter(value => value !== noneOpt.id) : values[`checkboxGroup_${id}`].concat(choice.id)
      setFieldValue(`checkboxGroup_${id}`, nextValue)
    }
  }

  // Generate components for the selected choices
  const generateChoices = (selectedChoices) => selectedChoices.map((choice) => {
    if (choice.imageLocation) {
      return (
        <Grid item xs={3} md={2} className={classes.iconContainer} key={choice.id}>
          <IconButton onClick={() => handleChange(choice)}>
            <Icon className={questionText.includes('diet') ? classes.icon : classes.preferencesIcon}>
              <img src={getIcon(choice.imageLocation)} alt="" className={classes.allergyImg} />
            </Icon>
            {
                values[`checkboxGroup_${id}`] && values[`checkboxGroup_${id}`].includes(choice.id) ? (
                  choice.overlayLocation === 'CHECK_CIRCLE' ? (
                    <CheckCircleOutlineIcon className={classes.overlayIcon} />
                  ) : (
                    <BlockIcon className={classes.overlayBlockIcon} />
                  )
                ) : null
              }
          </IconButton>
          <span className={classes.allergyTitle}>{choice.choiceText}</span>
        </Grid>
      )
    }
    const formControlProps = {
      label: (
        <div>
          <Typography variant="body1">{choice.choiceText}</Typography>
          {choice.choiceSubtext ? (
            <Typography variant="body1" className={classes.subtext}>{choice.choiceSubtext}</Typography>
          ) : null}
        </div>
      ),
      checked: values[`checkboxGroup_${id}`] ? values[`checkboxGroup_${id}`].includes(choice.id) : false,
      onChange: () => handleChange(choice),
    }

    return (
      <Paper className={values[`checkboxGroup_${id}`] && values[`checkboxGroup_${id}`].includes(choice.id) ? classes.selectedPaper : classes.paper} key={choice.id}>
        <FormControlLabel {...formControlProps} control={<CustomCheckbox />} />
      </Paper>
    )
  })

  // Dynamically generate columns for the components
  const generateStylesForChoices = () => {
    const hasImage = choices.find((choice) => choice.imageLocation)

    if (hasImage) {
      return (
        <Grid container item xs={12} className={classes.iconChoiceContainer} justify={choices.length < 6 ? 'space-between' : 'center'}>
          {generateChoices(choices)}
        </Grid>
      )
    }

    const gridArr = []
    const colNum = xsDown ? 0 : parseInt(choices.length / 6, 10)
    const itemNum = Math.round(choices.length / (colNum + 1))

    for (let i = 0; i < colNum + 1; i += 1) {
      const tempChoices = cloneDeep(choices)
      gridArr.push(
        //previous xs={colNum === 0 ? 12 : 6}
        <Grid container item xs={12} className={classes.choiceContainer} key={i}>
          {generateChoices(tempChoices.splice(itemNum * i, itemNum))}
        </Grid>,
      )
    }

    

    return (
      <Grid container>
        {gridArr}
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.questionContainer}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {generateStylesForChoices()}
            {errors[`checkboxGroup_${id}`] && <Typography variant="body1" className={classes.errorTxt}>{errors[`checkboxGroup_${id}`]}</Typography>}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

MultipleChoiceForm.propTypes = {
  id: PropTypes.number.isRequired,
  questionText: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  setFieldValue: PropTypes.func.isRequired,
  choices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    choiceText: PropTypes.string,
    choiceSubtext: PropTypes.string,
    imageLocation: PropTypes.string,
    overlayLocation: PropTypes.string,
    isNoneChoice: PropTypes.bool,
  })),
}

MultipleChoiceForm.defaultProps = {
  questionText: '',
  values: {},
  errors: {},
  choices: [],
}
