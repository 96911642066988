import React from 'react';
import { Table, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Typography, makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './MarketingSection.styles'

const MarketingSection = ({ data, title, description }) => {
    const classes = useStyles();
    return (
        <div className={classes.marketingSection}>
            <Typography variant="body1" className={classes.marketingTitle}>{title}</Typography>
            <Typography variant="body2" className={classes.marketingDesc}>{description}</Typography>
            <Table className={classes.table}>
                <TableBody>
                    {data?.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell className={clsx(classes.tableCell, classes.topAlignedElement)}>
                                <img alt="" src={row.image} className={classes.image} />
                            </TableCell>
                            <TableCell className={clsx(classes.tableCell, classes.removePadding)}>
                                <Typography variant="h5" className={classes.marketingSubTitle}>{row.title}</Typography>
                                <Typography variant="body4" className={classes.marketingSubDes}>{row.description}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default MarketingSection