import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles({
  paperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    width: '92%',
    border: `1px solid ${colors.darkGrey}`,
    padding: '2rem',
    zIndex: 10,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 'bold',
  },
  icon: {
    width: 40,
    height: 40,
  },
  descriptionContainer: {
    marginTop: 20,
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default useStyles
