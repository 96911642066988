import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import BackButton from 'components/BackButton';
import { ProductCard } from '../../components/componentsV2';
import withCartInitialized from '../../components/withCartInitialized';
import { addItemToCart } from '../../store/actions/additionalProducts';
import { registerGAClickEventEnhanced, EventCategories } from '../../utils/googleanalytics';
import { useQueryParams } from 'hooks';
import { genopalateClickIdKey } from 'services/shopify/utils';

const styles = {
  productCard: {
    display: 'flex',
  },
};

const AdditionalProducts = (props) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { getQueryParam } = useQueryParams()
  const { classes } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const { products, cart } = useSelector((state) => state.additionalProducts);

  const addToCartClicked = useCallback((product) => {
    let additionalAttributes = {};
    if (getQueryParam(genopalateClickIdKey)){
      additionalAttributes = {
        key: genopalateClickIdKey,
        value: getQueryParam(genopalateClickIdKey)
      };
    }

    registerGAClickEventEnhanced(EventCategories.AddOns, 'Add to Cart', product.name);
    dispatch(addItemToCart(product.id, undefined, undefined, additionalAttributes));
  }, [dispatch]);

  const onLearnMoreClicked = useCallback((product) => {
    registerGAClickEventEnhanced(EventCategories.AddOns, 'Learn More', product.name);
    history.push(`/account/products/product-details/${product.id}`);
  }, [history]);

  const mappedProducts = useMemo(() => {
    const prods = get(products, 'data', []);
    const nonSubscriptionProds = prods.filter(product => !product.tags.includes('SUBSCRIPTION'));
    const cartLines = get(cart, 'cart.lineItems', []);
    // todo: move the atob parse of the productId into Redux and decide how to handle the URL
    return nonSubscriptionProds.map(prod => ({
      ...prod,
      inCart: !!cartLines.find(line => line.id === atob(prod.id)),
    }));
  }, [products, cart]);

  return (
    <div>
      <div style={{ marginTop: 20, marginLeft: smDown ? 0 : 20 }}>
        <BackButton />
        <Typography variant={smDown ? 'h4' : 'h3'} align="left" gutterBottom>
          Additional Products
        </Typography>
      </div>
      <Grid container spacing={smDown ? 2 : 3}>
        {mappedProducts.map(product => (
          <Grid key={product.id} item xs={6} sm={4} md={3} className={classes.productCard}>
            <ProductCard
              product={product}
              onAddToCartClicked={addToCartClicked}
              onLearnMoreClicked={onLearnMoreClicked}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

AdditionalProducts.propTypes = {
  classes: PropTypes.shape({
    productCard: PropTypes.string,
  }).isRequired,
};

export default withCartInitialized(withStyles(styles)(AdditionalProducts));
