import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles({
  card: {
    padding: 0,
  },
  cardContent: {
    padding: '0 !important',
    margin: 0,
    minWidth: 320,
  },
  titleContainer: {
    paddingTop: 5,
    paddingBottom: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 800,
    color: colors.darkGrey,
  },
  subtitle: {
    color: colors.darkGrey,
  },
  leftContainer: {
    backgroundColor: colors.highlightsBlue,
    color: colors.white,
    height: 130,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 10,
  },
  rightContainer: {
    backgroundColor: colors.lightGrey,
    color: colors.darkGrey,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 10,
  },
  intakeText: {
    fontWeight: 800,
    fontSize: '24px',
  },
  recommendation: {
    textAlign: 'center',
    fontSize: '14px',
  },
  buttonContainer: {
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  buttonText: {
    fontWeight: 800,
  },
})

export default useStyles
