import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import { colors, fonts } from 'common/assets/sharedUI'
import { helpers } from 'utils'
import subscriptionHelpers, {
  SUBSCRIPTION_DURATIONS,
  calculateDiscountedPrice,
} from 'utils/subscriptionHelpers'
import { useSelector } from 'react-redux'

/**
 * Individual supply plan button.
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.disabled - Whether the button is disabled.
 * @param {string} props.supplyPlan - The supply plan duration.
 * @param {string} props.planPrice - The price of the plan.
 * @param {Function} props.onClickPlan - Function to handle the click event.
 * @param {boolean} props.isActive - Whether the button is currently active.
 * @returns {JSX.Element} SupplyButton component.
 */
export const SupplyButton = ({
  disabled,
  supplyPlan,
  planPrice,
  onClickPlan,
  isActive,
  durationUnit,
  pricePerMonth,
  isMonthly,
  activeSubscription,
}) => {
  const classes = useStyles()
  const { data: allSubscriptionProducts } = useSelector((state) => state.additionalProducts.subscriptionProducts);
  const monthlyProduct = subscriptionHelpers.getSpecificSubscriptionPlans(allSubscriptionProducts, ['VS0000-1MO'])[0]
  const savingPrice = calculateDiscountedPrice(99, isMonthly && supplyPlan === SUBSCRIPTION_DURATIONS.THREE ? monthlyProduct.pricePerMonth : pricePerMonth)

  return (
    <Box
      className={[
        classes.supplyButton,
        isActive
          ? classes.supplyButtonBorderActive
          : classes.supplyButtonBorderInActive,
        isMonthly && activeSubscription && supplyPlan !== SUBSCRIPTION_DURATIONS.THREE ? classes.supplyButtonDisabled : {},
      ]}
      onClick={!disabled ? () => onClickPlan(supplyPlan) : () => {}}
    >
      <Typography className={classes.supplyButtonHeading}>
        {`${supplyPlan}-Month Supply`}
      </Typography>
      <Box className={classes.supplyButtonWrapper}>
        <Typography variant='subtitle1' className={classes.supplyButtonText}>
          {`${helpers.formatNumber(isMonthly && supplyPlan === SUBSCRIPTION_DURATIONS.THREE ? monthlyProduct.pricePerMonth : pricePerMonth)}/${durationUnit}`}
        </Typography>

        {savingPrice && (
          <Typography
            variant='subtitle1'
            className={classes.showCostSavingLabel}
          >
            {`Save ${Math.round(savingPrice)}%`}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

SupplyButton.propTypes = {
  disabled: PropTypes.bool,
  supplyPlan: PropTypes.string,
  planPrice: PropTypes.string,
  onClickPlan: PropTypes.func,
  isActive: PropTypes.bool,
  durationUnit: PropTypes.number,
  savingPrice: PropTypes.number,
  isMonthly: PropTypes.bool,
  activeSubscription: PropTypes.bool,
}

/**
 * Container for a group of supply plan buttons.
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.plans - An array of plans, where each plan is an object with supplyPlan, planPrice, and isActive properties.
 * @param {Function} props.onClickPlan - Function to handle the click event for a plan.
 * @param {boolean} props.disabled - Whether the buttons should be disabled.
 * @returns {JSX.Element} SupplyButtons component.
 */
const SupplyButtons = ({
  plans = [],
  onClickPlan,
  disabled,
  activeSupplyPlan,
  supplyButtonContainerStyle,
  isMonthly,
  activeSubscription,
}) => {
  const classes = useStyles()

  const showCostSavingLabel = useMemo(() => {
    const [threeMonths, sixMonths] = plans
    return calculateDiscountedPrice(threeMonths.planPrice, sixMonths.planPrice)
  }, [plans.length])

  return (
    <Grid
      container
      spacing={2}
      className={[...classes.supplyButtonContainer, supplyButtonContainerStyle]}
    >
      {plans.map((plan) => {
        return (
          <Grid item>
            <SupplyButton
              key={plan.supplyPlan}
              disabled={disabled}
              onClickPlan={onClickPlan}
              isActive={plan.supplyPlan === activeSupplyPlan}
              isMonthly={isMonthly}
              activeSubscription={activeSubscription}
              {...plan}
              durationUnit='mo.'
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

SupplyButtons.propTypes = {
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      supplyPlan: PropTypes.string,
      planPrice: PropTypes.string,
      isActive: PropTypes.bool,
    })
  ),
  onClickPlan: PropTypes.func,
  disabled: PropTypes.bool,
  supplyButtonContainerStyle: PropTypes.object,
  isMonthly: PropTypes.bool,
  activeSubscription: PropTypes.bool,
}

export default SupplyButtons

const useStyles = makeStyles((theme) =>
  createStyles({
    supplyButtonContainer: {
      marginTop: '30px',
      marginBottom: '12px',
    },
    supplyButton: {
      lineHeight: 0,
      borderRadius: '10px',
      textTransform: 'none',
      padding: '5px 15px',
      paddingBottom: '15px',
      cursor: 'pointer',
      minWidth: 193,
    },
    supplyButtonBorderActive: {
      border: `2px solid ${colors.blue__06}`,
    },
    supplyButtonBorderInActive: {
      border: `1px solid ${colors.gray_04}`,
    },
    supplyButtonDisabled: {
      backgroundColor: colors.gray_01,
    },
    supplyButtonHeading: {
      fontWeight: '700',
      fontFamily: fonts.subHeading,
      fontSize: '18px',
      color: colors.gray_06,
    },

    supplyButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      gap: 5,
    },

    supplyButtonText: {
      fontWeight: 500,
      fontFamily: fonts.subHeading,
      fontSize: '16px',
      color: colors.gray_06,
      lineHeight: 1,
    },

    showCostSavingLabel: {
      fontFamily: fonts.subHeading,
      color: colors.blue__07,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: 1,
    },
  })
)
