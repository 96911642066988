import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { colors } from 'common/assets/sharedUI';

const styles = {
  container: {
    borderRadius: 6,
    textAlign: 'center',
    padding: '4px 6px',
    width: 'auto',
    display: 'inline-block',
  },
  textStyle: {
    fontSize: 13,
    fontWeight: '700',
  },
};

function Label(props) {
  const {
    text, backgroundColor, textColor, classes,
  } = props;

  return (
    <div style={{ backgroundColor }} className={classes.container}>
      <Typography className={classes.textStyle} style={{ color: textColor }}>{text}</Typography>
    </div>
  );
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  classes: PropTypes.shape({
    container: PropTypes.string,
    textStyle: PropTypes.string,
  }).isRequired,
};

Label.defaultProps = {
  backgroundColor: colors.gray_01,
  textColor: colors.gray_05,
};

export default withStyles(styles)(Label);
