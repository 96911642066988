import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import helpers from '../../utils/helpers'
import { colors, fonts } from '../../common/assets/sharedUI'

const useStyles = makeStyles((theme) => {
  return {
    headerText: {
      color: colors.green_07,
      fontFamily: fonts.BaskervilleBold,
      fontSize: 35,
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        fontSize: 26,
      },
    },
  }
})

// TODO: update styling
const Header = ({ name = '', formulaDescriptor }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.headerText}>
      {`${
        name ? `${helpers.possessive(name)} ` : 'Your '
      }${formulaDescriptor} Formula`}
    </Typography>
  )
}

export default Header

Header.propTypes = {
  name: PropTypes.string.isRequired,
  formulaDescriptor: PropTypes.string.isRequired,
}
