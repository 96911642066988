// @flow
import React, { PureComponent } from 'react'
import {
  Row, Col,
} from 'antd'
import ReactGA from 'react-ga4'
import { ForgotPasswordForm } from 'components'
import { images } from 'resources'
import './styles.css'

const { IS_PROD, IS_STAGING } = window.env

type Props = {};
export default class ForgotPasswordPage extends PureComponent<Props> {
  componentDidMount() {
    if (IS_PROD || IS_STAGING) {
      ReactGA.send({ hitType: "pageview", page: '/forgot_password' });
    }
  }

  render() {
    return (
      <Row type="flex" justify="space-around" align="middle" styleName="container" className="flex_1 center" style={{ textAlign: 'center' }}>
        <Col>
          <Row type="flex" justify="space-around" align="middle">
            <img src={images.logo} alt="logo" styleName="logo" />
          </Row>
          <h1 className="marginB-20">Forgot your password?</h1>
          <ForgotPasswordForm />
        </Col>
      </Row>
    )
  }
}
