import { combineReducers } from 'redux';
import { getInitialState } from 'utils/storage';
import reduxStorageWhiteList from '../constants';
import actionItemsReducer from './actionItems';
import additionalProducts from './additionalProducts';
import consentReducer from './consent';
import dnaUploadReducer from './dnaUpload';
import domainReducer from './domain';
import foodReducer from './food';
import geneticsReducer from './genetics';
import nutritionReducer from './nutrition';
import preferenceReducer from './preferences';
import profileReducer from './profile';
import questionnaireReducer from './questionnaire';
import registrationReducer from './registration';
import subscriptionReducer from './subscription';
import statusReducer from './status';
import topFoodsReducer from './topFoods';
import traitReducer from './trait';
import userReducer from './user';
import featureFlagReducer from './featureFlagReducer';
import registrationTMKitReducer from './registrationTMKit';

const allReducers = combineReducers({
  actionItems: actionItemsReducer,
  additionalProducts,
  consent: consentReducer,
  dnaUpload: dnaUploadReducer,
  domain: domainReducer,
  food: foodReducer,
  genetics: geneticsReducer,
  nutrition: nutritionReducer,
  profile: profileReducer,
  preferences: preferenceReducer,
  questionnaire: questionnaireReducer,
  subscription: subscriptionReducer,
  registration: registrationReducer,
  status: statusReducer,
  topFoods: topFoodsReducer,
  trait: traitReducer,
  user: userReducer,
  featureFlag: featureFlagReducer,
  registerKit: registrationTMKitReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    state = undefined;
  }

  getInitialState(reduxStorageWhiteList);
  return allReducers(state, action);
};

export default rootReducer;
