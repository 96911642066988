import React, { useMemo } from 'react';
import { faClipboardListCheck } from '@fortawesome/pro-duotone-svg-icons';
import helpers from '../../../utils/helpers'

import {
  nutrientsHeader,
  nutrientsSubheading,
  nutrientsLink,
} from 'resources/constants';
import CustomHeader from '../CustomHeader';
import useStyles from './Nutrients.styles';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { EventActions, EventCategories, registerGAClickEventEnhanced } from 'utils/googleanalytics';

function Nutrients() {
    const classes = useStyles();
    const { userProfile } = useSelector((state) => state.profile);
    const name = useMemo(() => (get(userProfile, 'data.name', '')), [userProfile]);
    const onClickNutrientsLink = () => {
      registerGAClickEventEnhanced(EventCategories.Nutrients, EventActions.NutrientsRecommendationCalculation, EventActions.NutrientsRecommendationCalculation);
    }

  return (
    <CustomHeader
      header={`${helpers.possessive(name)} ${nutrientsHeader}`}
      subHeading={nutrientsSubheading}
      link={'https://www.genopalate.com/pages/creating-your-analysis'}
      icon={faClipboardListCheck}
      linkText={nutrientsLink}
      extraStyles={classes.extraStyles}
      iconStyles={classes.icon}
      bannerStyles={classes.banner}
      headingStyles={classes.textStyles}
      subheadingStyles={classes.textStyles}
      linkStyles={classes.textStyles}
      onClickLink={onClickNutrientsLink}
    />
  );
}

export default Nutrients;
