/* eslint-disable react/prop-types */
import { Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect, useMemo } from "react";
import { images } from "resources";
import { history } from "routes/history";
import {
  EventActions,
  EventCategories,
  EventLabels,
  registerGAClickEventEnhanced,
} from "utils/googleanalytics";
import { useSelector, useDispatch } from "react-redux";
import { fetchSubscriptionProducts } from "../store/actions/additionalProducts";
import { internalURLs, supplementProductSkus } from "../resources/constants";
import { appConstants } from "utils/constants/appConstants";
import ShopSupplementBanner from "./ShopSupplementBanner";
import useGenoBlendSubscriptions from "../hooks/useGenoBlendSubscriptions";
import { calculateDiscountedPrice } from "./BlendSubscription/BlendSubscriptionHeader/BlendSubscriptionHeader";
import { useFeatureToggle } from "hooks";
import { FeatureComponentId } from "../services/featureFlags";

export default function SupplementFeaturedSpot(props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { eventCaller } = props;
  const dispatch = useDispatch();

  const [ isEnabled ] = useFeatureToggle();

  const { genoBlendBaseProduct } = useGenoBlendSubscriptions();
  // Subscription Products
  const {
    data: subscriptionProducts,
    fetching,
    error,
  } = useSelector((state) => state.additionalProducts.subscriptionProducts);

  const baseProduct = useMemo(
    () =>
      subscriptionProducts
        ? subscriptionProducts.find(
            (product) => product.sku === supplementProductSkus.VS0000
          )
        : null,
    [subscriptionProducts]
  );

  useEffect(() => {
    if (!subscriptionProducts && !error && !fetching) {
      dispatch(fetchSubscriptionProducts());
    }
  }, [subscriptionProducts, dispatch, error, fetching]);

  const eventCallerGA = () => {
    switch (eventCaller) {
      case "Home":
        registerGAClickEventEnhanced(
          EventCategories.LandingScreenSupplement,
          EventActions.Click,
          EventLabels.LandingScreenSupplement
        );
        break;
      case "Shop":
        registerGAClickEventEnhanced(
          EventCategories.ShopPageSupplement,
          EventActions.Click,
          EventLabels.ShopPageSupplement
        );
        break;
      default:
        break;
    }
  };

  const onSupplementsClick = (supplementType) => {
    eventCallerGA();
    switch (supplementType) {
      case appConstants.genoVit: {
        history.push(internalURLs.SUPPLEMENT_PRODUCT_DETAILS);
        break;
      }
      case appConstants.genoBlend: {
        history.push(internalURLs.BLEND_PRODUCT_DETAILS);
        break;
      }
    }
  };

  const isGenoblendEnable = isEnabled(FeatureComponentId.GENOBLEND_BANNER);

  return (
    <Grid container xs={12} md={12} justify={"space-between"} spacing={isSmall ? 0 : 5}>
      <Grid item xs={12} md={isGenoblendEnable ? 6 : 12}>
        <ShopSupplementBanner
          onSupplementsClick={onSupplementsClick}
          baseProduct={baseProduct}
          image={images.supplementFeature}
          header={appConstants.genoVitamins.header}
          description={appConstants.genoVitamins.description}
          supplementType={appConstants.genoVit}
          unit="mo"
          imageStyle={{ maxHeight: isGenoblendEnable ? '290px': '440px' }}
        />
      </Grid>

      {isGenoblendEnable ?
        <Grid item xs={12} md={6}>
          <ShopSupplementBanner
            onSupplementsClick={onSupplementsClick}
            baseProduct={genoBlendBaseProduct}
            image={genoBlendBaseProduct?.image || images.genoBlendFeature}
            header={genoBlendBaseProduct?.name || ''}
            description={appConstants.genoBlends.description}
            supplementType={appConstants.genoBlend}
            unit="mo"
            price={calculateDiscountedPrice(genoBlendBaseProduct?.pricePerMonth, 9.1)}
          />
        </Grid>
        : null}
    </Grid>
  );
}
