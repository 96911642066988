import { createStyles, makeStyles } from '@material-ui/core/styles';
import { fonts } from 'common/assets/sharedUI';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles((theme) => createStyles({
  accountContainer: {
  },
  userTxt: {
    paddingBottom: 30,
  },
  emailTxt: {
    overflowWrap: 'break-word',
    fontSize: '0.9rem',
  },
  link: {
    alignSelf: 'center',
  },
  subheadingStyle: {
    fontFamily: fonts.paragraph2,
    fontSize: '1.25rem',
    fontWeight: '500',
    letterSpacing: '0.15px;',
    lineHeight: '24px',
    color: '#44484E',
    '& .MuiTypography-subtitle1': {
      fontSize: '1.25rem',
    },
    '& .makeStyles-label-342': {
      fontSize: '1.25rem',
    },
  },
  caption: {
    fontFamily: fonts.paragraph2,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
    marginTop: 4,
    marginBottom: 80,
  },
  headingStyle: {
    fontFamily: fonts.heading3,
    fontSize: '25px',
    fontWeight: '600',
    lineHeight: '32px',
    textAlign: 'left',
    color: '#085C7D',
    '& .MuiTypography-subtitle1': {
      fontSize: '1.25rem',
    },
  },
  consentBtn: {
    color: '#477721',
    textTransform: 'none',
    fontFamily: fonts.paragraph2,
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    alignItems: 'left',
    textDecoration: 'underline',
    marginLeft: '-7px',
  },
  tabs: {
    marginBottom: 80,
    '& .MuiTab-root': {
      textTransform: 'none',
      fontFamily: fonts.heading3,
      fontWeight: 600,
      fontSize: 24,
      color: colors.gray_04,
      padding: 0,
    },
    '& .Mui-selected': {
      color: colors.darkBlue,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-wrapper': {
      alignItems: 'start',
      marginRight: 10,
    },
    '& .MuiTabs-flexContainer': {
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  },
  linkText: {
    color: '#477721',
    textTransform: 'none',
    padding: '0px 8px',
    fontFamily: fonts.paragraph1,
    fontSize: '1rem',
    alignItems: 'left',
    marginLeft: '-7px',
    textDecoration: 'underline',
    marginBottom: '0.25rem',
    '&:hover': {
      color: '#477721',
     }
  },
  paragraphText: {
    fontFamily: fonts.paragraph1,
    fontSize: '1rem',
  },
  deleteBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',
  },
  
  deleteBtn: {
    color: colors.white,
    backgroundColor: '#B70200',
    textTransform: 'none',
    fontFamily: fonts.heading2,
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '24px',
    padding: '12px 20px',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#B70200',
     }
  },
  arrow: {
    fontSize: '24px',
    alignSelf: 'center',
    verticalAlign: 'center',
    marginBottom: 0,
    paddingLeft:'10px',
  },
  label: {
    fontSize: '20px',
  }
}));

export default useStyles;
