import { questionnaire } from 'services'

import actionTypes from '../actionTypes'

export const beginStarted = () => ({ type: actionTypes.BEGIN_QUESTIONNAIRE_STARTED })

export const beginSuccess = payload => ({ type: actionTypes.BEGIN_QUESTIONNAIRE_SUCCESS, payload })

export const beginFailed = payload => ({ type: actionTypes.BEGIN_QUESTIONNAIRE_ERROR, payload })

export const begin = (questionnaireName) => async dispatch => {
  dispatch(beginStarted())
  try {
    const response = await questionnaire.begin(questionnaireName)
    dispatch(beginSuccess(response))
  } catch (error) {
    dispatch(beginFailed(error))
  }
}

export const forwardStarted = () => ({ type: actionTypes.FORWARD_QUESTIONNAIRE_STARTED })

export const forwardSuccess = payload => ({ type: actionTypes.FORWARD_QUESTIONNAIRE_SUCCESS, payload })

export const forwardFailed = payload => ({ type: actionTypes.FORWARD_QUESTIONNAIRE_ERROR, payload })

export const forward = (questionId) => async dispatch => {
  dispatch(forwardStarted())
  try {
    const response = await questionnaire.forward(questionId)
    dispatch(forwardSuccess(response))
  } catch (error) {
    dispatch(forwardFailed(error))
  }
}

export const backStarted = () => ({ type: actionTypes.BACK_QUESTIONNAIRE_STARTED })

export const backSuccess = payload => ({ type: actionTypes.BACK_QUESTIONNAIRE_SUCCESS, payload })

export const backFailed = payload => ({ type: actionTypes.BACK_QUESTIONNAIRE_ERROR, payload })

export const back = (questionId) => async dispatch => {
  dispatch(backStarted())
  try {
    const response = await questionnaire.back(questionId)
    dispatch(backSuccess(response))
  } catch (error) {
    dispatch(backFailed(error))
  }
}

export const saveStarted = () => ({ type: actionTypes.SAVE_QUESTIONNAIRE_STARTED })

export const saveSuccess = payload => ({ type: actionTypes.SAVE_QUESTIONNAIRE_SUCCESS, payload })

export const saveFailed = payload => ({ type: actionTypes.SAVE_QUESTIONNAIRE_ERROR, payload })

export const save = (answer) => async dispatch => {
  dispatch(saveStarted())
  try {
    const response = await questionnaire.save(answer)
    dispatch(saveSuccess(response))
    const { questionId } = answer.answers[0]
    dispatch(forward(questionId))
  } catch (error) {
    dispatch(saveFailed(error))
  }
}

export const getProgressStarted = () => ({ type: actionTypes.GET_PROGRESS_STARTED })

export const getProgressSuccess = payload => ({ type: actionTypes.GET_PROGRESS_SUCCESS, payload })

export const getProgressFailed = payload => ({ type: actionTypes.GET_PROGRESS_ERROR, payload })

export const getProgress = (questionnaireId) => async dispatch => {
  dispatch(getProgressStarted())
  try {
    const response = await questionnaire.getProgress(questionnaireId)
    dispatch(getProgressSuccess(response))
  } catch (error) {
    dispatch(getProgressFailed(error))
  }
}
