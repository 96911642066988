import { getAxiosInstance } from '../../utils/axios';

export default async (panelName) => {
  const resp = await getAxiosInstance().get('/api/v1/nutrient/traits', {
    params: {
      panelName,
    },
  });

  return resp.data;
};
