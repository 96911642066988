import { colors } from 'common/assets/sharedUI';

const styles = ({
  individualNutrientValues: {
    '& th, & td': {
      border: 0,
      padding: 0,
      lineHeight: 1.2,
    },
    '& th': {
      fontSize: 12,
      color: colors.gray_04,
      paddingBottom: 5,
    },
    '& td': {
      fontSize: 16,
      paddingBottom: 10,
    },
    '& span': {
      color: colors.gray_04,
    },
  },
  nutrientColumn: {
    minWidth: 133,
    width: 138,
  },
  unitColumn: {
    width: 80,
  },
  dailyValueColumn: {
    width: 60,
  },
  goodMatchColumn: {
    width: 70,
  },
  checkIcon: {
    color: colors.green_03,
  },
  invisibleText: {
    color: `${colors.transparent} !important`,
    transform: 'scale(1, .01)',
  },
});

export default styles;
