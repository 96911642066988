import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Grid, Typography, Button } from '@material-ui/core';
import { fetchAdditionalProducts, fetchPurchasedProducts } from 'store/actions/additionalProducts';
import {
  AppStoreURL, PlayStoreURL, ProductTypes, PanelResultStatuses, panelNames,
} from 'resources/constants';
import { images, sharedStyles } from 'resources';
import useStyles from './AdditionalPurchasesPage/ProductDetails.styles';
import { registerGAClickEventEnhanced, EventCategories } from '../utils/googleanalytics';

const Details = ({
  productName, mobileOnly, qcStatus,
}) => {
  if (qcStatus === PanelResultStatuses.QCFailed) {
    return `There was an issue with your ${productName}, our Customer service team will reach out to you shortly.`;
  }
  if (mobileOnly) {
    return `Your ${productName} is currently only available on the GenoPalate mobile app.`;
  }
  return null;
};

const ProductImage = ({ productName }) => {
  if (productName === 'Eating Insights Report') {
    return (
      <img src="https://cdn.shopify.com/s/files/1/0272/9689/0928/t/14/assets/eating-insights-mockups_1x.png" alt={`${productName} screenshot`} style={{ width: '100%' }} />
    );
  }

  if (productName === 'Stress Insights Report') {
    return (
      <img src="https://cdn.shopify.com/s/files/1/0272/9689/0928/t/14/assets/stress-insights-mockups_1x.png" alt={`${productName} screenshot`} style={{ width: '100%' }} />
    );
  }

  return null;
};

const Action = ({
  productName,
}) => (
  <Grid container>
    <Grid item xs={12}><div style={{ fontWeight: 'bold', paddingTop: 20 }}>If you haven&apos;t already, download or open the mobile app now.</div></Grid>
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
      <a href={AppStoreURL} onClick={() => registerGAClickEventEnhanced(`${productName} App Store Button`, 'Click', 'My Insights')}>
        <img alt="GenoPalate in the App Store" height={35} src={images.appStore_banner} />
      </a>
      <a href={PlayStoreURL} onClick={() => registerGAClickEventEnhanced(`${productName} Google Play Button`, 'Click', 'My Insights')}>
        <img alt="GenoPalate in the Play Store" height={50} src={images.googlePlay_banner} />
      </a>
    </Grid>
  </Grid>
);

const PurchasedInsights = ({
  productName, mobileOnly, qcStatus,
}) => (
  <Grid item md={12}>
    <Grid container>
      <Typography variant="h4" align="left" gutterBottom>{productName}</Typography>
    </Grid>
    <Grid container>
      <Grid item xs={12} md={2}>
        <ProductImage productName={productName} />
      </Grid>
      <Grid item xs={12} md={8}>
        <Details productName={productName} mobileOnly={mobileOnly} qcStatus={qcStatus} />
        {mobileOnly && qcStatus !== PanelResultStatuses.QCFailed && <Action productName={productName} />}
      </Grid>
    </Grid>
  </Grid>
);

const AdditionalProducts = ({
  product: {
    id, name, image, descriptionHtml,
  },
}) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const history = useHistory();

  const goToAdditionalProducts = useCallback(() => {
    registerGAClickEventEnhanced(EventCategories.AddOns, 'Buy Now', name);
    history.push(`/account/products/product-details/${id}`);
  }, [id, history]);

  return (
    <Grid item md={12}>
      <Grid container>
        <Typography variant="h4" align="left" gutterBottom>{name}</Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={2}>
          <img src={image} alt={`${name} screenshot`} style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Button name="buyNowButton" id="buyNowButtonID" className={clsx(sharedClasses.primaryButton, classes.button)} onClick={goToAdditionalProducts}>
            BUY NOW
          </Button>
          <div style={{ marginTop: 15 }} dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default () => {
  const dispatch = useDispatch();

  const { purchasedProducts, products: additionalProducts } = useSelector(state => state.additionalProducts);

  useEffect(() => {
    if (!purchasedProducts.fetching) {
      dispatch(fetchPurchasedProducts());
    }
  }, []);

  useEffect(() => {
    if (!additionalProducts.fetching) {
      dispatch(fetchAdditionalProducts());
    }
  }, []);

  if (purchasedProducts.error || additionalProducts.error) {
    return (
      <div style={{ maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}>
        <Typography variant="h5">An error occurred while loading your products. Please refresh the page and try again.</Typography>
      </div>
    );
  }

  const filteredPurchasedProducts = useMemo(() => (purchasedProducts.data
    // This is using the EN product name rather than the panel name
    ? purchasedProducts.data.products.filter((pp) => pp.productType === ProductTypes.Panel && pp.productName !== 'Essential Nutrition Report')
    : null), // Return null rather than empty array to know if data hasn't been fetched
  [purchasedProducts]);

  const filteredAdditionalProducts = useMemo(() => {
    // These products are coming from shopify, so we can't just check the product type
    const { ESSENTIAL_NUTRITION, ...validPanels } = panelNames;
    const validProducts = Object.values(validPanels);
    return additionalProducts.data
      ? additionalProducts.data.filter(({ name }) => validProducts.includes(name))
      : null; // Return null rather than empty array to know if data hasn't been fetched
  }, [additionalProducts]);

  if (!filteredPurchasedProducts || !filteredAdditionalProducts) return null;

  return (
    <Grid container>
      {filteredPurchasedProducts.map(({
        productSku, productName, orderDate, mobileOnly, qcStatus,
      }) => (

        <Grid
          key={productSku + orderDate}
          container
          item
          xs={12}
          spacing={4}
          style={{ padding: 20 }}
        >
          <PurchasedInsights productName={productName} mobileOnly={mobileOnly} qcStatus={qcStatus} />

        </Grid>
      ))}

      {filteredAdditionalProducts.map(product => (
        <Grid
          key={product.id}
          item
          xs={12}
          container
          spacing={4}
          style={{ padding: 20 }}
        >
          <AdditionalProducts product={product} />
        </Grid>
      ))}
    </Grid>
  );
};
