import React from 'react'
import { colors } from 'common/assets/sharedUI'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles({
  root: {
    color: colors.gray_04,
    '&$checked': {
      color: colors.blue,
    },
  },
  checked: {},
})

export default function CustomCheckbox(props) {
  const classes = useStyles()

  return (
    <Checkbox
      className={classes.root}
      color="default"
      {...props}
    />
  )
}
