import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPurchasedProducts } from 'store/actions/additionalProducts';

/**
 * fetch or load from redux global state cache ('state.additionalProducts.purchasedProducts')
 * @param {React.DependencyList} dependencies
 * @returns {{
 *  purchaseProductsFetching: boolean,
 *  purchasedProducts: { products: { mobileOnly: boolean, productType: 'Recipe' | 'Panel' | string }[], recipeQuestionnaireCompletedDate?: Date, userPackage: { name: string, sku: string } } | null,
 *  purchasedProductsError: Error | null
 * }}
 */
const usePurchasedProducts = (dependencies = []) => {
  const dispatch = useDispatch();
  const { fetching, data, error } = useSelector(state => state.additionalProducts.purchasedProducts);

  useEffect(() => {
    if (!fetching) {
      dispatch(fetchPurchasedProducts());
    }
  }, dependencies);

  return {
    purchasedProductsFetching: fetching,
    purchasedProducts: data,
    purchasedProductsError: error,
  };
};

export default usePurchasedProducts;
