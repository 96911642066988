import React, { PureComponent } from 'react';
import { Layout } from 'antd';

import styles from './styles.css';

export default class BaseContainer extends PureComponent {
  render() {
    return (
      <Layout styleName="layout">
        <Layout.Content {...this.props} />
      </Layout>
    );
  }
}
