import { colors } from 'common/assets/sharedUI';

const styles = () => ({
  logo: {
    position: 'absolute',
    top: '10px',
    left: '45%',
  },
  closeBtn: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  frequentActions: {
    marginBottom: '2em',
  },
  noprint: {
    '@media print': {
      display: 'none',
    },
  },
  tipsAndTricks: {
    background: '#e9e9ea',
    padding: '30px',
    borderRadius: '4px',
    width: '30%',
    marginRight: '30px',
    '& h2': {
      marginTop: '0.5em',
      fontSize: '1.15em',
      fontWeight: '700',
      marginBottom: '.5em',
    },
    '& p': {
      margin: '0',
      fontSize: '1em',
    },
    '@media print': {
      display: 'none',
    },
  },
  tipText: {
    '& p': {
      margin: '0 0 0 2.4em',
      fontSize: '1em',
    },
  },
  tip: {
    margin: '1em 0',
  },
  copyButton: {
    display: 'inline-block',
    backgroundColor: colors.buttonBlue,
    color: colors.white,
    borderColor: colors.buttonBlue,
    borderRadius: '6px',
    padding: '5px 8px',
    textDecoration: 'none',
  },
  container: {
  },
  circle: {
    height: '30px',
    width: '30px',
    borderRadius: '30px',
    background: '#277e9f',
    color: '#ffffff',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconAndText: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

export default styles;
