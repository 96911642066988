import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const styles = theme => ({
  container: {
    boxShadow: `0 1px 6px ${colors.gray_01}`,
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
      marginTop: 0,
      paddingTop: 20,
      border: 'none',
    },
    borderRadius: 6,
    textAlign: 'center',
    padding: '40px 20px',
    margin: '30px auto 0 auto',
    border: `solid 1px ${colors.gray_01}`,
  },
})

function PaperContainer(props) {
  const {
    maxWidth, children, classes, style,
  } = props
  const iMaxWidth = maxWidth || 650
  const paperStyle = {
    maxWidth: iMaxWidth,
    ...style,
  }

  return (
    <Paper style={paperStyle} className={classes.container}>
      {children}
    </Paper>
  )
}

PaperContainer.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
  }).isRequired,
  ...Paper.propTypes,
}

export default withStyles(styles)(PaperContainer)
