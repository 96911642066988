import begin from './begin'
import forward from './forward'
import back from './back'
import save from './save'
import getProgress from './getProgress'

export default {
  begin,
  forward,
  back,
  save,
  getProgress,
}
