import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/pro-solid-svg-icons';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';
import FoodListItem from 'components/FoodListItem';
import FoodToggleItem from 'components/FoodToggleItem';
import { getContentLegend } from 'utils/dataTools';

const styles = theme => ({
  foodGroupContainer: {
    padding: '12px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `solid 1px ${colors.gray_02}`,
    marginTop: '-1px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.gray_03,
    },
  },
  foodGroupName: {
    fontSize: 18,
    margin: 0,
  },
  foodContentLegend: {
    marginRight: 15,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      background: colors.white,
      borderBottom: `1px solid ${colors.gray_03}`,
      height: 45,
      padding: 10,
      marginBottom: 0,
      textAlign: 'right',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedFoodGroup: {
    backgroundColor: colors.lightBlue,
    color: colors.white,
  },
  unselectedFoodGroup: {
    backgroundColor: colors.white,
    color: colors.gray_06,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hiddenFoodsText: {
    padding: 10,
  },
  eyeContainer: {
    marginRight: 10,
  },
});

const FoodGroupListItem = (props) => {
  const {
    foodGroup,
    expanded,
    handleClick,
    classes,
    isXsScreen,
    handleFoodClick,
    showToggle,
  } = props;

  const contentLegend = getContentLegend(foodGroup.id);
  const filteredFoods = foodGroup.foods.filter(food => !food.individuallyHidden && food.missingDietaryPreferences.length === 0);
  const hiddenFoodsCount = foodGroup.foods.length - filteredFoods.length;

  return (
    <div>
      <div
        className={
          clsx(
            classes.foodGroupContainer,
            expanded ? classes.selectedFoodGroup : classes.unselectedFoodGroup,
          )
        }
        key={foodGroup.name}
        onClick={() => handleClick()}
      >
        <p className={classes.foodGroupName}>{`${foodGroup.name }`}</p>
        <div className={classes.rowContainer}>
          {hiddenFoodsCount > 0 && (
            <div className={classes.eyeContainer}>
              <label>
                (
                <FontAwesomeIcon style={{ color: expanded ? colors.white : colors.gray_04 }} icon={faEyeSlash} />
                {' '}
                {hiddenFoodsCount}
                {' '}
                )
              </label>
            </div>
          )}
          <FontAwesomeIcon icon={expanded ? isXsScreen ? faChevronUp : faChevronRight : faChevronDown} style={{ fontSize: 14 }} />
        </div>
      </div>
      {expanded && isXsScreen && contentLegend.length > 0 && (
        <div className={classes.foodContentLegend}>{contentLegend}</div>
      )}
      {expanded && isXsScreen && (
        showToggle
          ? foodGroup.foods.map((food) => (
            <FoodToggleItem
              food={food}
              foodGroupId={foodGroup.id}
              key={food.name}
            />
          ))
          : filteredFoods.length > 0
            ? filteredFoods.map((food) => (
              <FoodListItem
                foodGroup={foodGroup}
                food={food}
                handleClick={handleFoodClick}
                isXsScreen={isXsScreen}
                key={food.name}
              />
            ))
            : (
              <div className={classes.hiddenFoodsText}>You have hidden all the foods in this category. You can adjust this in your settings.</div>
            )
      )}
    </div>
  );
};

FoodGroupListItem.propTypes = {
  foodGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    foods: PropTypes.array,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  isXsScreen: PropTypes.bool.isRequired,
  showToggle: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleFoodClick: PropTypes.func,
  classes: PropTypes.shape({
    foodGroupContainer: PropTypes.string,
    foodGroupName: PropTypes.string,
    rowContainer: PropTypes.string,
    eyeContainer: PropTypes.string,
    selectedFoodGroup: PropTypes.string,
    unselectedFoodGroup: PropTypes.string,
    foodContentLegend: PropTypes.string,
    flexRow: PropTypes.string,
    hiddenFoodsText: PropTypes.string,
  }).isRequired,
};

FoodGroupListItem.defaultProps = {
  handleFoodClick: () => {},
};

export default withStyles(styles)(FoodGroupListItem);
