import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  container: {
    paddingTop: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textContainer: {
    width: '50%',
    marginTop: 50,
  },
})

export default useStyles
