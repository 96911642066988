import React from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Button, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { updateProfile } from 'store/actions/profile';
import { sharedStyles } from '../../resources';
import AccountFormikInput from './AccountFormikInput';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../../utils/googleanalytics';

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: 440,
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const UpdateProfileForm = ({ email, name, surname }) => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .required('Email is required'),
    name: Yup.string()
      .required('First name is required'),
    surname: Yup.string()
      .required('Last name is required'),
  });

  return (
    <Formik
      initialValues={{
        email,
        name,
        surname,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        if (values && values.email && values.name && values.surname) {
          registerGAClickEventEnhanced(EventCategories.AccountProfile, EventActions.Update, EventLabels.UpdateProfile);
          dispatch(updateProfile(values.email, values.name, values.surname));
          resetForm();
        }
      }}
      enableReinitialize
    >
      {({
        handleSubmit, submitCount, isValid, dirty,
      }) => (
        <Form onSubmit={handleSubmit} className={classes.form}>
          <Grid item xs={12}>
            <AccountFormikInput
              name="email"
              label="Email"
              disabled
              submitCount={submitCount}
              type="email"
              placeholder="Enter email"
            />
          </Grid>
          <Grid item xs={12}>
            <AccountFormikInput
              name="name"
              label="First Name"
              submitCount={submitCount}
              type="text"
              placeholder="Enter first name"
            />
          </Grid>
          <Grid item xs={12}>
            <AccountFormikInput
              name="surname"
              label="Last Name"
              submitCount={submitCount}
              type="text"
              placeholder="Enter last name"
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 20, paddingBottom: 20,
            }}
          >
            <Button
              disabled={!dirty || !isValid}
              className={sharedClasses.rebrandedPrimaryButton}
              type="submit"
            >
              Save →
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = state => ({
  email: (!!state.profile && !!state.profile.userProfile && !!state.profile.userProfile.data && state.profile.userProfile.data.email) || '',
  name: (!!state.profile && !!state.profile.userProfile && !!state.profile.userProfile.data && state.profile.userProfile.data.name) || '',
  surname: (!!state.profile && !!state.profile.userProfile && !!state.profile.userProfile.data && state.profile.userProfile.data.surname) || '',
});

UpdateProfileForm.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(UpdateProfileForm);
