import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from '@material-ui/core';
import { LoginForm } from 'components';
import { images } from 'resources';
import { colors } from 'common/assets/sharedUI';
import constants from '../resources/constants';

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 20,
    // height: '100%', // todo: this isn't quite working, need to change the parent to have height: 100% (antd issue) + safari buggos
  },
  logo: {
    marginBottom: 10,
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    fontSize: '14px',
  },
  footerText: {
    fontSize: '14px',
  },
};

const LoginPage = ({ classes }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.container}>
      <img src={images.logo} alt="logo" className={classes.logo} style={{ width: smDown ? 120 : 150 }} />
      <LoginForm />
      <div className={classes.footer}>
        <Typography className={classes.footerText}>
          Having trouble?&nbsp;
          <a href={`${constants.CONTACT_SUPPORT_URL}`} id="loginContactSupportLink" target="_blank" rel="noreferrer" style={{ color: colors.black, fontWeight: 600 }}>Contact Support</a>
        </Typography>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  classes: PropTypes.shape({
    loginPage: PropTypes.string,
    container: PropTypes.string,
    logo: PropTypes.string,
    footer: PropTypes.string,
    footerText: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(LoginPage);
