import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors, fonts } from 'common/assets/sharedUI';

const useStyles = makeStyles(() => createStyles({
  container: {
    marginTop: 15,
    marginBottom: 15,
  },
  label: {
    fontWeight: 600,
    fontFamily: fonts.heading3,
    fontSize: '20px',
    lineHeight: '24px',
    paddingBottom: '16px',
  },
  description: {
    color: colors.darkGrey,
  },
}));

export default useStyles;
