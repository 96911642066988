import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles({
  paper: {
    boxShadow: 'none',
    margin: '5px 0px 5px',
    padding: '5px 15px 5px 15px',
    border: `solid 1px ${colors.gray_02}`
  },
  selectedPaper: {
    boxShadow: 'none',
    margin: '5px 0px 5px',
    padding: '5px 15px 5px 15px',
    border: `solid 1px ${colors.blue}`
  },
  errorPaper: {
    boxShadow: 'none',
    margin: '5px 0px 5px',
    padding: '5px 15px 5px 15px',
    border: `solid 1px ${colors.red}`
  },
  choiceContainer: {
    display: 'flex',
    flexFlow: 'column',
    //padding: 10,
  },
  iconChoiceContainer: {
    flexBasis: 'auto',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'column',
    maxHeight: 100,
  },
  icon: {
    backgroundColor: colors.filterLightGreen,
    fontSize: '3.15rem',
    borderRadius: 25,
  },
  preferencesIcon: {
    backgroundColor: colors.gray_03,
    fontSize: '3.15rem',
    borderRadius: 25,
  },
  overlayIcon: {
    position: 'absolute',
    color: colors.chartGreen,
    backgroundColor: 'rgba(255,255,255,.8)',
    fontSize: '4rem',
  },
  overlayBlockIcon: {
    position: 'absolute',
    color: colors.chartRed,
    backgroundColor: 'rgba(255,255,255,.8)',
    fontSize: '4rem',
  },
  allergyImg: {
    height: '100%',
    width: '100%',
    padding: 7,
  },
  errorTxt: {
    textAlign: 'center',
    fontSize: '0.9rem',
    color: colors.red,
    marginTop: 10,
  },
  subtext: {
    color: colors.subtextGrey,
    fontSize: '0.85rem',
  },
})

export default useStyles
