import React, { PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import { colors } from 'common/assets/sharedUI';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  bold: {
    fontWeight: 'bold',
    color: colors.white,
  },
  container: {
    marginTop: 20,
  },
  legendContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  descriptionContainer: {
    display: 'flex',
  },
  blueDescription: {
    color: blue[400],
    marginRight: 10,
  },
  greyDescription: {
    color: grey[400],
    marginRight: 10,
  },
};

const GeneSummary = ({ classes, totalVariants }) => {
  const geneticVariantsDescription =
    `We detected ${totalVariants || 0} genetic variant${totalVariants !== 1 ? 's' : ''} that impacted your recommendation.`;

  return (
    <div>
      <Typography variant="h6" className={classes.bold}>YOUR GENES</Typography>
      <div className={classes.container}>
        <Typography>
          {geneticVariantsDescription}
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(GeneSummary);
