import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles({
  errorText: {
    margin: 20,
    color: colors.chartRed,
  },
  modal: {
    zIndex: '1301 !important',
  },
})

export default useStyles
