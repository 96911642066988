import { axios } from 'utils';
import { constants } from 'resources';

export default async function setFoodVisibility( foodId, hidden ) {
  try {
    const params = { foodId: foodId, hidden: hidden };
    return axios.post(constants.API_SET_FOOD_VISIBILITY, params);
  } catch (e) {
    return null
  }
}
