import * as React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@mui/material/Snackbar';
import {
  Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import { colors } from 'common/assets/sharedUI';
import { AlertTypes } from 'resources/constants';
import { sharedStyles } from '../../resources';

const styles = theme => ({
  snackbar: {
    width: '100%',
    maxWidth: 426,
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 40px',
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 30px)',
    },
  },
  success: {
    backgroundColor: colors.green_01,
    border: `1px solid ${colors.green_05}`,
    boxShadow: `0px 4px 12px ${colors.gray_03}`,
  },
  error: {
    backgroundColor: colors.lightRed,
    border: `1px solid ${colors.red}`,
    boxShadow: `0px 4px 12px ${colors.red}`,
  },
  successTitle: {
    color: colors.green_05,
  },
  errorTitle: {
    color: colors.red,
  },
  closeIcon: {
    position: 'absolute',
    fontSize: 14,
    top: 10,
    right: 10,
    [theme.breakpoints.down('xs')]: {
      right: 25,
    },
  },
});

const AccountAlert = (props) => {
  const {
    title, message, onClose, type, classes,
  } = props;
  const sharedClasses = sharedStyles();

  return (
    <Snackbar
      open
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      className={classes.snackbar}
    >
      <div
        className={clsx(classes.container, type === AlertTypes.ERROR ? classes.error : classes.success)}
      >
        <Typography
          className={clsx(sharedClasses.h6, type === AlertTypes.ERROR ? classes.errorTitle : classes.successTitle)}
        >
          {title}
        </Typography>
        <Typography className={sharedClasses.body}>{message}</Typography>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onClose}
          className={classes.closeIcon}
        />
      </div>
    </Snackbar>
  );
};

AccountAlert.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
    snackbar: PropTypes.string,
    success: PropTypes.string,
    error: PropTypes.string,
    successTitle: PropTypes.string,
    errorTitle: PropTypes.string,
    closeIcon: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(AccountAlert);
