import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  reportHighlightsBtnContainer: {
    justifyContent: 'center',
  },
  reportHighlightsBtn: {
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
})

export default useStyles
