import get from 'lodash/get';

import { login, logoutWithoutRedirect } from '../../flows';
import { auth, product } from '../../services';
import types from '../actionTypes';
import { setFirstTimeKitUser, selectStatus } from './status';

const getRegistrationDetailsStarted = () => ({ type: types.GET_REGISTRATION_DETAILS_STARTED });
const getRegistrationDetailsSuccess = details => ({ type: types.GET_REGISTRATION_DETAILS_SUCCESS, details });
const getRegistrationDetailsError = error => ({ type: types.GET_REGISTRATION_DETAILS_ERROR, error });
export const registrationNextStepClicked = () => ({ type: types.REGISTRATION_NEXT_STEP });
export const registerBarcodeButtonClicked = () => ({ type: types.REGISTRATION_BARCODE_CLICKED });
export const videoNextStepClicked = () => ({ type: types.VIDEO_NEXT_STEP });

export const getRegistrationDetails = token => async dispatch => {
  dispatch(getRegistrationDetailsStarted());
  try {
    const details = await auth.accountShell({ token });
    dispatch(getRegistrationDetailsSuccess(details));
  } catch (e) {
    dispatch(getRegistrationDetailsError(get(e, 'response.data.message', 'An unknown error occurred.')));
  }
};

const createAccountStarted = () => ({ type: types.CREATE_ACCOUNT_STARTED });

const createAccountSuccess = response => ({ type: types.CREATE_ACCOUNT_SUCCESS, response });

const createAccountError = error => ({ type: types.CREATE_ACCOUNT_ERROR, error });

export const createAccount = (accountDetails, history) => async dispatch => {
  dispatch(createAccountStarted());

  try {
    // technically this is partially unnecessary because login clears localStorage, but this forces a call
    // to the server side logout just in case they were logged into another account and
    // there may be latent idempotency bugs on the server with sessions.
    await logoutWithoutRedirect();

    const {
      data: {
        token, userId, isNewKitUser, uuidCode,
      },
    } = await auth.register(accountDetails);

    login(token, userId, uuidCode);

    dispatch(setFirstTimeKitUser(isNewKitUser));
    dispatch(createAccountSuccess());
    history.push('/home');
  } catch (e) {
    dispatch(createAccountError(get(e, 'response.data.message', 'An unknown error occurred.')));
  }
};

const assignBarcodeStarted = () => ({ type: types.BARCODE_ASSIGN_STARTED });

const assignBarcodeError = ({ barcodeError, barcodeErrorCode }) => ({
  type: types.BARCODE_ASSIGN_ERROR,
  barcodeError,
  barcodeErrorCode,
});

export const updateBarcode = barcode => ({ type: types.BARCODE_UPDATE_BARCODE, barcode });

export const assignBarcode = (barcode) => async (dispatch) => {
  dispatch(assignBarcodeStarted());
  try {
    await product.assignBarcode(barcode);
    dispatch(selectStatus());
  } catch (error) {
    dispatch(assignBarcodeError({
      barcodeError: get(error, 'response.data.message', 'An unknown error occurred.'),
      barcodeErrorCode: get(error, 'response.data.code'),
    }));
  }
};

const registerThirdPartyMarketPlaceKitStarted = () => ({ type: types.REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_STARTED });

const registerThirdPartyMarketPlaceKitSuccess = response => ({ type: types.REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_SUCCESS, payload: response });

const registerThirdPartyMarketPlaceKitError = error => ({ type: types.REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_ERROR, error });

export const registerThirdPartyMarketPlaceKit = (data, history) => async dispatch => {
  dispatch(registerThirdPartyMarketPlaceKitStarted());

  try {
    const response = await auth.registerTMKit(data);

    dispatch(registerThirdPartyMarketPlaceKitSuccess(get(response, 'data')));
  } catch (e) {
    dispatch(registerThirdPartyMarketPlaceKitError(get(e, 'response.data.message', 'An unknown error occurred.')));
  }
};