import Product from './Product'

export default function UserProduct({
  id, userId, product, orderId, ownerName, ownerSurname, barcode, file, dnaResult,
}) {
  return {
    id,
    userId,
    product: Product(product),
    orderId,
    owner: ownerName && ownerSurname ? `${ownerName} ${ownerSurname}` : ownerName,
    barcode,
    dnaResult,
    file,
  }
}
