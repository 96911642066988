import { subscription } from '../../services';
import types from '../actionTypes';

const getShippingAddressStarted = () => ({ type: types.GET_SHIPPING_ADDRESS_STARTED });
const getShippingAddressSuccess = data => ({ type: types.GET_SHIPPING_ADDRESS_SUCCESS, data });
const getShippingAddressError = error => ({ type: types.GET_SHIPPING_ADDRESS_ERROR, error });

export const getShippingAddress = () => async (dispatch) => {
  dispatch(getShippingAddressStarted());
  subscription.getShippingAddress()
    .then(response => {
      const { addresses } = response;
      const primaryAddress = addresses.filter(addr => addr.primary === true)[0];
      dispatch(getShippingAddressSuccess(primaryAddress));
    })
    .catch(error => {
      if (typeof error === 'object') { dispatch(getShippingAddressError(error.message)); } else { dispatch(getShippingAddressError(error)); }
    });
};

const updateShippingAddressStarted = () => ({ type: types.UPDATE_SHIPPING_ADDRESS_STARTED });
const updateShippingAddressSuccess = data => ({ type: types.UPDATE_SHIPPING_ADDRESS_SUCCESS, data });
const updateShippingAddressError = error => ({ type: types.UPDATE_SHIPPING_ADDRESS_ERROR, error });

export const updateShippingAddress = (shippingAddress, shippingAddressId) => async (dispatch) => {
  dispatch(updateShippingAddressStarted());
  subscription.updateShippingAddress(shippingAddress, shippingAddressId)
    .then(response => {
      dispatch(updateShippingAddressSuccess(response.data));
    })
    .catch(error => {
      if (typeof error === 'object') { dispatch(updateShippingAddressError(error.message)); } else { dispatch(updateShippingAddressError(error)); }
    });
};

const getSubscriptionsStarted = () => ({ type: types.GET_SUBSCRIPTIONS_STARTED });
const getSubscriptionsSuccess = data => ({ type: types.GET_SUBSCRIPTIONS_SUCCESS, data });
const getSubscriptionsError = error => ({ type: types.GET_SUBSCRIPTIONS_ERROR, error });

const getSubscriptionOrdersStarted = () => ({ type: types.GET_SUBSCRIPTION_ORDERS_STARTED });
const getSubscriptionOrdersSuccess = data => ({ type: types.GET_SUBSCRIPTION_ORDERS_SUCCESS, data });
const getSubscriptionOrdersError = error => ({ type: types.GET_SUBSCRIPTION_ORDERS_ERROR, error });

export const getSubscriptions = () => async (dispatch) => {
  dispatch(getSubscriptionsStarted());
  subscription.getSubscriptions()
    .then(response => {
      dispatch(getSubscriptionsSuccess(response));
    })
    .catch(error => {
      dispatch(getSubscriptionsError(error));
    });
};

export const getSubscriptionOrders = () => async (dispatch) => {
  dispatch(getSubscriptionOrdersStarted());
  subscription.getSubscriptionOrders()
    .then(response => {
      dispatch(getSubscriptionOrdersSuccess(response));
    })
    .catch(error => {
      dispatch(getSubscriptionOrdersError(error));
    });
};

const getFormulationStarted = () => ({ type: types.GET_FORMULATION_STARTED });
const getFormulationSuccess = data => ({ type: types.GET_FORMULATION_SUCCESS, data });
const getFormulationError = error => ({ type: types.GET_FORMULATION_ERROR, error });

export const getFormulation = () => async (dispatch) => {
  dispatch(getFormulationStarted());
  subscription.getFormulation()
    .then(response => {
      if (response.data) { dispatch(getFormulationSuccess(response.data.values)); }
    })
    .catch(error => {
      dispatch(getFormulationError(error));
    });
};

const updateBillingStarted = () => ({ type: types.UPDATE_SUBSCRIPTION_BILLING_STARTED });
const updateBillingSuccess = data => ({ type: types.UPDATE_SUBSCRIPTION_BILLING_SUCCESS, data });
const updateBillingError = error => ({ type: types.UPDATE_SUBSCRIPTION_BILLING_ERROR, error });

export const updateBilling = () => async (dispatch) => {
  dispatch(updateBillingStarted());
  subscription.updateBilling()
    .then(() => {
      dispatch(updateBillingSuccess('success'));
    })
    .catch(error => {
      if (typeof error === 'object') { dispatch(updateBillingError(error.message)); } else { dispatch(updateBillingError(error)); }
    });
};

const getPaymentMethodStarted = () => ({ type: types.GET_PAYMENT_METHOD_STARTED });
const getPaymentMethodSuccess = data => ({ type: types.GET_PAYMENT_METHOD_SUCCESS, data });
const getPaymentMethodError = error => ({ type: types.GET_PAYMENT_METHOD_ERROR, error });

export const getPaymentMethod = () => async (dispatch) => {
  dispatch(getPaymentMethodStarted());
  subscription.getPaymentMethod()
    .then(response => {
      if (response.data) { dispatch(getPaymentMethodSuccess(response.data)); }
    })
    .catch(error => {
      dispatch(getPaymentMethodError(error));
    });
};

const cancelSubscriptionStarted = () => ({ type: types.CANCEL_SUBSCRIPTION_STARTED });
const cancelSubscriptionSuccess = data => ({ type: types.CANCEL_SUBSCRIPTION_SUCCESS, data });
const cancelSubscriptionError = error => ({ type: types.CANCEL_SUBSCRIPTION_ERROR, error });

export const cancelSubscription = (subscriptionId, reason, reasonComments) => async (dispatch) => {
  dispatch(cancelSubscriptionStarted());
  subscription.cancelSubscription(subscriptionId, reason, reasonComments)
    .then((response) => {
      dispatch(cancelSubscriptionSuccess(response));
    })
    .catch(error => {
      if (typeof error === 'object') { dispatch(cancelSubscriptionError(error.message)); } else { dispatch(cancelSubscriptionError(error)); }
    });
};

const getSubscriptionQuestionnaireStarted = () => ({ type: types.GET_SUBSCRIPTION_QUESTIONNAIRE_STARTED });
const getSubscriptionQuestionnaireSuccess = data => ({ type: types.GET_SUBSCRIPTION_QUESTIONNAIRE_SUCCESS, data });
const getSubscriptionQuestionnaireError = error => ({ type: types.GET_SUBSCRIPTION_QUESTIONNAIRE_ERROR, error });

export const getSubscriptionQuestionnaire = () => async (dispatch) => {
  dispatch(getSubscriptionQuestionnaireStarted());
  subscription.getSubscriptionQuestionnaire()
    .then(response => {
      if (response.data) { dispatch(getSubscriptionQuestionnaireSuccess(response.data)); }
    })
    .catch(error => {
      dispatch(getSubscriptionQuestionnaireError(error));
    });
};

const saveSubscriptionQuestionnaireStarted = () => ({ type: types.SAVE_SUBSCRIPTION_QUESTIONNAIRE_STARTED });
const saveSubscriptionQuestionnaireSuccess = data => ({ type: types.SAVE_SUBSCRIPTION_QUESTIONNAIRE_SUCCESS, data });
const saveSubscriptionQuestionnaireError = error => ({ type: types.SAVE_SUBSCRIPTION_QUESTIONNAIRE_ERROR, error });

export const saveSubscriptionQuestionnaire = (questionnaireId, answers) => async (dispatch) => {
  dispatch(saveSubscriptionQuestionnaireStarted());
  subscription.saveSubscriptionQuestionnaire(questionnaireId, answers)
    .then(response => {
      dispatch(saveSubscriptionQuestionnaireSuccess(response.data));
    })
    .catch(error => {
      if (typeof error === 'object') { dispatch(saveSubscriptionQuestionnaireError(error.message)); } else { dispatch(saveSubscriptionQuestionnaireError(error)); }
    });
};

const pauseSubscriptionStarted = () => ({ type: types.PAUSE_SUBSCRIPTION_STARTED });
const pauseSubscriptionSuccess = data => ({ type: types.PAUSE_SUBSCRIPTION_SUCCESS, data });
const pauseSubscriptionError = error => ({ type: types.PAUSE_SUBSCRIPTION_ERROR, error });

export const pauseSubscription = (subscriptionId, nextChargeDate, properties) => async (dispatch) => {
  dispatch(pauseSubscriptionStarted());
  subscription.pauseSubscription(subscriptionId, nextChargeDate, properties)
    .then((response) => {
      dispatch(pauseSubscriptionSuccess(response));
    })
    .catch(error => {
      if (typeof error === 'object') { dispatch(pauseSubscriptionError(error.message)); } else { dispatch(pauseSubscriptionError(error)); }
    });
};

const updateSubscriptionStarted = () => ({ type: types.UPDATE_SUBSCRIPTION_STARTED });
const updateSubscriptionSuccess = data => ({ type: types.UPDATE_SUBSCRIPTION_SUCCESS, data });
const updateSubscriptionError = error => ({ type: types.UPDATE_SUBSCRIPTION_ERROR, error });

/**
 * @typedef {Object} SubscriptionUpdate
 * @property {number} quantity - The updated quantity of the subscription.
 * @property {string} variant - The updated variant of the subscription.
 * @property {string} frequency - The updated frequency of the subscription.
 * @property {string} sku - The updated SKU (Stock Keeping Unit) of the subscription.
 */

/**
 * Asynchronously updates a subscription and dispatches corresponding actions.
 * @param {string} subscriptionId - ID of the subscription to be updated.
 * @param {SubscriptionUpdate} updatedSubscription - Updated subscription object.
 * @param {Function} cb - Callback function to be executed after a successful update.
 * @param {Function} dispatch - Redux dispatch function.
 * @throws {any} Throws an error if the update fails.
 * @example await updateSubscription('123', { quantity: 5, variant: 'variantId', frequency: 'daily' })(dispatch);
 */
export const updateSubscriptionV2 = (subscriptionId, updatedSubscription, cb) => async (dispatch) => {
  dispatch(updateSubscriptionStarted());
  subscription.updateSubscriptionV2(subscriptionId, updatedSubscription)
    .then((response) => {
      dispatch(updateSubscriptionSuccess(response));

      // callback for after updating successful
      cb();
    })
    .catch(error => {
      if (typeof error === 'object') { dispatch(updateSubscriptionError(error.message)); } else { dispatch(updateSubscriptionError(error)); }
    });
};

/**
 * Asynchronously updates a subscription and dispatches corresponding actions.
 * @param {string} subscriptionId - ID of the subscription to be updated.
 * @param {Object} updatedSubscription - Updated subscription object with properties like quantity, variant, frequency.
 * @param {Function} cb - Callback function to be executed after a successful update.
 * @param {Function} dispatch - Redux dispatch function.
 * @throws {any} Throws an error if the update fails.
 * @example await updateSubscription('123', { quantity: 5, variant: 'variantId', frequency: 'daily' })(dispatch);
 */
export const updateSubscription = (subscriptionId, updatedSubscription, cb) => async (dispatch) => {
  dispatch(updateSubscriptionStarted());
  subscription.updateSubscription(subscriptionId, updatedSubscription)
    .then((response) => {
      dispatch(updateSubscriptionSuccess(response));

      // callback for after updating successful
      cb();
    })
    .catch(error => {
      if (typeof error === 'object') { dispatch(updateSubscriptionError(error.message)); } else { dispatch(updateSubscriptionError(error)); }
    });
};