import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEyeSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';
import { addNoToFilterDisplayName } from 'utils/dataTools';
import { setFoodVisibility } from 'store/actions/topFoods';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import ToggleItem from './ToggleItem';
import { apiStatuses } from 'resources/constants';

const styles = theme => ({
  hiddenFoodCard: {
    height: 60,
    width: 350,
    borderBottom: `1px solid ${colors.gray_03}`,
    padding: '5px 30px 5px 20px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 10,
      marginBottom: 0,
    },
    background: colors.gray_01,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  foodDescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  foodName: {
    fontSize: 18,
    margin: 0,
    flex: 3,
  },
  filterList: {
    fontSize: 12,
  },
  eyeIcon: {
    fontSize: 16,
    color: colors.gray05,
  },
});

const FoodToggleItem = (props) => {
  const {
    food, classes,
  } = props;

  const dispatch = useDispatch();
  const hidden = food.individuallyHidden;
  const status = useSelector(state => state.topFoods.foodVisibilityStatus);
  const [loading, setLoading] = useState(false);

  const setVisibility = useCallback(
    async () => {
      registerGAClickEventEnhanced(EventCategories.FoodTools, hidden ? EventActions.On : EventActions.Off, EventLabels.Food(food.name));
      setLoading(true);
      dispatch(setFoodVisibility(food.id, !hidden));
    },
    [food.id, food.name, hidden, dispatch],
  );

  useEffect(() => {
    if (status === apiStatuses.RESOLVED) setLoading(false);
  }, [status]);

  if (food.missingDietaryPreferences.length > 0) {
    const reasonsHidden = food.missingDietaryPreferences.map(
      missingPreference => {
        if ((missingPreference.dietaryPreferenceTypeName).toLowerCase() !== 'diet') {
          return addNoToFilterDisplayName(missingPreference.displayName);
        }
        return missingPreference.displayName;
      },
    );
    const reasonsHiddenString = reasonsHidden.join(', ');
    return (
      <div
        className={classes.hiddenFoodCard}
      >
        <div className={classes.foodDescriptionWrapper}>
          <label name={food.name} className={classes.foodName}>{food.name}</label>
          <label name="missing preferences" className={classes.filterList}>{reasonsHiddenString}</label>
        </div>
        <FontAwesomeIcon icon={faEyeSlash} color={colors.gray_04} className={classes.eyeIcon} />
      </div>
    );
  }

  return (
    <ToggleItem
      id={food.id}
      key={food.name}
      name={food.name}
      loading={loading}
      checked={!hidden}
      onChange={() => setVisibility(food.id, !hidden)}
    />
  );
};

FoodToggleItem.propTypes = {
  food: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    individuallyHidden: PropTypes.bool,
    missingDietaryPreferences: PropTypes.arrayOf({
      id: PropTypes.number,
      name: PropTypes.string,
      displayName: PropTypes.string,
      dietaryPreferenceTypeName: PropTypes.string,
    }),
  }).isRequired,
  classes: PropTypes.shape({
    hiddenFoodCard: PropTypes.string,
    foodDescriptionWrapper: PropTypes.string,
    foodName: PropTypes.string,
    filterList: PropTypes.string,
    eyeIcon: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(FoodToggleItem);
