import React from 'react';
import {
  Dialog, DialogContent, Grid, Typography, Hidden, Link, Divider,
} from '@material-ui/core';
import { PieChart, Pie, Cell } from 'recharts';
import { startCase } from 'lodash';
import { DialogTitle } from 'components/componentsV2';
import { images } from 'resources';
import { colors } from 'common/assets/sharedUI';
import { withStyles } from '@material-ui/core/styles';
import { registerGAClickEventEnhanced } from '../utils/googleanalytics';
import styles from './GeneModal.styles';

const pubMedGACall = (geneName) => registerGAClickEventEnhanced(`Gene-${geneName}`, 'Open', 'Pubmed');

const GeneModal = ({
  classes, open, markers, gene, titleColor, onClose, traitName,
}) => {
  const COLORS = [colors.green, colors.grey];
  const markersLength = markers.length;
  let variants = 0;
  gene.markers.forEach((markerElement) => {
    if (markerElement.variant) { variants++; }
  });

  return (
    <Dialog
      open={open}
      style={{ zIndex: 1302 }}
      onClose={onClose}
    >
      <DialogTitle name="dialogTitle" titleColor={titleColor} onClose={onClose}>
        {`${gene.geneName} GENE`}
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="body1">{gene.geneDescription}</Typography>
            <Typography variant="h6" className={classes.geneDescriptionLabel}>
              <span className={classes.variantNumber} style={{ color: titleColor }}>{`${variants}`}</span>
              {` VARIANT${variants !== 1 ? 'S' : ''} DETECTED`}
            </Typography>
          </Grid>
          {markers.length > 0 ? (
            markers.map((marker, index) => {
              if (marker.genotype) {
                const prevalence = Math.round(marker.prevalence);
                const pieData = [{ value: prevalence / 10 }, { value: 10 - (prevalence / 10) }];
                const chromosomeImage = marker.variant ? images.blue_chromosome : images.gray_chromosome;

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid container item xs={12} key={`${gene.geneName}-modal-${index}`}>
                    {index > 0 ? <Divider className={classes.divider} /> : null}
                    {
                      markersLength > 1 ? (
                        <Grid item xs={12} className={classes.variantTitle}>
                          <Typography variant="h6" className={classes.bold}>{`Variant ${index + 1}`}</Typography>
                        </Grid>
                      ) : null
                    }
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <Typography variant="h6">{`YOUR RESULT: ${marker.genotype} GENOTYPE`}</Typography>
                      <Typography variant="h6" className={classes.percentageLabel}>{`${prevalence}% of the population shares your genotype`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} className={classes.prevalenceContainer}>
                      <Hidden mdUp>
                        <PieChart height={200} width={200} style={{ margin: '0 auto' }}>
                          <text x={94} y={82} dy={8} className={classes.chartText} textAnchor="middle" fill={colors.black}>{marker.genotype}</text>
                          <text x={99} y={110} dy={8} className={classes.chartText} textAnchor="middle" fill={colors.black}>{`${prevalence}%`}</text>
                          <Pie
                            data={pieData}
                            cx={90}
                            cy={90}
                            innerRadius={70}
                            outerRadius={85}
                          >
                            {
                              pieData.map((entry, pieIndex) => <Cell key={`gene-${gene.geneName}`} fill={COLORS[pieIndex % COLORS.length]} />)
                            }
                          </Pie>
                        </PieChart>
                      </Hidden>
                      <Hidden smDown>
                        <PieChart height={125} width={125} style={{ margin: '0 auto' }}>
                          <text x={65} y={54} dy={8} textAnchor="middle" fill={colors.black}>{marker.genotype}</text>
                          <text x={67} y={68} dy={8} textAnchor="middle" fill={colors.black}>{`${prevalence}%`}</text>
                          <Pie
                            data={pieData}
                            cx={60}
                            cy={60}
                            innerRadius={40}
                            outerRadius={50}
                          >
                            {
                              pieData.map((entry, pieIndex) => <Cell key={`gene-${gene.geneName}`} fill={COLORS[pieIndex % COLORS.length]} />)
                            }
                          </Pie>
                        </PieChart>
                      </Hidden>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} className={classes.genotypeDescription}>
                      {
                        marker.genotypeDescription ? (<Typography variant="body1" className={classes.descriptionLabel}>{marker.genotypeDescription}</Typography>) : null
                      }
                      {
                        marker.studyLink ? (
                          <Typography variant="body2">
                            <Link className={classes.link} href={marker.studyLink} onClick={() => pubMedGACall(gene.geneName, traitName)} rel="noopener noreferrer" target="_blank">See the scientific evidence for  this variant &gt;&gt;</Link>
                          </Typography>
                        ) : null
                      }
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} className={classes.chromosomeContainer}>
                      <img src={chromosomeImage} className={classes.chromosome} />
                    </Grid>
                  </Grid>
                );
              }
              return (
                <Grid container item xs={12}>
                  {index > 0 ? <Divider className={classes.divider} /> : null}
                  {
                    markersLength > 1 ? (
                      <Grid item xs={12} className={classes.variantTitle}>
                        <Typography variant="h6" className={classes.bold}>{`Variant ${index + 1}`}</Typography>
                      </Grid>
                    ) : null
                  }
                  <Grid item xs={12} sm={12} md={7} lg={7}>
                    <Typography variant="subtitle1" className={classes.bold}>YOUR RESULT: -- </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} />
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <Typography variant="body1" className={classes.descriptionLabel}>{markers[0].notReadDescription}</Typography>
                    {
                      marker.markerStudyLink.length > 0 ? (
                        <Typography variant="body2">
                          <Link href={markers[0].markerStudyLink[0].studyLink} target="_blank">See the scientific evidence for  this variant >></Link>
                        </Typography>
                      ) : null
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} className={classes.chromosomeContainer}>
                    <img src={images.gray_chromosome} className={classes.chromosome} />
                  </Grid>
                </Grid>
              );
            })
          ) : null }
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(GeneModal);
