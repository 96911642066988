import { axios } from 'utils'
import { constants } from 'resources'

export default async function addConsent(addParams) {
  try {
    const { data } = await axios.post(constants.API_ADD_CONSENT, addParams)
    return data
  } catch (e) {
    console.log({ e })
    return { e }
  }
}
