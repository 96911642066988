import React from 'react'
import PropTypes from 'prop-types'
import { Typography, ButtonBase } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useDropzone } from 'react-dropzone'
import { colors, fontFamily } from 'common/assets/sharedUI'

const styles = {
  button: {
    width: 200,
    backgroundColor: colors.blue_05,
    borderRadius: 5,
    fontSize: 18,
    color: 'white',
    padding: 10,
    fontWeight: 'bold',
  },
  dropZone: {
    fontFamily,
    padding: 30,
    textAlign: 'center',
    border: `2px dashed ${colors.gray_03}`,
    // Webkit based browsers are adding an outline on focus
    outline: 'none',
    '& p': {
      marginBottom: 20,
    },
  },
}

function UploadDnaDropzone(props) {
  const { classes, handleOpen } = props

  const {
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    accept: 'application/zip, text/plain, application/x-zip-compressed, application/x-compressed, multipart/x-zip',
    onDrop: (files) => handleOpen(files[0]),
    maxFiles: 1,
    noClick: true,
    maxSize: 80000000,
  })

  return (
    <React.Fragment>
      <div {...getRootProps()} className={classes.dropZone}>
        {/* Input is required in order for file dialog to appear */}
        <input name="file" {...getInputProps()} />
        <Typography style={{ fontWeight: 'bold' }}>Drag and drop files here</Typography>
        <Typography>or</Typography>
        <ButtonBase className={classes.button} onClick={open}>BROWSE FILES</ButtonBase>
      </div>

    </React.Fragment>
  )
}

UploadDnaDropzone.propTypes = {
  handleOpen: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dropZone: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(UploadDnaDropzone)
