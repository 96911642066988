import { user } from 'services'
import types from '../actionTypes'

export const selectTopFoods = () => dispatch => {
  dispatch(topFoodsStarted())
  user.getTopFoods()
    .then(response => {
      dispatch(topFoodsSuccess(response))
    })
    .catch(error => {
      console.log(error)
      dispatch(topFoodsFailure(error))
    })
}

const topFoodsSuccess = topFoods => ({ type: types.SELECT_TOP_FOODS_SUCCESS, topFoods });

export const setTopFoods = topFoods => ({ type: types.SET_TOP_FOODS, topFoods });

const topFoodsStarted = () => ({
  type: types.SELECT_TOP_FOODS_STARTED,
})

const topFoodsFailure = error => ({
  type: types.SELECT_TOP_FOODS_ERROR,
  payload: {
    error,
  },
})

const setFoodVisibilityStarted = () => ({ type: types.SET_FOOD_VISIBILITY_STARTED });
const setFoodVisibilitySuccess = (payload) => ({ type: types.SET_FOOD_VISIBILITY_SUCCESS, payload });
const setFoodVisibilityError = error => ({ type: types.SET_FOOD_VISIBILITY_ERROR, error });

export const setFoodVisibility = (id, hidden) => async (dispatch) => {
  dispatch(setFoodVisibilityStarted());
  try {
    const response = await user.setFoodVisibility(id, hidden);
    dispatch(setFoodVisibilitySuccess(response))
  } catch (error) {
    dispatch(setFoodVisibilityError(error))
  }
};
