import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { sharedStyles } from 'resources'
import useStyles from './Congratulations.styles'

export default function Congratulations(props) {
  const { text } = props
  const sharedClasses = sharedStyles()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <h1>Congratulations!</h1>
      <h2>{text}</h2>
      <Button
        className={sharedClasses.primaryButton}
        type="button"
        variant="contained"
        size="large"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.genopalate.com/pages/education-what-to-expect"
      >
        Learn More
      </Button>
    </div>
  )
}

Congratulations.propTypes = {
  text: PropTypes.string,
}

Congratulations.defaultProps = {
  text: '',
}
