export default {
  // Login
  LOGIN: 'LOGIN',
  SET_FIRST_TIME_KIT_USER: 'SET_FIRST_TIME_KIT_USER',
  // Food
  SELECT_FOOD_DETAIL: 'SELECT_FOOD_DETAIL', // it seems like this type just sets the selected food id
  GET_FOOD_DETAILS_STARTED: 'GET_FOOD_DETAILS_STARTED',
  GET_FOOD_DETAILS_ERROR: 'GET_FOOD_DETAILS_ERROR',
  GET_FOOD_DETAILS_SUCCESS: 'GET_FOOD_DETAILS_SUCCESS',
  SELECT_EXPANDED_CATEGORY: 'SELECT_EXPANDED_CATEGORY',
  // Top foods
  SELECT_TOP_FOODS_STARTED: 'SELECT_TOP_FOODS_STARTED',
  SELECT_TOP_FOODS_ERROR: 'SELECT_TOP_FOODS_ERROR',
  SELECT_TOP_FOODS_SUCCESS: 'SELECT_TOP_FOODS_SUCCESS',
  SET_TOP_FOODS: 'SET_TOP_FOODS',
  SET_FOOD_VISIBILITY_STARTED: 'SET_FOOD_VISIBILITY_STARTED',
  SET_FOOD_VISIBILITY_ERROR: 'SET_FOOD_VISIBILITY_ERROR',
  SET_FOOD_VISIBILITY_SUCCESS: 'SET_FOOD_VISIBILITY_SUCCESS',
  // Preferences
  GET_PREFERENCES_STARTED: 'GET_PREFERENCES_STARTED',
  GET_PREFERENCES_ERROR: 'GET_PREFERENCES_ERROR',
  GET_PREFERENCES_SUCCESS: 'GET_PREFERENCES_SUCCESS',
  SET_PREFERENCE_STARTED: 'SET_PREFERENCE_STARTED',
  SET_PREFERENCE_ERROR: 'SET_PREFERENCE_ERROR',
  SET_PREFERENCE_SUCCESS: 'SET_PREFERENCE_SUCCESS',
  // Product
  SELECT_PRODUCT_STARTED: 'SELECT_PRODUCT_STARTED',
  SELECT_PRODUCT_ERROR: 'SELECT_PRODUCT_ERROR',
  SELECT_PRODUCT_SUCCESS: 'SELECT_PRODUCT_SUCCESS',
  // Status
  SELECT_STATUS: 'SELECT_STATUS',
  SELECT_STATUS_STARTED: 'SELECT_STATUS_STARTED',
  SELECT_STATUS_ERROR: 'SELECT_STATUS_ERROR',
  SELECT_STATUS_SUCCESS: 'SELECT_STATUS_SUCCESS',
  DISPLAY_HEADER_FOOTER: 'DISPLAY_HEADER_FOOTER',
  // Recommendation
  GET_TRAIT_RECOMMENDATION_STARTED: 'GET_TRAIT_RECOMMENDATION_STARTED',
  GET_TRAIT_RECOMMENDATION_SUCCESS: 'GET_TRAIT_RECOMMENDATION_SUCCESS',
  GET_TRAIT_RECOMMENDATION_ERROR: 'GET_TRAIT_RECOMMENDATION_ERROR',
  // Action items (tips)
  SELECT_ACTION_ITEMS_SUCCESS: 'SELECT_ACTION_ITEMS_SUCCESS',
  SELECT_ACTION_ITEMS_STARTED: 'SELECT_ACTION_ITEMS_STARTED',
  SELECT_ACTION_ITEMS_ERROR: 'SELECT_ACTION_ITEMS_ERROR',
  // Questionnaire
  BEGIN_QUESTIONNAIRE_STARTED: 'BEGIN_QUESTIONNAIRE_STARTED',
  BEGIN_QUESTIONNAIRE_SUCCESS: 'BEGIN_QUESTIONNAIRE_SUCCESS',
  BEGIN_QUESTIONNAIRE_ERROR: 'BEGIN_QUESTIONNAIRE_ERROR',
  FORWARD_QUESTIONNAIRE_STARTED: 'FORWARD_QUESTIONNAIRE_STARTED',
  FORWARD_QUESTIONNAIRE_SUCCESS: 'FORWARD_QUESTIONNAIRE_SUCCESS',
  FORWARD_QUESTIONNAIRE_ERROR: 'FORWARD_QUESTIONNAIRE_ERROR',
  BACK_QUESTIONNAIRE_STARTED: 'BACK_QUESTIONNAIRE_STARTED',
  BACK_QUESTIONNAIRE_SUCCESS: 'BACK_QUESTIONNAIRE_SUCCESS',
  BACK_QUESTIONNAIRE_ERROR: 'BACK_QUESTIONNAIRE_ERROR',
  SAVE_QUESTIONNAIRE_STARTED: 'SAVE_QUESTIONNAIRE_STARTED',
  SAVE_QUESTIONNAIRE_SUCCESS: 'SAVE_QUESTIONNAIRE_SUCCESS',
  SAVE_QUESTIONNAIRE_ERROR: 'SAVED_QUESTIONNAIRE_ERROR',
  GET_PROGRESS_STARTED: 'GET_PROGRESS_STARTED',
  GET_PROGRESS_SUCCESS: 'GET_PROGRESS_SUCCESS',
  GET_PROGRESS_ERROR: 'GET_PROGRESS_ERROR',
  // Profile
  GET_PROFILE_STARTED: 'GET_PROFILE_STARTED',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_ERROR: 'GET_PROFILE_ERROR',
  UPDATE_PROFILE_STARTED: 'UPDATE_PROFILE_STARTED',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_ERROR: 'UPDATE_PROFILE_ERROR',
  // Consent
  GET_CONTRACT_STARTED: 'GET_CONTRACT_STARTED',
  GET_CONTRACT_SUCCESS: 'GET_CONTRACT_SUCCESS',
  GET_CONTRACT_ERROR: 'GET_CONTRACT_ERROR',
  GET_ACCOUNT_CONTRACTS_STARTED: 'GET_ACCOUNT_CONTRACTS_STARTED',
  GET_ACCOUNT_CONTRACTS_SUCCESS: 'GET_ACCOUNT_CONTRACTS_SUCCESS',
  GET_ACCOUNT_CONTRACTS_ERROR: 'GET_ACCOUNT_CONTRACTS_ERROR',
  ADD_CONSENT_STARTED: 'ADD_CONSENT_STARTED',
  ADD_CONSENT_SUCCESS: 'ADD_CONSENT_SUCCESS',
  ADD_CONSENT_ERROR: 'ADD_CONSENT_ERROR',
  // Nutrition
  GET_EER_STARTED: 'GET_EER_STARTED',
  GET_EER_SUCCESS: 'GET_EER_SUCCESS',
  GET_EER_ERROR: 'GET_EER_ERROR',
  // Clear redux store
  CLEAR_STORE: 'CLEAR_STORE',

  // Registration
  GET_REGISTRATION_DETAILS_STARTED: 'GET_REGISTRATION_DETAILS_STARTED',
  GET_REGISTRATION_DETAILS_SUCCESS: 'GET_REGISTRATION_DETAILS_SUCCESS',
  GET_REGISTRATION_DETAILS_ERROR: 'GET_REGISTRATION_DETAILS_ERROR',
  CREATE_ACCOUNT_STARTED: 'CREATE_ACCOUNT_STARTED',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_ERROR: 'CREATE_ACCOUNT_ERROR',
  REGISTRATION_NEXT_STEP: 'REGISTRATION_NEXT_STEP',
  REGISTRATION_BARCODE_CLICKED: 'REGISTRATION_BARCODE_CLICKED',
  VIDEO_NEXT_STEP: 'VIDEO_NEXT_STEP',
  REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_STARTED: 'REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_STARTED',
  REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_SUCCESS: 'REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_SUCCESS',
  REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_ERROR: 'REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_ERROR',

  // DNA file upload
  DNA_UPLOAD_SELECT_FILE: 'DNA_UPLOAD_SELECT_FILE',
  DNA_UPLOAD_CLEAR_FILE: 'DNA_UPLOAD_CLEAR_FILE',
  DNA_UPLOAD_BEGIN_UPLOAD: 'DNA_UPLOAD_BEGIN_UPLOAD',
  DNA_UPLOAD_UPDATE_PROGRESS: 'DNA_UPLOAD_UPDATE_PROGRESS',
  DNA_UPLOAD_FINISHED: 'DNA_UPLOAD_FINISHED',
  DNA_UPLOAD_ERROR: 'DNA_UPLOAD_ERROR',
  DNA_UPLOAD_OPEN_MODAL: 'DNA_UPLOAD_OPEN_MODAL',
  DNA_UPLOAD_CLOSE_MODAL: 'DNA_UPLOAD_CLOSE_MODAL',
  DNA_UPLOAD_OPEN_INSTRUCTIONS: 'DNA_UPLOAD_OPEN_INSTRUCTIONS',
  DNA_UPLOAD_CLOSE_INSTRUCTIONS: 'DNA_UPLOAD_CLOSE_INSTRUCTIONS',
  DNA_UPLOAD_SELECT_SOURCE: 'DNA_UPLOAD_SELECT_SOURCE',
  HIDE_DNA_UPLOADED_BANNER: 'HIDE_DNA_UPLOADED_BANNER',

  // Barcode
  BARCODE_ASSIGN_STARTED: 'BARCODE_ASSIGN_STARTED',
  BARCODE_ASSIGN_ERROR: 'BARCODE_ASSIGN_ERROR',
  BARCODE_UPDATE_BARCODE: 'BARCODE_UPDATE_BARCODE',

  // Password reset
  UPDATE_PASSWORD_STARTED: 'UPDATE_PASSWORD_STARTED',
  UPDATE_PASSWORD_ERROR: 'UPDATE_PASSWORD_ERROR',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_RESET: 'UPDATE_PASSWORD_RESET',

  // Forgot password reset
  RESET_PASSWORD_STARTED: 'RESET_PASSWORD_STARTED',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',

  // Domain
  GET_MACROS_STARTED: 'GET_MACROS_STARTED',
  GET_MACROS_ERROR: 'GET_MACROS_ERROR',
  GET_MACROS_SUCCESS: 'GET_MACROS_SUCCESS',

  GET_ESSENTIAL_NUTRITION_STARTED: 'GET_ESSENTIAL_NUTRITION_STARTED',
  GET_ESSENTIAL_NUTRITION_ERROR: 'GET_ESSENTIAL_NUTRITION_ERROR',
  GET_ESSENTIAL_NUTRITION_SUCCESS: 'GET_ESSENTIAL_NUTRITION_SUCCESS',

  GET_ESSENTIAL_NUTRITION_DETAILS_STARTED: 'GET_ESSENTIAL_NUTRITION_DETAILS_STARTED',
  GET_ESSENTIAL_NUTRITION_DETAILS_ERROR: 'GET_ESSENTIAL_NUTRITION_DETAILS_ERROR',
  GET_ESSENTIAL_NUTRITION_DETAILS_SUCCESS: 'GET_ESSENTIAL_NUTRITION_DETAILS_SUCCESS',

  GET_TRAITS_STARTED: 'GET_TRAITS_STARTED',
  GET_TRAITS_ERROR: 'GET_TRAITS_ERROR',
  GET_TRAITS_SUCCESS: 'GET_TRAITS_SUCCESS',

  GET_USER_GENE_DETAILS_STARTED: 'GET_USER_GENE_DETAILS_STARTED',
  GET_USER_GENE_DETAILS_SUCCESS: 'GET_USER_GENE_DETAILS_SUCCESS',
  GET_USER_GENE_DETAILS_ERROR: 'GET_USER_GENE_DETAILS_ERROR',

  GET_ADDITIONAL_PRODUCTS_STARTED: 'GET_ADDITIONAL_PRODUCTS_STARTED',
  GET_ADDITIONAL_PRODUCTS_SUCCESS: 'GET_ADDITIONAL_PRODUCTS_SUCCESS',
  GET_ADDITIONAL_PRODUCTS_ERROR: 'GET_ADDITIONAL_PRODUCTS_ERROR',

  GET_PURCHASED_PRODUCTS_STARTED: 'GET_PURCHASED_PRODUCTS_STARTED',
  GET_PURCHASED_PRODUCTS_SUCCESS: 'GET_PURCHASED_PRODUCTS_SUCCESS',
  GET_PURCHASED_PRODUCTS_ERROR: 'GET_PURCHASED_PRODUCTS_ERROR',

  GET_SUBSCRIPTION_PRODUCTS_STARTED: 'GET_SUBSCRIPTION_PRODUCTS_STARTED',
  GET_SUBSCRIPTION_PRODUCTS_SUCCESS: 'GET_SUBSCRIPTION_PRODUCTS_SUCCESS',
  GET_SUBSCRIPTION_PRODUCTS_ERROR: 'GET_SUBSCRIPTION_PRODUCTS_ERROR',

  GET_SUBSCRIPTIONS_STARTED: 'GET_SUBSCRIPTIONS_STARTED',
  GET_SUBSCRIPTIONS_SUCCESS: 'GET_SUBSCRIPTIONS_SUCCESS',
  GET_SUBSCRIPTIONS_ERROR: 'GET_SUBSCRIPTIONS_ERROR',

  GET_SUBSCRIPTION_ORDERS_STARTED: 'GET_SUBSCRIPTION_ORDERS_STARTED',
  GET_SUBSCRIPTION_ORDERS_SUCCESS: 'GET_SUBSCRIPTION_ORDERS_SUCCESS',
  GET_SUBSCRIPTION_ORDERS_ERROR: 'GET_SUBSCRIPTION_ORDERS_ERROR',

  INIT_CART_STARTED: 'INIT_CART_STARTED',
  INIT_CART_SUCCESS: 'INIT_CART_SUCCESS',
  INIT_CART_ERROR: 'INIT_CART_ERROR',

  UPDATE_CART: 'UPDATE_CART',
  OPEN_CART: 'OPEN_CART',
  CLOSE_CART: 'CLOSE_CART',
  OPEN_CART_SPINNER: 'OPEN_CART_SPINNER',
  CLOSE_CART_SPINNER: 'CLOSE_CART_SPINNER',
  OPEN_CART_ERROR: 'OPEN_CART_ERROR',
  CLOSE_CART_ERROR: 'CLOSE_CART_ERROR',

  GET_HEALTHIE_URL_STARTED: 'GET_HEALTHIE_URL_STARTED',
  GET_HEALTHIE_URL_SUCCESS: 'GET_HEALTHIE_URL_SUCCESS',
  GET_HEALTHIE_URL_ERROR: 'GET_HEALTHIE_URL_ERROR',

  GET_SHIPPING_ADDRESS_STARTED: 'GET_SHIPPING_ADDRESS_STARTED',
  GET_SHIPPING_ADDRESS_SUCCESS: 'GET_SHIPPING_ADDRESS_SUCCESS',
  GET_SHIPPING_ADDRESS_ERROR: 'GET_SHIPPING_ADDRESS_ERROR',
  UPDATE_SHIPPING_ADDRESS_STARTED: 'UPDATE_SHIPPING_ADDRESS_STARTED',
  UPDATE_SHIPPING_ADDRESS_SUCCESS: 'UPDATE_SHIPPING_ADDRESS_SUCCESS',
  UPDATE_SHIPPING_ADDRESS_ERROR: 'UPDATE_SHIPPING_ADDRESS_ERROR',

  GET_FORMULATION_STARTED: 'GET_FORMULATION_STARTED',
  GET_FORMULATION_SUCCESS: 'GET_FORMULATION_SUCCESS',
  GET_FORMULATION_ERROR: 'GET_FORMULATION_ERROR',

  GET_PAYMENT_METHOD_STARTED: 'GET_PAYMENT_METHOD_STARTED',
  GET_PAYMENT_METHOD_SUCCESS: 'GET_PAYMENT_METHOD_SUCCESS',
  GET_PAYMENT_METHOD_ERROR: 'GET_PAYMENT_METHOD_ERROR',

  UPDATE_SUBSCRIPTION_BILLING_STARTED: 'UPDATE_SUBSCRIPTION_BILLING_STARTED',
  UPDATE_SUBSCRIPTION_BILLING_SUCCESS: 'UPDATE_SUBSCRIPTION_BILLING_SUCCESS',
  UPDATE_SUBSCRIPTION_BILLING_ERROR: 'UPDATE_SUBSCRIPTION_BILLING_ERROR',

  SET_BOOSTERS_IN_CART_STARTED: 'SET_BOOSTERS_IN_CART_STARTED',
  SET_BOOSTERS_IN_CART_SUCCESS: 'SET_BOOSTERS_IN_CART_SUCCESS',
  SET_BOOSTERS_IN_CART_ERROR: 'SET_BOOSTERS_IN_CART_ERROR',

  CANCEL_SUBSCRIPTION_STARTED: 'CANCEL_SUBSCRIPTION_STARTED',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_ERROR: 'CANCEL_SUBSCRIPTION_ERROR',

  GET_SUBSCRIPTION_QUESTIONNAIRE_STARTED: 'GET_SUBSCRIPTION_QUESTIONNAIRE_STARTED',
  GET_SUBSCRIPTION_QUESTIONNAIRE_SUCCESS: 'GET_SUBSCRIPTION_QUESTIONNAIRE_SUCCESS',
  GET_SUBSCRIPTION_QUESTIONNAIRE_ERROR: 'GET_SUBSCRIPTION_QUESTIONNAIRE_ERROR',

  SAVE_SUBSCRIPTION_QUESTIONNAIRE_STARTED: 'SAVE_SUBSCRIPTION_QUESTIONNAIRE_STARTED',
  SAVE_SUBSCRIPTION_QUESTIONNAIRE_SUCCESS: 'SAVE_SUBSCRIPTION_QUESTIONNAIRE_SUCCESS',
  SAVE_SUBSCRIPTION_QUESTIONNAIRE_ERROR: 'SAVE_SUBSCRIPTION_QUESTIONNAIRE_ERROR',

  GET_BOOSTER_FORMULATIONS_STARTED: 'GET_BOOSTER_FORMULATIONS_STARTED',
  GET_BOOSTER_FORMULATIONS_SUCCESS: 'GET_BOOSTER_FORMULATIONS_SUCCESS',
  GET_BOOSTER_FORMULATIONS_ERROR: 'GET_BOOSTER_FORMULATIONS_ERROR',

  PAUSE_SUBSCRIPTION_STARTED: 'PAUSE_SUBSCRIPTION_STARTED',
  PAUSE_SUBSCRIPTION_SUCCESS: 'PAUSE_SUBSCRIPTION_SUCCESS',
  PAUSE_SUBSCRIPTION_ERROR: 'PAUSE_SUBSCRIPTION_ERROR',

  UPDATE_SUBSCRIPTION_STARTED: 'UPDATE_SUBSCRIPTION_STARTED',
  UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
  UPDATE_SUBSCRIPTION_ERROR: 'UPDATE_SUBSCRIPTION_ERROR',

  DELETE_ACCOUNT_STARTED: 'DELETE_ACCOUNT_STARTED',
  DELETE_ACCOUNT_ERROR: 'DELETE_ACCOUNT_ERROR',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  // GenoBlend Products
  GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_STARTED: 'GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_STARTED',
  GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_SUCCESS: 'GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_SUCCESS',
  GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_ERROR: 'GET_GENOBLEND_SUBSCRIPTION_PRODUCTS_ERROR',

  GET_USER_BLEND_STARTED: 'GET_USER_BLEND_STARTED',
  GET_USER_BLEND_SUCCESS: 'GET_USER_BLEND_SUCCESS',
  GET_USER_BLEND_ERROR: 'GET_USER_BLEND_ERROR',

  ENABLE_FEATURE: 'ENABLE_FEATURE',
  DISABLE_FEATURE: 'DISABLE_FEATURE'
};
