import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button, Icon, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {
  faCopy, faEnvelopeSquare, faExclamation, faFilePdf, faPrint,
} from '@fortawesome/pro-solid-svg-icons';
import { colors } from 'common/assets/sharedUI';
import styles from './BrowserFoodExportInstructions.styles';
import { sharedStyles } from '../resources';
import { registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels } from '../utils/googleanalytics';

function BrowserFoodExportInstructions(props) {
  const {
    classes, copyToClipboard, id, printClicked,
  } = props;

  const [open, setOpen] = useState(false);

  const browserCopyClicked = useCallback((e) => {
    copyToClipboard(e, id);
    setOpen(true);
    setTimeout(() => { setOpen(false); }, 2000);
    registerGAClickEventEnhanced(EventCategories.OptimalFoods, EventActions.Copy, EventLabels.BrowserFoodExportInstructions);
  }, [copyToClipboard, id, setOpen]);

  const browserPrintClicked = useCallback(() => {
    printClicked();
    registerGAClickEventEnhanced(EventCategories.OptimalFoods, EventActions.Print, EventLabels.BrowserFoodExportInstructions);
  }, [printClicked]);

  const sharedClasses = sharedStyles();

  return (
    <div className={classes.tipsAndTricks}>
      <div className={classes.frequentActions}>
        <h2>Export List Frequent Actions</h2>
        <Button
          className={clsx(sharedClasses.primaryButton, classes.copyButton)}
          startIcon={(
            <Icon>
              <FontAwesomeIcon icon={faPrint} />
            </Icon>
          )}
          onClick={browserPrintClicked}
        >
          PRINT
        </Button>
        &nbsp;&nbsp;
        <Tooltip title="Copied!" open={open} placement="right" leaveDelay={1000} leaveTouchDelay={1000}>
          <Button
            className={clsx(sharedClasses.primaryButton, classes.copyButton)}
            startIcon={(
              <Icon>
                <FontAwesomeIcon icon={faCopy} />
              </Icon>
            )}
            onClick={browserCopyClicked}
          >
            COPY
          </Button>
        </Tooltip>
      </div>
      <h2>Export List Tips & Tricks</h2>
      <p>If the buttons above do not work for you, review the manual instructions below.</p>
      <div className={classes.tip}>
        <div className={classes.tipText}>
          <div className={classes.iconAndText}>
            <Icon className={classes.circle}>
              <FontAwesomeIcon icon={faPrint} color={colors.white} title="Print" />
            </Icon>
            &nbsp;
            <strong> Print</strong>
          </div>

          <p>From the browser&apos;s &quot;File&quot; menu select &quot;Print&quot;</p>
          <p><strong>or</strong></p>
          <p>Press CTRL+P(Windows) or ⌘+P(Mac) </p>
          <p><strong>or</strong></p>
          <p>Right-click anywhere on this page and choose &quot;Print&quot; from the options.</p>
        </div>
      </div>
      <div className={classes.tip}>
        <div className={classes.tipText}>
          <div className={classes.iconAndText}>
            <Icon className={classes.circle}>
              <FontAwesomeIcon icon={faFilePdf} color={colors.white} />
            </Icon>
            &nbsp;
            <strong> Save As PDF</strong>
          </div>
          <p>Follow the above steps to Print.</p>
          <p>From the print dialog window choose PDF from the Printer or Destinations list to save as a PDF onto the computer. </p>
        </div>
      </div>
      <div className={classes.tip}>
        <div className={classes.tipText}>
          <div className={classes.iconAndText}>
            <Icon className={classes.circle}>
              <FontAwesomeIcon icon={faCopy} color={colors.white} />
            </Icon>
            &nbsp;
            <strong> Copy & Paste</strong>
          </div>
          <p>
            Select text a line at a time by clicking and dragging the mouse (not double-clicking). Then, press CTRL+C (Windows) or ⌘+C (Mac) to copy text.
            Paste the contents of the clipboard into an email, document or other location.
          </p>
        </div>
      </div>
      <div className={classes.tip}>
        <div className={classes.tipText}>
          <div className={classes.iconAndText}>
            <Icon className={classes.circle}>
              <FontAwesomeIcon icon={faEnvelopeSquare} color={colors.white} />
            </Icon>
            &nbsp;
            <strong> Email</strong>
          </div>
          <p>Email this list by copy & pasting the text into the email client.</p>
        </div>
      </div>

      <div className={classes.tip}>
        <div className={classes.tipText}>
          <div className={classes.iconAndText}>
            <Icon className={classes.circle}>
              <FontAwesomeIcon icon={faExclamation} color={colors.white} />
            </Icon>
            &nbsp;
            <strong> Don&apos;t need all these foods?</strong>
          </div>
          <p>Customize your list of foods shown here by using the SETTINGS button in the GenoPalate app.</p>
        </div>
      </div>
    </div>
  );
}

BrowserFoodExportInstructions.propTypes = {
  classes: PropTypes.shape({
    logo: PropTypes.string,
    container: PropTypes.string,
    closeBtn: PropTypes.string,
    frequentActions: PropTypes.string,
    copyButton: PropTypes.string,
    tipsAndTricks: PropTypes.string,
    iconAndText: PropTypes.string,
    circle: PropTypes.string,
    tip: PropTypes.string,
    tipText: PropTypes.string,
  }).isRequired,
  copyToClipboard: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  printClicked: PropTypes.func.isRequired,

};

export default withRouter(withStyles(styles)(BrowserFoodExportInstructions));
