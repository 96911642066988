import React from 'react';
import GeneCard from 'components/GeneCard';
import { colors } from 'common/assets/sharedUI';

const GeneView = ({ traitName, domainName, geneDetails }) => {
  const generateGeneList = () => {
    // Get unique set of genes over all markers.  TODO: This should be done server side.
    const geneList = [];
    geneDetails.genes.forEach((gene, index) => {
      if (gene.markers.find((markerElement) => markerElement.variant) !== undefined) {
        geneList.push(<GeneCard key={`${gene.geneName}_${index}`} title={gene.geneName} titleColor={colors.blue} gene={gene} markers={gene.markers} chromosome="blue" traitName={traitName} domainName={domainName} />);
      }

      if (gene.markers.find((markerElement) => markerElement.variant) === undefined) {
        geneList.push(<GeneCard key={`${gene.geneName}_${index}`} title={gene.geneName} titleColor={colors.darkerGrey} gene={gene} markers={gene.markers} chromosome="gray" traitName={traitName} domainName={domainName} />);
      }
    });

    return geneList;
  };

  return (
    <div>
      {generateGeneList()}
    </div>
  );
};

export default GeneView;
