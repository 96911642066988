import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '90%',
    margin: 'auto',
    padding: '40 20 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 30,
  },
  documentContainer: {
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    border: `1px solid ${colors.gray_03}`,
    boxShadow: 'inset 0px 0px 15px 0px rgba(89,89,89,0.15)',
    opacity: 1,
    width: '90%',
    margin: 'auto',
    height: 450,
    overflow: 'auto',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      padding: '10px 10px 10px 20px',
      marginLeft: '-20px',
      height: 350,
    },
  },
  radioContainer: {
    width: '90%',
    margin: 'auto',
    padding: '40 20 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '20 0 0',
    },
  },
  form: {
    width: '100%',
  },
  bold: {
    fontWeight: 'bold',
  },
  subtitle: {
    width: '100%',
    fontSize: '.9em',
    padding: 10,
    marginBottom: 20,
  },
  btnContainer: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
  },
}))

export default useStyles
