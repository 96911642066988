import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  WelcomeMessage,
} from 'components/componentsV2';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import ReactGA from 'react-ga4';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';

import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import styles from './FoodSettingsPage.styles';

const { IS_PROD, IS_STAGING } = window.env;

const FoodSettingsPage = (props) => {
  const { classes } = props;
  const history = useHistory();
  const match = useRouteMatch();

  const handleClick = useCallback((url, name) => {
    registerGAClickEventEnhanced(EventCategories.FoodSettings, EventActions.Open, EventLabels.SettingsType(name));
    history.push(url);
  }, [history]);

  useEffect(() => {
    if (IS_PROD || IS_STAGING) {
      ReactGA.send({ hitType: "pageview", page: match.url });
    }
  }, [match]);

  return (
    <div className={classes.pageWrapper}>
      <Link to="/food">
        <div className={classes.backLink}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <label className={classes.backLinkText}>Back to Optimal Foods</label>
        </div>
      </Link>
      <WelcomeMessage title="Optimal Foods Settings" />
      <ul className={classes.settingsWrapper}>
        {
          [
            {
              name: 'Allergies & Preferences',
              url: '/food/settings/allergies-and-preferences',
            },
            {
              name: 'Diet Types',
              url: '/food/settings/diets',
            },
            {
              name: 'Individual Foods',
              url: '/food/settings/individual-foods',
            },
          ].map((setting) => (
            <li className={classes.settingsItem} onClick={() => handleClick(setting.url, setting.name)} key={setting.name}>
              <span className={classes.settingsItemName}>{setting.name}</span>
              <FontAwesomeIcon icon={faChevronRight} className={classes.settingsItemIcon} />
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default withStyles(styles)(FoodSettingsPage);

FoodSettingsPage.propTypes = {
  classes: PropTypes.shape({
    pageWrapper: PropTypes.string.isRequired,
    backLink: PropTypes.string.isRequired,
    backLinkText: PropTypes.string.isRequired,
    settingsWrapper: PropTypes.string.isRequired,
    settingsItem: PropTypes.string.isRequired,
    settingsItemIcon: PropTypes.string.isRequired,
    settingsItemName: PropTypes.string.isRequired,
  }).isRequired,
};
