import React from "react";

import { Button, IconButton } from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowRightAltSharp";

import { appConstants } from "utils/constants/appConstants";
import useStyles from "../pages/ReportLanding.styles";
import { helpers } from "utils";
import { startingAt } from "resources/constants";

function ShopSupplementBanner({ baseProduct, onSupplementsClick, image, header, description, supplementType, unit = "mo", price, imageStyle }) {

  const classes = useStyles();

  const handleSupplementClick = () => {
    onSupplementsClick(supplementType);
  }

  const formatPrice = (price) => helpers.formatNumber(price);

  return (
    <div>
      <div className={classes.supplementImgContainer}>
        <img
          src={image}
          className={classes.supplementImg}
          alt="Supplement tablets poured into open hand"
          id="supplementFeatureImage"
          style={imageStyle}
        />
        <Button
          id="supplementInfoButton"
          size="large"
          onClick={handleSupplementClick}
          className={classes.supplementButton}
        >
          {appConstants.learnMore}
          <IconButton size="small">
            <ArrowForward fontSize="small" />
          </IconButton>
        </Button>
      </div>
      <div className={classes.supplementSpotlightHeader}>
        <span>{header}</span>
      </div>
      <div
        className={classes.supplementSpotlightPrice}
        style={{ display: baseProduct ? "inline-block" : "none" }}
      >
        <span>{`${startingAt} ${price? price : baseProduct ? formatPrice(baseProduct.pricePerMonth) : undefined}`} {supplementType === appConstants.genoVit ? `/ ${unit}`: ``}</span>
      </div>
      <div className={classes.supplementSpotlightDescription}>
        <span>{description}</span>
      </div>
    </div>
  );
}

export default ShopSupplementBanner;
