/* eslint-disable camelcase */
import background from './images/background.svg';
import logo from './images/logo.png';
import barcode from './images/barcode.jpg';
import upload_file from './images/upload_file.jpg';
import recipes from './images/recipes.jpg';
import coaching from './images/coaching.jpg';
import report_cover from './images/report_cover.jpg';
import account_bg from './images/account_bg.jpg';
import saliva_step1 from './images/saliva_step1.jpg';
import saliva_step2 from './images/saliva_step2.jpg';
import saliva_step3 from './images/saliva_step3.jpg';
import saliva_step4 from './images/saliva_step4.jpg';
import saliva_step5 from './images/saliva_step5.jpg';
import saliva_step6 from './images/saliva_step6.jpg';
import swab_step1 from './images/swab_step1.png';
import swab_step2 from './images/swab_step2.png';
import swab_step3 from './images/swab_step3.png';
import swab_step4 from './images/swab_step4.png';
import swab_step5 from './images/swab_step5.png';
import swab_step6 from './images/swab_step6.png';
import dna_kit from './images/dna_kit.jpeg';
import existing_data from './images/existing_data.png';
import phone_preview from './images/phone_preview.png';
import googlePlay from './images/googlePlay.png';
import appStore from './images/appStore.png';
import food_collection from './images/food_collection.png';
import vitamin_lab_bottle from './images/vitamin_lab_bottle_banner_1x.png';
import blue_chromosome from './images/blue_chromosome.png';
import gray_chromosome from './images/gray_chromosome.png';
import barries_footer from './images/barries_footer.png';
import facebook from './images/facebook.png';
import insta from './images/insta.png';
import linkedin from './images/linkedin.png';
import pinterest from './images/pinterest.png';
import twitter from './images/twitter.png';
import university_menu from './images/university_menu.png';
import settings_menu from './images/settings_menu.png';
import nutrients_menu from './images/nutrients_menu.png';
import home_menu from './images/home_menu.png';
import food_menu from './images/food_menu.png';
import my_account_bg from './images/my_account_bg.jpg';
import fruit_fore from './images/fruit_fore.png';
import genetics_101 from './images/genetics_101.png';
import nutrition_101 from './images/nutrition_101.png';
import appStore_banner from './images/appStore_banner.png';
import googlePlay_banner from './images/googlePlay_banner.png';
import phone from './images/phone.png';
import support from './images/support.png';
import birthday from './images/birthday.png';
import healthGoals from './images/healthGoals.png';
import resultsAreReady from './images/results-are-ready.svg';
import uploadDna from './images/upload-dna.svg';
import whatToExpect from './images/what-to-expect.svg';
import enterBarcode from './images/enter-barcode.svg';
import whatToExpectNext from './images/what-to-expect-next.svg';
import logoNoTagline from './images/logo-no-tagline.svg';
import mailKit from './images/mail-kit.svg';
import emptyCart from './images/empty-cart-illustration.svg';
import supplementFeature from './images/supplement_feature_image.jpg';
import supplementBottle from './images/supplement_bottle.png';
import pea from './images/pea.jpg';
import rice from './images/rice.jpg';
import citraFiber from './images/citra-fiber.jpg';
import oatFiber from './images/oat-fiber.jpg';
import genoBlendFeature from './images/genoblend_feature_image.jpg';
import LandingPageHeader from './images/header.png'
import genovitUpsell from './images/genovit_upsell.jpg';
import dietitianSupport from './images/dietitian_support.jpg';
import customBlend from './images/custom-blend.jpg';
import dietitianSupportHome from './images/dietitian-support.jpg';
import genoblend from './images/genoblend.png';
import consultation from './images/consultation.png';


export default {
  background,
  logo,
  barcode,
  upload_file,
  recipes,
  coaching,
  report_cover,
  account_bg,
  saliva_step1,
  saliva_step2,
  saliva_step3,
  saliva_step4,
  saliva_step5,
  saliva_step6,
  swab_step1,
  swab_step2,
  swab_step3,
  swab_step4,
  swab_step5,
  swab_step6,
  dna_kit,
  existing_data,
  phone_preview,
  googlePlay,
  appStore,
  food_collection,
  blue_chromosome,
  gray_chromosome,
  barries_footer,
  facebook,
  insta,
  linkedin,
  pinterest,
  twitter,
  university_menu,
  settings_menu,
  nutrients_menu,
  home_menu,
  food_menu,
  my_account_bg,
  fruit_fore,
  genetics_101,
  nutrition_101,
  appStore_banner,
  googlePlay_banner,
  phone,
  support,
  birthday,
  healthGoals,
  resultsAreReady,
  uploadDna,
  whatToExpect,
  enterBarcode,
  whatToExpectNext,
  logoNoTagline,
  mailKit,
  vitamin_lab_bottle,
  emptyCart,
  supplementFeature,
  supplementBottle,
  pea,
  rice,
  citraFiber,
  oatFiber,
  genoBlendFeature,
  LandingPageHeader,
  genovitUpsell,
  dietitianSupport,
  customBlend,
  dietitianSupportHome,
  genoblend,
  consultation
};
