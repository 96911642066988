import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonBase, InputBase, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import clsx from 'clsx';
import { colors } from 'common/assets/sharedUI';
import { updateBarcode, assignBarcode } from '../store/actions/registration';
import images from '../resources/images';
import StatusTracker from './StatusTrackerV2';
import PaperContainer from './PaperContainer';
import ContactSupport from './ContactSupport';
import { BARCODE_DIGIT_LENGTH } from '../store/constants';

const styles = {
  header: {
    fontSize: 22,
    color: colors.blue_03,
  },
  input: {
    // width: 30,
    border: `2px solid ${colors.gray_02}`,
    borderRadius: 5,
    margin: '10px 2px',
    // float: 'left', // Prevents the jumping input bug on Safari
    '& input': {
      textAlign: 'center',
      // Hides the up/down controls on the inputs
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  button: {
    backgroundColor: colors.primaryBlue,
    display: 'block',
    margin: '30px auto',
    width: '100%',
    maxWidth: 300,
    fontSize: 18,
    color: 'white',
    borderRadius: 5,
    padding: '5px 0',
    '&.disabled': {
      backgroundColor: colors.gray_03,
    },
  },
  inputWrapper: {
    height: 56,
    width: 306,
    margin: 'auto',
    // The width where the inputs will start to be off center
    '@media (max-width: 378px)': {
      // Simplified from (100vw - (306px + 72px)) / 2 where 306px is the width of the inputs and 72px is the total padding on both sides
      marginLeft: 'calc(50vw - 189px)',
    },
  },
};

/**
 * @param {string} barcodeError
 * @param {string | undefined} barcodeErrorCode
 */
const BarcodeError = ({ barcodeError, barcodeErrorCode }) => (
  <div style={{ marginTop: 20, marginBottom: 20 }}>
    {barcodeErrorCode === 'barcode_doesnt_belong_to_order' ? (
      <Typography style={{ color: colors.red }}>
        This barcode is not associated with your purchase. Please register the barcode located on your DNA collection kit.
      </Typography>
    ) : (
      <Typography style={{ color: colors.red }}>{barcodeError}</Typography>
    )}
  </div>
);

BarcodeError.propTypes = {
  barcodeError: PropTypes.string.isRequired,
  barcodeErrorCode: PropTypes.string,
};

BarcodeError.defaultProps = {
  barcodeErrorCode: undefined,
};

const InputBarcode = ({
  classes,
  barcode,
  isBarcodeValid,
  isAssigningBarcode,
  barcodeError,
  barcodeErrorCode,
  // eslint-disable-next-line no-shadow
  updateBarcode,
  // eslint-disable-next-line no-shadow
  assignBarcode,
}) => {
  const onChange = useCallback((event) => {
    updateBarcode(get(event, 'target.value'));
  }, [updateBarcode]);

  const submit = useCallback(() => assignBarcode(barcode), [barcode, assignBarcode]);

  const disableButton = !isBarcodeValid || isAssigningBarcode;

  return (
    <PaperContainer style={{ marginTop: '-65px' }}>
      <StatusTracker productType="kit" activeStep={3} />
      <Typography className={classes.header}>Enter Your DNA Kit Code</Typography>
      <img src={images.enterBarcode} alt="" width={150} />
      <Typography>Enter the 9 digit barcode on the tube</Typography>
      <div className={classes.inputWrapper}>
        <InputBase
          className={classes.input}
          type="text"
          onChange={onChange}
          value={barcode}
          placeholder="ex: 123456789"
          inputProps={{ maxLength: BARCODE_DIGIT_LENGTH }}
        />
      </div>
      <ButtonBase
        disabled={disableButton}
        className={clsx(classes.button, disableButton && 'disabled')}
        onClick={submit}
      >
        Continue
      </ButtonBase>
      {barcodeError && <BarcodeError barcodeError={barcodeError} barcodeErrorCode={barcodeErrorCode} />}
      <ContactSupport />
    </PaperContainer>
  );
};

InputBarcode.propTypes = {
  classes: PropTypes.shape({
    header: PropTypes.string,
    input: PropTypes.string,
    button: PropTypes.string,
    inputWrapper: PropTypes.string,
  }).isRequired,
  barcode: PropTypes.string.isRequired,
  isBarcodeValid: PropTypes.bool.isRequired,
  isAssigningBarcode: PropTypes.bool.isRequired,
  barcodeError: PropTypes.string,
  barcodeErrorCode: PropTypes.string,
  updateBarcode: PropTypes.func.isRequired,
  assignBarcode: PropTypes.func.isRequired,
}

InputBarcode.defaultProps = {
  barcodeError: undefined,
  barcodeErrorCode: undefined,
};

export default connect(
  state => ({
    barcode: state.registration.barcode,
    isBarcodeValid: state.registration.isBarcodeValid,
    isAssigningBarcode: state.registration.isAssigningBarcode,
    barcodeError: state.registration.barcodeError,
    barcodeErrorCode: state.registration.barcodeErrorCode,
  }),
  {
    updateBarcode,
    assignBarcode,
  },
)(withStyles(styles)(InputBarcode));
