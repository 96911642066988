import React from 'react'

import MuiModal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Dialog, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
  },

  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}))

const Modal = ({ children }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box textAlign='right'>
      <Button variant='outlined' style={{ borderWidth: colors.green_06, color: colors.green_06 }} onClick={handleOpen}>
        Conversions →
      </Button>
      <Dialog
        maxWidth='lg'
        style={{width: '100%'}}
        aria-labelledby='title'
        aria-describedby='description'
        className={classes.modal}
        open={open}
        
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {/* Close button */}
            <IconButton className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            {children}
          </div>
        </Fade>
      </Dialog>
    </Box>
  )
}

Modal.propTypes = {}

export default Modal
