import { colors } from 'common/assets/sharedUI';

const styles = theme => ({
  closeBtn: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  tipsAndTricks: {
    background: '#e9e9ea',
    padding: '3px',
    margin: '0px',
    borderRadius: '2px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  noprint: {
    '@media print': {
      display: 'none',
    },
  },
  expandOpen: {
    float: 'right',
    transform: 'rotate(180deg)',
  },
  cardcontent: {
    padding: '3px',
    '&:last-child': {
      paddingBottom: '2px',
    },
  },
  primaryButton: {
    backgroundColor: colors.buttonBlue,
    color: colors.white,
    borderColor: colors.buttonBlue,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 2,
    fontWeight: 500,
    fontSize: '12px',
    width: 'auto',
    padding: '3px 10px 3px 10px',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: colors.buttonHoverBlue,
      color: colors.white,
      borderColor: colors.buttonHoverBlue,
    },
    '&:disabled': {
      backgroundColor: colors.buttonDisabled,
      color: colors.buttonDisabledText,
      borderColor: colors.buttonDisabled,
    },
  },
  tipText: {
    margin: '0',
    '& p': {
      margin: '0em',
      textIndent: '0em',
      fontSize: '0.75em',
    },
  },
  circle: {
    height: '30px',
    width: '30px',
    borderRadius: '30px',
    background: '#095d7c',
    color: '#ffffff',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconAndText: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

export default styles;
