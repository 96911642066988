import { colors } from 'common/assets/sharedUI';

const styles = theme => ({
  pageWrapper: {
    paddingTop: 40,
  },
  backLink: {
    color: colors.lightBlue,
    paddingLeft: 20,
    cursor: 'pointer',
  },
  backLinkText: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  settingsWrapper: {
    border: `1px solid ${colors.gray_03}`,
    paddingLeft: 0,
    marginLeft: 20,
    borderRadius: 6,
    width: 450,
    listStyleType: 'none',
  },
  settingsItem: {
    border: `1px solid ${colors.gray_02}`,
    borderTop: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    cursor: 'pointer',
    '&:hover': {
      // Brie, not sure if I should change background color or text color for hover state?
      backgroundColor: colors.gray_02,
    },
  },
  settingsItemName: {
    fontSize: 16,
  },
  settingsItemIcon: {
    color: colors.gray_05,
    fontSize: 16,
  },
});

export default styles;
