/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdditionalProducts, initCart } from '../store/actions/additionalProducts';
import { localStorageCartKey } from '../services/shopify/utils';
import { get } from 'lodash';

export default (Component) => (props) => {
  const dispatch = useDispatch();

  const products = useSelector(state => state.additionalProducts.products);
  const cart = useSelector(state => state.additionalProducts.cart);
  const profile = useSelector(state => state.profile.userProfile);

  useEffect(() => {
    if (!products.fetching && !products.data) {
      dispatch(fetchAdditionalProducts());
    }
  }, []);

  useEffect(() => {
    const cartId = localStorage.getItem(localStorageCartKey);
    // after getting the user email, then initiate the cart
    const email = get(profile, 'data.email');

    if (!!cartId && !cart.spinnerOpen && !cart.cart && email) {
      dispatch(initCart(cartId));
    }
  }, [cart.cart, cart.spinnerOpen, dispatch, profile]);

  if (!products.data) return null;

  return <Component {...props} />;
};
