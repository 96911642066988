import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
  dialogContainer: {
    padding: '20 80 30',
    [theme.breakpoints.down('xs')]: {
      padding: '20 0',
    },
  },
  itemContainer: {
    display: 'flex',
    flexFlow: 'column',
    textAlign: 'center',
  },
  title: {
    marginBottom: 20,
  },
  body: {
    marginBottom: 30,
  },
}));

export default useStyles;
