import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Typography } from '@material-ui/core';
import { LoginForm } from 'components';
import { images } from 'resources';
import { colors } from 'common/assets/sharedUI';
import constants from '../resources/constants';
import DeleteRequestConfirmation from 'components/DeleteRequestConfirmation';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 20,
    // height: '100%', // todo: this isn't quite working, need to change the parent to have height: 100% (antd issue) + safari buggos
  },
  logo: {
    marginBottom: 10,
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    fontSize: '20px',
  },
  footerText: {
    fontSize: '20px',
  },
};

const DeleteRequestConfirmationPage = ({ classes }) => {
  const theme = useTheme();
  const { token } = useParams();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div className={classes.container}>
      <img src={images.logo} alt="logo" className={classes.logo} style={{ width: smDown ? 120 : 150 }} />
      <div className={classes.footer}>
        <Typography className={classes.footerText}>
         Delete Account
        </Typography>
      </div>
      <DeleteRequestConfirmation token={token} />
      
    </div>
  );
};

DeleteRequestConfirmationPage.propTypes = {
  classes: PropTypes.shape({
    deleteRequestConfirmationPage: PropTypes.string,
    container: PropTypes.string,
    logo: PropTypes.string,
    footer: PropTypes.string,
    footerText: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(DeleteRequestConfirmationPage);
