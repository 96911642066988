import React from 'react'

const boldStyle = {
  fontWeight: '600',
}

const paragraphStyle = {
  fontSize: 14,
  marginVertical: 6,
}

const boldParagraphStyle = {
  fontWeight: '600',
  fontSize: 14,
  marginVertical: 6,
}

const PrivacyPolicyLink = () => (
  <a href="https://www.genopalate.com/privacy">Privacy Policy</a>
)

const FAQsLink = () => (
  <a href="https://www.genopalate.com/pages/faqs">F.A.Qs</a>
)

const Terms = () => (
  <div style={{ padding: 15 }}>
    <h1 style={{ fontWeight: '600', fontSize: 18, marginVertical: 6 }}>Terms of Service</h1>
    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>Effective Date / Last Updated: September 24, 2020</div>
    </div>

    <div style={paragraphStyle}>
      These Terms of Service (
      <span style={boldStyle}>“Terms”</span>
) govern your access to and use of GenoPalate's
      website, mobile application ("mobile app") the online customer portal through which you access
      your account, and the related services (including DNA testing services, add-on Services, and
      any software applications) (collectively, the
      {' '}
      <span style={boldParagraphStyle}>"Services"</span>
) provided by GenoPalate,
      Inc. (together with any and all affiliates,
      {' '}
      <span style={boldParagraphStyle}>“GenoPalate,” “we,” “us,” or “our”</span>
      ).
    </div>

    <div style={boldParagraphStyle}>
      By purchasing or using the Services, or by clicking "I agree", you (“you”) agree to be legally
      bound to these Terms. If you do not agree with these Terms, please do not access or use the Services.
    </div>

    <div style={boldParagraphStyle}>
      We reserve the right to modify these Terms at any time. All changes will be effective
      immediately upon posting to the Services and, by accessing or using the Services after changes
      are posted, you agree to those changes. However, with respect to material changes only, you
      may choose to exercise your right below to terminate your GenoPalate account if any material
      changes are not acceptable. Material changes will be conspicuously posted on the Services or
      otherwise communicated to you.
    </div>

    <div style={boldParagraphStyle}>
      ARBITRATION NOTICE: THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION. YOU AGREE THAT
      DISPUTES BETWEEN YOU AND GENOPALATE WILL BE RESOLVED BY BINDING ARBITRATION AND YOU WAIVE ANY
      RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
    </div>

    <div style={paragraphStyle}>
      <span style={boldParagraphStyle}>1.&nbsp;</span>
      <PrivacyPolicyLink />
      . We may collect, use and disclose information from or about you, including personal
      information, as described in our&nbsp;
      <PrivacyPolicyLink />
      . If you do not agree to any terms in our&nbsp;
      <PrivacyPolicyLink />
, please do not use our Services.&nbsp;
      <span style={boldParagraphStyle}>
        If you choose to purchase and/or participate in our Services, you understand that our
        Services involve DNA testing services that involve the collection and use of genetic
        information as further described in our&nbsp;
        <PrivacyPolicyLink />
      </span>
      .
    </div>

    <div style={boldParagraphStyle}>2. License; Term.</div>

    <div style={paragraphStyle}>
        &ensp;a. <em>General License.</em> Provided you comply with these Terms, GenoPalate grants you a nonexclusive,
      non-assignable, nontransferable, non-sublicensable, revocable, limited license to access and use
      the Services solely for your own personal noncommercial purposes.
    </div>

    <div style={paragraphStyle}>
        &ensp;b. <em>Use of Genetic Reports and Other Results.</em> To expand upon the definition listed in the first paragraph above, “Services” also includes, without
      limitation, all genetic and nutrition reports, personalized recipes, and other documents,
      reports, and materials generated or produced as a result of your use of the Services
      (together,&nbsp;
      <span style={boldParagraphStyle}>“Reports”</span>
      ). In addition to the general license granted above, you may download and copy Reports
      provided to you by GenoPalate and share those Reports with others, provided you do not sell or
      otherwise commercialize those Reports nor modify or delete any copyright, trademark, or other
      proprietary notices.
    </div>

    <div style={paragraphStyle}>
        &ensp;c.<em> Term.</em> These Terms begin upon the earlier of (i) your clicking to accept these Terms, where
      that option is available, or (ii) your first access to or use of the Services (including by
      purchasing or registering a DNA test kit, ordering Data testing services, submitting a saliva
      sample, or filling out any questionnaire on our website or within our mobile app) and will
      continue in effect until these Terms are terminated as provided below.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>3. Intellectual Property Rights.</div>
      &nbsp;The Services, including their text, audio, video, graphics, charts, photographs,
      interfaces, icons, software, computer code, data, names of products and services,
      documentation, other components and content, and the design, selection, and arrangement of
      content, and all intellectual property rights in the foregoing (collectively, the&nbsp;
      <span style={boldParagraphStyle}>"Content"</span>
      ), are exclusively the property of GenoPalate or, as applicable, its suppliers and licensors,
      and are protected by copyright, trademark, and other intellectual property laws. Any reference
      to the Services includes the Content.
    </div>
    <div style={paragraphStyle}>
          As between you and GenoPalate, any research or analyses resulting from or including your personal information,
          genetic material, or DNA samples, is the sole and exclusive property of GenoPalate (e.g. research performed on biobanked
          DNA samples. See our Research and Biobanking
      {' '}
      <FAQsLink />
      {' '}
for more information).
    </div>
    <div style={paragraphStyle}>
      GenoPalate and its suppliers and licensors, as applicable, are and will remain the exclusive
      owners of all right, title, and interest in and to the Services and Content, and all ideas,
      concepts, know-how, methodologies, and techniques related to the Services and Content,
      including all patent, copyright, trademark, trade secret, and other intellectual property
      rights in or to the same, whether owned or developed by or on behalf of GenoPalate or its
      suppliers or licensors, and including all modifications, enhancements, improvements, and
      derivative works of any of the foregoing, regardless of when created or by whom.
    </div>

    <div style={paragraphStyle}>
      Nothing in these Terms grants to you any right to use GenoPalate's or any third party's
      trademarks, tradenames, service marks, logos or names. Any unauthorized use of any trademarks,
      trade dress, copyrighted materials, or any other intellectual property belonging to GenoPalate
      or any third party is strictly prohibited and may be prosecuted to the fullest extent of the
      law.
    </div>

    <div style={paragraphStyle}>
      Except as expressly stated in these Terms, no licenses or other rights, express or implied,
      are granted by GenoPalate to you under any patent, copyright, trademark, trade secret, or
      other intellectual property right of GenoPalate. The Services may contain references to
      third-party trademarks and copies of third-party copyrighted materials, which are the property
      of their respective owners.
    </div>

    <div style={boldParagraphStyle}>4. Restrictions on Your Use of the Services.</div>

    <div style={paragraphStyle}>
      &ensp;a. You will not copy, reproduce, display, duplicate, sell, publish, post, license, rent,
      lease, loan, rent, transmit, otherwise distribute, modify, translate, adapt, disassemble,
      decompile, manipulate, reconfigure, reverse-engineer, discover the source code of, or create
      derivative works of the Services or any part of the Services (including any Reports) without
      GenoPalate’s prior written consent.
    </div>

    <div style={paragraphStyle}>
      &ensp;b. You will not obscure, modify, alter, remove, or delete any copyright, trademark,
      proprietary, or intellectual property notices, markings, or designations in or on the Services
      or any Reports.
    </div>

    <div style={paragraphStyle}>
      &ensp;c. You will not use the Services if you are under the age of majority in your place of
      residence (typically 18 or 19 years old).
    </div>

    <div style={paragraphStyle}>
      &ensp;d. You will not use the Services for unlawful purposes. You are responsible for
      complying with all applicable laws and regulations, including all applicable rules regarding
      online conduct.
    </div>

    <div style={paragraphStyle}>
      &ensp;e. You will not use the Services in a manner that infringes, violates, or
      misappropriates GenoPalate’s or any third party’s rights, including patent, copyright,
      trademark, trade secret, other intellectual property rights, and privacy rights.
    </div>

    <div style={paragraphStyle}>
      &ensp;f. You will not submit inaccurate, incomplete, or out-of-date information via the
      Services (including misrepresenting your medical history, food allergies, or other
      attributes), commit fraud or falsify information in connection with your use of the Services,
      act maliciously against the business interests or reputation of GenoPalate, submit any
      information or content that you do not have permission to provide to GenoPalate, or submit or
      post anything to the Services that is fraudulent, libelous, obscene, pornographic, indecent,
      violent, offensive, hate speech, harassing, threatening, defamatory, harms another person
      or invades the privacy of another.
    </div>

    <div style={paragraphStyle}>
      &ensp;g. You will not use the Services to develop, create, produce, enhance, or add to any
      database. You will not develop, assist in developing, or have developed on behalf of yourself
      or any other person any software, technology, or services that compete with or are
      substantially similar to the Services. You will not access, use, or analyze the Services for
      any purpose that is to GenoPalate’s detriment or commercial disadvantage. You will not use the
      Services as part of any effort to compete with GenoPalate or to provide services as a service
      bureau.
    </div>

    <div style={paragraphStyle}>
      &ensp;h. You will not impersonate or attempt to impersonate any other person. You will not
      create an account for any person or order Services for any person other than yourself without
      that other person’s permission. You will not sell or otherwise transfer your GenoPalate
      account.
    </div>

    <div style={paragraphStyle}>
      &ensp;i. You will not access, use, or copy any portion of the Services, including any of its
      content, through the use of indexing agents, spiders, scrapers, bots, web crawlers, or other
      automated devices or mechanisms. You will not engage in data mining or similar data gathering
      or extraction activities or retrieve data or other content from the Services for purposes of
      creating or compiling that content for any purpose other than your authorized use of the
      Services as permitted by these Terms.
    </div>

    <div style={paragraphStyle}>
      &ensp;j. You will not use the Services to post, transmit, input, upload, or otherwise provide
      any information or material that contains any viruses, worms, Trojan horses, logic bombs, time
      bombs, cancelbots, malware, ransomware, adware, or other harmful computer code or programming
      routines, including those designed to or that reasonably may disable, damage, impair,
      interfere with, surreptitiously intercept, or expropriate the Services or any computers,
      hardware, software, system, data, or networks.
    </div>

    <div style={paragraphStyle}>
      &ensp;k. You will not engage in activities that aim to render the Services or associated
      services inoperable or to make their use more difficult.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>5. DNA Testing Services.</div>
      &nbsp;Please review the following paragraphs carefully before you decide to use our DNA
      testing services.
    </div>

    <div style={paragraphStyle}>
      &ensp;a. <em>How It Works.</em> Each DNA test kit is sent with its own unique product key code that
      allows the recipient to create an online account. After you purchase a DNA test kit through
      our website, we will send you an email asking you to create an account and register your kit
      online. If another person has purchased a DNA test kit test for you, you must obtain the
      product key code sent to the purchaser in order to create your GenoPalate account and to
      register the DNA test kit on our website. If you have previously worked with another DNA
      testing company, you can purchase a DNA data upload from us, in which case you do not need a
      DNA test kit, nor do you need to provide us with a saliva sample. After you purchase a DNA
      data upload, please ask the applicable third-party DNA testing company for your raw genetic
      data. That DNA data will need to be uploaded through your GenoPalate account in .txt format.
      We will not be able to generate Reports unless you (i) provide a saliva sample to us or (ii)
      upload .txt file of your DNA data.
    </div>

    <div style={paragraphStyle}>
      &ensp;b. <em>Permission For DNA Test Services.</em> You give permission to GenoPalate, its contractors,
      successors and assignees to perform the DNA test services from the saliva sample you submit
      and disclose the analyses performed on your DNA to you and to others you specifically
      authorize.
    </div>

    <div style={paragraphStyle}>
      &ensp;c. <em>DNA Data from Third Parties.</em> Any agreement between you and any third party for the
      provision of or with regard to DNA data from that third party to GenoPalate is solely between
      you and that third party. GenoPalate is not responsible for any third parties or their
      practices.
    </div>

    <div style={paragraphStyle}>
      &ensp;d. <em>Your Warranties.</em> You warrant that you are in compliance with and will comply with all
      laws and regulations applicable to your receipt or use of DNA testing services. You warrant
      that any saliva sample you provide is in fact your own saliva and does not contain saliva from
      any other person. You warrant that you are using the DNA testing services solely for your own
      personal informational purposes. You warrant that you are not an insurance company or employer
      attempting to obtain information about an insured person or employee. You warrant that you
      will not use the DNA test services for paternity testing. You warrant that you will not resell
      the DNA test services or related kits we provide.
    </div>

    <div style={paragraphStyle}>
      &ensp;e. <em>Saliva Samples Cannot Be Returned.</em> Once you deposit your saliva sample in the mail,
      it cannot and will not be returned to you. Saliva samples will be analyzed by the applicable
      third-party laboratory. We cannot comply with any requests to cancel analyses once saliva
      samples have been deposited in the mail.
    </div>

    <div style={paragraphStyle}>
      &ensp;f. <em>Analyses of Other Persons’ Samples.</em> If you submit the personal information of any
        person other than yourself in using the Services, including if you purchase a DNA test kit
        or arrange for DNA testing services for another person, you warrant that you have that person’s
        permission—or the written permission of that person’s legally authorized representative, as
        applicable—to provide us with that information. The legally authorized representative, as applicable,
        assumes full responsibility for ensuring that the information that he/she provides to GenoPalate is kept
        secure and that the information submitted is accurate. The Services are only intended for adults who are
        the age of majority in their state of residence (typically this is 18 or 19 years of age). You should not
        submit saliva samples belonging to a person under the age of majority.
    </div>

    <div style={paragraphStyle}>
      &ensp;g. <em>Results Limited to Current Knowledge.</em> DNA testing results are generated using the
      genetic knowledge then being used by GenoPalate to provide our DNA testing services. Future
      knowledge or research may reveal previous knowledge (and therefore DNA testing results,
      Reports, or their conclusions) to have been inaccurate or incomplete.
    </div>

    <div style={paragraphStyle}>
      &ensp;h. <em>Nature of Results. </em>DNA testing results are for the subject’s own informational and
      descriptive purposes only. DNA testing services results should not be used for diagnostic or
      medical purposes. GenoPalate does not warrant or endorse any specific or general actions or
      inactions that may be taken (or not taken) in response to the results of our DNA testing
      services.
    </div>

    <div style={paragraphStyle}>
      &ensp;i. <em>Consult a Health Care Professional.</em> If you have questions about or would like to
      discuss or learn more about the results of our DNA testing services, please consult your
      nutritionist, dietitian, geneticist, physician, or other medical or health care professional.
      You should consult a medical or health care professional before acting on or making decisions
      in response to any results of our DNA testing services.
    </div>

    <div style={paragraphStyle}>
      &ensp;j. <em>Sharing Results.</em> If you decide to share Reports or other results of our DNA testing
      services with anyone (including medical or health care professionals), we cannot and do not
      control how those persons will use or disclose that information. You, alone, are responsible
      for how and with whom you share the results of our DNA testing services.
    </div>

    <div style={paragraphStyle}>
      &ensp;k. <em>Add-On Services.</em> If you have received DNA testing services from GenoPalate you may be
      eligible for various add-on services, which are a type of Service. Add-on Services may include
      Services such as personalized recipes, personalized grocery shopping lists, customized recipes
      and coaching services. You will not be able to receive add-on Services unless you have also
      received our DNA testing services. Some of these add-on Services may come at an additional
      cost and/or require you to provide additional information, including personal information,
      which will be treated as set forth in our
      {' '}
      <PrivacyPolicyLink />
    </div>

    <div style={paragraphStyle}>
          &ensp;l.<em>Biobanking.</em>  You have the option to separately consent to biobank your DNA sample with GenoPalate.
          "Biobanking" means that GenoPalate and its third-party genotyping laboratory will store your DNA sample and
          related personal information for up to 10 years and use such information to perform additional research and
          analyses. Biobanking is a separate, additional Service from the Services purchased by you regarding analysis
          of your genetic information in order to provide you Reports about eating for your genes.
    </div>
    <div style={paragraphStyle}>
          We only biobank your DNA sample and related personal information if you have signed our "Consent to Biobanking"
          form found inside of your GenoPalate account.  You may withdraw your consent to biobanking at any time by emailing <a href="mailto:research@genopalate.com"> research@genopalate.com</a>.
          There is no penalty for withdrawing your consent.  To learn more about how we use your
          bio banked DNA, please see our
      {' '}
      <PrivacyPolicyLink />
.
    </div>
    <div style={paragraphStyle}>
      Biobanking is provided at no cost to you.  If you consent to biobanking, GenoPalate has no obligation to provide you
          with any additional information, research or analyses results, or any additional services. If you consent to
          biobanking, you understand and agree that GenoPalate and certain third parties may use and store your DNA sample
          and related personal information (in compliance with our Privacy Policy) without further compensation or attribution
          to you.
    </div>
    <div style={paragraphStyle}>
          Biobanking is further described in our F.A.Q.s which can be found
      {' '}
      <a href="https://www.genopalate.com/pages/faqs">here</a>
.
          The biobanking F.A.Q.s are incorporated into and part of these Terms.
    </div>
    <div style={paragraphStyle}>
      &ensp;m. <em>No Compensation.</em> You understand that you are not entitled to any payment or other
      compensation or financial benefit of any kind from GenoPalate or any third party as a result
      of or in connection with our Services, including our use of DNA testing results or our use of
      your data, including personal information, that you provide in order to receive Services,
      including add-on Services and biobanking.
    </div>

    <div style={paragraphStyle}>
      &ensp;n. <em>No License; No Ownership.</em> Except for your limited right to download, copy, and share
      Reports as described at Section 2 above, you do not obtain any license, ownership, or other
      right, title, or interest, express or implied, in or to any results of our Services or any
      products or services that may be developed or improved upon using information resulting from
      our Services (including any patent, copyright, trademark, trade secret, or other intellectual
      property rights in or to the same).
    </div>

    <div style={boldParagraphStyle}>6. Terms of Sale.</div>

    <div style={paragraphStyle}>
      &ensp;a.<em> Payment.</em> Purchases made using the Services must be made by credit or debit card.
      Information about our collection and use of payment-related information is described in our <PrivacyPolicyLink />.
        If the credit or debit card information that you submit is incorrect or
      invalid, your payment will not be processed. We have no responsibility or liability if your
      credit or debit card is declined by your financial institution. Payments are processed by our
      PCI-compliant third-party payment processor. Refunds, if available, are the responsibility of
      GenoPalate and are at GenoPalate’s sole discretion.
    </div>

    <div style={paragraphStyle}>
      &ensp;b. <em>Shipping and Handling; Taxes. </em>Unless otherwise noted at the point of sale, shipping
      and handling fees for DNA testing kits will be charged separately and are your responsibility.
      GenoPalate charges you for sales, use, and other taxes for products and services ordered
      through our website or mobile app to the extent required by law. GenoPalate will not ship any
      orders outside of the continental United States.
    </div>

    <div style={paragraphStyle}>
      &ensp;c. <em>Order Acceptance.</em> Nothing on our website or mobile app constitutes an offer, but an
      invitation to you to make an offer to purchase DNA test kits, DNA data uploads, or other
      products or services through our website or mobile app. All orders are subject to acceptance
      by GenoPalate. In addition, there may be certain orders that we cannot accept or must cancel.
      We reserve the right, in our sole discretion and without liability, to reject or cancel any
      order not yet shipped for any reason, including the following: limitations on quantities
      available for purchase; inaccuracies or errors in product descriptions, images, or pricing
      information; and problems identified by credit and fraud avoidance services. We may also
      require verifications, approvals, or other information before considering acceptance of any
      order. We will contact you if all or any portion of your order is rejected or canceled or if
      additional information is required to accept your order. An order is deemed accepted when
      GenoPalate ships the products ordered (where applicable).
    </div>

    <div style={paragraphStyle}>
      &ensp;d.<em> Errors in Sample Processing.</em> If the volume of your saliva sample is too low, if your
      saliva sample does not contain sufficient DNA material, or if the saliva sample you provide
      contains other issues or errors, the third-party laboratory may not be able to analyze or
      process your saliva sample. In this event, GenoPalate will send you a replacement DNA test kit
      for reprocessing at no additional charge to you. If the third-party laboratory is unable to
      analyze or process your second saliva sample, GenoPalate will either refund to the purchaser
      the original amount paid for the DNA test kit (excluding shipping and handling) or will offer
      to send you a second replacement DNA test kit at no additional charge. If you choose to and do
      provide a third saliva sample but the third-party laboratory is again unable to analyze or
      process your saliva sample, GenoPalate will refund to the purchaser the original amount paid
      for the DNA test kit (excluding shipping and handling). If we issue the purchaser of a DNA
      test kit a refund under this subsection, you agree to not submit another saliva sample to
      GenoPalate without our prior written consent; regardless, if you submit another saliva sample
      to GenoPalate in the future, we will not be responsible for analyzing or processing that
      sample.
    </div>

    <div style={paragraphStyle}>
      &ensp;e.<em> Errors in DNA Data.</em>If any DNA data you provide is corrupted, incomplete, or contains
      other issues or errors, GenoPalate may not be able to analyze that DNA data. In this event,
      GenoPalate will refund to the purchaser the original amount paid for the DNA data upload. In
      such an event, we recommend that you instead purchase a DNA test kit.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>7. Your Account.</div>
      &nbsp;While you may purchase DNA test kits, DNA data uploads, and other products and services
      through our website or mobile app as a guest, you will be required to set up an online
      GenoPalate account in order to receive DNA testing services from GenoPalate. You also will be
      required to set-up an account with GenoPalate in order to access our mobile app or to use
      add-on Services through your Account. You will be required to submit certain information in
      order to create your account, including personal information, and will be required to
      establish a username and password. You may also provide additional personal information
      requested in the Services, at your option. Certain add-on Services may not be available to you
      if certain information, including personal information, is not provided. You are responsible
      for maintaining the confidentiality of any information you use in connection with the
      Services, including your username and password. You are responsible for all activity on your
      account. You agree to maintain true, accurate and current account information and to promptly
      update your account to keep it true, accurate and current. If you believe someone else has
      accessed your account, please change your password and notify us immediately at&ensp;
        <a href="mailto:privacy@genopalate.com">privacy@genopalate.com</a>.
    </div>

    <div style={paragraphStyle}>
      You agree that by signing into your account, including by using the mobile app, we may display
      your genetic and related nutrition results, and related recommendations, food logging, grocery
      lists and other add-on Services to you. These displays may include genetic variants, genotypes
      and related gene information, as well as information related to how your logged activity
      compares to recommendations made by the Services.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>8. Suspension and Termination.</div>
      &nbsp;GenoPalate may, for any reason and in its sole discretion, suspend or terminate your
      GenoPalate account or your access to or use of the Services, and may terminate these Terms,
      without notice or liability, including if you breach these Terms or if we suspect you have
      breached these Terms; upon any unauthorized use of your username or password; if you act in an
      abusive manner; if you act in a manner inconsistent with applicable laws or regulations; if
      required by law; or if it becomes no longer commercially viable to provide the Services. If we
      suspend or terminate your account, you will not create another account without our prior
      written permission. You may terminate your GenoPalate account and these Terms if any material
        changes to these Terms are not acceptable to you by contacting us at&ensp;<a href="mailto:hello@genopalate.com">hello@genopalate.com</a>,
        but that termination will not entitle you to a refund of any fees you have paid. Upon any
      termination of your account or these Terms you must immediately stop accessing and using the
      Services. To terminate your mobile app account, you must completely delete and uninstall the
      mobile app from your mobile device. You understand that upon termination of your account we
      may retain your data and other Personal Information as described in our <PrivacyPolicyLink />.
    </div>

    <div style={paragraphStyle}>
      We also maintain the right to filter, remove, modify, refuse to display or delete any content
      that you submit to the Services in order to comply with applicable laws or if we believe such
      content is contrary to the visions and purpose of GenoPalate.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>9. Availability of Products and Services; Right to Modify the Services.</div>
      &nbsp;The prices and availability of products and services, including DNA testing services and
      add-on Services, and biobanking mentioned on or made available through our website or mobile app may change
      at any time without notice to you and without liability. GenoPalate reserves the right, for
      any reason and at any time and without notice or liability to you, to modify or cease offering
      any Services, including without limitation the mobile app. Prices remain valid while they are
      listed and offered on our website or mobile app. Prices will be as posted on our website or
      mobile app as of the date and time of your order, as applicable. GenoPalate is not responsible
      for any pricing or typographical errors on our website or in our mobile app. Availability of
      products and services may be limited and products may not be available for immediate delivery.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>10. Monitoring the Services.</div>
      &nbsp;You understand and agree that GenoPalate may monitor the use of the Services, at any
      time, including but not limited your account and the mobile app.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>11. Social Media.</div>
      &nbsp;Links to GenoPalate's social media pages (e.g., Facebook, Instagram, and Linkedin) are
      included on the website and mobile app (“Social Media Pages”). Because anyone may post or tag
      on Social Media Pages, posts do not necessarily reflect GenoPalate's views. We will treat all
      information posted to our Social Media pages as non-confidential. We reserve the right to
      remove anything from our Social Media Pages, in our sole discretion. We may also take steps to
      block users from access to our Social Media Pages who violate these Terms. If we follow, like,
      re-tweet, favorite, share, or re-post an individual’s content on our Social Media Pages, we
      are not endorsing that third party or any product, service or company they represent.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>12. NO WARRANTY.</div>
      &nbsp;THE SERVICES ARE PROVIDED “AS IS,” “AS AVAILABLE,” AND WITHOUT ANY WARRANTY OF ANY KIND.
      GENOPALATE DOES NOT WARRANT OR GUARANTEE THE ACCURACY, RELIABILITY, QUALITY, COMPLETENESS,
      TIMELINESS, OR AVAILABILITY OF THE SERVICES. GENOPALATE DOES NOT WARRANT OR GUARANTEE THAT THE
      SERVICES WILL BE UNINTERRUPTED OR ERROR FREE, THAT ANY DEFECTS IN THE SERVICES WILL BE
      CORRECTED, OR THAT THE SERVICES OR THE SERVERS THAT MAKE THEM AVAILABLE ARE FREE OF VIRUSES OR
      OTHER HARMFUL CONDITIONS OR COMPONENTS. GENOPALATE IS NOT RESPONSIBLE FOR ANY TYPOGRAPHICAL
      ERRORS ON THE SERVICES.
    </div>

    <div style={paragraphStyle}>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, GENOPALATE EXPRESSLY DISCLAIMS ALL
      WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICES, INCLUDING WITHOUT LIMITATION THOSE
      REGARDING AVAILABILITY, QUALITY, ACCURACY, MERCHANTABILITY, FITNESS FOR ANY USE OR PURPOSE,
      COMPATIBILITY WITH ANY STANDARDS OR USER REQUIREMENTS, TITLE, AND NONINFRINGEMENT, AS WELL AS
      ANY ARISING BY OPERATION OF LAW OR FROM A COURSE OF DEALING OR USAGE IN TRADE. GENOPALATE HAS
      NO RESPONSIBILITY FOR THE TIMELINESS, DELETION, MISDELIVERY, OR FAILURE TO STORE ANY DATA OR
      USER COMMUNICATION. WE ARE NOT RESPONSIBLE FOR ANY VIRUS OR OTHER MALWARE THAT MAY INFECT YOUR
      DEVICE FROM OUR SERVICES, OR ANY DAMAGES CAUSED FROM SUCH VIRUS OR MALWARE.
    </div>

    <div style={paragraphStyle}>
      GENOPALATE DOES NOT PROVIDE MEDICAL SERVICES AND THE SERVICES ARE NOT INTENDED TO BE A
      SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT.
    </div>

    <div style={paragraphStyle}>
      YOU ACKNOWLEDGE AND AGREE THAT THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER
      PROBLEMS INHERENT IN THE USE OF TELECOMMUNICATIONS, THE INTERNET, WIRELESS NETWORKS, AND
      ELECTRONIC COMMUNICATIONS. GENOPALATE IS NOT RESPONSIBLE FOR ANY DELAYS, INACCURACIES,
      DELIVERY FAILURES, OR OTHER FAILURES OR DAMAGE RESULTING FROM THOSE PROBLEMS OR ANY OTHER
      PROBLEMS OUTSIDE OF GENOPALATE’S REASONABLE AND DIRECT CONTROL.
    </div>

    <div style={paragraphStyle}>
      WE MAKE NO REPRESENTATION THAT THE SERVICES ARE APPROPRIATE OR AVAILABLE FOR USE IN LOCATIONS
      OTHER THAN THE UNITED STATES. IF YOU CHOOSE TO ACCESS OR USE THE SERVICES FROM LOCATIONS OTHER
      THAN THE UNITED STATES, YOU DO SO AT YOUR OWN RISK AND YOU ARE RESPONSIBLE FOR COMPLYING WITH
      APPLICABLE LAWS AND REGULATIONS.
    </div>

    <div style={paragraphStyle}>
      YOUR USE OF THE SERVICES (INCLUDING ALL REPORTS) IS AT YOUR OWN RISK AND YOU, ALONE, ARE
      RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER HARDWARE, SOFTWARE, SYSTEMS, AND NETWORKS, ANY
      LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY INFORMATION FROM THE SERVICES, AND ANY
      OTHER DAMAGE THAT MAY BE INCURRED.
    </div>

    <div style={paragraphStyle}>
      NO INFORMATION, ORAL OR WRITTEN, OBTAINED BY YOU FROM GENOPALATE OR IN ANY MANNER FROM THE
      SERVICES CREATES ANY WARRANTY.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>13. LIMITATION OF LIABILITY.</div>
      &nbsp;TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL GENOPALATE, ITS
      AFFILIATES, OR ITS OR THEIR RESPECTIVE DIRECTORS, OFFICERS, OWNERS, EMPLOYEES, CONTRACTORS,
      REPRESENTATIVES, CONSULTANTS, AGENTS, SUPPLIERS, OR LICENSORS (TOGETHER,
      <div style={boldParagraphStyle}>“GENOPALATE PARTIES”</div>
) BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
      EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL LOSS OR DAMAGE, OR ANY OTHER LOSS OR DAMAGE OF ANY KIND,
      ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR YOUR ACCESS TO OR USE OF, OR INABILITY TO
      ACCESS OR USE, THE SERVICES (INCLUDING WITHOUT LIMITATION THE INPUT OF PERSONAL AND OTHER
      INFORMATION INTO THE SERVICES AND ANY ACTIONS YOU TAKE AS A RESULT OF USING THE SERVICES),
      WHETHER THE CLAIM IS BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY,
      WARRANTY, OR OTHERWISE, AND EVEN IF A GENOPALATE PARTY HAS EXPRESS KNOWLEDGE OF THE
      POSSIBILITY OF THE LOSS OR DAMAGE.
    </div>

    <div style={paragraphStyle}>
      YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP ACCESSING AND USING THE SERVICES. WITHOUT LIMITING
      THE FOREGOING, IN NO EVENT WILL THE GENOPALATE PARTIES’ LIABILITY TO YOU EXCEED $100, EVEN IF
      THIS REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
    </div>

    <div style={paragraphStyle}>
      SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OF THE
      ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>14. Indemnification.</div>
      &nbsp;You will indemnify, defend, and hold harmless the GenoPalate Parties from and against
      all losses, claims, liabilities, demands, complaints, actions, damages, judgments,
      settlements, fines, penalties, expenses, and costs (including reasonable attorneys’ fees) that
      arise out of or in connection with (a) your access to or receipt or use of the Services
      (including DNA test kits); (b) your misuse of or reliance on any material, data, or other
      documentation or information downloaded or otherwise obtained from the Services, including
      Reports; (c) your submission of information (including personal information) using or in
      connection with the Services; (d) your violation of or noncompliance with applicable laws or
      regulations; (e) use of the Services for unlawful purposes; (f) your violation of any third
      party’s rights; (g) your negligence, willful misconduct, other tortious acts or omissions, or
      fraud; or (h) your breach of these Terms. We reserve, and you grant to us, the exclusive right
      to assume the defense and control of any matter subject to indemnification by you.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>15. Sweepstakes, Promotions & Contests.</div>
      &nbsp;We may offer sweepstakes, promotions or contests that you may participate in at your
      option. Sweepstakes, promotions and contests may be subject to additional terms and conditions
      which will be presented to you at such time.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>16. Feedback / Stories.</div>
      &nbsp;GenoPalate is the exclusive owner of and you hereby irrevocably assign to GenoPalate at
      no cost all right, title, and interest (including all intellectual property rights) in and to
      all feedback pertaining to the Services that you may provide, including suggestions,
      enhancements, recommendations, and comments. You understand that feedback may be used by or on
      behalf of GenoPalate for any purpose but that GenoPalate is not required to use any feedback.
    </div>

    <div style={paragraphStyle}>
        &nbsp;Furthermore, if you submit your story to us on the "Stories" page of our website,
        you agree to only provide accurate, complete information that you have a right to provide.
        You grant to GenoPalate a worldwide, nonexclusive, transferable, perpetual, sublicense able
        right and license to post your story and any photo that you send to us relating to the story
        on our website, and otherwise use such information for our business purposes. You understand that
        any story and related information you send to us may become public information and will not be treated
        confidentially (although we will not post your last name on the website). We have no obligation to
        review or use any story that you send to us. While GenoPalate makes commercially reasonable efforts
        to only post true, accurate information on its website, GenoPalate relies on the users who send us
        their stories to provide true and accurate information. Therefore, GenoPalate makes no warranty of
        truth or accuracy.
    </div>
    <div style={paragraphStyle}>
        &nbsp;Any feedback or stories may be used by GenoPalate without attribution or compensation to you.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>17. Injunctive Relief.</div>
      &nbsp;Your violation of GenoPalate’s intellectual property rights would cause irreparable harm
      to GenoPalate and monetary damages would be insufficient to remedy that harm. Accordingly, in
      the event of an actual or threatened violation of our intellectual property rights, we will be
      entitled to injunctive relief, without the need to post bond, prove damages, or meet any
      similar requirement, as well as any other remedies available at law or at equity.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>18. Third-Party Websites and Services.</div>
      &nbsp;The Services may link to, or be linked to, websites, applications and services not
      maintained or controlled by GenoPalate. Those links are provided as a convenience and
      GenoPalate is not responsible for examining or evaluating the content or accuracy of, and does
      not warrant or endorse, any third-party websites, applications or services or any products or
      services made available through those websites, applications or services. Please take care
      when leaving the Services to visit a third-party website, application or service. You should
      read the terms of use and privacy policy for each website, application and service that you
      use.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>19. Linking to the Website.</div>
      &nbsp;You may not link to our website without our prior written consent. You are responsible
      for all costs we incur enforcing this clause.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>20. Beta Testing New Services.</div>
      &nbsp;We may provide you the option to beta-test certain new add-on Services. In the event you
      agree to participate in any beta-testing of new add-on Services, additional terms and
      conditions may apply, in addition to these Terms, which will be made available to you at the
      time you agree to participate in such beta-testing.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>21. Governing Law.</div>
      &nbsp;These Terms are governed by the laws of the state of Wisconsin, without regard for its
      conflict of law principles. The Uniform Computer Information Transactions Act or any version
      thereof adopted by any state in any form does not apply to these Terms. Venue is exclusively
      in the state or federal courts, as applicable, located in Milwaukee County, Wisconsin, with
      respect to any dispute arising under these Terms unless otherwise determined by GenoPalate in
      its sole discretion. The parties expressly agree to the exclusive jurisdiction of those
      courts.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>22. Arbitration Agreement; Class Waiver; Waiver of Trial by Jury.</div>
      &nbsp;Please read the following paragraphs carefully because they require you to arbitrate
      disputes with GenoPalate and limit the manner in which you can seek relief from us.
    </div>

    <div style={paragraphStyle}>
      &ensp;a. <em>Applicability.</em> Any dispute, claim, or controversy arising out of or relating to the
      Services or these Terms, including the breach, termination, enforcement, interpretation, or
      validity of these Terms (together,
      {' '}
      <span style={boldParagraphStyle}>“Disputes”</span>
      ), will be resolved by binding arbitration on an individual basis as described in these Terms
      (this
      {' '}
      <span style={boldParagraphStyle}>“Arbitration Agreement”</span>
      ). But, in the event of any actual, alleged, or threatened violation of confidentiality or
      violation of GenoPalate’s intellectual property or other proprietary rights, GenoPalate may
      immediately resort to court proceedings in a court of competent jurisdiction in order to seek
      immediate injunctive relief without posting bond, proving damages, or meeting any similar
      requirement. Any institution of any action for injunctive relief will not constitute a waiver
      of the right or obligation of either party to submit any claim seeking relief other than
      injunctive relief to arbitration. This Arbitration Agreement applies to you; GenoPalate
      Parties; their respective predecessors in interest, successors in interest, and assigns;
      authorized and unauthorized users or beneficiaries of the Services; and any third-party
      beneficiaries.
    </div>

    <div style={paragraphStyle}>
      &ensp;b.<em>Arbitrator.</em> Arbitration proceedings will be administered by JAMS/Endispute (
      <span style={boldParagraphStyle}>“JAMS”</span>
) before an arbitrator selected pursuant to the JAMS rules. The decision of
      the arbitrator will be final and binding. Any final award or judgment may be filed and
      enforced in any court of competent jurisdiction. Each party will bear its own expenses and
      fees, including without limitation its own attorneys’ fees and costs, arising from
      arbitration, unless the arbitrator determines that your claims were improper or frivolous, in
      which case the arbitrator may require you to reimburse the GenoPalate Parties for certain fees
      and expenses in accordance with the JAMS rules. Any arbitration proceeding may not be
      consolidated or joined with any other proceeding and will not proceed as a class action.
      {' '}
      <div style={boldParagraphStyle}>
        The parties understand that they would have had a right or opportunity to litigate Disputes
        through a court, to have a judge or jury decide their case, and to participate in a class
        action or other proceeding involving multiple claimants, but they have instead chosen to
        have all Disputes decided through individual arbitration.
      </div>
    </div>

    <div style={paragraphStyle}>
      &ensp;c. <em>Place; Federal Arbitration Act.</em> The place of arbitration will be Milwaukee,
      Wisconsin, unless otherwise agreed to in writing by all parties to the arbitration. This
      Arbitration Agreement evidences a transaction involving interstate commerce and the Federal
      Arbitration Act, 9 U.S.C. Sections 1-16, will govern the interpretation, enforcement, and
      proceedings pursuant to this Arbitration Agreement.
    </div>

    <div style={paragraphStyle}>
      &ensp;d.<em>Confidentiality.</em> Any and all actions taken under this Arbitration Agreement,
      including all filings, orders, judgments, and awards made in any arbitration proceeding, are
      confidential and may not be disclosed to any third party.
    </div>

    <div style={paragraphStyle}>
      &ensp;e. <em>Time Limitation on Claims.</em>Arbitration proceedings must be initiated within one year
      after any Dispute arises; otherwise, the Dispute is permanently barred.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>23. Assignment.</div>
      &nbsp;You may not assign these Terms or any of its rights or obligations under these Terms, in
      whole or in part, without GenoPalate’s prior written consent. Any attempted assignment by you
      in violation of this section will be void. GenoPalate may assign these Terms or any of its
      rights or obligations under these Terms, in whole or in part, without obtaining your consent.
      These Terms are binding upon and inures to the benefit of GenoPalate’s successors and assigns.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>24. Third-Party Beneficiaries.</div>
      &nbsp;These Terms do not confer any rights, remedies, or benefits upon any person other than
      you and GenoPalate, except that our affiliates, our mobile app store providers (e.g. Google
      and Apple), and Shopify, Inc., our website hosting services provider, are third-party
      beneficiaries of these Terms.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>25. Force Majeure.</div>
      &nbsp;GenoPalate will not be liable to you for any delay or other failure to perform under
      these Terms that is due to causes beyond GenoPalate’s control, including without limitation
      acts of God, actions of government, acts of a public enemy, terrorist threats or acts, civil
      disorders or disturbances, strikes or other labor disputes, fires, floods, earthquakes,
      blizzards, other acts of nature, explosions, national emergencies, power, telecommunications,
      Internet, satellite, and network outages or failures, and similar causes and events.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>26. Relationship of the Parties.</div>
      &nbsp;GenoPalate is an independent contractor. These Terms do not create any employment,
      agency, partnership, or joint venture relationship between the parties.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>27. Notice.</div>
      &nbsp;All notices, consents, and other communications permitted or required to be given under
      these Terms must be in writing and addressed to the recipient as provided in this section and
      will be deemed validly given upon delivery if personally delivered with fees prepaid,
      including by a recognized courier service; upon receipt if delivered by certified or
      registered United States mail, postage prepaid and return receipt requested, as indicated by
      the date on the signed receipt; or on the date the email is sent if via email, provided a hard
      copy is also provided. Where GenoPalate is the recipient, communications must be sent to
      GenoPalate, Inc., 10437 W. Innovation Drive, Suite 518, Milwaukee, Wisconsin 53226, with a copy to <a href="mailto:hello@genopalate.com">hello@genopalate.com</a>. Where you are the recipient, communications must be sent to any address
      on file with GenoPalate.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>28. Entire Agreement; Interpretation.</div>
      &nbsp;These Terms, our
      {' '}
      <PrivacyPolicyLink />
, the Biobanking
      {' '}
      <FAQsLink />
, the Consent to Biobanking form found inside
        of your GenoPalate account, and any additional terms we have posted throughout the Services, are the entire
        agreement between you and GenoPalate with respect to your access to and use of the Services and supersede all
        previous agreements and understandings, whether oral or written, between you and GenoPalate with respect to the
        Services. In the event of conflict or inconsistency, the documents govern in the order listed here: the Consent
        to Biobanking form, the
      {' '}
      <PrivacyPolicyLink />
, any terms posed on our website, and the Terms.
    </div>

    <div style={paragraphStyle}>
          &nbsp;GenoPalate’s failure to enforce any provision of these Terms will not constitute a
          waiver of that provision or any other provision. Any waiver of any provision of these Terms
          will be effective only if in writing and signed by GenoPalate. If any provision of these Terms
          is held invalid, void, or unenforceable, that provision will be severed from the remaining provisions
          and the remaining provisions will remain in full force and effect. The headings in these Terms are
          for convenience only and do not affect the interpretation of these Terms.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>29. Survival.</div>
      &nbsp;Any provisions of these Terms that are intended to survive termination (including any
      provisions regarding indemnification, limitation of our liability, our intellectual property
      rights, or dispute resolution) will continue in effect beyond any termination of these Terms
      or of your access to or use of the Services.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>30. New Jersey Consumers.</div>
      &nbsp;To the extent required by New Jersey state law, no provision in these Terms shall apply
      to any consumer in New Jersey if the provision limits remedies for (i) negligence, (ii)
      products liability claims, (iii) the punitive damages laws, (iv) the New Jersey Uniform
      Commercial Code, or (v) failure to reasonably protect against harm arising from certain
      criminal acts of third parties (e.g., computer hacking and identity theft). The provisions of
      these Terms concerning the exclusion or limitation of certain damages are not applicable in
      New Jersey with respect to statutory damages, punitive damages, loss of data, and loss of or
      damage to property, to the extent required by New Jersey state law. GenoPalate reserves all
      rights, defenses and permissible limitations under the laws of New Jersey and under the laws
      of your state of residence.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>31. California Users.</div>
      &nbsp;Under California Civil Code Section 1789.3, California users of the Services receive the
      following specific consumer rights notice: The Complaint Assistance Unit of the Division of
      Consumer Services of the California Department of Consumer Affairs may be contacted in writing
      at 1020 N Street, #501, Sacramento, California 95814, or by telephone at 1-916-445-1254.
    </div>

    <div style={paragraphStyle}>
      <div style={boldParagraphStyle}>32. Contact Us.</div>
      Please direct any questions and concerns regarding these Terms or the Services to us by email
      at <a href="mailto:hello@genopalate.com">hello@genopalate.com</a>, by telephone at 414-801-6686, or by mail at GenoPalate, Inc.; Attn:
      Legal Department; GenoPalate Inc, 10437 W. Innovation Drive, Suite 518, Milwaukee, Wisconsin 53226.
    </div>
  </div>
)

export default Terms
