import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import ReactGA from 'react-ga4';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
  Button, Grid, Box, Tabs, Tab, Tooltip, Typography, CircularProgress,
} from '@material-ui/core';
import { getAccountContracts } from 'store/actions/consent';
import { useSelector, useDispatch } from 'react-redux';
import { sharedStyles } from 'resources';
import { updateBilling, getShippingAddress, getSubscriptions } from 'store/actions/subscription';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { colors } from 'common/assets/sharedUI';
import { getCurrentSubscription } from 'services/shopify/utils';
import { AccountRow, DeleteAccountDisclaimer } from '../components/componentsV2';
import {
  UpdatePasswordForm, AccountAlert, SectionWrapper, UpdateProfileForm,
} from '../components/AccountForm';
import useStyles from './MyAccountPage.styles';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import {
  AlertTypes, apiStatuses, defaultErrorMessage, deleteAccountDisclaimer, deleteAccountPrivacyPolicyText, deleteAccountText, deleteAccountTextSecondary, deleteAccountWarning, genericErrorMessage,
} from '../resources/constants';
import UpdateShippingForm from '../components/AccountForm/UpdateShippingForm';

import PurchasedProducts from './PurchasedProductsPage';
import OrderHistory from './OrderHistory';
import { deleteAccount } from 'store/actions/user';

const { IS_PROD, IS_STAGING } = window.env;

const MyAccountPage = () => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  // User contracts
  const contracts = useSelector(state => state.consent.accountContracts);
  const updateContractsStatus = useSelector(state => state.consent.addConsentStatus);
  // Password status
  const { passwordUpdated, updatePasswordError } = useSelector(state => ({
    passwordUpdated: state.user.passwordUpdated,
    updatePasswordError: state.user.updatePasswordError,
  }));

  // Account Deleting status
  const { accountDeleted, deletingAccount, accountDeletedError } = useSelector(state => state.user);

  // Profile status
  const { updateProfileStatus, updateProfileError } = useSelector(state => ({
    updateProfileStatus: state.profile.updateProfileStatus,
    updateProfileError: state.profile.updateProfileError,
  }));
  // Billing status
  const { updateBillingFetching, updateBillingError, updateBillingSuccess } = useSelector(state => ({
    updateBillingFetching: state.subscription.billing.fetching,
    updateBillingError: state.subscription.billing.error,
    updateBillingSuccess: state.subscription.billing.data,
  }));
  // Shipping address status
  const {
    updateShippingAddressFetching,
    updateShippingAddressError,
    updateShippingAddressSuccess,
  } = useSelector(state => ({
    updateShippingAddressFetching: state.subscription.shippingAddress.updateFetching,
    updateShippingAddressError: state.subscription.shippingAddress.updateError,
    updateShippingAddressSuccess: state.subscription.shippingAddress.updateSuccess,
  }));
  // User subscriptions
  const { data: subscriptions } = useSelector((state) => state.subscription.subscriptions);
  const [tabValue, setTabValue] = useState(0);

  // User data
  const userProfile = useSelector(state => state.profile.userProfile);
  const isKitUser = useMemo(() => (userProfile && userProfile.data && userProfile.data.isKitUser), [userProfile]);

  const [showAlert, setShowAlert] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [alertType, setAlertType] = useState(AlertTypes.ERROR);
  const currentSubscription = useMemo(() => (subscriptions ? getCurrentSubscription(subscriptions) : null), [subscriptions]);

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, [setTabValue]);

  const handleNewAlert = useCallback(({ message, title, type }) => {
    setAlertMessage(message);
    setAlertTitle(title);
    setAlertType(type);
    setShowAlert(true);
  }, []);

  const handleCloseAlert = useCallback(() => {
    setAlertMessage('');
    setAlertTitle('');
    setAlertType(AlertTypes.DEFAULT);
    setShowAlert(false);
  }, []);

  useEffect(() => {
    if (IS_PROD || IS_STAGING) {
      ReactGA.send({ hitType: "pageview", page: match.url });
    }
    dispatch(getAccountContracts());
    dispatch(getShippingAddress());
    dispatch(getSubscriptions());
  }, [dispatch, match]);

  useEffect(() => {
    dispatch(getAccountContracts());
  }, [dispatch, updateContractsStatus]);

  useEffect(() => {
    if (accountDeleted) {
      handleNewAlert({
        message: 'Confirmation email sent. Please check your email for instructions. ',
        title: 'Success',
        type: AlertTypes.SUCCESS,
      });
    } else if (accountDeletedError) {
      handleNewAlert({
        message: accountDeletedError === 'error' || accountDeletedError.includes(genericErrorMessage)
          ? defaultErrorMessage : accountDeletedError,
        title: 'Error',
        type: AlertTypes.ERROR,
      });
    }
  }, [accountDeleted, accountDeletedError, handleNewAlert]);

  useEffect(() => {
    if (passwordUpdated) {
      handleNewAlert({
        message: 'Your password has been successfully updated.',
        title: 'Success',
        type: AlertTypes.SUCCESS,
      });
    } else if (updatePasswordError) {
      handleNewAlert({
        message: updatePasswordError === 'error' || updatePasswordError.includes(genericErrorMessage)
          ? defaultErrorMessage : updatePasswordError,
        title: 'Error',
        type: AlertTypes.ERROR,
      });
    }
  }, [passwordUpdated, updatePasswordError, handleNewAlert]);

  useEffect(() => {
    if (updateProfileStatus === apiStatuses.RESOLVED) {
      handleNewAlert({
        message: 'Your profile has been successfully updated.',
        title: 'Success',
        type: AlertTypes.SUCCESS,
      });
    } else if (updateProfileError) {
      handleNewAlert({
        message: updateProfileError === 'error' || updateProfileError.includes(genericErrorMessage)
          ? defaultErrorMessage : updateProfileError,

        title: 'Error',
        type: AlertTypes.ERROR,
      });
    }
  }, [updateProfileStatus, updateProfileError, handleNewAlert]);

  useEffect(() => {
    if (updateBillingFetching === false && updateBillingSuccess) {
      handleNewAlert({
        message: 'Email sent. Please check your email for instructions.',
        title: 'Success',
        type: AlertTypes.SUCCESS,
      });
    } else if (updateBillingError) {
      handleNewAlert({
        message: 'An error occurred. Please try again later.',
        title: 'Error',
        type: AlertTypes.ERROR,
      });
    }
  }, [updateBillingFetching, updateBillingSuccess, updateBillingError, handleNewAlert]);

  useEffect(() => {
    if (updateShippingAddressFetching === false && updateShippingAddressSuccess) {
      handleNewAlert({
        message: 'Your shipping address has been successfully updated.',
        title: 'Success',
        type: AlertTypes.SUCCESS,
      });
    } else if (updateShippingAddressError) {
      handleNewAlert({
        message: (updateShippingAddressError === 'error'
          || updateShippingAddressError.includes('failed with status code 422')
          || updateShippingAddressError.includes(genericErrorMessage))
          ? 'The zipcode is not valid for the selected state.' : updateShippingAddressError,
        title: 'Error',
        type: AlertTypes.ERROR,
      });
    }
  }, [updateShippingAddressFetching, updateShippingAddressSuccess, updateShippingAddressError, handleNewAlert]);

  // clean up any old alerts when page initially loads
  useEffect(() => {
    handleCloseAlert();
  }, [handleCloseAlert]);

  /* const onBuyProductsClicked = useCallback(() => {
    registerGAClickEventEnhanced(EventCategories.AddOns, EventActions.Open, EventLabels.AddOns);
    history.push('/account/products');
  }, [history]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPurchasedProductsClicked = useCallback(() => {
    registerGAClickEventEnhanced(EventCategories.AddOns, EventActions.Open, EventLabels.PurchasedProducts);
    history.push('/account/products/purchased');
  }); */

  const onChangeConsentClicked = useCallback(() => {
    registerGAClickEventEnhanced(EventCategories.AccountConsent, EventActions.Open, EventLabels.ChangeConsent);
    history.push('/account/consent');
  }, [history]);

  const onDeleteAccountClicked = useCallback(() => {    
    if(currentSubscription)
      setShowWarning(true);
    else {
      registerGAClickEventEnhanced(EventCategories.DeleteAccount, EventActions.Click, EventLabels.DeleteAccount);
    dispatch(deleteAccount());
    }
  }, [deletingAccount, subscriptions?.length]);

  const handleDeleteAccount = () => {
    registerGAClickEventEnhanced(EventCategories.DeleteAccount, EventActions.Click, EventLabels.DeleteAccount);
    dispatch(deleteAccount());
    setShowWarning(false);
  };
  const handleUpdateBilling = useCallback(() => {
    dispatch(updateBilling());
  }, [dispatch]);

  const shouldRenderContract = useMemo(() => (
    // Check if any of the contracts have a defined opt in or opt out value
    Array.isArray(contracts) && contracts.map((contractObj) => Object.prototype.hasOwnProperty.call(contractObj, 'optedIn')).includes(true)
  ), [contracts]);

  const getContractDescription = useCallback((contractArray) => {
    const description = contractArray.map((x) => {
      if (x.optedIn === true || x.optedIn === false) {
        return (
          <p>
            You chose
            {' '}
            <b>{x.optedIn ? 'to consent' : 'not to consent'}</b>
            {' '}
            to
            {' '}
            {x.name}
            {' '}
            on
            {' '}
            {x.consentDate}
          </p>
        );
      }
      return <p />;
    });
    return description;
  }, []);

   const PrivacyPolicyLink = () => (
  <a href="https://www.genopalate.com/privacy" className={classes.linkText}>{deleteAccountPrivacyPolicyText}</a>
) 
   const getDeleteDataDescription = useCallback((user) => {
     if(user)
       return (
       <Grid container>
          <Grid item xs={12} md={8}>
            <Typography className={classes.paragraphText}>
            {deleteAccountText}
            </Typography>
            <Typography className={classes.paragraphText}>
              {deleteAccountTextSecondary}
              {PrivacyPolicyLink()}
            </Typography>
            <Typography className={classes.paragraphText}>
              {deleteAccountWarning}
            </Typography>
            <Typography className={classes.paragraphText}>{user.data.name+ ' '+ user.data.surname}</Typography>
             <Typography className={classes.paragraphText}>{deleteAccountDisclaimer}</Typography>
          </Grid>
           <Grid item xs={12} md={8} className={classes.deleteBtnContainer}>
             {deletingAccount
               ? <CircularProgress size={24} color={colors.red} />
               :
               (<Button
                 className={classes.deleteBtn}
                 name="deleteBtn"
                 onClick={onDeleteAccountClicked}
                 disabled={deletingAccount}
               >
                 Permanently Delete Data
                 <Typography className={classes.arrow}>→</Typography>
               </Button>)}
           </Grid>
           </Grid>
        );
     else
       return <Typography className={classes.paragraphText}>{' '}</Typography>

  }, [deletingAccount]);

  // eslint-disable-next-line react/prop-types
  const TabPanel = useCallback(({
    children, value, index, ...other
  }) => (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  ),
    []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            className={classes.tabs}
            aria-label="account page tabs"
          >
            <Tab label="My Account" />
            <Tab label="My Products" />
            <Tab label="Order History" />
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={tabValue} index={0}>
          <DeleteAccountDisclaimer
            handleDeleteAccount={handleDeleteAccount}
            handleCancel={() => setShowWarning(false)}
            open={showWarning}
          />
          <Grid container>
            <Grid item xs={12} md={6}>
              <SectionWrapper title="Profile">
                <UpdateProfileForm />
              </SectionWrapper>
              <SectionWrapper title="Change Password">
                <UpdatePasswordForm />
              </SectionWrapper>
              {showAlert && (
                <AccountAlert
                  title={alertTitle}
                  message={alertMessage}
                  type={alertType}
                  onClose={() => handleCloseAlert()}
                />
              )}
              {
                shouldRenderContract && (
                  <SectionWrapper
                    title="Preferences"
                    className={classes.headingStyle}
                  >
                    <AccountRow
                      label={`Research${isKitUser ? '/Biobanking' : ''} Consent`}
                      description={getContractDescription(contracts)}
                      className={classes.subheadingStyle}
                    >
                      <Grid item xs={12}>
                        <Button
                          className={classes.consentBtn}
                          name="consentBtn"
                          onClick={onChangeConsentClicked}
                        >
                          Allow data to be shared for Research
                        </Button>
                      </Grid>
                    </AccountRow>
                  </SectionWrapper>
                )
              }
              <SectionWrapper
                title="Data"
                className={classes.headingStyle}
              >
               
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" className={classes.label}>{'Delete Data'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography className={classes.paragraphText}>
                      {deleteAccountText}
                      </Typography>
                      <Typography className={classes.paragraphText}>
                        {deleteAccountTextSecondary}
                        {PrivacyPolicyLink()}
                      </Typography>
                      <Typography className={classes.paragraphText}>
                        {deleteAccountWarning}
                      </Typography>
                      <Typography className={classes.paragraphText}>{userProfile?.data.name+ ' '+ userProfile?.data.surname}</Typography>
                      <Typography className={classes.paragraphText}>{deleteAccountDisclaimer}</Typography>
                    </Grid>
                    <Grid item xs={12} md={8} className={classes.deleteBtnContainer}>
                      {deletingAccount
                        ? <CircularProgress size={24} color={colors.red} />
                        :
                        (<Button
                          className={classes.deleteBtn}
                          name="deleteBtn"
                          onClick={()=>onDeleteAccountClicked(subscriptions)}
                          disabled={deletingAccount}
                        >
                          Permanently Delete Data
                          <Typography className={classes.arrow}>→</Typography>
                        </Button>)}
                    </Grid>
                  </Grid>
                   
              </SectionWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <SectionWrapper title="Billing">
                <Tooltip title={!currentSubscription ? 'GenoVit subscription required' : ''}>
                  <span>
                    <Button
                      disabled={updateBillingSuccess || !currentSubscription}
                      className={updateBillingSuccess
                        ? sharedClasses.rebrandedSuccessButton
                        : sharedClasses.rebrandedPrimaryButton}
                      onClick={handleUpdateBilling}
                    >
                      {updateBillingSuccess
                        ? 'Update Email Sent' : 'Update Billing Info →'}
                      {updateBillingSuccess
                  && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      color={colors.green_05}
                      style={{ marginLeft: 5 }}
                    />
                  )}
                    </Button>
                  </span>
                </Tooltip>
                <p className={classes.caption}>Email secure link to update payment and billing information</p>
              </SectionWrapper>
              <SectionWrapper title="Shipping">
                <UpdateShippingForm disabled={!currentSubscription} />
              </SectionWrapper>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <PurchasedProducts />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <OrderHistory />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default MyAccountPage;
