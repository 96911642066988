import { makeStyles } from '@material-ui/core'
import { colors, fonts } from '../../../common/assets/sharedUI'

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
  },

  legendIcon: {
    minWidth: '20px',
  },

  servingAmount: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '30px',
    padding: '10px 0px',
  },

  inline: {
    display: 'inline',
  },

  text: {
    fontSize: 18,
    fontFamily: fonts.Swiss721MD,
    color: colors.gray_06,
  },

  heavyText: {
    fontFamily: fonts.Swiss721HY,
  },

  impactedGenetic: {
    color: colors.blue_03,
  },

  nutritionHeader: {
    textAlign: 'right',
    fontSize: 18,
    fontFamily: fonts.Swiss721LT,
    color: colors.gray_06,
    margin: '5px 0px 5px 5px',
  },

  nested: {
    paddingLeft: theme.spacing(4),
  },

  blueText: {
    color: colors.blue_03,
  }
}))

export default useStyles
