import { clearLocalStorage } from './storage';

const handle401 = async (error) => {
  // todo no side effects in interceptors
  clearLocalStorage();
  // Case where URL do not change we need to redirect browser to login screen
  location.href = '/';
  throw error;
};

const handleServiceErrorByCode = async (error) => {
  const { code } = error;
  switch (code) {
    case 'ECONNABORTED':
      // Connection Timeout
      break;
    default:
      break;
  }

  throw error;
};

const handleServiceErrorByStatusCode = async (error) => {
  const { response } = error;
  switch (response.status) {
    case 401:
      // Token not provided or expired
      return handle401(error);
    default:
      break;
  }

  throw error;
};

// Main Handler
const serviceError = (error) => {
  const { code, response } = error;

  if (code) {
    // If network or device based error occurs
    return handleServiceErrorByCode(error);
  }

  if (response) {
    // If server responds
    return handleServiceErrorByStatusCode(error);
  }

  // Just in case...
  return Promise.reject(error);
};

export default serviceError;
