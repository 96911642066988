import React, {
  useEffect, useCallback, useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { images, sharedStyles } from 'resources';
import {
  Grid, Button, Typography, Icon, Card, CardContent, CardActions, withStyles,
} from '@material-ui/core';
import { get } from 'lodash';
import {
  Loading, Carousel, TopFood, SupplementFeaturedSpot, SummaryCard,
} from 'components/componentsV2';
import ReportHighlight from 'components/ReportHighlight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboardList, faCarrot, faDna, faSalad, faCalendarAlt,
} from '@fortawesome/pro-light-svg-icons';
import {
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';
import { upperCase } from 'lodash';
import { selectExpandedCategory } from '../store/actions/food';
import { getHealthieUrl } from '../store/actions/user';
import { getMacros } from '../store/actions/domain';
import useStyles from './ReportLanding.styles';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import icons from '../resources/icons';
import { apiStatuses, internalURLs } from '../resources/constants';
import { useAdditionalProducts, useNutrients } from 'hooks';
import { helpers } from 'utils';
import { faBowlSalad, faClipboardListCheck } from '@fortawesome/pro-duotone-svg-icons';
import { colors } from 'common/assets/sharedUI';

const genopalateUniversityClick = () => registerGAClickEventEnhanced(EventCategories.Menu, EventActions.Open, EventLabels.GenoPalateUniversity);
const facebookMemberForumClick = () => registerGAClickEventEnhanced(EventCategories.Menu, EventActions.Open, EventLabels.FacebookMemberForum);
const groupConsultClick = () => registerGAClickEventEnhanced(EventCategories.Menu, EventActions.Open, EventLabels.GroupConsult);

const ColorButton = withStyles((theme) => ({
  root: {
    color: colors.white,
    backgroundColor: colors.green_07,
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.green_05,
      boxShadow: 'none',
    },
  },
}))(Button);

const UpSellCard = ({ title, subtitle, image, buttonTitle, href }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    // registerGAClickEventEnhanced(EventCategories.Nutrients, title === 'Personalized Vitamins' ? EventActions.NutrientsGenoVitCallout : title === '1-on-1 Consultation' ? EventActions.NutrientsConsult : EventActions.Click, EventLabels.NutrientUpSell(title));
    history.push(href);
  }
  
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          variant='h2'
          className={classes.subtitle}
          style={{ color: colors.black }}
          color='textSecondary'
          gutterBottom
        >
          {subtitle}
        </Typography>
        <Typography variant='h5' component='h2' className={classes.title}>
          {title}
        </Typography>

        <img
          src={image}
          alt={title}
          style={{ objectFit: 'cover', width:'16rem', height:'6.5rem' }}
        />
      </CardContent>

      <CardActions style={{ paddingTop: 0 }}>
        <ColorButton size='small' variant="contained" onClick={handleClick} className={classes.buttonText}>
          {buttonTitle} →
        </ColorButton>
      </CardActions>
    </Card>
  )
}

const PutIntoActionCard = ({ title, detail, image, buttonTitle, href }) => {
  const classes = useStyles();
  
  return (
    <Card className={classes.card}>
      <CardContent style={{marginRight: 0}}>
        <Grid container sm={12} xs={12} md={12} lg={12} spacing={2}>
          <Grid item md={3} xs={3}>
             <img
              src={image}
              alt={title}
              style={{ objectFit: 'contain',  width: '4rem', height: '4rem' }}
            />
          </Grid>
          <Grid item md={9} xs={9}>
            <Typography variant='h5' component='h2' className={classes.title}>
              {title}
            </Typography>
            </Grid>
        </Grid>
        <Typography variant='body1' component='h2' className={classes.detail} >
            {detail}
        </Typography>
      </CardContent>

      <CardActions style={{ paddingTop: 0 }}>
        <ColorButton size='small' variant="contained" href={href} className={classes.buttonText}>
          {buttonTitle}
        </ColorButton>
      </CardActions>
    </Card>
  )
}

const ReportLanding = () => {
  const sharedClasses = sharedStyles();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: products } = useAdditionalProducts();

  // Product data
  const macros = useSelector(state => state.domain.macros);
  // Top foods
  const userFoodsStatus = useSelector(state => state.topFoods.status);
  const userFoods = useSelector(state => state.topFoods.userTopFoods);
  // User data
  const profile = useSelector(state => state.profile.userProfile);
  const profileStatus = useSelector(state => state.profile.status);
  const hasConsult = get(profile, 'data.hasConsult', false);
  // Healthie URL
  const healthieUrlStatus = useSelector(state => state.user.healthieUrlStatus);
  const healthieUrl = useSelector(state => state.user.healthieUrl);

  const productName = '1-on-1 Consultation with a Registered Dietitian';
  const product = useMemo(() => products && products.find(({ name }) => name === productName), [products?.length]);

  const { numberOfNutrients} = useNutrients();

  const optimalFoodCount = useMemo(() => {
    return userFoods?.reduce((total, item) =>
      total + item.foods.filter((foodItem) =>
        !foodItem.individuallyHidden && foodItem.missingDietaryPreferences.length === 0
      ).length, 0);
  }, [userFoods]);


  useEffect(() => {
    if (!macros.fetching && !macros.data) {
      dispatch(getMacros());
    }
  }, [dispatch, macros.data, macros.fetching]);

  useEffect(() => {
    if (hasConsult && healthieUrlStatus !== apiStatuses.PENDING && healthieUrlStatus !== apiStatuses.REJECTED && healthieUrlStatus !== apiStatuses.RESOLVED) {
      dispatch(getHealthieUrl());
    }
  }, [dispatch, hasConsult, healthieUrlStatus, healthieUrl]);

  const onNutrientsClick = e => {
    e.preventDefault();
    registerGAClickEventEnhanced(EventCategories.Home, EventActions.Click, EventLabels.AllRecommendations);
    history.push('/nutrients');
  };

  const onFoodsClick = e => {
    e.preventDefault();
    registerGAClickEventEnhanced(EventCategories.Home, EventActions.Click, EventLabels.ViewOptimalFoods);
    history.push('/food');
    dispatch(selectExpandedCategory(8)); // sets initial expanded food group id
  };

  const onHealthieClick = e => {
    registerGAClickEventEnhanced(EventCategories.Home, EventActions.Click, EventLabels.ScheduleConsultation);
  };

  const displayTopFoods = useCallback(
    () => {
      if (userFoods) {
        const bestFoodMap = userFoods.map((item) => {
          if (item.foods.length > 0) return <TopFood id={item.foods[0].id} name={item.name} image={item.foods[0].fileName} foodName={item.foods[0].name} eventName={item.name} />;
          return null;
        });
        return bestFoodMap;
      }
      return null;
    },
    [userFoods],
  );
 
  const handleShopClick = (event) => {
    registerGAClickEventEnhanced(EventCategories.Navbar, EventActions.Open,  EventLabels.Shop);
    history.push('/shop');
  };

  
  if ([userFoodsStatus, profileStatus].some(status => [apiStatuses.IDLE, apiStatuses.PENDING].includes(status)) || macros.fetching) { return <Loading />; }
  return (
    <div style={{backgroundColor: colors.gray_01}}>
      <Grid container spacing={0} justify='center'>
        <Grid container lg={12} md={12} xs={12} spacing={4} justify='center' alignItems='center' className={classes.nutrientCardsContainer} >
          <Grid item md={6} lg={6} sm={12} xs={12} className={classes.nutrientsCard}>
            <SummaryCard
              colorTheme={'dark'}
              iconName={faClipboardListCheck}
              iconColor={[colors.darkestDarkGreen, colors.darkestDarkGreen]}
              heading={`${helpers.possessive(profile.data.name)} Label`}
              count={numberOfNutrients}
              description1={'A nutrition label tailored to your unique needs with:'}
              description2={'Personalized'}
              description3={'Nutrition Recommendations'}
              onClick={(e) => {
                history.push('/nutrients');
                 registerGAClickEventEnhanced(
                  EventCategories.Nutrients,
                  EventActions.Open,
                  EventLabels.PageOpened,
                );
                      }}
            />
            </Grid>
          <Grid item md={6} lg={6} sm={12} xs={12}  className={classes.foodsCard}>
            <SummaryCard
              colorTheme={'light'}
              iconName={faBowlSalad}
              iconColor={[colors.darkBlue, colors.white]}
              heading={`Food List`}
              count={optimalFoodCount}
              description1={'With your genetic-based nutrition analysis in mind, we found:'}
              description2={'Gene-Approved'}
              description3={'Optimal Foods'}
              onClick={(e) => {
                history.push('/food');
                 registerGAClickEventEnhanced(
                  EventCategories.OptimalFoods,
                  EventActions.Open,
                  EventLabels.PageOpened,
                );
                      }}
              />
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Typography variant="p2" className={classes.heading}>Put It Into Action</Typography>
        </Grid>
        <Grid container lg={12} md={12} xs={12} spacing={3} justify='center' alignItems='center' className={classes.nutrientCardsContainer}>
          <Grid item md={4} lg={4} sm={12} xs={12}>
            <UpSellCard 
              title='GenoVit' 
              subtitle='PERSONALIZED VITAMINS'
              image={images.genovitUpsell}
              buttonTitle='See Custom Formula'
              href={internalURLs.SUPPLEMENT_PRODUCT_DETAILS}
            />
          </Grid>
           <Grid item md={4} lg={4} sm={12} xs={12}>
           <UpSellCard 
            title='GenoBlend' 
            subtitle='PERSONALIZED PROTEIN &amp; FIBER'
            image={images.customBlend}
            buttonTitle='See Custom Blend'
            href={internalURLs.BLEND_PRODUCT_DETAILS}
          />
          </Grid>
           <Grid item md={4} lg={4} sm={12} xs={12}>
            <UpSellCard 
              title='1-on-1 Consultations' 
              subtitle='DIETITIAN SUPPORT'
              image={images.dietitianSupportHome}
              buttonTitle='See Details'
              href={product?.id ? `/account/products/product-details/${product.id}` : '/account/products/product-details/not-found'}
            />
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12} className={classes.shopAllContainer}>
          <Typography variant="p2" className={classes.shopAllLink}> <a href='/shop'>SHOP ALL → </a></Typography>
        </Grid>

         <Grid item lg={12} md={12} xs={12}>
          <Typography variant="p2" className={classes.heading}>Resources</Typography>
        </Grid>
        <Grid container lg={12} md={12} xs={12} spacing={3} justify='center' alignItems='center' className={classes.nutrientCardsContainer}>
          <Grid item md={4} lg={4} sm={12} xs={12}>
            <PutIntoActionCard 
              title='Free 1:1 Consultation' 
              image={icons.calender}
              buttonTitle='Sign Up →'
              detail={'Sign up for a complimentary 20-minute session with a GenoPalate dietitian to get you started with your analysis.'}
              href={internalURLs.CONSULTATIONS_CALENDLY}
            />
          </Grid>
           <Grid item md={4} lg={4} sm={12} xs={12}>
            <PutIntoActionCard 
              title='GenoPalate University' 
              image={icons.graduation}
              buttonTitle='Explore →'
              detail={'Resources about genetics, nutrition and more.'}
              href={internalURLs.GENOPALATE_UNIVERSITY}
            />
          </Grid>
           <Grid item md={4} lg={4} sm={12} xs={12}>
            <PutIntoActionCard 
              title={`Facebook Member Forum`}
              image={icons.facebook}
              buttonTitle='Explore →'
              detail={'Share your story, learn from others, and gain motivation to become the healthiest you.'}
              href={internalURLs.FACEBOOK_FORUM}
            />
          </Grid>
        </Grid>
       
        {/* <Grid item lg={8} xs={12}>
          <Grid item xs={12}>
            <div className={classes.reportHighlightsContainer} id="homeRecommendedMacronutrients">
              {macros.data && <ReportHighlight data={macros.data} onClick={onNutrientsClick} />}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <SupplementFeaturedSpot eventCaller="Home" />
        </Grid>
        <Grid item xs={12} className={classes.carouselGrid}>
          <div className={classes.carouselContainer}>
            <Carousel infinite eventSource="HomePage">
              {displayTopFoods()}
            </Carousel>
          </div>
          <div className={classes.viewAllFoodsContainer}>
            <Button id="homeViewAllFoodsButton" size="large" onClick={e => onFoodsClick(e)} className={sharedClasses.primaryButton}>View All Optimal Foods</Button>
          </div>
          <div className={classes.secondImgContainer}>
            <img src={images.fruit_fore} className={classes.secondImg} alt="" />
          </div>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default ReportLanding;
