import React from 'react'
import PropTypes from 'prop-types'

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core'
import { faFingerprint } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

import { useUserBlendType } from 'hooks'
import Seprator from 'components/shared/Seprator'

import { colors } from '../../common/assets/sharedUI'
import useStyles from './styles/NutritionFacts.styles'

// TODO: can be in a separate component
const DaggerIcon = () => <>&dagger;</>

const NutritionFact = ({
  item,
  classNames,
  children,
  onClick,
  parentLevel = 0,
  hideImpactfulIcon,
}) => {
  const {
    name,
    amountPerServing,
    dailyValue,
    isDailyValueBasedOn2000CalorieDiet,
    isDailyValueNotEstablished,
    unit = '',
    isImpactful,
    category,
    traitId,
  } = item

  const classes = useStyles()
  const history = useHistory()

  const level = parentLevel + 1

  const handleClick = () => {
    if (onClick) {
      onClick()
      return
    }
    history.push(`/nutrients/${category}/${traitId}`)
  }

  return (
    <React.Fragment>
      <ListItem className={classes.listItem} button onClick={handleClick}>
        <ListItemIcon className={classes.legendIcon}>
          {isImpactful && !hideImpactfulIcon && (
            <FontAwesomeIcon
              icon={faFingerprint}
              size='xl'
              color={colors.blue_03}
            />
          )}
        </ListItemIcon>

        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                component='span'
                variant='h6'
                className={[
                  classes.inline,
                  classNames,
                ]}
                style={{ paddingLeft: level !== 1 ? `${level * 10}px` : '1px' }}
              >
                {name}
              </Typography>
              <Typography component='span' className={classes.text}>
                {` ${amountPerServing} ${unit}`}
              </Typography>
            </React.Fragment>
          }
          color='textPrimary'
        />

        <ListItemSecondaryAction>
          <Typography variant='body1' component='h6' className={classes.text}>
            {!isDailyValueNotEstablished ? (
              `${dailyValue}%${isDailyValueBasedOn2000CalorieDiet ? '*' : ''}`
            ) : (
              <DaggerIcon />
            )}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>

      <Seprator color='#707070' />

      {children && (
        <List component='div' disablePadding>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              parentLevel: level, // Pass the current level as parentLevel to children
            })
          )}
        </List>
      )}
    </React.Fragment>
  )
}

NutritionFact.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    dailyValue: PropTypes.string.isRequired,
    amountPerServing: PropTypes.string.isRequired,
    isDailyValueBasedOn2000CalorieDiet: PropTypes.string.isRequired,
    isDailyValueNotEstablished: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    isImpactful: PropTypes.bool.isRequired,
  }).isRequired,
  classNames: PropTypes.object,
  onClick: PropTypes.func,
  hideImpactfulIcon: PropTypes.bool,
  parentLevel: PropTypes.number,
  children: PropTypes.node,
}

const NutritionFacts = () => {
  const { blendNutrition = {} } = useUserBlendType()

  const {
    calcium,
    calories,
    dietaryFiber,
    insolubleFiber,
    iron,
    protein,
    saturatedFat,
    sodium,
    solubleFiber,
    totalCarbohydrates,
    totalFat,
  } = blendNutrition

  const classes = useStyles()

  return (
    <>
      <div className={classes.servingAmount}>
        <Typography variant='h5'>{calories?.name || ''}</Typography>
        <Typography variant='h5'>{calories?.amountPerServing || 0}</Typography>
      </div>

      <Seprator thickness={10} color='#707070' />

      <Typography className={classes.nutritionHeader}>
        % Daily Value*
      </Typography>

      <List component='nav' disablePadding dense={true}>
        <NutritionFact
          item={{ ...totalFat }}
          classNames={[classes.text, classes.heavyText, classes.blueText]}
        >
          <NutritionFact item={{ ...saturatedFat }} classNames={[classes.text, classes.blueText]} />
        </NutritionFact>

        <NutritionFact
          item={{ ...totalCarbohydrates }}
          classNames={[classes.text, classes.heavyText, classes.blueText]}
        >
          <NutritionFact item={dietaryFiber} classNames={[classes.text, classes.blueText]}>
            {/* since we don't need to navigate soluble fiber to the nutrient page, so we override onClick with empty function  */}
            <NutritionFact
              item={solubleFiber}
              classNames={[classes.text]}
              onClick={() => {}}
            />

            {/* since we don't need to navigate insoluble fiber to the nutrient page, so we override onClick with empty function  */}
            <NutritionFact
              item={insolubleFiber}
              classNames={[classes.text]}
              onClick={() => {}}
            />
          </NutritionFact>
        </NutritionFact>

        <NutritionFact
          item={{ ...protein }}
          classNames={[classes.text, classes.blueText]}
        />
      </List>

      <Seprator thickness={18} color='#707070' />

      <List component='nav' disablePadding dense={true}>
        <NutritionFact
          item={{ ...calcium }}
          classNames={[classes.text, classes.blueText]}
        />

        <NutritionFact
          item={{ ...iron }}
          classNames={[classes.text, classes.blueText]}
        />
      <NutritionFact
          item={{ ...sodium }}
          classNames={[classes.text, classes.heavyText, classes.blueText]}
        />
      </List>
    </>
  )
}

NutritionFacts.propTypes = {}

export default NutritionFacts
