import { product } from 'services';
import types from '../actionTypes';

export const selectActionItems = () => dispatch => {
  dispatch(actionItemsStarted());
  product.actionItems()
    .then(response => {
      dispatch(actionItemsSuccess(response));
    })
    .catch(error => {
      console.log(error);
      dispatch(actionItemsFailure(error));
    });
};

const actionItemsSuccess = actionItems => ({
  type: types.SELECT_ACTION_ITEMS_SUCCESS,
  payload: {
    ...actionItems,
  },
});

const actionItemsStarted = () => ({
  type: types.SELECT_ACTION_ITEMS_STARTED,
});

const actionItemsFailure = error => ({
  type: types.SELECT_ACTION_ITEMS_ERROR,
  payload: {
    error,
  },
});
