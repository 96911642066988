import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import { colors } from 'common/assets/sharedUI'

export default function ProgressBar(props) {
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 15,
      borderRadius: 10,
    },
    colorPrimary: {
      backgroundColor: colors.grey,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: colors.blue,
    },
  }))(LinearProgress)

  return (
    <BorderLinearProgress {...props} />
  )
}
