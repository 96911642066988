import { axios } from 'utils'
import { constants } from 'resources'

export default async function logFilterAction({ userProductId, selected, deselected }) {
  try {
    const params = { userProductId, selected, deselected }

    return axios.post(constants.API_LOG_FILTER_ACTION, params)
  } catch (e) {
    return null
  }
}
