import { makeStyles } from '@material-ui/core/styles';
import { colors, fonts } from 'common/assets/sharedUI';

const sharedStyles = makeStyles(theme => ({
  primaryButton: {
    backgroundColor: colors.buttonBlue,
    color: colors.white,
    borderColor: colors.buttonBlue,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 2,
    fontWeight: 800,
    fontSize: '16px',
    width: 'auto',
    padding: '3px 32px 3px 32px',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: colors.buttonHoverBlue,
      color: colors.white,
      borderColor: colors.buttonHoverBlue,
    },
    '&:disabled': {
      backgroundColor: colors.buttonDisabled,
      color: colors.buttonDisabledText,
      borderColor: colors.buttonDisabled,
    },
  },
  secondaryButton: {
    backgroundColor: colors.white,
    color: colors.blue,
    borderColor: colors.buttonBorderBlue,
    borderRadius: 4,
    borderWidth: 2,
    borderStyle: 'solid',
    fontWeight: 800,
    fontSize: '16px',
    width: 'auto',
    padding: '3px 32px 3px 32px',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: colors.buttonHoverBlue,
      color: colors.white,
      borderColor: colors.buttonHoverBlue,
    },
    '&:disabled': {
      backgroundColor: colors.buttonDisabled,
      color: colors.buttonDisabledText,
      borderColor: colors.buttonDisabled,
    },
  },
  rebrandedPrimaryButton: {
    height: 48,
    backgroundColor: colors.blue_05,
    color: colors.white,
    borderColor: colors.blue_05,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    fontFamily: fonts.paragraph2,
    fontWeight: 700,
    fontSize: '16px',
    width: 'auto',
    letterSpacing: 1.25,
    textTransform: 'capitalize',
    padding: '16px 72px',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: colors.buttonHoverBlue,
      color: colors.white,
      borderColor: colors.buttonHoverBlue,
    },
    '&:disabled': {
      backgroundColor: colors.gray_03,
      color: colors.white,
      borderColor: colors.white,
          textTransform: 'capitalize',

    },
    [theme.breakpoints.down('xs')]: {
      padding: '16px 30px',
    },
  },
  rebrandedSecondaryButton: {
    height: 48,
    backgroundColor: colors.green_05,
    color: colors.white,
    borderColor: colors.green_05,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    fontFamily: fonts.paragraph2,
    fontWeight: 700,
    fontSize: '16px',
    width: 'auto',
    letterSpacing: 1.25,
    textTransform: 'uppercase',
    padding: '16px 72px',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: colors.hoverGreen,
      color: colors.white,
      borderColor: colors.hoverGreen,
    },
    '&:disabled': {
      backgroundColor: colors.green_00,
      color: colors.white,
      borderColor: colors.green_01,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '16px 30px',
    },
  },
  rebrandedSuccessButton: {
    height: 48,
    backgroundColor: colors.green_01,
    color: colors.green_05,
    borderColor: colors.green_01,
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    fontFamily: fonts.paragraph2,
    fontWeight: 700,
    fontSize: '16px',
    width: 'auto',
    letterSpacing: 1.25,
    textTransform: 'uppercase',
    padding: '16px 72px',
    alignSelf: 'center',
    '&:disabled': {
      backgroundColor: colors.green_01,
      color: colors.green_05,
      borderColor: colors.green_01,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '16px 30px',
    },
  },
  h5: {
    fontFamily: fonts.heading2,
    color: colors.gray_05,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
  },
  h6: {
    fontWeight: 600,
    fontFamily: fonts.heading3,
    color: colors.gray_05,
    fontSize: 20,
    lineHeight: '24px',
  },
  h7: {
    fontFamily: fonts.paragraph2,
    color: colors.gray_05,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
  },
  body: {
    fontFamily: fonts.paragraph2,
    color: colors.gray_05,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
  },
  caption: {
    fontFamily: fonts.raparagraph2leway,
    color: colors.blue_05,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
  },
  link: {
    fontFamily: fonts.paragraph1,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    textDecorationLine: 'underline',
  },
}));

export default sharedStyles;
