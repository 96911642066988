/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Grid,
  ButtonBase,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { colors, fontFamily } from 'common/assets/sharedUI';
import PaperContainer from './PaperContainer';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import { videoNextStepClicked } from '../store/actions/registration';

const styles = {
  container: {
    textAlign: 'center',
    fontFamily,
  },
  button: {
    backgroundColor: colors.primaryBlue,
    flex: 1,
    margin: '15px 5px 0px 5px',
    color: 'white',
    borderRadius: 5,
    fontSize: 18,
    padding: 10,
    fontWeight: 700,
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  videoWrapper: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingBottom: '56.25%',
    paddingTop: 25,
  },
  titleText: {
    fontSize: 22,
    color: colors.primaryBlue,
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
  }
};

const JumpGoVideo = ({ classes }) => {
  const dispatch = useDispatch();

  const video = "https://www.youtube-nocookie.com/embed/EuN1GVobJro";
  const handleVideoClick = () => {
    registerGAClickEventEnhanced(EventCategories.Onboarding, EventActions.Click, EventLabels.IntroVideo);
  };

  const handleClick = () => {
    dispatch(videoNextStepClicked());
  };

  return (
    <PaperContainer style={{ marginTop: '-65px', paddingTop: 20 }} maxWidth={925}>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.cardWrapper}>
          <Typography className={classes.titleText}>
            Welcome to GenoPalate
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.videoWrapper}>
          <iframe
            className={classes.iframe}
            src={video}
            title="Introduction to GenoJump & GenoGo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            onClick={() => handleVideoClick()}
          />
        </Grid>
        <Grid item xs={12} className={classes.cardWrapper}>
          <ButtonBase className={classes.button} onClick={() => handleClick()}>
            Next
          </ButtonBase>
        </Grid>
      </Grid>
    </PaperContainer>
  );
};

JumpGoVideo.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    button: PropTypes.string,
    cardWrapper: PropTypes.string,
    videoWrapper: PropTypes.string,
    iframe: PropTypes.string,
    titleText: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(JumpGoVideo);
