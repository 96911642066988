import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import types from '../actionTypes';
import { BARCODE_DIGIT_LENGTH } from '../constants';

const defaultState = {
  isCreatingAccount: false,
  accountCreationError: '',
  isFetchingOrderDetails: false,
  orderDetails: null,
  fetchingOrderDetailsError: '',
  isAssigningBarcode: false,
  barcodeError: undefined,
  barcodeErrorCode: undefined,
  barcode: '',
  isBarcodeValid: false,
  registrationNextStep: false,
  videoNextStep: false,
  registerBarcodeClicked: false,
};

/**
 * @param {string} barcode
 */
const isBarcodeValid = (barcode) => {
  const digitArray = Array.from(barcode);
  return digitArray.length === BARCODE_DIGIT_LENGTH
    && digitArray.every(digit => {
      const asInt = parseInt(digit, 10);
      return isNumber(asInt) && !isNaN(asInt);
    });
};

const registrationReducer = (state = defaultState, { type, ...action }) => {
  switch (type) {
    case types.CREATE_ACCOUNT_STARTED:
      return {
        ...state,
        isCreatingAccount: true,
        accountCreationError: '',
      };
    case types.CREATE_ACCOUNT_ERROR:
      return {
        ...state,
        isCreatingAccount: false,
        accountCreationError: action.error,
      };
    case types.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isCreatingAccount: false,
        accountCreationError: '',
      };
    case types.GET_REGISTRATION_DETAILS_STARTED:
      return {
        ...state,
        isFetchingOrderDetails: true,
        fetchingOrderDetailsError: '',
        orderDetails: null,
      };
    case types.GET_REGISTRATION_DETAILS_ERROR:
      return {
        ...state,
        isFetchingOrderDetails: false,
        fetchingOrderDetailsError: action.error,
      };
    case types.GET_REGISTRATION_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingOrderDetails: false,
        orderDetails: action.details,
      };
    case types.BARCODE_ASSIGN_STARTED:
      return {
        ...state,
        isAssigningBarcode: true,
        barcodeError: undefined,
        barcodeErrorCode: undefined,
      };
    case types.BARCODE_ASSIGN_ERROR:
      return {
        ...state,
        isAssigningBarcode: false,
        barcodeError: action.barcodeError,
        barcodeErrorCode: action.barcodeErrorCode,
      };
    case types.BARCODE_UPDATE_BARCODE:
      return {
        ...state,
        barcode: action.barcode,
        isBarcodeValid: isBarcodeValid(action.barcode),
      };
    case types.REGISTRATION_NEXT_STEP:
      return {
        ...state,
        registrationNextStep: true,
      };
    case types.VIDEO_NEXT_STEP:
      return {
        ...state,
        videoNextStep: true,
      };
    case types.REGISTRATION_BARCODE_CLICKED:
      return {
        ...state,
        registerBarcodeClicked: true,
      };
    default:
      return state;
  }
};

export default registrationReducer;
