import actionTypes from '../actionTypes';
import { domain } from '../../services';
import { panelNames } from '../../resources/constants';

export const getMacros = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_MACROS_STARTED });
  try {
    const data = await domain.getMacros();
    dispatch({ type: actionTypes.GET_MACROS_SUCCESS, data });
  } catch (error) {
    dispatch({ type: actionTypes.GET_MACROS_ERROR, error });
  }
};

export const getEssentialNutrition = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ESSENTIAL_NUTRITION_STARTED });
  try {
    const domains = await domain.getTraits(panelNames.ESSENTIAL_NUTRITION);
    dispatch({ type: actionTypes.GET_TRAITS_SUCCESS, data: domains });
    const geneticInsights = await domain.getGeneticInsights(domains.reduce((prev, curr) => [...prev, ...curr.traits.map(t => t.id)], []));
    const data = domains.map(d => ({
      ...d,
      traits: d.traits.map(t => geneticInsights.find(gi => gi.traitId === t.id)),
    }));
    dispatch({ type: actionTypes.GET_ESSENTIAL_NUTRITION_SUCCESS, data, geneticInsights });
  } catch (error) {
    dispatch({ type: actionTypes.GET_ESSENTIAL_NUTRITION_ERROR, error });
  }
};

export const getEssentialNutritionDetails = (traitIds) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ESSENTIAL_NUTRITION_DETAILS_STARTED });
  try {
    const traitDetails = await domain.getEssentialNutritionDetails(traitIds);
    dispatch({ type: actionTypes.GET_ESSENTIAL_NUTRITION_DETAILS_SUCCESS, data: traitDetails });
  } catch (error) {
    dispatch({ type: actionTypes.GET_ESSENTIAL_NUTRITION_ERROR, error });
  }
};

export const getTraitRecommendations = (geneticInsightIds) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_TRAIT_RECOMMENDATION_STARTED });
  try {
    const recommendations = await domain.getTraitRecommendation(geneticInsightIds);
    if (recommendations && !!recommendations.data && !!recommendations.data.flattenedRec) {
      dispatch({ type: actionTypes.GET_TRAIT_RECOMMENDATION_SUCCESS, data: recommendations.data.flattenedRec[0] });
    }
  } catch (error) {
    dispatch({ type: actionTypes.GET_TRAIT_RECOMMENDATION_ERROR, error });
  }
};
