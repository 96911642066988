import actionTypes from '../actionTypes'

export const enableFeature = (featureName) => ({
  type: actionTypes.ENABLE_FEATURE,
  payload: featureName,
})

export const disableFeature = (featureName) => ({
  type: actionTypes.DISABLE_FEATURE,
  payload: featureName,
})
