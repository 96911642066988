import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles(() => createStyles({
  topFoodsCard: {
    margin: 10,
    height: 225,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 200,
  },
  topFoodsCardHeader: {
    backgroundColor: colors.foodDetailsGrey,
    color: colors.white,
    height: 38,
  },
  topFoodsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  topFoodsImg: {
    height: 130,
  },
}));

export default useStyles;
