import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useRouteMatch, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import {
  Button, Grid,
} from '@material-ui/core';
import { getAccountContracts } from 'store/actions/consent';
import { sharedStyles } from 'resources';
import { BioBankingResearchConsent } from '../components/componentsV2';
import useStyles from './ConsentPage.styles';

const { IS_PROD, IS_STAGING } = window.env;

const ConsentPage = () => {
  const sharedClasses = sharedStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  // User contracts
  const contracts = useSelector(state => state.consent.accountContracts);
  const isBioBank = (contracts ? contracts.length > 1 : false);
  const bioBankOptedOut = (contracts && isBioBank ? !contracts.find(x => x.name === 'BioBanking') : false);

  useEffect(() => {
    if (IS_PROD || IS_STAGING) {
      ReactGA.send({ hitType: "pageview", page: match.url });
    }
    dispatch(getAccountContracts());
  }, [match, dispatch]);

  const bioBankingResearchConsentProps = {
    isBioBank, contracts, bioBankOptedOut, edit: true,
  };

  return (
    contracts ? (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} md={10} className={classes.linkContainer}>
            <Link to="/account">
              <Button className={clsx(sharedClasses.secondaryButton, classes.backButton)} variant="outlined">Back to My Account</Button>
            </Link>
          </Grid>
          <Grid item xs={12} className={classes.consentContainer}>
            <BioBankingResearchConsent {...bioBankingResearchConsentProps} />
          </Grid>
        </Grid>
      </React.Fragment>
    ) : <div />
  );
};

export default ConsentPage;
