import register from './register';
import forgotPassword from './forgotPassword';
import changePassword from './changePassword';
import accountShell from './accountShell';

export { default as login } from './login';
export { default as logout } from './logout';
export { default as registerTMKit } from './registerTMKit';

export {
  register,
  forgotPassword,
  changePassword,
  accountShell,
};
