import {  createStyles, makeStyles } from "@material-ui/core";
import { colors, fonts } from 'common/assets/sharedUI'

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      marginBottom: '30px',
      backgroundColor: colors.gray_01,
      borderRadius: '10px',
      maxWidth: '409px',
    },
    radioButtonsWrapper: {
      paddingLeft: '16px',
      paddingTop: '10px',
      paddingBottom: '7px',
    },
    installmentButtonText: {
      fontFamily: fonts.paragraph2,
      fontSize: '14px',
      fontWeight: 500,
      color: colors.gray_06,
      width: '280px',
      maxWidth: '380px',
    },
  })
)
