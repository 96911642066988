import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getEssentialNutrition } from 'store/actions/domain'

const macro_nutrient_names = ['Carbohydrates', 'Fats', 'Protein']
const sensitivity_or_substance = ['sensitivities', 'substances']

/**
 * 
 * @param {string} domainName 
 * @returns boolean 
 */
export const isSensitivityOrSubstance = (domainName = '') =>
  sensitivity_or_substance.includes(domainName.toLowerCase())

const useNutrients = () => {
  const dispatch = useDispatch()

  const { data: allTraits } = useSelector((state) => state.trait)

  const {
    fetching,
    data = [],
    geneticInsights = [],
    error,
  } = useSelector((state) => state.domain.essentialNutrition)

  useEffect(() => {
    if (!fetching && !error && (!data || !allTraits)) {
      dispatch(getEssentialNutrition())
    }
  }, [fetching, dispatch, data, error, allTraits])

  const sensitivitiesOrSubstances = geneticInsights.filter(
    ({ domainName, geneticResultNotRead }) =>
      isSensitivityOrSubstance(domainName) && !geneticResultNotRead
  )

  const macroNutrients = geneticInsights.filter(
    ({ isTraitMacroNutrient, domainName, geneticResultNotRead }) =>
      isTraitMacroNutrient &&
      !isSensitivityOrSubstance(domainName) &&
      !geneticResultNotRead
  )

  const microNutrients = geneticInsights.filter(
    ({ isTraitMacroNutrient, domainName, geneticResultNotRead }) =>
      !isTraitMacroNutrient &&
      !isSensitivityOrSubstance(domainName) &&
      !geneticResultNotRead
  )
  const numberOfNutrients = macroNutrients.length + microNutrients.length;

  return {
    geneticInsightsFetching: fetching,
    geneticInsights: data,
    insights: geneticInsights,
    geneticInsightsError: error,
    macroNutrients,
    microNutrients,
    sensitivitiesOrSubstances,
    numberOfNutrients,
  }
}

export default useNutrients
