import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Card, CardContent, CardActionArea, Typography,
} from '@material-ui/core';
import { cleanRecommendation } from 'utils/dataTools';
import useStyles from './RecommendationBlock.styles';

export default function RecommendationBlock({
  title,
  subtitle,
  onClick,
  sensitivitiesOrSubstances,
  intake,
  generalIntake,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.card} id="nutrientDailyRecommendation">
      <div className={classes.titleContainer}>
        <Typography variant="subtitle1" name="title" className={classes.title}>{title}</Typography>

      </div>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12} className={classes.leftContainer}>

            { !sensitivitiesOrSubstances && (
              <Typography className={classes.recommendation}>Your recommended daily intake is</Typography>
            )}
            <Typography className={classes.intakeText}>{intake}</Typography>
            <Typography className={classes.recommendation} name="subtitle">{subtitle}</Typography>
          </Grid>
          { !title && !sensitivitiesOrSubstances && (
            <Grid item xs={12} className={classes.rightContainer}>
              <Typography className={classes.recommendation} align="center">
                The recommendation for the general population is&nbsp;
                {cleanRecommendation(generalIntake)}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
      { title && (
        <CardActionArea className={classes.buttonContainer} onClick={onClick}>
          <Typography variant="button" name="buttonText" className={classes.buttonText}>Learn More &gt;</Typography>
        </CardActionArea>
      )}
    </Card>
  );
}

RecommendationBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  intake: PropTypes.string.isRequired,
  generalIntake: PropTypes.string,
  sensitivitiesOrSubstances: PropTypes.bool.isRequired,
};

RecommendationBlock.defaultProps = {
  title: '',
  generalIntake: '',
  onClick: {},
};
