import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import CustomTextField from 'components/CustomTextField'
import PropTypes from 'prop-types'
import useStyles from './FreeResponseForm.styles'

export default function FreeResponseForm(props) {
  const {
    id,
    maxAnswerCount,
    values,
    errors,
    handleChange,
    handleBlur,
    questionText,
  } = props

  const freeResponseArray = []
  const classes = useStyles()

  const onChangeField = (event) => {
    event.target.value = event.target.value.replace(/(\r\n|\n|\r)/gm, '') // Remove newline character
    handleChange(event)
  }

  for (let form = 0; form < maxAnswerCount; form += 1) {
    freeResponseArray.push(
      <CustomTextField
        key={form}
        name={`textResponse_${id}_${form}`}
        value={values[`textResponse_${id}_${form}`]}
        onChange={onChangeField}
        onBlur={handleBlur}
        error={!!errors[`textResponse_${id}_${form}`]}
        helperText={errors[`textResponse_${id}_${form}`] && errors[`textResponse_${id}_${form}`]}
        margin="normal"
        multiline
        rowsMax={1}
        rows={1}
        variant="outlined"
        inputProps={{ maxLength: 255 }}
      />,
    )
  }

  return (
    <Grid container item xs={12} className={classes.freeResponseContainer}>
      { questionText ? (
        <div className={classes.labelContainer}>
          <Typography variant="body1" className={classes.centerSpan}>{questionText}</Typography>
        </div>
      ) : null}
      {freeResponseArray}
    </Grid>
  )
}

FreeResponseForm.propTypes = {
  id: PropTypes.number.isRequired,
  maxAnswerCount: PropTypes.number,
  values: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  questionText: PropTypes.string,
}

FreeResponseForm.defaultProps = {
  maxAnswerCount: 1,
  values: {},
  errors: {},
  questionText: '',
}
