import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles({
  container: {
    marginTop: -65,
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    flexFlow: 'row',
    textAlign: 'center',
    alignItems: 'baseline',
    '& h5': {
      color: colors.blue,
    }
  },
  popoverPaper: {
    maxWidth: 350,
    padding: 20,
  },
  icon: {
    fontSize: '1.15rem',
    color: colors.blue,
  },
  subtext: {
    marginBottom: 15,
    fontSize: '0.85rem',
    color: colors.gray_04,
  },
  image: {
    width: 100,
  },
  subtextContainer: {
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
})

export default useStyles
