import React, { useEffect, useState } from 'react';
import { recipe as RecipeService } from '../services';

const useRecipes = () => {
  const [recipes, setRecipes] = useState([]);
  const [userHasPurchasedRecipes, setUserHasPurchasedRecipes] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setFetching(true);
    setError(undefined);

    (async () => {
      try {
        const recipes = await RecipeService.listRecipes();
        setRecipes(recipes);
        setUserHasPurchasedRecipes(true);
      } catch (error) {
        if (error.response.status === 404) {
          setUserHasPurchasedRecipes(false);
        }
        setError(error);
      } finally {
        setFetching(false);
      }
    })();
  }, []);

  return {
    fetching,
    userHasPurchasedRecipes,
    recipes,
    error
  };
};

export default useRecipes;
