import { withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { colors } from 'common/assets/sharedUI'

const CustomTextField = withStyles({
  root: {
    '& .Mui-error': {
      color: colors.red
    },
    '& label.Mui-focused': {
      color: colors.blue,
    },
    '& label.Mui-error': {
      color: colors.red,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colors.blue,
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '14px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: colors.blue,
      },
      '&.Mui-error fieldset': {
        borderColor: colors.red,
      },
    },
  },
})(TextField)

export default CustomTextField
