const initialState = {
  fetching: false,
  data: null,
  error: null,
};

export default (beginType, successType, errorType) => (state = initialState, action) => {
  switch (action.type) {
    case beginType:
      return {
        fetching: true,
        data: null,
        error: null,
      };
    case successType:
      return {
        fetching: false,
        data: action.data,
        error: null,
      };
    case errorType:
      return {
        fetching: false,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
};
