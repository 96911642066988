import { apiStatuses } from "../../resources/constants"

/**
 * this can be reused or wrapped in a function that
 * returns a reducer with this signature if we pass it
 * the 3 conditions...
 */
export default actionTypes => (state = apiStatuses.IDLE, action) => {
  switch (action.type) {
    case actionTypes.pending:
      return apiStatuses.PENDING
    case actionTypes.resolved:
      return apiStatuses.RESOLVED
    case actionTypes.rejected:
      return apiStatuses.REJECTED
    default:
      return state
  }
}
