import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors, fonts } from 'common/assets/sharedUI';
import { images } from 'resources';


const useStyles = makeStyles((theme) =>
  createStyles({
    banner: {
      backgroundColor: colors.blue_03,
      display: 'flex',
      width: '100vw',
      minHeight: 333,
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        minHeight: 'auto',
        padding: '30px 20px',
        marginBottom: 0,
      },
    },
    headerHeading: {
      fontFamily: fonts.Baskerville,
      fontSize: '45px',
      lineHeight: '56px',
      textTransform: 'capitalize',
      color: colors.white,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        lineHeight: '40px',
      },
    },
    headerSubheading: {
      fontFamily: fonts.paragraph1,
      fontSize: '25px',
      lineHeight: '30px',
      color: colors.white,
      marginBottom: 35,
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    headerLink: {
      fontFamily: fonts.paragraph1,
      fontSize: '25px',
      lineHeight: '30px',
      color: colors.white,
      fontWeight: 'bold',
      marginTop: 35,
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    icon: {
      fontSize: '10em',
      '--fa-primary-color': colors.blue_05,
      '--fa-secondary-color': colors.white,
      '--fa-secondary-opacity': 0.72,
      [theme.breakpoints.down('md')]: {
        fontSize: '8em',
      },
    },
    divider: { backgroundColor: colors.gray_07 },
    bannerWithBackground: {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        width: '50%',
        backgroundImage: `url(${images.LandingPageHeader})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.4,
      },
    },
  })
);

export default useStyles;
