import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors, fonts } from 'common/assets/sharedUI';

/* remove unused classNames */
const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  
  wrapper: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    margin: 'auto', // this center the card
    borderRadius: theme.shape.borderRadius * 2, // 4 * 2 = 8
    boxShadow: '0px 3px 6px #00000029',

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  title: {
    fontFamily: fonts.Baskerville,
    fontSize: '48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
      textAlign: 'center'
    },
    color: colors.darkestDarkGreen,
    lineHeight: '48px',
  },

  inline: {
    display: 'inline',
  },
  text: {
    fontSize: 16,
    fontFamily: fonts.Swiss721LT,
    color: colors.gray_06,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '26px',
    },
    fontWeight: 600,
    letterSpacing: 0.5,
    lineHeight: '28px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },

  blueText: {
    color: colors.blue_03,
  },

  pageBreak: {
    pageBreakBefore: 'always',
  },
  printOnly: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  },
  imageContainer: {
    width: '50vw',
    paddingLeft: '40px',
    paddingTop: '40px',
    paddingBottom: '40px'

  },
  contentContainer: {
    width: '50vw',
    paddingRight: '40px',
    paddingTop: '40px',
    paddingBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '20px'
    },
  },
  image: {
    width: '90%',
    maxHeight: '90%',
    objectFit: 'cover',
    height: '-webkit-fill-available'
  },
  starIcon: {
    fill: '#FFD700'
  },
  checkIconContainer: {
  },
  checkText: {
     [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    },
  },
  checkIcon: {
    color: colors.darkestDarkGreen,
    width: '20px',
    height: '20px',
    alignSelf: 'start',
    marginTop: '5px',
    marginLeft: '20px',
  },
  bullet: {
    marginTop: '15px'
  },
  mt20: {
    marginTop: '20px'
  },
  pl10: {
    paddingLeft: '10px'
  },
  scheduleButton: {
    marginTop: 30,
    height: 55,
    backgroundColor: colors.darkestDarkGreen,
    color: colors.white,
    borderColor: colors.darkestDarkGreen,
    borderStyle: 'solid',
    borderRadius: 4,
    borderWidth: 1,
    fontFamily: fonts.paragraph2,
    fontWeight: 700,
    fontSize: '16px',
    width: 'auto',
    letterSpacing: 1.25,
    padding: '16px 30px',
    alignSelf: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: colors.green_08,
      color: colors.white,
      borderColor: colors.green_08,
    },
    
    [theme.breakpoints.down('xs')]: {
      padding: '16px 30px',
      width: '100vw'
    },
  },
  laterButton: {
    marginTop: 30,
    marginLeft: 20,
    height: 55,
    backgroundColor: colors.green_11,
    color: colors.black,
    borderColor: colors.green_11,
    borderStyle: 'solid',
    borderRadius: 4,
    borderWidth: 1,
    fontFamily: fonts.paragraph2,
    fontWeight: 700,
    fontSize: '16px',
    width: 'auto',
    letterSpacing: 1.25,
    padding: '16px 30px',
    alignSelf: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: colors.darkerGrey,
      color: colors.white,
      borderColor: colors.darkerGrey,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '16px 30px',
      width: '100vw',
      marginLeft: 0
    },
  },
  disclaimer: {
    fontSize: '12px',
    fontStyle: 'italic',
    color: colors.separatorColor,
    marginTop: '30px',
    font: fonts.Baskerville,
  },
  statistics: {
    backgroundColor: colors.white,
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginTop: '25px',
  },
  textCenter: {
    textAlign: 'center'
  },
  container: {
    backgroundColor: colors.gray_01,
    paddingLeft: '14vw',
    paddingRight: '14vw',
    paddingTop: '40px',
    paddingBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '20px',
      paddingBottom: '20px'
    },
  },
  starContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'ruby-text',
    },
    alignSelf: 'center'
  },
  testimonialsTitle: {
    fontFamily: fonts.Swiss721MD,
    fontSize: '33px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem',
      textAlign: 'center'
    },
    color: colors.darkestGrey,
    lineHeight: '33px',
    textAlign: 'center',
    alignSelf: 'center'
  },
  starIconSmall: {
    fill: '#FFD700',
    width: '20px',
    height: 'auto'

  },
  testimonialText: {
    fontSize: '16px',
    fontStyle: 'italic',
    color: colors.separatorColor,
    marginTop: '30px',
    font: fonts.Baskerville,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '400px'
  },
  testimonialAuthor: {
    fontSize: '14px',
    color: colors.darkestGrey,
    marginTop: '20px',
    font: fonts.Baskerville,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '400px',
     [theme.breakpoints.down('xs')]: {
      marginBottom: '30px'
    },
  },
  testimonialSection: {
    backgroundColor: 'white',
    padding: 20,
    paddingTop: 100,
    paddingBottom: 100
  }

}));

export default useStyles;
