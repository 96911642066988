import { combineReducers } from 'redux'
import { get } from 'lodash'
import basicReducer from './basicReducer';
import types from '../actionTypes'

function selectedFoodIdReducer(state = null, action) {
  switch (action.type) {
    case types.SELECT_FOOD_DETAIL:
      return get(action, 'payload', null)
    default:
      return state
  }
}

function expandedCategoryReducer(state = 8, action) {
  switch (action.type) {
    case types.SELECT_EXPANDED_CATEGORY:
      return get(action, 'payload', 8) // "8" is the initial expanded food group id
    default:
      return state
  }
}

export default combineReducers({
  selectedFoodId: selectedFoodIdReducer,
  expandedCategory: expandedCategoryReducer,
  details: basicReducer(types.GET_FOOD_DETAILS_STARTED, types.GET_FOOD_DETAILS_SUCCESS, types.GET_FOOD_DETAILS_ERROR),
})
