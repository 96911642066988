import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { get, upperCase } from "lodash";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Divider } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint } from "@fortawesome/pro-solid-svg-icons";
import { colors, fonts } from "common/assets/sharedUI";
import { sharedStyles } from "resources";
import Header from "./Header";
import NeutritionCard from "components/Neutritions/NeutritionCard";
import Seprator from "components/shared/Seprator";
import MarketingSection from "components/MarketingSection/MarketingSection";
import { marketingdata, marketingDataTitle, marketingDataDesc } from 'resources/constants'
import { helpers } from "utils";
import { useUserBlendType } from '../../hooks'
import NutritionFacts  from './NutritionFacts'

const styles = (theme) => ({
  horizontalRule: {
    marginTop: 24,
    marginBottom: 24,
    borderTop: `1px solid ${colors.gray_03}`,
  },
  formulaWrapper: {
    width: "100%",
    padding: 32,
    borderRadius: 16,
    border: `1px solid ${colors.gray_03}`,
    backgroundColor: colors.gray_01,
    marginBottom: 80,
  },
  columnHeader: {
    textTransform: "uppercase",
    fontWeight: 800,
    fontFamily: fonts.raleway,
    color: colors.gray_06,
    fontSize: 12,
    lineHeight: "16px",
  },
  leftCol: {
    width: "100%",
  },
  rightCol: {
    width: 178,
    textAlign: "right",
    marginRight: 64,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 12,
  },
  ingredient: {
    fontFamily: fonts.raleway,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "24px",
  },
  subHeader: {
    fontFamily: fonts.raleway,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "16px",
  },
  icon: {
    width: 22,
    height: 21,
    fill: 'red',
  },

  footerText: {
    fontSize: 25,
    color: colors.gray_06,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
})

const CustomFormula = ({ boosterFormulas, classes, isExampleFormula }) => {
  const { servingSize, servingsPerContainer, servingSizeScoop } =
    useUserBlendType()

  const { userProfile } = useSelector((state) => state.profile)
  const { data: formulation } = useSelector(
    (state) => state.subscription.formulation
  );
  const sharedClasses = sharedStyles();

  const name = useMemo(() => get(userProfile, "data.name", ""), [userProfile]);

  const combinedFormulation = useMemo(() => {
    const baseFormulation = formulation !== null ? formulation : [];
    return [...baseFormulation, ...boosterFormulas];
  }, [formulation, boosterFormulas]);

  const sortedFormulation = useMemo(
    () =>
      combinedFormulation
        ? [...combinedFormulation].sort((a, b) => {
          if (Object.prototype.hasOwnProperty.call(a, "provenance")) {
            return Object.prototype.hasOwnProperty.call(b, "provenance")
              ? a.nutrient.localeCompare(b.nutrient)
              : -1;
          }
          return Object.prototype.hasOwnProperty.call(b, "provenance")
            ? 1
            : a.nutrient.localeCompare(b.nutrient);
        })
        : [],
    [combinedFormulation]
  );

  const formulaDescriptor = isExampleFormula ? "Example" : "Custom";

  const customData = [
    {
      title: "Total Fat 1.8 g",
      children: [
        {
          title: "Saturated Fat 0.5 g",
        },
        { title: "Trans Fat 0 g" },
      ],
    },
    {
      title: "Total Carbohydrates 8 g",
      children: [
        {
          title: "Dietary Fiber 7.2 g",
          children: [
            {
              title: "Soluble Fiber 1.4 g",
            },
          ],
        },
      ],
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header name={name} formulaDescriptor={formulaDescriptor} />
      </Grid>
      
      <Grid item xs={12}>
        <hr className={classes.horizontalRule} />
      </Grid>

      <Grid container direction="row" spacing={5} justify="space-between">
        <Grid item md={5}>
          <NeutritionCard>
            <NeutritionCard.Header>
              <Typography
                variant="subtitle1"
                fontSize="20px"
                gutterBottom
                style={{ color: colors.gray_06 }}
              >
                {`${helpers.capitalizePossessive(upperCase(name))} GENOBLEND`}
              </Typography>
              <Typography
                variant="h4"
                component="h4"
                fontSize="40px"
                gutterBottom
                style={{ fontFamily: fonts.Baskerville, lineHeight: '47px', fontWeight: '900', color: colors.black }}
              >
                Supplement Facts
              </Typography>
            </NeutritionCard.Header>

            <Seprator color="#707070" />

            <NeutritionCard.Legend
              icon={
                <FontAwesomeIcon
                  icon={faFingerprint}
                  size="xl"
                  color={colors.blue_03}
                />
              }
              text="You have genetic variants impacting your needs"
            />

            <NeutritionCard.Container>
              <NeutritionCard.Serving
                servingSize={servingSize}
                servingsPerContainer={servingsPerContainer}
                servingSizeScoop={servingSizeScoop}
              />
              <NutritionFacts />
              <Seprator thickness={18} color='#707070' />
            </NeutritionCard.Container>

            <NeutritionCard.Footer>
              <Typography
                style={{
                  color: colors.gray_06,
                }}
              >
                &lowast; Percent Daily Values are based on a 2,000 calorie diet
              </Typography>
              <Typography
                style={{
                  color: colors.gray_06,
                }}
              >
                &dagger; Daily Value not established
              </Typography>

              <div style={{ marginTop: '30px' }}>
                <Typography style={{
                  color: colors.gray_06,
                }}>
                  Ingredients: Organic Protein Blend (Organic Brown Rice
                  Protein, Organic Pea Protein) Fiber Blend (Organic Citrus
                  Fiber, Oat Fiber), Inulin (as bulking agent)
                </Typography>
              </div>
            </NeutritionCard.Footer>
          </NeutritionCard>
        </Grid>
        <Grid item md={6}>
          <MarketingSection
            data={marketingdata}
            title={marketingDataTitle}
            description={marketingDataDesc} />
        </Grid>
      </Grid>
    </Grid>
  );
};

CustomFormula.propTypes = {
  boosterFormulas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      amount: PropTypes.number,
      uom: PropTypes.string,
      nutrient: PropTypes.string,
    })
  ).isRequired,
  classes: PropTypes.shape({
    horizontalRule: PropTypes.string,
    formulaWrapper: PropTypes.string,
    columnHeader: PropTypes.string,
    leftCol: PropTypes.string,
    rightCol: PropTypes.string,
    rowWrapper: PropTypes.string,
  }).isRequired,
  isExampleFormula: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CustomFormula);
