import { useMemo, useState } from 'react';
import { supplementProductSkus } from 'resources/constants';
import { subscriptionHelpers } from 'utils';

const useGetSupplementBaseProduct = (subscriptionProducts = [], supplyPlanID) => {
  const [updateBaseProductId, setUpdateBaseProductId] = useState(null);

  const isMatchingProduct = (product, sku) => product.sku === sku;
  const isThreeMonthSubscription = supplyPlanID === subscriptionHelpers.SUBSCRIPTION_DURATIONS.THREE;
  const isSixMonthSubscription = supplyPlanID === subscriptionHelpers.SUBSCRIPTION_DURATIONS.SIX;
  const isOneTimeProduct = supplyPlanID === subscriptionHelpers.SUBSCRIPTION_DURATIONS.ONE_TIME;
  const isInstallmentProduct = supplyPlanID === subscriptionHelpers.SUBSCRIPTION_DURATIONS.MONTHLY;

  const findBaseProduct = (product) => {
    if (updateBaseProductId === product.id) {
      return product;
    }

    if (!updateBaseProductId && isOneTimeProduct && isMatchingProduct(product, supplementProductSkus['VS0000-1T'])) {
      return product;
    }

    if (!updateBaseProductId && isThreeMonthSubscription && isMatchingProduct(product, supplementProductSkus.VS0000)) {
      return product;
    }

    if (!updateBaseProductId && isSixMonthSubscription && isMatchingProduct(product, supplementProductSkus['VS0000-6MO'])) {
      return product;
    }

    if (!updateBaseProductId && isInstallmentProduct && isMatchingProduct(product, supplementProductSkus['VS0000-1MO'])) {
      return product;
    }

    return undefined;
  };

  const baseProduct = useMemo(() => subscriptionProducts?.find(findBaseProduct), [
    subscriptionProducts,
    updateBaseProductId,
    isThreeMonthSubscription,
    isSixMonthSubscription,
    isInstallmentProduct,
  ]);

  const handleUpdateBaseProduct = (productId) => {
    setUpdateBaseProductId((prevProductId) => (prevProductId === productId ? null : productId));
  };

  return {
    baseProduct: baseProduct || null,
    onUpdateBaseProduct: handleUpdateBaseProduct,
  };
};

export default useGetSupplementBaseProduct;
