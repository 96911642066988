import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Button, Grid, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'common/assets/sharedUI';
import { resetPassword } from '../store/actions/user';
import { passwordRequirements } from '../utils';
import { sharedStyles } from '../resources';
import RegistrationFormikInput from './RegistrationFormikInput';

// TODO: Consolidate these styles when we decide on a strategy
const useStyles = makeStyles(theme => ({
  checkIcon: {
    color: colors.primaryGreen,
    fontSize: '5rem',
    paddingBottom: 20,
  },
  successContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  successText: {
    paddingBottom: 20,
  },
  errorText: {
    textAlign: 'center',
    color: colors.red,
    maxWidth: 400,
  },
  form: {
    width: 400,
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  passwordReq: {
    margin: '0 20px',
  },
}));

const ForgotPasswordChangeForm = ({ forgotKey }) => {
  const sharedClasses = sharedStyles();
  const classes = useStyles();

  const { resettingPassword, passwordReset, resetPasswordError } = useSelector(state => ({
    resettingPassword: state.user.resettingPassword,
    passwordReset: state.user.passwordReset,
    resetPasswordError: state.user.resetPasswordError,
  }));

  const dispatch = useDispatch();

  const onSubmit = useCallback((values) => {
    if (values && values.password) {
      dispatch(resetPassword(forgotKey, values.password));
    }
  }, [forgotKey, dispatch]);

  const validationSchema = Yup.object().shape({
    password: passwordRequirements,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('You are required to confirm your password'),
  });

  return passwordReset
    ? (
      <div className={classes.successContainer}>
        <FontAwesomeIcon icon={faCheckCircle} className={classes.checkIcon} />
        <Typography variant="h6" className={classes.successText}>
          Changed successfully!
        </Typography>
        <Typography className={classes.successText}>
          You can log in with your new password!
        </Typography>
        <Link to="/">
          <Button className={sharedClasses.primaryButton}>Go to login</Button>
        </Link>
      </div>
    )
    : (
      <Formik initialValues={{ password: '', confirmPassword: '' }} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, submitCount }) => (
          <Form onSubmit={handleSubmit} style={{ width: 400 }}>
            <Grid item xs={12}>
              <RegistrationFormikInput name="password" label="Password" submitCount={submitCount} type="password" />
            </Grid>
            <Grid item xs={12}>
              <RegistrationFormikInput name="confirmPassword" label="Confirm Password" submitCount={submitCount} type="password" />
            </Grid>
            {resetPasswordError && <Grid item xs={12} className={classes.errorText} id="resetPasswordErrorMessage">{resetPasswordError}</Grid>}
            <Grid item xs={12}>
              Password requirements:
              <p className={classes.passwordReq}>At least 8 characters</p>
              <p className={classes.passwordReq}>At least one number</p>
              <p className={classes.passwordReq}>At least one special character (examples: #@!*)</p>
            </Grid>
            <Grid item xs={12}>
              <Button disabled={resettingPassword} className={sharedClasses.primaryButton} type="submit" style={{ margin: '20px auto 0 auto', display: 'block' }}>RESET PASSWORD</Button>
            </Grid>
          </Form>
        )}
      </Formik>
    );
};

ForgotPasswordChangeForm.propTypes = {
  forgotKey: PropTypes.string.isRequired,
};

export default ForgotPasswordChangeForm;
