import getSubscriptions from './getSubscriptions';
import getSubscriptionOrders from './getSubscriptionOrders';
import getShippingAddress from './getShippingAddress';
import updateShippingAddress from './updateShippingAddress';
import getFormulation from './getFormulation';
import updateBilling from './updateBilling';
import getPaymentMethod from './getPaymentMethod';
import cancelSubscription from './cancelSubscription';
import getSubscriptionQuestionnaire from './getSubscriptionQuestionnaire';
import saveSubscriptionQuestionnaire from './saveSubscriptionQuestionnaire';
import pauseSubscription from './pauseSubscription';
import updateSubscription from './updateSubscription';
import updateSubscriptionV2 from './updateSubscriptionV2';

export default {
  getShippingAddress,
  updateShippingAddress,
  getFormulation,
  getSubscriptions,
  getSubscriptionOrders,
  updateBilling,
  getPaymentMethod,
  cancelSubscription,
  getSubscriptionQuestionnaire,
  saveSubscriptionQuestionnaire,
  pauseSubscription,
  updateSubscription,
  updateSubscriptionV2
};
