import React, { useMemo } from 'react';
import { faHatChef } from '@fortawesome/pro-duotone-svg-icons';
import helpers from '../../../utils/helpers'

import {
  recipeHeader,
  recipeSubheading,
  recipeLink,
} from 'resources/constants';
import CustomHeader from '../CustomHeader';
import useStyles from './Recipe.styles'
import { useSelector } from 'react-redux';
import { get } from 'lodash';

function Recipe() {
  const classes = useStyles();
  const { userProfile } = useSelector((state) => state.profile);
  const name = useMemo(() => (get(userProfile, 'data.name', '')), [userProfile]);

  return (
    <CustomHeader
      header={`${helpers.possessive(name)} ${recipeHeader}`}
      subHeading={recipeSubheading}
      link={'https://www.genopalate.com/pages/how-your-personalized-recipes-were-created?_ab=0&key=1707416783111'}
      icon={faHatChef}
      linkText={recipeLink}
      iconStyles={classes.icon}
      bannerStyles={classes.banner}
    />
  );
}

export default Recipe;
