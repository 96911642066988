import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles({
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginTop: 20,
  },
  title: {
    color: colors.white,
    fontWeight: 800,
  },
})

export default useStyles
