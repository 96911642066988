import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  WelcomeMessage,
  Loading,
} from 'components/componentsV2';
import {
  faChevronLeft,
} from '@fortawesome/pro-solid-svg-icons';
import ReactGA from 'react-ga4';
import { useRouteMatch, Link } from 'react-router-dom';
import FoodGroupListItem from 'components/FoodGroupListItem';
import FoodToggleItem from 'components/FoodToggleItem';
import { getContentLegend } from 'utils/dataTools';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import { useWindowWidth } from '../utils/hooks';
import styles from './IndividualFoodSettingsPage.styles';

const { IS_PROD, IS_STAGING } = window.env;

const IndividualFoodSettingsPage = (props) => {
  const { classes } = props;
  const match = useRouteMatch();
  const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const userTopFoodsStatus = useSelector(state => state.topFoods.status);
  const userTopFoods = useSelector(state => state.topFoods.userTopFoods);
  const [selectedFoodGroupIds, setSelectedFoodGroupIds] = useState([8]);
  const width = useWindowWidth();

  useEffect(() => {
    if (IS_PROD || IS_STAGING) {
      ReactGA.send({ hitType: "pageview", page: match.url });
    }
  }, [match]);

  const getFirstSelectedFoodGroup = useCallback(() => userTopFoods.find(foodGroup => selectedFoodGroupIds.includes(foodGroup.id)), [userTopFoods, selectedFoodGroupIds]);

  const handleAccordionGaEvent = (action, name) => registerGAClickEventEnhanced(EventCategories.MyFoods, action, EventLabels.FoodGroup(name));

  const handleFoodGroupClick = useCallback(
    (foodGroupId, name) => {
      if (isXsScreen) {
        if (!selectedFoodGroupIds.includes(foodGroupId)) {
          handleAccordionGaEvent(EventActions.Open, name);
          setSelectedFoodGroupIds([...selectedFoodGroupIds, foodGroupId]);
        } else {
          handleAccordionGaEvent(EventActions.Close, name);
          setSelectedFoodGroupIds(selectedFoodGroupIds.filter(selectedFoodGroupId => foodGroupId !== selectedFoodGroupId));
        }
      } else {
        handleAccordionGaEvent(EventActions.Open, name);
        setSelectedFoodGroupIds([foodGroupId]);
      }
    },
    [selectedFoodGroupIds, isXsScreen],
  );

  // Display foods
  const generateFoods = useMemo(() => {
    if (userTopFoods) {
      const selectedFoodGroup = getFirstSelectedFoodGroup();
      if (selectedFoodGroup && selectedFoodGroup.foods) {
        return selectedFoodGroup.foods.map((food) => (
          <FoodToggleItem
            key={food.name}
            food={food}
            foodGroupId={selectedFoodGroup.id}
          />
        ));
      }
      return null;
    }
    return null;
  }, [userTopFoods, getFirstSelectedFoodGroup]);

  if (userTopFoodsStatus === 'resolving') return <Loading />;

  return (
    <Grid container>
      <Grid item xs={12} md={5}>
        <div className={classes.pageWrapper}>
          <Link to="/food/settings">
            <div className={classes.backLink}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <label className={classes.backLinkText}>Back to Settings</label>
            </div>
          </Link>
          <WelcomeMessage title="Individual Foods" />
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
        <p className={classes.pageDescription}>
          Turn off the toggle for any foods you don&apos;t want to see on your optimal foods list.
        </p>
      </Grid>
      <Grid container style={{ padding: !isXsScreen ? '0px 20px' : '0px' }}>
        <Grid item xs={12} sm={3} className={classes.foodCategoriesWrapper}>
          { userTopFoods && userTopFoods.map((foodGroup) => (
            <FoodGroupListItem
              handleClick={() => handleFoodGroupClick(foodGroup.id, foodGroup.name)}
              showToggle
              foodGroup={foodGroup}
              selectedFoodGroupIds={selectedFoodGroupIds}
              width={width}
              isXsScreen={isXsScreen}
              expanded={selectedFoodGroupIds.includes(foodGroup.id)}
            />
          ))}
        </Grid>
        {
          !isXsScreen && userTopFoods && (
            <Grid item sm={9}>
              <div className={classes.foodContainer}>
                {/* This empty div allows the foodListContainer
                to not take its parent's minHeight */}
                <div>
                  <div className={classes.foodListContainer}>
                    {generateFoods}
                  </div>
                </div>
                {/* The non-small-screen view of the web app only displays one food group at a time,
                so the array will only have one food group id */}
                <div className={classes.foodContentLegend}>{getContentLegend(selectedFoodGroupIds[0])}</div>
              </div>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(IndividualFoodSettingsPage);

IndividualFoodSettingsPage.propTypes = {
  classes: PropTypes.shape({
    pageDescription: PropTypes.string.isRequired,
    pageWrapper: PropTypes.string.isRequired,
    backLink: PropTypes.string.isRequired,
    backLinkText: PropTypes.string.isRequired,
    foodCategoriesWrapper: PropTypes.string.isRequired,
    foodContainer: PropTypes.string.isRequired,
    foodListContainer: PropTypes.string.isRequired,
    foodContentLegend: PropTypes.string.isRequired,
  }).isRequired,
};
