import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import clsx from 'clsx';
import { sharedStyles } from 'resources';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useRouteMatch } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCart } from 'store/actions/additionalProducts';
import useStyles from './ProductDetails.styles';
import { registerGAClickEventEnhanced, EventCategories } from '../../utils/googleanalytics';
import withCartInitialized from '../../components/withCartInitialized';
import BackButton from '../../components/BackButton';
import { useQueryParams } from 'hooks';
import { genopalateClickIdKey } from 'services/shopify/utils';

const ProductDetails = () => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const { getQueryParam } = useQueryParams()

  const { params: { productId } } = useRouteMatch();
  const { products, cart } = useSelector(state => state.additionalProducts);

  const product = useMemo(() => products.data && products.data.find(({ id }) => id === productId), [productId, products]);
  // todo: move the atob parse of the productId into Redux and decide how to handle the URL
  const inCart = useMemo(() => !!get(cart, 'cart.lineItems', []).find(line => line.id === atob(productId)), [cart, productId]);

  const onAddToCartClicked = useCallback(() => {
    let additionalAttributes = {};
    if (getQueryParam(genopalateClickIdKey)){
      additionalAttributes = {
        key: genopalateClickIdKey,
        value: getQueryParam(genopalateClickIdKey)
      };
    }

    registerGAClickEventEnhanced(EventCategories.AddOns, 'Add to Cart', product.name);
    dispatch(addItemToCart(product.id, undefined, undefined, additionalAttributes));
  }, [product, dispatch]);

  if (!product) return null;

  return (
    <div className={classes.productWrapper}>
      <div className={classes.productNavigation}>
        <BackButton />
      </div>
      <div className={classes.productHeader}>
        {/* todo: need to update alt tag to be product specific */}
        <img src={product.image} alt={`${product.name} screenshot`} style={{ width: 145 }} />
        <div className={classes.productDetails}>
          <Typography align="left" className={classes.productTitle}>
            {product.name}
          </Typography>
          <Typography align="left" className={classes.productPrice}>
            $
            {product.price}
          </Typography>
          <Button name="addToCartBtn" className={clsx(sharedClasses.primaryButton, classes.button)} onClick={onAddToCartClicked} disabled={inCart}>
            <FontAwesomeIcon icon={inCart ? faCheck : faPlus} className={classes.icon} />
            {inCart ? 'IN CART' : 'ADD TO CART' }
          </Button>
        </div>
      </div>
      <div className={classes.productDescription} dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
    </div>
  );
};

export default withCartInitialized(ProductDetails);
