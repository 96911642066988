import { product } from 'services'
import types from '../actionTypes'
import { selectStatus } from './status'

export const selectFile = file => ({ type: types.DNA_UPLOAD_SELECT_FILE, file })
export const clearFile = () => ({ type: types.DNA_UPLOAD_CLEAR_FILE })

export const openModal = () => ({ type: types.DNA_UPLOAD_OPEN_MODAL })
export const closeModal = () => ({ type: types.DNA_UPLOAD_CLOSE_MODAL })

export const openInstructions = () => ({ type: types.DNA_UPLOAD_OPEN_INSTRUCTIONS })
export const closeInstructions = () => ({ type: types.DNA_UPLOAD_CLOSE_INSTRUCTIONS })

export const selectSource = source => ({ type: types.DNA_UPLOAD_SELECT_SOURCE, source })
export const SOURCE_TYPES = {
  ANCESTRY: 'ANCESTRY',
  // Can't start a variable with a number, eslint complained about a leading underscore, and I wasn't feeling creative.
  MEAND23: 'MEAND23',
}

export const uploadFile = (file) => async (dispatch) => {
  dispatch({ type: types.DNA_UPLOAD_BEGIN_UPLOAD })
  try {
    await product.uploadDnaResult(file, progress => dispatch({ type: types.DNA_UPLOAD_UPDATE_PROGRESS, progress }))
    dispatch({ type: types.DNA_UPLOAD_FINISHED })
    dispatch(selectStatus())
  } catch (e) {
    dispatch({ type: types.DNA_UPLOAD_ERROR, error: e })
  }
}

export const hideDnaUploadedBanner = () => ({ type: types.HIDE_DNA_UPLOADED_BANNER })
