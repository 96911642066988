import React from 'react';
import { useDispatch } from 'react-redux';
import { ButtonBase, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { images } from 'resources';
import { colors } from 'common/assets/sharedUI';
import PaperContainer from './PaperContainer';

import { registrationNextStepClicked } from '../store/actions/registration';

const styles = theme => ({
  header: {
    color: colors.primaryBlue,
    fontSize: 22,
  },
  image: {
    maxWidth: 250,
    marginTop: '-25px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
    },
  },
  button: {
    backgroundColor: colors.primaryBlue,
    borderRadius: 4,
    fontWeight: 700,
    fontSize: 16,
    display: 'block',
    color: colors.white,
    margin: '0.5em 0',
    padding: '.75em 5em',
    '&:hover': {
      backgroundColor: colors.blue_05,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  userStatusHeader: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: colors.primaryGreen,
    padding: '1em',
    fontWeight: 900,
    position: 'relative',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
});

const KitRegistrationSuccessPage = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(registrationNextStepClicked());
  }

  return (
    <PaperContainer style={{ marginTop: '-65px' }}>
      <img src={images.mailKit} alt="Mailbox illustration" className={classes.image} />
      <Typography className={classes.header}>Take your sample and mail it!</Typography>
      <Typography>
        If you haven&apos;t already, now&apos;s the time to take your DNA sample. Follow the instructions on the kit or watch the video below.
      </Typography>
      <iframe
        title="DNA Sample procedure"
        width="300"
        height="200"
        style={{ marginTop: 15 }}
        src="https://www.youtube.com/embed/ij93_B3n-8Q"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <div className={classes.buttonWrapper}>
        <ButtonBase className={classes.button} onClick={handleClick}>NEXT STEPS &gt;</ButtonBase>
      </div>
    </PaperContainer>
  );
};

KitRegistrationSuccessPage.propTypes = {
  classes: PropTypes.shape({
    image: PropTypes.string,
    header: PropTypes.string,
    button: PropTypes.string,
    buttonWrapper: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(KitRegistrationSuccessPage);
