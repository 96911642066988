import { getAxiosInstance } from '../../utils/axios';
import { constants } from 'resources';

export default async (foodId) => {
  const resp = await getAxiosInstance().get(constants.API_GET_FOOD_DETAILS, {
    params: {
      foodId,
    },
  });

  return resp.data;
};
