import { useState, useEffect } from 'react';

const getWidth = () => window.innerWidth 
|| document.documentElement.clientWidth 
|| document.body.clientWidth;

export const useWindowWidth = () => {
// save current window width in the state object
let [width, setWidth] = useState(getWidth());

// in this case useEffect will execute only once because
// it does not have any dependencies.
useEffect(() => {
  // timeoutId for debounce mechanism
  let timeoutId = null;
  const resizeListener = () => {
    // prevent execution of previous setTimeout
    clearTimeout(timeoutId);
    // change width from the state object after 100 milliseconds
    timeoutId = setTimeout(() => setWidth(getWidth()), 100);
  };
  // set resize listener
  window.addEventListener('resize', resizeListener);

  // clean up function
  return () => {
    // remove resize listener
    window.removeEventListener('resize', resizeListener);
  }
}, [])

return width;
}

export default {
  useWindowWidth,
}