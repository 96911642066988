import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import icons from 'resources/icons';
import {
  Paper, Typography, Grid, Button,
} from '@material-ui/core';
import { Carousel } from 'components/componentsV2';
import { selectExpandedCategory } from 'store/actions/food';
import { sharedStyles } from 'resources';
import useStyles from './TipsPaper.styles';
import { registerGAClickEventEnhanced } from '../utils/googleanalytics';

export const BestSource = ({
  id, onClick, item, itemImage, classes,
}) => (
  <div key={id}>
    <Link to="/food" onClick={onClick}>
      <Paper className={classes.bestSourcesContainer}>
        <Typography variant="body1">{item}</Typography>
        <img className={classes.bestSourcesImage} src={`https://geno-icons.s3.amazonaws.com/${itemImage}`} alt={item} />
      </Paper>
    </Link>
  </div>
);

const TipsPaper = ({
  traitData, sensitivitiesOrSubstances, enDetails,
}) => {
  const { traitName } = traitData;
  const sharedClasses = sharedStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const domainActionItems = useSelector(state => state.actionItems.domainActionItems);
  const topFoods = useSelector(state => state.topFoods.userTopFoods);

  const [actionItems] = domainActionItems && traitData ? domainActionItems.filter(rec => rec.geneticInsightId === traitData.geneticInsightId) : [{ description: '' }];

  const handleBestSourcesClick = useCallback((foodGroupId, foodGroupName) => {
    registerGAClickEventEnhanced(`Nutrient-${traitName}`, 'Open', `Tips-${foodGroupName}`);
    dispatch(selectExpandedCategory(foodGroupId));
  }, [traitName, dispatch]);

  const displayBestSources = useCallback(() => {
    const foodGroups = topFoods || [];
    const { keySources } = enDetails;

    const bestSourcesMap = keySources.map((item) => {
      const itemFoodGroup = foodGroups.find(foodGroup => foodGroup.name === item);
      const itemImage = itemFoodGroup ? itemFoodGroup.foods[0].fileName : '';
      if (!itemFoodGroup || !itemImage) { return null; }
      return (
        <BestSource
          id={itemFoodGroup.id}
          onClick={() => handleBestSourcesClick(itemFoodGroup.id, itemFoodGroup.name)}
          item={item}
          itemImage={itemImage}
          classes={{
            bestSourcesContainer: classes.bestSourcesContainer,
            bestSourcesImage: classes.bestSourcesImage,
          }}
        />
      );
    });
    return bestSourcesMap;
  }, [topFoods, enDetails, handleBestSourcesClick, classes]);

  if (!actionItems && sensitivitiesOrSubstances) { return null; }

  const onViewAllClick = () => {
    registerGAClickEventEnhanced(`Nutrient-${traitName}`, 'Open', 'View All Foods');
    dispatch(selectExpandedCategory(8)); // sets initial expanded food group id
  };

  return (
    <div className={classes.paperContainer} id="nutrientTips">
      <Paper className={classes.paper}>
        <Grid container>
          {!!actionItems && (
            <React.Fragment>
              <Grid item xs={12} className={classes.titleContainer}>
                <Typography variant="h6" className={classes.title} name="tipsTitle">Tips</Typography>
                <img src={icons.tipsIcon} className={classes.icon} name="tipsIcon" alt="" />
              </Grid>
              <Grid item xs={12} className={classes.descriptionContainer}>
                <Typography variant="body1" name="tipsDescription">{actionItems ? actionItems.description : ''}</Typography>
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12}>
            { !sensitivitiesOrSubstances
              && (
                <Grid item xs={12} className={classes.myBestSourcesContainer}>
                  <Typography variant="h6" className={classes.title} name="bestSourcesTitle">
                    {`My Best Sources for ${traitName}`}
                  </Typography>
                  <div className={classes.carouselContainer}>
                    <Carousel infinite={false} myBestSource>
                      {displayBestSources()}
                    </Carousel>
                  </div>
                </Grid>
              )}
          </Grid>
          { !sensitivitiesOrSubstances
              && (
                <Grid item xs={12} className={classes.btnContainer}>
                  <Link to="/food" onClick={onViewAllClick}>
                    <Button size="large" className={sharedClasses.primaryButton}>View All &gt;&gt;</Button>
                  </Link>
                </Grid>
              )}
        </Grid>
      </Paper>
    </div>
  );
};

export default TipsPaper;

TipsPaper.propTypes = {
  sensitivitiesOrSubstances: PropTypes.bool.isRequired,
  traitData: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  enDetails: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

BestSource.propTypes = {
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  item: PropTypes.string.isRequired,
  itemImage: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    bestSourcesContainer: PropTypes.string.isRequired,
    bestSourcesImage: PropTypes.string.isRequired,
  }).isRequired,
};
