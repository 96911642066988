import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Dialog, DialogContent, DialogTitle, Checkbox, FormControlLabel, DialogActions,
} from '@material-ui/core'
import { sharedStyles } from 'resources'

const BioBankingResearchWarningModal = (props) => {
  const {
    open, onClose, onSubmit, dialogTitle, dialogContent, controlLabel,
  } = props
  const sharedClasses = sharedStyles()
  const [confirmOptOut, setConfirmOptOut] = React.useState(false)

  const handleClose = () => {
    setConfirmOptOut(false)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        {dialogContent}
        <FormControlLabel
          control={<Checkbox checked={confirmOptOut} onChange={() => setConfirmOptOut(!confirmOptOut)} name="confirm" />}
          label={controlLabel}
        />
      </DialogContent>
      <DialogActions>
        <Button className={sharedClasses.secondaryButton} onClick={handleClose} variant="outlined">Cancel</Button>
        <Button className={sharedClasses.primaryButton} disabled={!confirmOptOut} onClick={onSubmit} type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

export default BioBankingResearchWarningModal

BioBankingResearchWarningModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogContent: PropTypes.node.isRequired,
  controlLabel: PropTypes.string.isRequired,
}
