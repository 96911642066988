import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSubscriptionGenoBlendProducts, fetchUserBlendTypes } from '../store/actions/additionalProducts'
import { userStatuses } from 'common/resources/constants'
import userUserBlendType from './userUserBlendType';

const useGenoBlendSubscriptions = () => {
  const dispatch = useDispatch();
  const { userBlendSKU } = userUserBlendType()

  const { fetching, data, error } = useSelector(
    (state) => state.additionalProducts.subscriptionGenoBlendProducts
  )
  const status = useSelector((state) => state.status.userStatus)

  useEffect(() => {
    if (!fetching && !data && !error) {
      dispatch(fetchSubscriptionGenoBlendProducts())
    }
  }, /* [fetching, dispatch, data, error] */)

  useEffect(() => {
    dispatch(fetchUserBlendTypes())
  }, [])

  const baseProduct = useMemo(() => (data ? data.find(product => product.sku === userBlendSKU) : undefined), [data, userBlendSKU])

  const isReportDelivered = useMemo(
    () =>
      !!status &&
      !!status.webDisplay &&
      status.webDisplay === userStatuses.RENDER_REPORT,
    [status]
  )

  const sellingPlans =
    baseProduct?.sellingPlanGroups?.[0].plans?.map((plan) => {
      return {
        ...plan,
        planId: plan.id,
        value: plan.id,
        name: plan.name,
      }
    }) || []

  const variants =
    baseProduct?.variants?.edges?.map((edge) => {
      return {
        ...edge.node,
        name: edge.node.title,
        value: edge.node.id
      }
    }) || [];


  return {
    isReportDelivered,
    genoBlendBaseProduct: baseProduct,
    genoBlendSubscriptionsFetching: fetching,
    genoBlendSubscriptions: data,
    genoBlendSubscriptionsError: error,
    sellingPlans,
    variants,
  }
}

export default useGenoBlendSubscriptions
