import axios from 'axios';
import loadExistingCart from './loadExistingCart';
import createCart from './createCart';
import addToCart from './addToCart';
import removeFromCart from './removeFromCart';

const { IS_PROD, IS_QA } = window.env;

const API_TOKEN = IS_PROD ? '2992ffe685d9e4b576d3b21eef0d4610' : IS_QA ? '680885cb22961f65660e33ba7fe5c3bf' : '7545a49482e48a82fa427441ef83b583';
const API_URL = IS_PROD ? 'https://www.genopalate.com/api/2022-10/graphql.json' : IS_QA ? 'https://genopalate-dev.myshopify.com/api/2024-01/graphql.json' : 'https://genp-test.myshopify.com/api/2022-10/graphql.json';

const shopifyQuery = async (query, variables = {}) => axios({
  headers: {
    'Content-Type': 'application/json',
    'X-Shopify-Storefront-Access-Token': API_TOKEN,
  },
  method: 'post',
  url: API_URL,
  data: { query, variables },
});

export default {
  addToCart: addToCart(shopifyQuery),
  createCart: createCart(shopifyQuery),
  loadExistingCart: loadExistingCart(shopifyQuery),
  removeFromCart: removeFromCart(shopifyQuery),
};
