import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BlendSubscriptionHeader from 'components/BlendSubscription/BlendSubscriptionHeader'
import { useStyles } from './BlendSubscriptionPage.styles'
import AdditionalProducts from 'components/AdditionalProducts/AdditionalProducts'
import CustomFormula from './CustomFormula'
import { useGenoBlendSubscriptions } from 'hooks'
import {
  getSubscriptions,
  getShippingAddress,
  getPaymentMethod,
  getSubscriptionQuestionnaire
} from 'store/actions/subscription';
import {fetchAdditionalProducts, fetchSubscriptionProducts, fetchPurchasedProducts,  } from 'store/actions/additionalProducts';
import { Loading } from 'components/componentsV2'
import { blendSKUs } from '../../resources/constants'

const BlendSubscriptionPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch();
  const { fetching } = useSelector(
    (state) => state.subscription.subscriptions);
  const { genoBlendBaseProduct, variants, sellingPlans, isReportDelivered } =
    useGenoBlendSubscriptions()
    useEffect(() => {
      dispatch(getSubscriptions());
      dispatch(fetchSubscriptionProducts());
      dispatch(fetchPurchasedProducts());
      dispatch(fetchAdditionalProducts());
      dispatch(getShippingAddress());
      dispatch(getPaymentMethod());
      dispatch(getSubscriptionQuestionnaire());
    }, [dispatch]);

  const { isGenoBlendInCart } = useSelector((state) => ({
    isGenoBlendInCart: state.additionalProducts.cart?.cart?.lineItems?.some(
      (lineItem) => lineItem.sku === blendSKUs[lineItem.sku]
    ),
  }))

  return genoBlendBaseProduct && !fetching ? (
    <div className={classes.pageWrapper}>
      <BlendSubscriptionHeader
        baseProduct={genoBlendBaseProduct}
        sellingPlans={sellingPlans}
        variants={variants}
        isItemInCart={isGenoBlendInCart}
      />
      <CustomFormula
        boosterFormulas={[]}
        isExampleFormula={!isReportDelivered}
      />
      <AdditionalProducts />
    </div>
  ) : (
    <Loading />
  )
}

export default BlendSubscriptionPage
