import React from 'react'
import {
  Collapse, Box, IconButton, Paper, Typography,
} from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import CloseIcon from '@material-ui/icons/Close'
import {
  isTablet,
  isMobile,
  isIOS,
  osName,
} from 'react-device-detect'
import { colors } from 'common/assets/sharedUI'
import { images } from 'resources'
import { AppStoreURL, PlayStoreURL } from 'resources/constants'
import useStyles from './MobileBanner.styles'

type Props = {
  openBanner: any,
  onClose: any,
}

const MobileBanner = (props: Props) => {
  const { openBanner, onClose } = props
  const classes = useStyles()

  const url = (((isMobile || isTablet) && isIOS) || osName.includes('Mac')) ? AppStoreURL : PlayStoreURL
  const storeTxt = (((isMobile || isTablet) && isIOS) || osName.includes('Mac')) ? 'GET - On The App Store' : 'Free - In Google Play'

  return (
    <Collapse in={openBanner}>
      <Box pr={2} pl={5} bgcolor={colors.lightGrey} color={colors.white} className={classes.banner}>
        <div className={classes.bannerContainer}>
          <div className={classes.leftColumn}>
            <IconButton aria-label="close" id="mobileBannerCloseBtn" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Paper elevation={0} className={classes.logoContainer}>
              <img src={images.logo} alt="GenoPalate Logo" className={classes.logoImg} />
            </Paper>
            <div className={classes.appDetails}>
              <Typography variant="h6" className={classes.greyTitle}>GenoPalate</Typography>
              <Typography variant="body1" className={classes.detailsText}>Health and Fitness</Typography>
              <div>
                <StarIcon className={classes.starIcon} />
                <StarIcon className={classes.starIcon} />
                <StarBorderIcon className={classes.starIcon} />
                <StarBorderIcon className={classes.starIcon} />
                <StarBorderIcon className={classes.starIcon} />
              </div>
              <Typography variant="body1" className={classes.detailsText}>{storeTxt}</Typography>
            </div>
          </div>
          <a href={url}>
            <Typography variant="h5" className={classes.link}>View</Typography>
          </a>
        </div>
      </Box>
    </Collapse>
  )
}

export default MobileBanner
