import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles((theme) => createStyles({
  banner: {
    width: '100%',
    position: 'fixed',
    left: 0,
    zIndex: 1301,
    fontSize: '1.25rem',
    height: 110,
    color: colors.darkGrey,
  },
  bannerContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '6vw',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  leftColumn: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    paddingTop: 4,
  },
  closeButton: {
    height: 60,
    width: 60,
    marginLeft: -30,
    [theme.breakpoints.down('xs')]: {
      marginLeft: -50,
    },
  },
  logoContainer: {
    height: 80,
    width: 90,
    padding: '20px 10px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px 12px',
      marginTop: 4,
    },
  },
  logoImg: {
    height: 40,
    [theme.breakpoints.down('xs')]: {
      height: 35,
    },
  },
  appDetails: {
    paddingLeft: 20,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
    },
  },
  greyTitle: {
    color: colors.darkGrey,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.15rem',
    },
  },
  detailsText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  starIcon: {
    color: colors.starOrange,
  },
  link: {
    color: colors.linkBlue,
  },
}));

export default useStyles;
