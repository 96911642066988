import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { assign, get } from 'lodash';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { save, forward } from 'store/actions/questionnaire';
import {
  getYupObject, getInitialValues, isAnswerEmpty, getAnswerObject,
} from 'utils/questionnaire';
import QuestionNavigation from 'components/QuestionNavigation';
import AnswerHandler from 'components/AnswerHandler';
import helpers from 'utils/helpers';
import { apiStatuses } from 'resources/constants';

export default function AnswerWrapper(props) {
  // Main container for all answers
  const {
    question,
    questionnaireId,
    onClickBackBtn,
    questionGroupName,
  } = props; // These should probably come from Redux instead of through props

  const dispatch = useDispatch();
  // validation
  const [schema, setSchema] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  // isOptional - this will handle validation for mandatory and optional questions
  const [isOptional, setIsOptional] = useState(false);
  const saveStatus = useSelector(state => state.questionnaire.saveStatus);

  // Reload schema when the question array changes
  useEffect(() => {
    (async () => {
      const yupObjectArray = {};
      const response = {};

      question.forEach(questionItem => {
        assign(yupObjectArray, getYupObject(questionItem));
        assign(response, getInitialValues(questionItem));
      });

      const yupSchema = Yup.object().shape(yupObjectArray);
      setSchema(yupSchema);
      setInitialValues(response);

      // Validate schema on init
      const isResponseValid = await yupSchema.isValid(response);
      setIsOptional(isResponseValid);
    })();
  }, [question]);

  // isEighteenOrOlder - this will determine if the birthdate is valid or not
  const [isBirthdateValid, setIsBirthdateValid] = useState(true);

  const questionStatus = useSelector(state => state.questionnaire.status);

  const onSubmit = (values) => {
    let isValid = true;
    // Birthdate validation for the first question
    if (get(question, '[0].questionText', '') === "What's your birthday?" && get(question, '[0].questionTypeId', 0) === 3) {
      isValid = helpers.isEighteenOrOlder(values.date_1);
      setIsBirthdateValid(isValid);
    }
    // Dynamically generate answers array
    if (saveStatus !== apiStatuses.PENDING && questionStatus !== apiStatuses.PENDING && isValid) {
      const isEmpty = question.every(questionElem => isAnswerEmpty(values, questionElem));
      if (isEmpty) {
        dispatch(forward(question[0].id));
      } else {
        const answers = question.map(questionElem => getAnswerObject(values, questionElem));
        const params = {
          answers,
          questionnaireId,
        };
        dispatch(save(params));
      }
    }
  };

  const handleDateChange = () => {
    setIsBirthdateValid(true);
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(formikProps) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            isValid,
            dirty,
          } = formikProps;
          return (
            <form onSubmit={handleSubmit} style={{ maxWidth: '400px' }}>
              {question.map(questionItem => {
                const answerProps = {
                  key: questionItem.id,
                  id: questionItem.id,
                  questionGroupId: questionItem.questionGroupId,
                  questionText: questionGroupName ? questionItem.questionText : '',
                  singleAnswer: questionItem.singleAnswer,
                  minAnswerCount: questionItem.minAnswerCount,
                  maxAnswerCount: questionItem.maxAnswerCount,
                  questionTypeId: questionItem.questionTypeId,
                  imageLocation: questionItem.imageLocation,
                  overlayLocation: questionItem.overlayLocation,
                  isNoneChoice: questionItem.isNoneChoice,
                  choices: questionItem.questionChoices,
                  answer: questionItem.answer,
                  isEighteenOrOlder: isBirthdateValid,
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  onDateChange: handleDateChange,
                };
                return <AnswerHandler {...answerProps} />;
              })}
              <QuestionNavigation
                isValidated={isOptional ? isValid : (isValid && dirty)}
                saveStatus={saveStatus}
                questionStatus={questionStatus}
                onClickBackBtn={onClickBackBtn}
              />
            </form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}

AnswerWrapper.propTypes = {
  question: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    questionGroupId: PropTypes.number,
    questionText: PropTypes.string,
    questionSubtext: PropTypes.string,
    questionTypeId: PropTypes.number,
    purpose: PropTypes.string,
    sortOrder: PropTypes.number,
    singleAnswer: PropTypes.bool,
    minAnswerCount: PropTypes.number,
    maxAnswerCount: PropTypes.number,
    mandatory: PropTypes.bool,
    imageLocation: PropTypes.string,
    questionChoices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      choiceText: PropTypes.string,
      choiceSubtext: PropTypes.string,
      imageLocation: PropTypes.string,
      overlayLocation: PropTypes.string,
      isNoneChoice: PropTypes.bool,
    })),
    answer: PropTypes.shape({
      id: PropTypes.number,
      answerChoices: PropTypes.arrayOf(PropTypes.shape({
        questionChoiceId: PropTypes.number,
      })),
      answerText: PropTypes.arrayOf(PropTypes.any),
      dateResponse: PropTypes.string,
      noneResponse: PropTypes.bool,
      numberResponse: PropTypes.number,
      timeResponse: PropTypes.string,
    }),
  })).isRequired,
  questionnaireId: PropTypes.number.isRequired,
  onClickBackBtn: PropTypes.func.isRequired,
  questionGroupName: PropTypes.string,
};

AnswerWrapper.defaultProps = {
  questionGroupName: null,
};
