import types from '../actionTypes'
import { food } from '../../services';

export const selectFoodDetail = (foodId) => ({
  type: types.SELECT_FOOD_DETAIL,
  payload: foodId,
})
export const selectExpandedCategory = (category) => ({
  type: types.SELECT_EXPANDED_CATEGORY,
  payload: category,
})

export const getFoodDetailsStarted = () => ({ type: types.GET_FOOD_DETAILS_STARTED })

export const getFoodDetailsSuccess = data => ({ type: types.GET_FOOD_DETAILS_SUCCESS, data })

export const getFoodDetailsFailure = error => ({ type: types.GET_FOOD_DETAILS_ERROR, error })

export const getFoodDetails = (foodId) => async dispatch => {
  dispatch(getFoodDetailsStarted())
  try {
    const response = await food.getDetails(foodId)
    dispatch(getFoodDetailsSuccess(response))
  } catch (error) {
    dispatch(getFoodDetailsFailure(error))
  }
}
