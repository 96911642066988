import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdditionalProducts } from 'store/actions/additionalProducts'

/**
 * fetch or load from redux global state cache ('state.additionalProducts.products')
 */
const useAdditionalProducts = () => {
  const dispatch = useDispatch()
  const { fetching, data, error } = useSelector(
    (state) => state.additionalProducts.products
  )

  useEffect(() => {
    if (!fetching && !data && !error) {
      dispatch(fetchAdditionalProducts())
    }
  }, [fetching, dispatch, data, error])

  return { fetching, data, error }
}

export default useAdditionalProducts
