import { get } from 'lodash'
import types from '../actionTypes'

const defaultState = {
  file: null,
  isUploading: false,
  uploadProgress: 0,
  error: null,
  errorCode: null,
  errorParams: null,
  displayModal: false,
  displayInstructions: false,
  source: '',
  dnaUploadedBannerVisible: true, // needs to default to true so the banner is visible upon first login if status is correct, and can be overridden once user navs away from the welcomeQuestionnaire page
}

export default function dnaUploadReducer(state = defaultState, action) {
  switch (action.type) {
    case types.DNA_UPLOAD_SELECT_FILE: {
      return {
        ...state,
        file: action.file,
      }
    }
    case types.DNA_UPLOAD_CLEAR_FILE: {
      return {
        ...state,
        file: null,
      }
    }
    case types.DNA_UPLOAD_BEGIN_UPLOAD: {
      return {
        ...state,
        isUploading: true,
        uploadProgress: 0,
        error: null,
        errorCode: null,
      }
    }
    case types.DNA_UPLOAD_UPDATE_PROGRESS: {
      return {
        ...state,
        uploadProgress: action.progress,
      }
    }
    case types.DNA_UPLOAD_ERROR: {
      const errorCode = get(action.error, 'response.status', 500)
      const message = get(action.error, 'response.data.message', 'An unknown error has occurred.')
      const errorParams = get(action.error, 'response.data.messageParams', null)

      return {
        ...state,
        error: message,
        errorCode,
        errorParams,
        file: null,
        isUploading: false,
      }
    }
    case types.DNA_UPLOAD_FINISHED: {
      return {
        ...state,
        isUploading: false,
        displayModal: false,
      }
    }
    case types.DNA_UPLOAD_OPEN_MODAL: {
      return {
        ...state,
        displayModal: true,
      }
    }
    case types.DNA_UPLOAD_CLOSE_MODAL: {
      return {
        ...state,
        displayModal: false,
      }
    }
    case types.DNA_UPLOAD_OPEN_INSTRUCTIONS: {
      return {
        ...state,
        displayInstructions: true,
      }
    }
    case types.DNA_UPLOAD_CLOSE_INSTRUCTIONS: {
      return {
        ...state,
        displayInstructions: false,
      }
    }
    case types.DNA_UPLOAD_SELECT_SOURCE: {
      return {
        ...state,
        source: action.source,
      }
    }
    case types.HIDE_DNA_UPLOADED_BANNER: {
      return {
        ...state,
        dnaUploadedBannerVisible: false,
      }
    }
    default: {
      return state
    }
  }
}
