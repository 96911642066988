import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { colors } from 'common/assets/sharedUI';

const styles = {
  userStatusHeader: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: colors.primaryGreen,
    padding: '1em',
    fontWeight: 900,
    position: 'relative',
  },
};

const UserStatusBanner = ({
  classes, status, message, dataStatus,
}) => {
  if (!status) return null;
  const messageUI = (
    <div>
      {message}
      <FontAwesomeIcon icon={faCheckCircle} />
    </div>
  );
  return (
    <div className={classes.userStatusHeader} id="onboardingStatusHeader" data-status={dataStatus}>
      {messageUI}
    </div>
  );
};

UserStatusBanner.propTypes = {
  classes: PropTypes.shape({
    userStatusHeader: PropTypes.string,
  }).isRequired,
  status: PropTypes.shape({
    webDisplay: PropTypes.string,
  }),
  message: PropTypes.string.isRequired,
  dataStatus: PropTypes.string,
};

UserStatusBanner.defaultProps = {
  status: undefined,
  dataStatus: undefined,
};

export default withStyles(styles)(UserStatusBanner);
