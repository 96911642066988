import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import {
  Grid, Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { images } from 'resources';
import * as QueryString from 'query-string';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
  createAccount, getRegistrationDetails,
} from 'store/actions/registration';
import { colors } from 'common/assets/sharedUI';
import { registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels } from '../../utils/googleanalytics';
import RegistrationFormikInput from '../../components/RegistrationFormikInput';
import StatusTrackerV2 from '../../components/StatusTrackerV2';
import constants from '../../resources/constants';
import { passwordRequirements } from '../../utils';
import { Link } from 'react-router-dom';

const { IS_PROD, IS_STAGING } = window.env;

const handleGACall = (eventLabel) => registerGAClickEventEnhanced(EventCategories.Registration, EventActions.Click, eventLabel);

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.validationSchema = Yup.object().shape({
      orderNumber: Yup.string()
        .required('Order number is required'),
      productCode: Yup.string()
        .required('Product code is required'),
      firstName: Yup.string()
        .required('First name is required'),
      lastName: Yup.string()
        .required('Last name is required'),
      email: Yup.string()
        .email('Must be a valid email')
        .required('Email is required'),
      confirmEmail: Yup.string()
        .oneOf([Yup.ref('email')], 'Emails must match')
        .email('Must be a valid email')
        .required('You are required to confirm your email'),
      password: passwordRequirements,
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('You are required to confirm your password'),
      tos: Yup.bool()
        .oneOf([true], 'You must accept the Privacy Policy and Terms of Service before continuing'),
    });

    this.state = {
      productCode: '',
      orderNo: '',
    };

    const { location } = props;
    if (location && location.search) {
      const { userProductCode, orderNo } = QueryString.parse(location.search);
      if (userProductCode) {
        this.state = { ...this.state, productCode: userProductCode };
      }
      if (orderNo) {
        this.state = { ...this.state, orderNo };
      }
    }
  }

  async componentDidMount() {
    const { location, getRegistrationDetails: dispatchGetRegistrationDetails } = this.props;

    /** TODO: Remove hard-coded dependencies */
    if (IS_PROD || IS_STAGING) {
      ReactGA.send({ hitType: "pageview", page: '/register' });
    }

    if (location && location.search) {
      const { token } = QueryString.parse(location.search);
      if (token) {
        dispatchGetRegistrationDetails(token);
      }
    }
  }

  onSubmit(values) {
    const { createAccount: dispatchCreateAccount, history } = this.props;
    const body = {
      email: values.email,
      password: values.password,
      name: values.firstName,
      surname: values.lastName,
      userProductCode: values.productCode.trim(),
      userAgreement: values.tos,
      orderNumber: values.orderNumber,
    };
    dispatchCreateAccount(body, history);
  }

  renderRegisterForm() {
    const {
      classes, accountCreationError, isCreatingAccount, orderDetails,
    } = this.props;

    const { orderNo, productCode } = this.state;

    return (
      <Formik
        initialValues={{
          orderNumber: orderDetails ? orderDetails.orderNumber : orderNo,
          productCode: orderDetails ? orderDetails.productCode : productCode,
          firstName: orderDetails ? orderDetails.name : '',
          lastName: orderDetails ? orderDetails.surname : '',
          email: orderDetails ? orderDetails.email : '',
          confirmEmail: '',
          password: '',
          confirmPassword: '',
          tos: false,
        }}
        validationSchema={this.validationSchema}
        onSubmit={(values) => this.onSubmit(values)}
      >
        {(props) => {
          const {
            handleSubmit,
            errors,
            submitCount,
          } = props;
          return (
            <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Grid item xs={12}>
                <RegistrationFormikInput
                  name="orderNumber"
                  id="createAccountOrderNumberInput"
                  label="Order number"
                  submitCount={submitCount}
                  tooltip="This code can be found in your order confirmation email from GenoPalate. If you ordered from a third-party retailer, request your registration codes from the link above."
                  type={orderDetails && orderDetails.orderNumber ? 'hidden' : 'text'}
                />
              </Grid>
              <Grid item xs={12}>
                <RegistrationFormikInput
                  name="productCode"
                  label="Product code"
                  id="createAccountProductCodeInput"
                  submitCount={submitCount}
                  tooltip="This code can be found in your order confirmation email from GenoPalate. If you ordered from a third-party retailer, request your registration codes from the link above."
                  type={orderDetails && orderDetails.productCode ? 'hidden' : 'text'}
                />
              </Grid>
              <Grid item xs={12}>
                <RegistrationFormikInput
                  name="firstName"
                  label="First name"
                  id="createAccountFirstNameInput"
                  submitCount={submitCount}
                />
              </Grid>
              <Grid item xs={12}>
                <RegistrationFormikInput
                  name="lastName"
                  label="Last name"
                  id="createAccountLastNameInput"
                  submitCount={submitCount}
                />
              </Grid>
              <Grid item xs={12}>
                <RegistrationFormikInput
                  name="email"
                  label="Email"
                  id="createAccountEmailInput"
                  submitCount={submitCount}
                />
              </Grid>
              <Grid item xs={12}>
                <RegistrationFormikInput
                  name="confirmEmail"
                  label="Re-enter email address"
                  id="createAccountConfirmEmailInput"
                  submitCount={submitCount}
                  type="email"
                />
              </Grid>
              <Grid item xs={12}>
                <RegistrationFormikInput
                  name="password"
                  label="Password"
                  id="createAccountPasswordInput"
                  submitCount={submitCount}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <RegistrationFormikInput
                  name="confirmPassword"
                  label="Re-enter password"
                  id="createAccountConfirmPasswordInput"
                  submitCount={submitCount}
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                Password requirements:
                <p className={classes.passwordReq}>At least 8 characters</p>
                <p className={classes.passwordReq}>At least one number</p>
                <p className={classes.passwordReq}>At least one special character (examples: #@!*)</p>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    {/* The MUI checkbox control would not work with the Formik validation */}
                    <Field type="checkbox" id="createAccountTermsInput" name="tos" style={{ width: 20, height: 20 }} />
                  </Grid>
                  <Grid item xs={10} sm={11} className={classes.tosAndPPWrapper}>
                    By clicking here you agree to our&nbsp;
                    <a href="https://www.genopalate.com/pages/privacy-policy" target="_blank" rel="noopener noreferrer" onClick={() => handleGACall(EventLabels.PrivacyPolicy)}>Privacy Policy</a>
                    &nbsp;and&nbsp;
                    <a href="https://www.genopalate.com/pages/terms-of-service" target="_blank" rel="noopener noreferrer" onClick={() => handleGACall(EventLabels.TermsOfService)}>Terms of Service</a>
                  </Grid>
                </Grid>
              </Grid>
              {errors.tos && submitCount > 0 && (
                <Grid item xs={12} className={classes.errorText}>
                  <span>{errors.tos}</span>
                </Grid>
              )}
              {accountCreationError && (
                <Grid item xs={12} className={classes.errorText} id="accountCreationError">
                  <span>{accountCreationError}</span>
                </Grid>
              )}
              <Grid item xs={12} className={classes.centerGrid}>
                <Button disabled={isCreatingAccount} variant="contained" id="createAccountSubmitButton" onClick={() => handleGACall(EventLabels.Login)} className={classes.createAccountButton} type="submit">CREATE ACCOUNT</Button>
              </Grid>
              <Grid item xs={12} className={classes.alreadyHaveAccountText}>
                Already have an account?&nbsp;
                <Link to="/login"><b>Sign in</b></Link>
              </Grid>
              <Grid item xs={12} className={classes.centerGrid}>
                Having trouble?&nbsp;
                <a href={`${constants.CONTACT_SUPPORT_URL}`} id="createAccountSupportLink" style={{ color: 'black' }} target="_blank" rel="noopener noreferrer" onClick={() => handleGACall(EventLabels.ContactSupport)}><b>Contact support</b></a>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }

  render() {
    const {
      classes,
      fetchingOrderDetailsError,
      isFetchingOrderDetails,
      orderDetails,
    } = this.props;

    return (
      <React.Fragment>
        <img src={images.logoNoTagline} alt="GenoPalate logo" className={classes.logo} />
        {orderDetails && <StatusTrackerV2 productType={orderDetails.isKit ? 'kit' : 'edna'} activeStep={1} />}
        <Grid container className={classes.registerPage}>

          <Grid item xs={12} className={classes.signInText}>
            <h2 className={classes.headerText}>Register Your Account</h2>
            New to GenoPalate?&nbsp;
            <a href="https://genopalate.com"><b>Buy now</b></a>
            
            <br/><br/>

            Don’t have your order number or product code? <br/> Request your registration codes&nbsp;
            <Link to="/register-kit"><b>here</b></Link>

           
          </Grid>
          {fetchingOrderDetailsError && (
            <Grid item xs={12} className={classes.errorText}>
              We’re sorry, your authentication token has expired. Please check your email for a link to create your account. Thank you!
            </Grid>
          )}
          {!isFetchingOrderDetails && this.renderRegisterForm()}
        </Grid>
      </React.Fragment>
    );
  }
}

RegisterPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({
    passwordReq: PropTypes.string,
    registerPage: PropTypes.string,
    tosAndPPWrapper: PropTypes.string,
    createAccountButton: PropTypes.string,
    centerGrid: PropTypes.string,
    logo: PropTypes.string,
    signInText: PropTypes.string,
    headerText: PropTypes.string,
    alreadyHaveAccountText: PropTypes.string,
    errorText: PropTypes.string,
  }).isRequired,
  createAccount: PropTypes.func.isRequired,
  getRegistrationDetails: PropTypes.func.isRequired,
  isCreatingAccount: PropTypes.bool.isRequired,
  accountCreationError: PropTypes.string.isRequired,
  isFetchingOrderDetails: PropTypes.bool.isRequired,
  fetchingOrderDetailsError: PropTypes.string.isRequired,
  orderDetails: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    productCode: PropTypes.string.isRequired,
    orderNumber: PropTypes.string.isRequired,
    isKit: PropTypes.bool.isRequired,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.any.isRequired,
};

RegisterPage.defaultProps = {
  orderDetails: null,
};

const styles = theme => ({
  registerPage: {
    color: '#282c36',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    maxWidth: 400,
    margin: 'auto',
  },
  passwordReq: {
    margin: '0 20px',
  },
  tosAndPPWrapper: {
    display: 'inline-block',
    alignItems: 'center',
    '& a': {
      fontWeight: 'bold',
      color: '#095d7c',
    },
  },
  createAccountButton: {
    width: '100%',
    alignSelf: 'center',
    backgroundColor: colors.blue_04,
    color: 'white',
    fontSize: '16px',
    boxShadow: 'none',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: colors.blue_05,
      boxShadow: 'none',
    },
  },
  centerGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  logo: {
    width: 120,
    margin: 'auto',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      width: 100,
    },
  },
  signInText: {
    fontSize: 14,
    textAlign: 'center',
    color: colors.gray_05,
  },
  alreadyHaveAccountText: {
    fontSize: 14,
    textAlign: 'center',
    color: colors.gray_05,
    marginTop: 15,
  },
  errorText: {
    padding: 4,
    marginTop: 10,
    borderStyle: 'solid',
    borderRadius: 4,
    backgroundColor: 'rgba(180,0,0,.15)',
    color: colors.red,
    textAlign: 'center',
  },
  headerText: {
    fontSize: 24,
    color: colors.blue_03,
    fontWeight: 300,
    textAlign: 'center',
    margin: 0,
    paddingTop: 10,
  },

  registerDNAKitText: {
    fontSize: 24,
    color: colors.blue_03,
    fontWeight: 300,
    textAlign: 'center',
    margin: 0,
    paddingTop: 10,
  }
});

export default connect(
  (state) => state.registration,
  {
    createAccount,
    getRegistrationDetails,
  },
)(withStyles(styles)(RegisterPage));
