import React, { useState, useEffect } from 'react';
import { user } from 'services';
import useStyles from './WebAppHomePage.styles';

export default function RerunReport() {
  const classes = useStyles();
  const [fetchCounter, setFetchCounter] = useState(false);
  let checkStatusTimer = null;
  const DnaResultStatusCode = {
    NEW: 0,
    COMPLETE: 1,
    RESULTS_READY: 2,
    UNKNOWN_ERROR: 3,
  };

  const beginRerun = async () => {
    await runReport();
    setFetchCounter(0);
    checkStatusTimer = setInterval(checkStatus, 10000);
  };

  useEffect(() => {
    (async () => {
      await beginRerun();
    })();
  }, []);

  const checkStatus = async () => {
    setFetchCounter(fetchCounter + 1);
    if (fetchCounter < 30) {
      const userProduct = await user.getProduct();
      const { dnaResult } = userProduct.product;
      if (
        parseInt(dnaResult.dnaStatusId, 10) === DnaResultStatusCode.RESULTS_READY
        || parseInt(dnaResult.dnaStatusId, 10) === DnaResultStatusCode.UNKNOWN_ERROR
      ) {
        refreshPage();
        clearInterval(checkStatusTimer);
      }
    }
  };

  const runReport = async () => {
    await user.runReport();
  };

  const refreshPage = () => {
    window.parent.location = window.parent.location.href;
  };

  return (
    <div style={{ paddingTop: 80 }}>
      <div className={classes.flexContainer}>
        <div style={{ width: '50%', marginTop: 50 }}>
          <h3>
            Welcome back to GenoPalate! We updated our nutrient and food scoring
            and now take your age and gender into account for your GenoPalate
            recommendations. There’s a lot of number crunching needed to make
            these updates, so there may be a one-time, one-hour delay while our
            system re-analyzes your recommendations.
          </h3>
          <h3>
            While you’re waiting, consider doing something healthy for yourself.
            Plan your meals for the rest of the week, find a quiet place for a few
            minutes, or head outside for a 20-30 minute stroll.
          </h3>
        </div>
      </div>
    </div>
  );
}
