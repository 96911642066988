import { axios } from 'utils';
import { constants } from 'resources';

export default async function getSubscriptionOrders() {
  try {
    const response = await axios.get(constants.API_GET_SUBSCRIPTION_ORDERS);
    return response.data.length ? response.data : [];
  } catch (e) {
    return null;
  }
}
