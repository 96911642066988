import { get } from 'lodash';
import types from '../actionTypes';
import { apiStatuses } from '../../resources/constants';

const initialProfileState = {
  status: apiStatuses.IDLE,
  updateProfileStatus: apiStatuses.IDLE,
  userProfile: null,
  error: null,
  updateProfileError: null,
};

const profileReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case types.GET_PROFILE_STARTED:
      return {
        ...state,
        error: null,
        status: apiStatuses.PENDING,
      };
    case types.GET_PROFILE_ERROR:
      return {
        ...state,
        error: action.payload,
        status: apiStatuses.REJECTED,
      };
    case types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        error: null,
        userProfile: get(action, 'payload', null),
        status: apiStatuses.RESOLVED,
      };
    case types.UPDATE_PROFILE_STARTED:
      return {
        ...state,
        updateProfileError: null,
        updateProfileStatus: apiStatuses.PENDING,
      };
    case types.UPDATE_PROFILE_ERROR: {
      const message = get(action, 'payload.response.data.message', null);
      return {
        ...state,
        updateProfileError: message,
        updateProfileStatus: apiStatuses.REJECTED,
      };
    }
    case types.UPDATE_PROFILE_SUCCESS: {
      const prevProfileData = get(state, 'userProfile.data', {});
      const updatedProfileData = get(action, 'payload.data', {});
      return {
        ...state,
        updateProfileError: null,
        userProfile: {
          data: {
            ...prevProfileData,
            ...updatedProfileData,
          },
        }, // todo: flatten all of this
        updateProfileStatus: apiStatuses.RESOLVED,
      };
    }
    default:
      return state;
  }
};

export default profileReducer;
