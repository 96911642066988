import getEssentialNutritionDetails from './getEssentialNutritionDetails';
import getGeneticInsights from './getGeneticInsights';
import getMacros from './getMacros';
import getTraitRecommendation from './getTraitRecommendation';
import getTraits from './getTraits';

export default {
  getEssentialNutritionDetails,
  getGeneticInsights,
  getMacros,
  getTraitRecommendation,
  getTraits,
};
