import React from 'react'
import { Grid } from '@material-ui/core'
import CustomTextField from 'components/CustomTextField'
import PropTypes from 'prop-types'
import useStyles from './NumberForm.styles'

export default function NumberForm(props) {
  const classes = useStyles()
  const {
    id,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = props

  const numberProps = {
    name: `number_${id}`,
    value: values[`number_${id}`],
    onChange: handleChange,
    onBlur: handleBlur,
    error: errors[`number_${id}`] && touched[`number_${id}`],
    helperText: (errors[`number_${id}`] && touched[`number_${id}`]) && errors[`number_${id}`],
    margin: 'normal',
    variant: 'outlined',
    size: 'small',
    inputProps: { maxLength: 5 },
  }

  return (
    <Grid container>
      <Grid container item xs={12} className={classes.questionContainer} justify="center">
        <CustomTextField {...numberProps} />
      </Grid>
    </Grid>
  )
}

NumberForm.propTypes = {
  id: PropTypes.number.isRequired,
  values: PropTypes.objectOf(PropTypes.any),
  touched: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}

NumberForm.defaultProps = {
  values: {},
  touched: {},
  errors: {},
}
