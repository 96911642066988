import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  buttonContainer: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'center',
  },
  backBtn: {
    marginRight: 10,
    '& .svg-inline--fa': {
      marginRight: '5px',
    },
  },
  nextBtn: {
    '& .svg-inline--fa': {
      marginLeft: '5px',
    },
  },
}));

export default useStyles;
