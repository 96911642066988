import types from '../actionTypes';
import constants, { apiStatuses } from '../../resources/constants';

const defaultState = {
  status: apiStatuses.IDLE,
  userStatus: {
    webDisplay: '',
    webCopy: '',
  },
  error: null,
  firstTimeKitUser: true,
  headerFooter: true,
  panels: [],
};

function statusReducer(state = defaultState, action) {
  switch (action.type) {
    case types.SELECT_STATUS_STARTED:
      return {
        ...state,
        status: apiStatuses.PENDING,
      };
    case types.SELECT_STATUS_SUCCESS:
      return {
        ...state,
        status: apiStatuses.RESOLVED,
        userStatus: action.payload.data.result,
        panels: action.payload.data.panels,
      };
    case types.SELECT_STATUS_ERROR:
      return {
        ...state,
        status: apiStatuses.REJECTED,
        error: action.payload.error,
        userStatus: { webDisplay: 'statusError', webCopy: `An error has occurred. Please try refreshing the page. If this problem persists, please contact ${constants.SUPPORT_EMAIL}.` },
      };
    case types.SET_FIRST_TIME_KIT_USER:
      return {
        ...state,
        firstTimeKitUser: action.payload,
      };
    case types.DISPLAY_HEADER_FOOTER:
      return {
        ...state,
        headerFooter: action.payload,
      };
    default:
      return state;
  }
}

export default statusReducer;
