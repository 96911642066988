import { axios } from 'utils'
import { constants } from 'resources'

export default async function begin(questionnaireName) {
  try {
    const params = {
      questionnaireName,
    }
    const { data } = await axios.post(constants.API_BEGIN_QUESTIONNAIRE, params)
    return data
  } catch (e) { console.log({ e }) }
}
