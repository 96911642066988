import React from 'react'
import PropTypes from 'prop-types'

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFingerprint } from '@fortawesome/pro-solid-svg-icons'

import Seprator from '../shared/Seprator'
import { colors, fonts } from 'common/assets/sharedUI'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 656,
    backgroundColor: colors.gray_01,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius * 6 + 1, //25px
    boxShadow: '0px 3px 6px #00000029',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },

  lightText: {
    fontFamily: fonts.Swiss721LT,
    color: colors.gray_06,
  },

  mediumText: {
    fontFamily: fonts.Swiss721MD,
    color: colors.gray_06,
  },

  heavyText: {
    fontFamily: fonts.Swiss721HY,
    color: colors.gray_06,
  },

  servingText: {
    fontSize: 18,
  },

  cardContainer: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },

  legendIcon: {
    minWidth: '37px',
  },
  legendText: {
    color: colors.gray_06,
  },

  servingSize: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  servingSizeText: {
    fontSize: 20,
    lineHeight: 1.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },

  servingAmount: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '30px',
  },
}))

/**
 * Nutrition Card component.
 *
 * @param {Object} props - The component's props.
 * @property {React.ReactNode} props.children - The content to be displayed within the card.
 * @returns {JSX.Element} - React element representing the Nutrition Card.
 */
const NeutritionCard = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}

NeutritionCard.propType = {
  children: PropTypes.node.isRequired,
}

/**
 * Nutrition Card Header component.
 *
 * @param {Object} props - The component's props.
 * @property {React.ReactNode} props.children - The content to be displayed in the header.
 * @returns {JSX.Element} - React element representing the Nutrition Card Header.
 */
const NeutritionCardHeader = ({ children }) => {
  return <div>{children}</div>
}

NeutritionCardHeader.propType = {
  children: PropTypes.node.isRequired,
}

/**
 * Nutrition Card Legend component.
 *
 * @param {object} props - The component's props.
 * @param {node} props.icon - The icon to display in the legend.
 * @param {string} props.text - The text associated with the legend.
 * @returns {JSX.Element} - React element representing the Nutrition Card Legend.
 */
const NeutritionCardLegend = ({ icon, text }) => {
  const classes = useStyles()

  return (
    <ListItem disableGutters>
      <ListItemIcon className={classes.legendIcon}>{icon}</ListItemIcon>
      <ListItemText primary={text} className={classes.legendText} />
    </ListItem>
  )
}

NeutritionCardLegend.propType = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}

/**
 * Nutrition Card Container component.
 *
 * @param {Object} props - The component's props.
 * @property {React.ReactNode} props.children - The content to be displayed within the container.
 * @returns {JSX.Element} - React element representing the Nutrition Card Container.
 */
const NeutritionCardContainer = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.cardContainer}>{children}</div>
}

NeutritionCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

/**
 * Nutrition Card Footer component.
 *
 * @param {Object} props - The component's props.
 * @property {React.ReactNode} props.children - The content to be displayed within the footer.
 * @returns {JSX.Element} - React element representing the Nutrition Card Footer.
 */
const NeutritionCardFooter = ({ children }) => {
  return <div>{children}</div>
}

NeutritionCardFooter.propTypes = {
  children: PropTypes.node.isRequired,
}

/////

const NeutritionServing = ({
  servingSize,
  servingsPerContainer,
  servingSizeScoop,
}) => {
  const classes = useStyles()

  return (
    <div>
      <Typography
        variant='subtitle1'
        className={[classes.lightText, classes.servingText]}
      >{`${servingsPerContainer} servings per container`}</Typography>

      <div className={classes.servingSize}>
        <Typography
          variant='h6'
          className={[classes.mediumText, classes.servingSizeText]}
        >
          Serving Size
        </Typography>
        <Typography
          variant='h6'
          className={[classes.mediumText, classes.servingSizeText]}
        >{`${servingSizeScoop}(${servingSize}g)`}</Typography>
      </div>

      <Seprator thickness={18} color='#707070' />

      <Typography
        variant='subtitle1'
        className={[classes.lightText, classes.servingText]}
      >
        Amount per serving
      </Typography>
    </div>
  )
}

NeutritionServing.propTypes = {
  servingSize: PropTypes.string.isRequired,
  servingsPerContainer: PropTypes.number.isRequired,
  servingSizeScoop: PropTypes.string.isRequired,
}

/**
 * NeutritionFactsList is a component for rendering a nested list based on dynamic data.
 *
 * @component
 * @param {Object[]} data - An array of objects representing the data to be rendered.
 * Each object should have a `title` property for the main item text and an optional
 * `children` property, which is an array of nested items.
 * @returns {JSX.Element} The JSX element representing the nested list.
 * @example
 * // Example data structure
 * const data = [
 *   {
 *     title: 'Parent 1',
 *     children: [
 *       { title: 'Child 1' },
 *       { title: 'Child 2' },
 *     ],
 *   },
 *   {
 *     title: 'Parent 2',
 *     children: [
 *       { title: 'Child 3' },
 *     ],
 *   },
 * ];
 *
 * // Usage of the NestedList component
 * <NestedList data={data} />
 */

const NeutritionFactsList = ({ title = '', data = [] }) => {
  const classes = useStyles()
  const theme = useTheme()

  const renderNestedItems = (items, level = 0) => {
    return items.map((item, index) => {
  
      return (
        <div key={`${index}_${item.title}`}>
          <ListItem button>
            <ListItemIcon className={classes.legendIcon}>
              <FontAwesomeIcon
                icon={faFingerprint}
                size='xl'
                color={colors.blue_03}
              />
            </ListItemIcon>

            <div style={{ paddingLeft: theme.spacing(3 * level) }}>
              <ListItemText primary={item.title} color='textPrimary' />
            </div>

            <ListItemSecondaryAction>
              <Typography variant='body1' component='h6'>
                2.3%
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>

          <Seprator color='#070707' />

          {item.children && item.children.length > 0 && (
            <List component='div' dense disablePadding>
              {renderNestedItems(item.children, level + 1)}
            </List>
          )}
        </div>
      )
    })
  }

  return (
    <>
      <div className={classes.servingAmount}>
        <Typography variant='h5'>Calories</Typography>
        <Typography variant='h5'>160</Typography>
      </div>
      <List component='nav' dense={true}>
        {renderNestedItems(data)}
      </List>
    </>
  )
}

NeutritionFactsList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
}

NeutritionCard.Header = NeutritionCardHeader
NeutritionCard.Legend = NeutritionCardLegend
NeutritionCard.Container = NeutritionCardContainer
NeutritionCard.Footer = NeutritionCardFooter
NeutritionCard.Serving = NeutritionServing
NeutritionCard.FactsList = NeutritionFactsList

export default NeutritionCard
