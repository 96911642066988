import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles((theme) =>
  createStyles({
    banner: {
      backgroundColor: colors.green_07,
    },
    icon: {
      '--fa-primary-color': colors.white,
      '--fa-secondary-color': colors.white,
    },
  })
);

export default useStyles;
