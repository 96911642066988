import * as React from 'react';
import {
  Route, Redirect,
} from 'react-router-dom';
import { get } from 'lodash';

import { storage } from 'utils';
import WebAppContainer from 'components/WebAppContainer';

export default ({
  component: Component, layout: Layout = WebAppContainer, navProps, additionalProps, ...props
}) => {
  const token = storage.getAccessToken();

  const url = get(props, 'computedMatch.url', '/');

  return (
    <Route
      {...props}
      render={renderProps => (token ? (
        <Layout navProps={navProps}>
          <Component {...additionalProps} {...renderProps} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: {
              from: url,
            },
          }}
        />
      ))}
    />
  );
};
