import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import styles from './IndividualNutrients.styles';

const IndividualNutrients = (props) => {
  const {
    classes,
    portionLabel,
    data,
  } = props;

  return (
    <Table aria-label="Nutrients" className={classes.individualNutrientValues}>
      { portionLabel
        && (
          <TableHead>
            <TableRow>
              <TableCell className={classes.nutrientColumn}>{portionLabel}</TableCell>
              <TableCell className={classes.unitColumn}></TableCell>
              <TableCell className={classes.dailyValueColumn}>DV</TableCell>
              <TableCell className={classes.goodMatchColumn} align="center">Good Match</TableCell>
            </TableRow>
          </TableHead>
        )}
      <TableBody>
        {data.map((row) => (
          <TableRow
            key={row.name}
          >
            <TableCell className={classes.nutrientColumn}>
              {row.name}
            </TableCell>
            <TableCell className={classes.unitColumn}>
              {row.amount}
              <span>
                {' '}
                {row.unit}
              </span>
            </TableCell>
            <TableCell className={classes.dailyValueColumn}>
              {row.dailyValuePercentage}
              {' '}
              <span>%</span>
            </TableCell>
            <TableCell className={classes.goodMatchColumn} align="center">{row.goodMatch ? (<FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />) : '' }</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

IndividualNutrients.propTypes = {
  classes: PropTypes.shape({
    individualNutrientValues: PropTypes.string,
    checkIcon: PropTypes.string,
    nutrientColumn: PropTypes.string,
    nutrientColumnHeader: PropTypes.string,
    unitColumn: PropTypes.string,
    dailyValueColumn: PropTypes.string,
    goodMatchColumn: PropTypes.string,
  }).isRequired,
  portionLabel: PropTypes.string,
  data: PropTypes.any.isRequired,
};

export default withStyles(styles)(IndividualNutrients);
