import { axios } from 'utils'
import { constants } from 'resources'

export default async function getFoodScores(foodIds) {
  try {
    const params = { foodIds }
    //console.log(JSON.stringify(params))
    return axios.post(constants.API_GET_FOOD_SCORES, params)
  } catch (e) {
    // console.log({ e })
    return null
  }
}
