import { user } from '../../services';
import { setTopFoods } from './topFoods';
import types from '../actionTypes';

const getPreferencesStarted = () => ({ type: types.GET_PREFERENCES_STARTED });
const getPreferencesSuccess = preferences => ({ type: types.GET_PREFERENCES_SUCCESS, preferences });
const getPreferencesError = error => ({ type: types.GET_PREFERENCES_ERROR, error });

export const getPreferences = () => dispatch => {
  dispatch(getPreferencesStarted());
  user.getPreferences()
    .then(response => {
      dispatch(getPreferencesSuccess(response));
    })
    .catch(error => {
      dispatch(getPreferencesError(error));
    });
};

const setPreferenceStarted = () => ({ type: types.SET_PREFERENCE_STARTED });
const setPreferenceSuccess = preferences => ({ type: types.SET_PREFERENCE_SUCCESS, preferences });
const setPreferenceError = error => ({ type: types.SET_PREFERENCE_ERROR, error });

export const setPreference = (id, enabled) => async (dispatch) => {
  dispatch(setPreferenceStarted());
  user.setPreference(id, enabled)
    .then(response => {
      const { dietaryPreferences, topFoods } = response.data;
      dispatch(setPreferenceSuccess(dietaryPreferences));
      dispatch(setTopFoods(topFoods));
    })
    .catch(error => {
      dispatch(setPreferenceError(error));
    });
};
