import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeCartError } from '../store/actions/additionalProducts';

// Spinner and error modal
const CartSpinner = () => {
  const dispatch = useDispatch();

  const { spinnerOpen, errorOpen, errorMessage } = useSelector(state => state.additionalProducts.cart);

  const closeErrorDialog = useCallback(() => dispatch(closeCartError()), [dispatch]);

  if (errorOpen) {
    return (
      <Dialog open={errorOpen} onClose={closeErrorDialog}>
        <DialogTitle>An Error Occurred</DialogTitle>
        <DialogContent>{errorMessage || 'An unknown error occurred'}</DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (spinnerOpen) {
    return (
      <div style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: '#00000040',
        zIndex: 1500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <CircularProgress />
      </div>
    );
  }

  return null;
};

export default CartSpinner;
