import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { cloneDeep } from 'lodash'
import {
  RadioGroup, Grid, FormControlLabel, Paper, Typography, IconButton, Icon,
} from '@material-ui/core'
import { getIcon } from 'utils/dataTools'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CustomRadio from 'components/CustomRadio'
import PropTypes from 'prop-types'
import useStyles from './SingleChoiceForm.styles'

export default function SingleChoiceForm(props) {
  const {
    id,
    questionGroupId,
    questionText,
    values,
    errors,
    handleChange,
    setFieldValue,
    handleBlur,
    choices,
    disabled,
  } = props
  const classes = useStyles()
  const theme = useTheme()
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))

  const createMarkup = (children) => ({
    __html: children,
  })

  // Generate components for the selected choices
  const generateChoices = (selectedChoices) => selectedChoices.map((choice) => {
    if (choice.imageLocation) {
      return (
        <Grid item xs={3} md={2} className={classes.iconContainer} key={choice.id}>
          <IconButton onClick={() => setFieldValue(`radioGroup_${id}`, choice.choiceText)}>
            <Icon className={questionText.includes('diet') ? classes.icon : classes.preferencesIcon}>
              <img src={getIcon(choice.imageLocation)} alt="" className={classes.allergyImg} />
            </Icon>
            {
                values[`radioGroup_${id}`] && values[`radioGroup_${id}`].includes(choice.choiceText) ? (
                  choice.overlayLocation === 'CHECK_CIRCLE' ? (
                    <CheckCircleOutlineIcon className={classes.overlayIcon} />
                  ) : (
                    <BlockIcon className={classes.overlayBlockIcon} />
                  )
                ) : null
              }
          </IconButton>
          <span className={classes.allergyTitle}>{choice.choiceText}</span>
        </Grid>
      )
    }
      const radioProps = {
        checked: values[`radioGroup_${id}`] === choice.choiceText,
        value: choice.choiceText,
        label: (
          <div>
            <Typography variant="body1">
              <span className={classes.documentContainer} dangerouslySetInnerHTML={createMarkup(choice.choiceText)} />
            </Typography>
            {choice.choiceSubtext ? (
              <Typography variant="body1" className={classes.subtext}>{choice.choiceSubtext}</Typography>
            ) : null}
          </div>
        ),
        onChange: handleChange,
        onBlur: handleBlur,
        disabled,
      }

      return (
        <Paper className={errors[`radioGroup_${id}`] ? classes.errorPaper : (values[`radioGroup_${id}`] === choice.choiceText ? classes.selectedPaper : classes.paper)} key={choice.id}>
          <FormControlLabel {...radioProps} control={<CustomRadio />} />
        </Paper>
      )
    })

  // Dynamically separate items in columns
  const generateStylesForChoices = () => {
    const hasImage = choices.find((choice) => choice.imageLocation)

    if (hasImage) {
      return (
        <Grid container item xs={12} className={classes.iconChoiceContainer} justify={choices.length < 6 ? 'space-between' : 'center'}>
          {generateChoices(choices)}
        </Grid>
      )
    }

    const gridArr = []
    const colNum = choices.length === 2 && questionGroupId ? 1 : (xsDown ? 0 : parseInt(choices.length / 6, 10))
    const itemNum = choices.length === 2 && questionGroupId ? 1 : Math.round(choices.length / (colNum + 1))

    for (let i = 0; i < colNum + 1; i += 1) {
      const tempChoices = cloneDeep(choices)
      const selectedChoices = tempChoices.splice(itemNum * i, itemNum)
      gridArr.push(
        // xs={colNum === 0 ? 12 : 6}
        <Grid container item xs={12} className={classes.choiceContainer} key={i}>
          {generateChoices(selectedChoices)}
        </Grid>,
      )
    }

    return (
      <Grid container>
        {gridArr}
      </Grid>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.questionContainer}>
        {questionGroupId ? (
          <Typography variant="body1">{questionText}</Typography>
        ) : null}
        <RadioGroup name={`radioGroup_${id}`}>
          {generateStylesForChoices()}
          {errors[`radioGroup_${id}`] && <Typography variant="body1" className={classes.errorTxt}>{errors[`radioGroup_${id}`]}</Typography>}
        </RadioGroup>
      </Grid>
    </Grid>
  )
}

SingleChoiceForm.propTypes = {
  id: PropTypes.number.isRequired,
  questionGroupId: PropTypes.number,
  questionText: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  choices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    choiceText: PropTypes.string,
    choiceSubtext: PropTypes.string,
    imageLocation: PropTypes.string,
    overlayLocation: PropTypes.string,
    isNoneChoice: PropTypes.bool,
  })),
}

SingleChoiceForm.defaultProps = {
  questionText: '',
  questionGroupId: null,
  values: {},
  errors: {},
  choices: [],
  disabled: false,
}
