import React, {
  useEffect, useCallback, useMemo, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from './DialogTitle';
import PropTypes from 'prop-types';
import {
  getSubscriptionQuestionnaire,
} from 'store/actions/subscription';
import {
  Loading,
} from 'components/componentsV2';
import MultiSelectQuestion from './MultiSelectQuestion';
import { sharedStyles } from '../../resources';

const QuestionnaireDialog = ({
  open, handleClose, handleSave,
}) => {
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const { questionnaire, questionnaireFetching } = useSelector(state => ({
    questionnaire: state.subscription.questionnaire.data,
    questionnaireFetching: state.subscription.questionnaire.fetching,
  }));

  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (open) {
      dispatch(getSubscriptionQuestionnaire());
    }
  }, [open, dispatch]);

  const isFormComplete = useMemo(() => {
    if (questionnaire && !questionnaireFetching && answers.length === questionnaire.question.length) {
      return true;
    }
    return false;
  }, [questionnaire, questionnaireFetching, answers]);

  const onSaveClicked = useCallback(() => {
    handleSave(answers);
  }, [handleSave, answers]);

  const updateAnswer = useCallback((choiceId, questionId) => {
    const existingAnswerIndex = answers.findIndex(answer => answer.questionId === questionId);
    if (existingAnswerIndex !== -1) {
      setAnswers(answers.map(answer => {
        if (answer.questionId === questionId) {
          return { questionId, questionChoiceId: parseInt(choiceId, 10) };
        }
        return answer;
      }));
    } else {
      setAnswers([...answers, { questionId, questionChoiceId: parseInt(choiceId, 10) }]);
    }
  }, [answers]);

  const getSelectedAnswer = useCallback((questionId) => {
    const selectedAnswer = answers.find(answer => answer.questionId === questionId);
    return selectedAnswer ? selectedAnswer.questionChoiceId : null;
  }, [answers]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="subscription-questionnaire-dialog"
      fullWidth
      maxWidth="md"
    >
      {(!questionnaire || questionnaireFetching) ? (
        <DialogContent>
          <DialogTitle onClose={handleClose}/>
          <Loading />
        </DialogContent>
      ) : (
        <React.Fragment>
          <DialogContent
            dividers="paper"
            style={{ height: 400 }}
            >
            <DialogTitle onClose={handleClose}/>
            {questionnaire.question.map(question => (
              <MultiSelectQuestion
                questionText={question.questionText}
                questionId={question.id}
                choices={question.questionChoices}
                updateAnswer={(e) => updateAnswer(e.target.value, question.id)}
                selectedAnswer={getSelectedAnswer(question.id)}
                key={question.id}
                style={{ marginBottom: 20 }}
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button className={sharedClasses.rebrandedSecondaryButton} onClick={onSaveClicked}>
              Skip
            </Button>
            <Button
              className={sharedClasses.rebrandedPrimaryButton}
              onClick={onSaveClicked}
              disabled={!isFormComplete}
            >
              Continue
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

QuestionnaireDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default QuestionnaireDialog;
