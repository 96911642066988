import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import { get, assign } from 'lodash';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  getYupObject, getInitialValues,
} from 'utils/questionnaire';
import { addConsent } from 'store/actions/consent';
import {
  Grid, Typography, Button,
} from '@material-ui/core';
import { sharedStyles } from 'resources';
import SingleChoiceForm from './SingleChoiceForm';
import BioBankingResearchWarningModal from './BioBankingResearchWarningModal';
import useStyles from './BioBankingResearchConsent.styles';
import {
  registerGAClickEventEnhanced,
  EventCategories,
  EventActions,
  EventLabels,
} from '../utils/googleanalytics';

const BioBankingResearchConsent = (props) => {
  const {
    isBioBank, contracts, edit,
  } = props;
  const sharedClasses = sharedStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openBioBankWarningModal, setOpenBioBankWarningModal] = React.useState(false);
  const [openResearchWarningModal, setOpenResearchWarningModal] = React.useState(false);
  const history = useHistory();

  const createMarkup = (children) => ({
    __html: children,
  });

  const yupObjectArray = {};
  const initialValues = {};

  const choices = [{
    id: 1,
    choiceText: 'I have read this document and <b>I give consent</b>',
    bool: true,
  }, {
    id: 2,
    choiceText: 'I have read this document and <b>I do not give consent</b>',
    bool: false,
  }];

  const formArray = [];
  if (contracts) {
    contracts.forEach(contract => {
      const formItem = {
        id: get(contract, 'contract.id', 0),
        active: true,
        answer: null,
        mandatory: true,
        questionChoices: choices,
        singleAnswer: true,
        questionTypeId: 1,
      };
      const answer = {
        id: get(contract, 'contract.id', 0),
        answerChoices: [{
          questionChoiceId: contract.optedIn ? 1 : 2,
          answerId: get(contract, 'contract.id', 0),
        }],
      };

      formArray.push((contract.optedIn !== null && contract.optedIn !== undefined) ? { ...formItem, answer } : formItem);
    });
  }

  formArray.forEach(formItem => {
    assign(yupObjectArray, getYupObject(formItem));
    assign(initialValues, getInitialValues(formItem));
  });

  const yupSchema = Yup.object().shape(yupObjectArray);

  // Check if user was previously opted in and is now opting out
  const isOptingOut = (currentSelection, wasOptedIn) => {
    const wasOptedInOrUndefined = (wasOptedIn === undefined || wasOptedIn === null ? true : wasOptedIn); // Check if previous selection does not exist
    const selection = currentSelection || '';
    return (selection.includes('do not') && wasOptedInOrUndefined); // This is fragile but the "values" property only includes the text strings, not the true/false value
  };

  const onSubmit = (values) => {
    // Dynamically generate consents array
    const consents = contracts.map(contract => {
      const radioValue = get(values, `radioGroup_${contract.contract.id}`, 0);
      const optIn = choices.find(choice => choice.choiceText === radioValue);
      return {
        contractualAgreementId: contract.contract.id,
        optIn: get(optIn, 'bool', null),
      };
    });
    const params = {
      consents,
    };
    dispatch(addConsent(params));
    if (edit) history.push('/account');
  };

  const researchIdx = contracts.findIndex(contract => contract.name === 'Research');
  const bioBankIdx = contracts.length > 1 ? contracts.findIndex(contract => contract.name === 'BioBanking') : 0;

  return (
    <Grid container className={classes.container} style={{ marginTop: '-65px' }}>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.title} id={isBioBank ? 'researchAndBiobankingHeader' : 'genoPalateResearchHeader'}>{isBioBank ? 'Research and Biobanking' : 'GenoPalate Research'}</Typography>
        <div className={classes.documentContainer} dangerouslySetInnerHTML={createMarkup(get(contracts, `[${bioBankIdx}].contract.document`, ''))} />
        <Grid container item xs={12} className={classes.radioContainer}>
          <Formik
            initialValues={initialValues}
            validationSchema={yupSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formikProps) => {
              const {
                values,
                errors,
                handleChange,
                setFieldValue,
                handleSubmit,
                handleBlur,
                isValid,
                dirty,
                isSubmitting,
              } = formikProps;

              const canOptInToBioBanking = get(contracts, `[${bioBankIdx}].canOptIn`, true);
              const canOptInToResearch = get(contracts, `[${researchIdx}].canOptIn`, true);
              const optedInToBioBanking = get(contracts, `[${bioBankIdx}].optedIn`, true);
              const optedInToResearch = get(contracts, `[${researchIdx}].optedIn`, true);
              const previousBioBankAgreementDate = get(contracts, `[${bioBankIdx}].consentDate`, '');
              const previousResearchAgreementDate = get(contracts, `[${researchIdx}].consentDate`, '');

              const researchProgramProps = {
                id: get(contracts, `[${researchIdx}].contract.id`, ''),
                values,
                errors,
                handleChange,
                setFieldValue,
                handleBlur,
                choices,
                disabled: !canOptInToResearch && !optedInToResearch,
              };

              const biobankingProps = {
                id: get(contracts, `[${bioBankIdx}].contract.id`, ''),
                values,
                errors,
                handleChange,
                setFieldValue,
                handleBlur,
                choices,
                disabled: !canOptInToBioBanking,
              };

              const shouldSubmit = () => !(isOptingOut(values.radioGroup_2, optedInToBioBanking) || (isOptingOut(values.radioGroup_1, optedInToResearch) && !canOptInToResearch));
              const triggerOptOutModal = (setModalFunc) => {
                setModalFunc(true);
                registerGAClickEventEnhanced(
                  EventCategories.AccountConsent,
                  EventActions.Click,
                  EventLabels.Submit,
                ); // todo: differentiate between opt out and opt in
              };

              const switchToResearchModal = () => {
                setOpenBioBankWarningModal(false);
                setOpenResearchWarningModal(true);
              };

              return (
                <React.Fragment>
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <Grid item xs={12} sm={11} md={10} lg={9}>
                      <Typography variant="body1" className={classes.bold}>GenoPalate Research Program</Typography>
                      <SingleChoiceForm {...researchProgramProps} />
                      {!canOptInToResearch && !optedInToResearch ? (
                        <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
                          {`You chose to opt-out of research for the 2nd time on  ${previousResearchAgreementDate}. 
                        This means you cannot opt back in.`}
                        </Typography>
                      ) : null}
                      {isBioBank ? (
                        <React.Fragment>
                          <Typography variant="body1" className={classes.bold}>Biobanking</Typography>
                          <SingleChoiceForm {...biobankingProps} />
                          {!canOptInToBioBanking ? (
                            <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
                              {`You chose to opt-out of biobanking on ${previousBioBankAgreementDate}. 
                            This means you have chosen to destroy your sample and therefore cannot opt back in.`}
                            </Typography>
                          ) : null}
                        </React.Fragment>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} className={classes.btnContainer}>
                      {shouldSubmit() ? (
                        <Button
                          className={sharedClasses.primaryButton}
                          disabled={!(isValid && dirty) || isSubmitting}
                          type="submit"
                          onClick={() => registerGAClickEventEnhanced(EventCategories.AccountConsent, EventActions.Click, EventLabels.Submit)}
                        >
                          {edit ? 'Submit' : 'Next'}
                        </Button>
                      ) : (
                        <Button
                          className={sharedClasses.primaryButton}
                          disabled={edit ? false : !(isValid && dirty)}
                          onClick={isOptingOut(values.radioGroup_2, optedInToBioBanking)
                            ? () => triggerOptOutModal(setOpenBioBankWarningModal)
                            : () => triggerOptOutModal(setOpenResearchWarningModal)}
                        >
                          {edit ? 'Submit' : 'Next'}
                        </Button>
                      )}
                    </Grid>
                    <BioBankingResearchWarningModal
                      open={openBioBankWarningModal}
                      onClose={() => setOpenBioBankWarningModal(false)}
                      dialogTitle="Are you sure?"
                      dialogContent={(
                        <p>
                          Once you&nbsp;
                          <b>opt-out</b>
                          &nbsp;of biobanking, your sample will be destroyed and you&nbsp;
                          <b>will not be able to opt back in</b>
                          .&nbsp;Please confirm that you want to&nbsp;
                          <b>opt-out</b>
                          &nbsp;of biobanking.
                        </p>
                      )}
                      controlLabel="I confirm that I want to opt-out of the biobanking program"
                      onSubmit={
                        (isOptingOut(values.radioGroup_1, optedInToResearch) && !canOptInToResearch)
                          ? () => switchToResearchModal()
                          : handleSubmit
                      }
                    />
                    <BioBankingResearchWarningModal
                      open={openResearchWarningModal}
                      onClose={() => setOpenResearchWarningModal(false)}
                      dialogTitle="Are you sure?"
                      dialogContent={(
                        <p>
                          This will be the 2nd time you have chosen to&nbsp;
                          <b>opt-out</b>
                          &nbsp;of the GenoPalate research program. Completing this action will mean you&nbsp;
                          <b>will not be able to opt back in</b>
                          .
                          Please confirm that you want to&nbsp;
                          <b>opt-out</b>
                          &nbsp;of the research program.
                        </p>
                      )}
                      controlLabel="I confirm that I want to opt-out of the research program"
                      onSubmit={handleSubmit}
                    />
                  </form>
                </React.Fragment>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BioBankingResearchConsent;

BioBankingResearchConsent.propTypes = {
  isBioBank: PropTypes.bool.isRequired,
  contracts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    document: PropTypes.string,
  })),
  edit: PropTypes.bool,
};

BioBankingResearchConsent.defaultProps = {
  contracts: {},
  edit: false,
  bioBankOptedOut: false,
};
