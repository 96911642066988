import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import useStyles from './TimeForm.styles';

export default function TimeForm(props) {
  const classes = useStyles();
  const {
    id,
    questionText,
    values,
    errors,
    setFieldValue,
  } = props;

  const timeProps = {
    name: `time_${id}`,
    label: '',
    value: values[`time_${id}`],
    onChange: value => setFieldValue(`time_${id}`, value),
    error: !!errors[`time_${id}`],
    helperText: errors[`time_${id}`] && errors[`time_${id}`],
    margin: 'normal',
    inputVariant: 'outlined',
    size: 'small',
  };

  return (
    <Grid container>
      <Grid item xs={6} className={classes.labelContainer}>
        <Typography variant="body1">{questionText}</Typography>
      </Grid>
      <Grid item xs={6} className={classes.questionContainer}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TimePicker {...timeProps} />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
}

TimeForm.propTypes = {
  id: PropTypes.number.isRequired,
  questionText: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  setFieldValue: PropTypes.func.isRequired,
};

TimeForm.defaultProps = {
  questionText: '',
  values: {},
  errors: {},
};
