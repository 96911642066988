import React, { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { sharedStyles } from 'resources';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Typography, Button } from '@material-ui/core';
import useStyles from './ProductCard.styles';

export default function ProductCard(props) {
  const {
    product, onAddToCartClicked, onLearnMoreClicked,
  } = props;

  const {
    image, name, price, inCart,
  } = (product || {});
  const sharedClasses = sharedStyles();
  const classes = useStyles();

  const addToCart = useCallback(() => onAddToCartClicked(product), [onAddToCartClicked, product]);
  const learnMore = useCallback(() => onLearnMoreClicked(product), [onLearnMoreClicked, product]);

  if (!product) return null;

  return (
    <div className={classes.productCard}>
      <div className={classes.productDetails}>
        <img src={image} alt={name} style={{ height: 145 }} />
        <Typography className={classes.productTitle}>{name}</Typography>
        <Typography className={classes.productPrice}>
          {`$${price}`}
        </Typography>
        <Button onClick={learnMore} className={clsx(classes.productLearnMore)}>
          <Typography style={{ marginRight: 5 }}>
            Learn more
          </Typography>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </div>
      <Button name="addToCartBtn" className={clsx(sharedClasses.primaryButton, classes.button)} onClick={addToCart} disabled={inCart}>
        <FontAwesomeIcon icon={inCart ? faCheck : faPlus} className={classes.plusIcon} />
        { inCart ? 'IN CART' : 'ADD TO CART' }
      </Button>
    </div>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    image: PropTypes.string,
    price: PropTypes.string,
    title: PropTypes.string,
    inCart: PropTypes.bool,
  }).isRequired,
  onAddToCartClicked: PropTypes.func.isRequired,
  onLearnMoreClicked: PropTypes.func.isRequired,
};
