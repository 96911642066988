import { colors } from 'common/assets/sharedUI';

const styles = theme => ({
  pageWrapper: {
    paddingTop: 40,
  },
  backLink: {
    color: colors.lightBlue,
    paddingLeft: 20,
    cursor: 'pointer',
  },
  backLinkText: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  pageDescription: {
    fontSize: 16,
    paddingLeft: 20,
  },
  settingsWrapper: {
    border: `solid 1px ${colors.gray_03}`,
    borderRadius: '6px',
    background: 'white',
    width: 350,
    marginLeft: 20,
  },
  icon: {
    fontSize: '2.5rem',
    marginLeft: 3,
    marginRight: 8,
  },
  img: {
    height: '100%',
    width: '100%',
    padding: 7,
  },
});

export default styles;
