/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import AccountInput from './AccountInput';

const AccountFormikInput = ({ name, submitCount, ...props }) => (
  <Field name={name}>
    {({ field, meta }) => (
      <AccountInput
        error={(meta.error && (meta.touched || submitCount > 0)) && meta.error}
        {...field}
        {...props}
      />
    )}
  </Field>
);

AccountFormikInput.propTypes = {
  name: PropTypes.string,
  submitCount: PropTypes.number,
};

AccountFormikInput.defaultProps = {
  name: '',
  submitCount: 0,
};

export default AccountFormikInput;
