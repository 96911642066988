import React from 'react'
import PropTypes from 'prop-types'

import { Typography, createStyles, makeStyles } from '@material-ui/core'
import { colors, fonts } from 'common/assets/sharedUI'

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      fontSize: 16,
      fontFamily: fonts.Swiss721MD,
      color: colors.gray_06,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  })
)

/**
 * React component for rendering recommendation text with dynamic color based on the text content.
 * @module RecommendationText
 * @param {Object} props - The component props.
 * @param {string} props.text - The recommendation text to display.
 * @returns {JSX.Element} - The rendered component.
 */
const RecommendationText = ({ text = '' }) => {
  const classes = useStyles()

  const textColor = {
    high: colors.darkestGreen,
    'moderately high': colors.darkestGreen,
    low: colors.red,
    'moderately low': colors.red,
    normal: colors.gray_06,
  }

  const getTitleColor = (text) => textColor[text.toLowerCase()] || colors.gray_06

  const titleColor = getTitleColor(text)

  return (
    <Typography
      variant='subtitle1'
      className={classes.text}
      style={{ color: titleColor }}
    >
      {text}
    </Typography>
  )
}

export default RecommendationText

/**
 * Prop types for the RecommendationText component.
 * @memberof RecommendationText
 * @type {Object}
 * @property {string} text - The recommendation text to display.
 */
RecommendationText.propTypes = {
  text: PropTypes.string.isRequired,
}
