import { createStyles, makeStyles } from '@material-ui/core'
import { colors, fontFamily, fonts } from 'common/assets/sharedUI'

export const useStyles = makeStyles((theme) => createStyles({
    select: {
    '&:focus': {
        backgroundColor: 'transparent',
    },
    },
    dropDownPicker: {
        borderWidth: 1,
        border: `1px solid ${colors.gray_03}`,
        fontSize: 14
    },
    dropDownItem: {
        color: colors.green_07,
        fontSize: 14,
        fontFamily: fonts.Swiss721MD
    },

    error: {
        '&.MuiFormHelperText-root.Mui-error': {
          color: colors.red,
        },
      },

    placeholder: {
        color: colors.gray_07,
        fontFamily: fonts.Swiss721MD
    }  
}))