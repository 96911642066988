import React from 'react';
import PropTypes from 'prop-types';

import { Container, Box, createStyles, makeStyles } from '@material-ui/core';

import { colors } from 'common/assets/sharedUI';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    banner: {
      width: '100%',
      zIndex: 999,
      height: 50,
      backgroundColor: colors.bannerColor,
      [theme.breakpoints.down('sm')]: {
        zIndex: 999,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },

    wrapper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0 2.5vw 0 2.5vw',
      [theme.breakpoints.down('sm')]: {
       display: 'block',
        marginTop: 5
      },
    },
  }),
);
const Banner = ({ children, bannerStyle, style }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.banner, bannerStyle)} style={style}>
      <Container maxWidth='lg' className={classes.wrapper}>
        {children}
      </Container>
    </Box>
  );
};

Banner.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Banner;
