import { axios } from 'utils';
import { constants } from 'resources';

export default async function setTermsAgreement() {
  try {
    return axios.post(constants.API_SET_TERMS_AGREEMENT);
  } catch (e) {
    return null
  }
}
