import get from 'lodash/get';
import {
  throwIfErrorResponse, localStorageCartKey, parseCartResponse,
} from './utils';

const createCart = (shopifyQuery) => async (email, uuid) => {
  const newCartResp = await shopifyQuery(
    `mutation cartCreateMutation($cartInput: CartInput!) {
      cartCreate(input: $cartInput) {
        cart {
          id
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
    {
      cartInput: {
        buyerIdentity: {
          email: email || undefined, // null or an empty string will cause an error, but Shopify will ignore undefined
        },
        note: 'In App Purchase',
        attributes: [
          {
            key: 'uuidCode',
            value: uuid,
          },
        ],
      },
    },
  );

  throwIfErrorResponse(newCartResp);

  const newCartId = get(newCartResp, 'data.data.cartCreate.cart.id');
  localStorage.setItem(localStorageCartKey, newCartId);

  return parseCartResponse(get(newCartResp, 'data.data.cartCreate.cart', {}));
};

export default createCart;
