import React from 'react';
import clsx from 'clsx';
import {
  Grid, Box, IconButton, Collapse, Container, Button,
} from '@material-ui/core';
import {
  useHistory,
} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';
import { sharedStyles, images } from 'resources';
import useStyles from './SupplementBanner.styles';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';

type Props = {
  openBanner: any,
  onClose: any,
  url: String
}

const SupplementBanner = (props) => {
  const { openBanner, onClose, url } = props;
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const sharedClasses = sharedStyles();
  const classes = useStyles();
  const history = useHistory();

  const clickHandler = () => {
    registerGAClickEventEnhanced(EventCategories.SupplementBanner, EventActions.Open, EventLabels.SupplementBanner);
    onClose();
    history.push(url);
  };

  const closeHandler = () => {
    registerGAClickEventEnhanced(EventCategories.SupplementBanner, EventActions.Close, EventLabels.SupplementBanner);
    onClose();
  };

  return (
    <Collapse in={openBanner}>
      <Box pr={2} pl={5} bgcolor={colors.chartYellow} color={colors.black} className={classes.banner}>
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item xs={12} className={classes.bannerContainer}>
            <Container className={classes.gridContainer} maxWidth="lg">
              <div className={classes.callToActionContainer}>
                <div className={classes.callToAction}>
                  <img src={images.supplementBottle} className={classes.bannerImage} alt="Supplement bottle" />
                  <span className={classes.bannerTxt} name="bannerTxt">
                    New! Personalized Supplements
                  </span>
                  <Button
                    variant="outlined"
                    className={clsx(sharedClasses.secondaryButton, classes.learnMoreButton)}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={clickHandler}
                    size={smDown ? 'small' : 'medium'}
                  >
                    {('View Your Formula').toUpperCase()}
                  </Button>
                </div>
                <IconButton id="SupplementBannerCloseBtn" aria-label="close" onClick={closeHandler} className={classes.closeButton}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
};

export default SupplementBanner;
