import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Button, Card, CardActions, Collapse, Icon, IconButton, Tooltip,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy, faEnvelopeSquare, faExclamation, faPrint,
} from '@fortawesome/pro-solid-svg-icons';
import {
  isAndroid,
  isIOS,
  isIOS13,
} from 'react-device-detect';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'common/assets/sharedUI';
import styles from './MobileFoodExportInstructions.styles';
import { registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels } from '../utils/googleanalytics';

function MobileFoodExportInstructions(props) {
  const {
    classes, copyToClipboard, id,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const mobileCopyClicked = useCallback((e) => {
    copyToClipboard(e, id);
    setOpen(true);
    setTimeout(() => { setOpen(false); }, 2000);
    registerGAClickEventEnhanced(EventCategories.OptimalFoods, EventActions.Copy, EventLabels.BrowserFoodExportInstructions);
  }, [id, copyToClipboard, setOpen]);

  return (
    <div className={classes.noprint}>
      <Card className={classes.tipsAndTricks}>
        <CardContent className={classes.cardcontent}>
          <Typography variant="h6">Export List Frequent Actions</Typography>
          <Tooltip open={open} title="Copied!" placement="right" leaveDelay={1000} leaveTouchDelay={1000}>
            <Button
              className={classes.primaryButton}
              startIcon={(
                <Icon>
                  <FontAwesomeIcon icon={faCopy} />
                </Icon>
              )}
              onClick={mobileCopyClicked}
            >
              COPY
            </Button>
          </Tooltip>
        </CardContent>
        <CardActions className={classes.cardcontent}>
          <Typography variant="h6">Export List Tips & Tricks</Typography>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            edge="end"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={classes.cardcontent}>
            <div className={classes.tipText}>
              <Typography paragraph>If the buttons above does not work, review the manual instructions below.</Typography>
              <div className={classes.iconAndText}>
                <Icon className={classes.circle}>
                  <FontAwesomeIcon icon={faPrint} color={colors.white} />
                </Icon>
                &nbsp;
                <strong>Print</strong>
              </div>
              {(isIOS || isIOS13)
                ? (
                  <React.Fragment>
                    <p>On iOS</p>
                    <p>Click the Export button in the upper right corner of the browser. Choose print from the list of actions.</p>
                  </React.Fragment>
                ) : ''}
              {isAndroid ? (
                <React.Fragment>
                  <p>On Android</p>
                  <p>
                    Hold down where you would like to start copying text. The text should highlight in blue.
                    Then, drag the blue pins to highlight all the text you would like to copy and then hit &quot;share&quot;.
                  </p>
                </React.Fragment>
              ) : ''}

            </div>
            <div className={classes.tipText}>
              <div className={classes.iconAndText}>
                <Icon className={classes.circle}>
                  <FontAwesomeIcon icon={faCopy} color={colors.white} />
                </Icon>
                &nbsp;
                <strong>Copy & Paste</strong>
              </div>
              {(isIOS || isIOS13)
                ? (
                  <React.Fragment>
                    <p>On iOS</p>
                    <p>
                      Hold down where you would like to start copying text. The text should highlight in blue.
                      Then, drag the blue pins to highlight all of the text you would like to copy and then click &quot;share&quot;. Then choose the application you wish to share.
                    </p>
                  </React.Fragment>
                ) : ''}
              {isAndroid ? (
                <React.Fragment>
                  <p>On Android</p>
                  <p>
                    Hold down where you would like to start copying text. The text should highlight in blue.
                    Then, drag the blue pins to highlight all the text you would like to copy and then click &quot;share&quot;.
                  </p>
                </React.Fragment>
              ) : ''}
            </div>
            <div className={classes.tipText}>
              <div className={classes.iconAndText}>
                <Icon className={classes.circle}>
                  <FontAwesomeIcon icon={faEnvelopeSquare} color={colors.white} />
                </Icon>
                &nbsp;
                <strong>Email</strong>
              </div>
              <p>Email this list by copy & pasting the text into email client. Or using the Copy & Paste directions above and choosing the email client from the list of options.</p>
            </div>
            <div className={classes.tipText}>
              <div className={classes.iconAndText}>
                <Icon className={classes.circle}>
                  <FontAwesomeIcon icon={faExclamation} color={colors.white} />
                </Icon>
                &nbsp;
                <strong> Don&apos;t need all these foods?</strong>
              </div>
              <p>Customize your list of foods shown here by using the SETTINGS button in the GenoPalate app.</p>
            </div>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}

MobileFoodExportInstructions.propTypes = {
  classes: PropTypes.shape({
    tipsAndTricks: PropTypes.string,
    primaryButton: PropTypes.string,
    expand: PropTypes.string,
    expandOpen: PropTypes.string,
    container: PropTypes.string,
    tipText: PropTypes.string,
    iconAndText: PropTypes.string,
    circle: PropTypes.string,
    noprint: PropTypes.string,
    cardcontent: PropTypes.string,
  }).isRequired,
  copyToClipboard: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default withRouter(withStyles(styles)(MobileFoodExportInstructions));
