import { combineReducers } from 'redux';
import { get } from 'lodash';
import types from '../actionTypes';
import getStatusReducer from './statusReducer';

function errorReducer(state = null, action) {
  switch (action.type) {
    case types.GET_EER_ERROR:
      return action.payload;
    case types.GET_EER_SUCCESS:
      return null;
    default:
      return state;
  }
}

function eerReducer(state = null, action) {
  switch (action.type) {
    case types.GET_EER_SUCCESS:
      return get(action, 'payload', null);
    case types.GET_PROGRESS_ERROR:
      return null;
    default:
      return state;
  }
}

const actionTypes = {
  pending: types.GET_EER_STARTED,
  resolved: types.GET_EER_SUCCESS,
  rejected: types.GET_EER_ERROR,
};

export default combineReducers({
  status: getStatusReducer(actionTypes),
  eer: eerReducer,
  error: errorReducer,
});
