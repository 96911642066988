import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, Grid,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldPlus } from '@fortawesome/pro-solid-svg-icons';
import {
  Loading,
} from 'components/componentsV2';
import { colors, fonts } from 'common/assets/sharedUI';
import { sharedStyles } from '../../resources';
import helpers from '../../utils/helpers';

const styles = (theme) => ({
  horizontalRule: {
    marginTop: 24,
    marginBottom: 24,
    borderTop: `1px solid ${colors.gray_03}`,
  },
  formulaWrapper: {
    width: '100%',
    padding: 32,
    borderRadius: 16,
    border: `1px solid ${colors.gray_03}`,
    backgroundColor: colors.gray_01,
    marginBottom: 80,
  },
  columnHeader: {
    textTransform: 'uppercase',
    fontWeight: 800,
    fontFamily: fonts.subHeading,
    color: colors.gray_06,
    fontSize: 14,
    lineHeight: '16px',
  },
  leftCol: {
    width: '100%',
  },
  rightCol: {
    width: 178,
    textAlign: 'right',
    marginRight: 64,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  rowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 12,
  },
  ingredient: {
    fontFamily: fonts.subHeading,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
  },
  subHeader: {
    fontFamily: fonts.subHeading,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '16px',
  },
  icon: {
    margin: 4,
    fontSize: 16,
    lineHeight: '150%',
    color: colors.green_03,
    verticalAlign: 'middle',
  },
});

const IngredientRow = ({
  nutrient, dosage, provenance, classes,
}) => (
  <div className={classes.rowWrapper}>
    <div className={classes.leftCol}>
      <div style={{ display: 'flex' }}>
        {provenance && (
          <FontAwesomeIcon icon={faShieldPlus} className={classes.icon} />
        )}
        <Typography className={classes.ingredient} style={{ paddingLeft: !provenance && 24 }}>{nutrient}</Typography>
      </div>
      {provenance && (<Typography className={classes.subHeader} style={{ paddingLeft: 24 }}>{provenance}</Typography>)}
    </div>
    <Typography className={clsx(classes.rightCol, classes.ingredient)}>{dosage}</Typography>
  </div>
);

IngredientRow.propTypes = {
  nutrient: PropTypes.string.isRequired,
  dosage: PropTypes.string.isRequired,
  provenance: PropTypes.string,
  classes: PropTypes.shape({
    rowWrapper: PropTypes.string,
    leftCol: PropTypes.string,
    rightCol: PropTypes.string,
    ingredient: PropTypes.string,
    icon: PropTypes.string,
    subHeader: PropTypes.string,
  }).isRequired,
};

IngredientRow.defaultProps = {
  provenance: '',
};

const CustomFormula = ({ boosterFormulas, classes, isExampleFormula }) => {
  const { userProfile } = useSelector((state) => state.profile);
  const { data: formulation } = useSelector((state) => state.subscription.formulation);
  const sharedClasses = sharedStyles();

  const name = useMemo(() => (get(userProfile, 'data.name', '')), [userProfile]);

  const combinedFormulation = useMemo(() => {
    const baseFormulation = formulation !== null ? formulation : [];
    return [...baseFormulation, ...boosterFormulas];
  }, [formulation, boosterFormulas]);

  const sortedFormulation = useMemo(() => (combinedFormulation
    ? [...combinedFormulation].sort((a, b) => {
      if (Object.prototype.hasOwnProperty.call(a, 'provenance')) {
        return Object.prototype.hasOwnProperty.call(b, 'provenance') ? a.nutrient.localeCompare(b.nutrient) : -1;
      }
      return Object.prototype.hasOwnProperty.call(b, 'provenance') ? 1 : a.nutrient.localeCompare(b.nutrient);
    })
    : []), [combinedFormulation]);

  const formulaDescriptor = isExampleFormula ? 'Example' : 'Custom';

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={sharedClasses.h5} style={{ color: colors.blue_05 }}>
          {`${name ? `${helpers.possessive(name)} ` : 'Your '}${formulaDescriptor} Formula`}
        </Typography>
      </Grid>
      <Grid item xs={12}><hr className={classes.horizontalRule} /></Grid>
      <Grid item xs={12}>
        <div className={classes.formulaWrapper}>
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={12} md={6}>
                <div className={classes.rowWrapper}>
                  <Typography className={clsx(classes.columnHeader, classes.leftCol)}>Ingredient</Typography>
                  <Typography className={clsx(classes.columnHeader, classes.rightCol)}>Dosage</Typography>
                </div>
              </Grid>
            </Grid>
            {
              sortedFormulation.length > 0 ? sortedFormulation.map(ingredient => (
                <Grid item xs={12} md={6} key={`${ingredient.id}-${ingredient.amount}`}>
                  <IngredientRow
                    nutrient={ingredient.formType ? `${ingredient.nutrient} (${ingredient.formType})` : ingredient.nutrient}
                    dosage={`${ingredient.amount} ${ingredient.uom}`}
                    provenance={ingredient.provenance}
                    classes={classes}
                  />
                </Grid>
              ))
                : <Loading />
            }
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

CustomFormula.propTypes = {
  boosterFormulas: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    amount: PropTypes.number,
    uom: PropTypes.string,
    nutrient: PropTypes.string,
  })).isRequired,
  classes: PropTypes.shape({
    horizontalRule: PropTypes.string,
    formulaWrapper: PropTypes.string,
    columnHeader: PropTypes.string,
    leftCol: PropTypes.string,
    rightCol: PropTypes.string,
    rowWrapper: PropTypes.string,
  }).isRequired,
  isExampleFormula: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CustomFormula);
