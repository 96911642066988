import React from 'react';
import { useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Typography, ButtonBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';
import { images, sharedStyles } from 'resources';
import PaperContainer from '../components/PaperContainer';

const styles = theme => ({
  wrapperContainer: {
    padding: '40px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0',
    },
  },
  header: {
    color: colors.primaryBlue,
    fontSize: 22,
  },
  subHeader: {
    fontWeight: 700,
  },
  image: {
    maxWidth: 250,
    marginTop: '-25px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
    },
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  groupOrientationWrapper: {
    marginTop: 40,
  },
  appStore: {
    width: 120,
    height: 40,
  },
  appStoreButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
  },
  appStoreButton: {
    margin: '0 10px',
  },
  getAppWrapper: {
    padding: 20,
    marginTop: 20,
    backgroundColor: colors.gray_02,
    [theme.breakpoints.down('xs')]: {
      position: 'inherit',
      bottom: 0,
      left: 0,
    },
    width: '100%',
  },
  getAppHeaderText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
});

const ReportInProgressPage = props => {
  const { classes, isKit } = props;
  const sharedClasses = sharedStyles();

  // User data
  const profile = useSelector(state => state.profile.userProfile);
  const { hasConsult } = profile && profile.data;

  const displayOrientation = hasConsult && !isKit ? 'block' : 'none';

  const messageHeader = 'That\'s it! Thanks for getting things rolling!';

  const message = () => {
    if (isKit) {
      return `Once you receive your kit and send back your sample, we'll notify you as soon as it arrives at our lab. We'll also send an email letting you know when your analysis is complete.
      ${hasConsult ? ' At that time, you\'ll be able to view your results and schedule one-on-one sessions with our registered dietitians.' : ''}`;
    }
    return `Good news—your GenoPalate analysis is underway! You will receive an email within 24 hours when your results are ready.
      ${hasConsult ? ' At that time, you\'ll be able to view your analysis and schedule one-on-one sessions with our registered dietitians.' : ''}`;
  };

  const messageFooter = 'Cheers to starting your personalized nutrition journey!';

  const groupOrientationMessage = 'Sign up for a live, 45-minute group orientation with a GenoPalate Registered Dietitian '
  + 'that covers how to review and interpret your analysis and how you can put it into action. '
    + 'There\'s an opportunity at the end for a question-and-answer session. \n ';
  const groupOrientationSubmessage = 'This orientation will help build the foundation for your one-on-one session(s), so we highly encourage you to attend.';

  return (
    <PaperContainer style={{ marginTop: '-65px', padding: 0 }}>
      <div className={classes.wrapperContainer}>
        <img src={images.whatToExpectNext} className={classes.image} alt="What to expect illustration" />
        <Typography className={classes.header}>{messageHeader}</Typography>
        <Typography style={{ marginBottom: 10 }}>{message()}</Typography>
        <Typography style={{ marginBottom: 10 }}>{messageFooter}</Typography>
        <div className={classes.groupOrientationWrapper} style={{ display: displayOrientation }}>
          <Typography className={classes.subHeader}>Group Orientation Session</Typography>
          <Typography>{groupOrientationMessage}</Typography>
          <Typography style={{ marginBottom: 10 }}>{groupOrientationSubmessage}</Typography>
          <ButtonBase className={`${sharedClasses.secondaryButton} ${classes.button}`} href="https://calendly.com/results-group-consultation" target="_blank">SIGN UP FOR OUR GROUP ORIENTATION</ButtonBase>
        </div>
        <div className={classes.getAppWrapper}>
          <Typography className={classes.getAppHeaderText}>Get the app while you wait</Typography>
          <Typography>Our app makes it easy to get the most out of your results.</Typography>
          <div className={classes.appStoreButtonWrapper}>
            <a href="https://apps.apple.com/us/app/genopalate/id1468074158" target="_blank" rel="noopener noreferrer" className={classes.appStoreButton}>
              <img
                src={images.appStore}
                className={classes.appStore}
                alt="Download the GenoPalate app from the app store"
                target="_blank"
                rel="noopener"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.genopalate.mobileapp"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.appStoreButton}
            >
              <img src={images.googlePlay} className={classes.appStore} alt="Download the GenoPalate app from the play store" />
            </a>
          </div>
        </div>
      </div>
    </PaperContainer>
  );
};

ReportInProgressPage.propTypes = {
  classes: PropTypes.shape({
    wrapperContainer: PropTypes.string,
    image: PropTypes.string,
    header: PropTypes.string,
    subHeader: PropTypes.string,
    button: PropTypes.string,
    groupOrientationWrapper: PropTypes.string,
    getAppWrapper: PropTypes.string,
    appStoreButtonWrapper: PropTypes.string,
    appStoreButton: PropTypes.string,
    getAppHeaderText: PropTypes.string,
    appStore: PropTypes.string,
  }).isRequired,
  isKit: PropTypes.bool.isRequired,
};

export default connect(state => ({
  isKit: get(state, 'profile.userProfile.data.isKitUser', false),
}), null)(withStyles(styles)(ReportInProgressPage));
