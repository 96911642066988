import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
})

export default useStyles
