/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { colors, fonts } from 'common/assets/sharedUI';
import {
  ProductTypes, PanelResultStatuses, panelNames, supplementProductNames, subscriptionStatuses,
  internalURLs,
  supplementProductSkus,
  blendSKUs,
  productNames,
} from 'resources/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrowsers, faMobileAlt, faMoneyCheckEdit } from '@fortawesome/pro-solid-svg-icons';
import helpers, { getProductName } from 'utils/helpers';
import { usePurchasedProducts, useSubscriptions } from '../hooks';
import { appConstants } from 'utils/constants/appConstants';

// todo: remove the hard-coded product names from this component where possible

const styles = {
  productNameText: {
    fontWeight: '600',
    fontFamily: fonts.heading3,
    fontSize: '20px',
    lineHeight: '24px',
  },
  panelDescriptionText: {
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '700',
    top: '-1px',
    position: 'relative',
    fontFamily: fonts.paragraph2,
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: colors.subtextGrey,
  },
  manageSubscriptionText: {
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: '700',
    top: '-1px',
    position: 'relative',
    fontFamily: fonts.paragraph2,
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    color: colors.blue_04,
    paddingRight: '6px',
  },
  productDetailsText: {
    fontFamily: fonts.paragraph2,
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.darkerGrey,
  },
  supplementBoosterText: {
    fontFamily: fonts.paragraph2,
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.gray_04,
  },
  productNameLine: {
    width: '45%',
    borderBottom: '1px',
    borderColor: colors.foodDetailsGrey,
    opacity: '10%',
    position: 'absolute',
  },
  productSeparatorLine: {
    width: '80%',
    borderBottom: '1px',
    borderColor: colors.foodDetailsGrey,
    opacity: '10%',
    position: 'absolute',
  },
  productImage: {
    width: '120px',
    height: '120px',
    objectFit: 'cover'
  },
  topPadding: {
    paddingTop: '25px',
  },
  bottomPadding: {
    paddingBottom: '30px',
  },
  bottomImgPadding: {
    paddingBottom: '10px',
  },
};

const Action = ({
  // eslint-disable-next-line react/prop-types
  classes, productType, mobileOnly, recipeQuestionnaireCompletedDate, qcStatus, productName,
}) => {
  if (productType === ProductTypes.Panel) {
    if (qcStatus === PanelResultStatuses.QCFailed) {
      return (
        <span className={classes.productDetailsText}>
          {`There was an issue with your ${productName}, our Customer service team will reach out to you shortly.`}
        </span>
      );
    } if (mobileOnly) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <FontAwesomeIcon icon={faMobileAlt} style={{ color: colors.gray_05, paddingRight: '6px' }} sx={{ fontSize: 16 }} />
            <span className={classes.panelDescriptionText}>
              Only Available in Mobile App
            </span>
          </Grid>
        </Grid>
      );
    } if (productName === panelNames.ESSENTIAL_NUTRITION) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <FontAwesomeIcon icon={faBrowsers} style={{ color: colors.gray_05, paddingRight: '6px' }} sx={{ fontSize: 16 }} />
            <span className={classes.panelDescriptionText}>
              Available in Mobile & Web App
            </span>
          </Grid>
        </Grid>
      );
    }
  } if (productType === ProductTypes.Recipe) {
    return recipeQuestionnaireCompletedDate
      ? (
        <Grid container>
          <Grid item xs={12} lg={12}>
            <span className={classes.productDetailsText}>
              {`You completed the recipe questionnaire on ${format(parseISO(recipeQuestionnaireCompletedDate), 'MM/dd/yy')}. If you'd like to update your food allergies or which foods you'd like to exclude from your recipes, please email `}
              <a href="mailto:hello@genopalate.com">hello@genopalate.com.</a>
            </span>
          </Grid>
        </Grid>
      )
      : (
        <Link to="/" className={classes.secondaryButton} style={{ display: 'inline-block', textAlign: 'center' }}>COMPLETE RECIPE QUESTIONNAIRE</Link>
      );
  }
  return null;
};

const SeparatorLine = ({ classes }) => (
  <Grid container>
    <Grid item xs={12}>
      <div className={classes.topPadding} />
      <hr className={classes.productSeparatorLine} />
      <div className={classes.bottomPadding} />
    </Grid>
  </Grid>
);

SeparatorLine.propTypes = {
  classes: PropTypes.shape({
    productNameText: PropTypes.string,
    panelDescriptionText: PropTypes.string,
    productDetailsText: PropTypes.string,
    supplementBoosterText: PropTypes.string,
    productNameLine: PropTypes.string,
    productSeparatorLine: PropTypes.string,
    topPadding: PropTypes.string,
    bottomPadding: PropTypes.string,
    bottomImgPadding: PropTypes.string,
  }),
};

SeparatorLine.defaultProps = {
  classes: null,
};

const NameSeparatorLine = ({ classes }) => (
  <Grid item xs={10}>
    <hr className={classes.productNameLine} />
    <div className={classes.bottomPadding} />
  </Grid>
);

NameSeparatorLine.propTypes = {
  classes: PropTypes.shape({
    productNameText: PropTypes.string,
    panelDescriptionText: PropTypes.string,
    productDetailsText: PropTypes.string,
    supplementBoosterText: PropTypes.string,
    productNameLine: PropTypes.string,
    productSeparatorLine: PropTypes.string,
    topPadding: PropTypes.string,
    bottomPadding: PropTypes.string,
    bottomImgPadding: PropTypes.string,
  }),
};

NameSeparatorLine.defaultProps = {
  classes: null,
};

const PurchasedProducts = ({ classes }) => {
  const { purchasedProducts, purchasedProductsError } = usePurchasedProducts();
  const { subscriptions, subscriptionsError } = useSubscriptions();

  // Filters out inactive/canceled/expired subscriptions and removes Probiotic from the list of boosters.
  const displayedSubscriptions = useMemo(() => (subscriptions && subscriptions
    .filter(subscription => [
      subscriptionStatuses.Active, subscriptionStatuses.Paused, subscriptionStatuses.Pending,
    ].includes(subscription.status))
    .map((sub) => ({ ...sub, subscriptionItems: sub.subscriptionItems.filter((item) => item !== 'Probiotic') }))
  ), [subscriptions]);

  if (purchasedProductsError || subscriptionsError) {
    return (
      <div style={{ maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}>
        <Typography variant="h5">{`An error occurred while loading your ${purchasedProductsError ? 'purchased products' : 'subscriptions'}. Please refresh the page and try again.`}</Typography>
      </div>
    );
  }

  if (!purchasedProducts || !purchasedProducts.products) return null;

  const handleProductDetailsHref = (type) => {
    if(type === appConstants.genoBlend) {
      return internalURLs.BLEND_PRODUCT_DETAILS
    }
    return internalURLs.SUPPLEMENT_PRODUCT_DETAILS
  }

  return (
    <Grid
      container
    >
      {/* Supplement Subscription Section */}
      {displayedSubscriptions && displayedSubscriptions
        .map(({
          subscriptionItems: supplementBoosters, nextOrderDate, subscriptionNumber, subscriptionName, productImage, productSku, productType
        }) => (
          <Grid
            container
            key={subscriptionName + subscriptionNumber}
          >
            <Grid item xs={12} md={2}>
              <img className={classes.productImage} src={productImage} alt={subscriptionName} />
              <div className={classes.bottomImgPadding} />
            </Grid>
            <Grid item xs={12} md={10}>
              <Grid container>
                <Grid item xs={12}>
                  <span className={classes.productNameText}>{getProductName(productSku)}</span>
                </Grid>
                <Grid item xs={12}>
                  {productSku === supplementProductSkus[productSku] && (supplementBoosters
                    && supplementBoosters.length
                    && supplementBoosters[0] !== null ? (
                      <div style={{ paddingTop: '8px' }}>
                        <span className={classes.productDetailsText}>Added Boosters</span>
                        <ul>
                          {supplementBoosters.sort().map((boosterName) => (
                            <li key={boosterName} className={classes.supplementBoosterText}>{boosterName}</li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <div style={{ paddingTop: '8px' }}>
                        <span className={classes.productDetailsText}>No Added Boosters</span>
                      </div>
                    ))}
                </Grid>
                <NameSeparatorLine classes={classes} />
                <Grid item xs={12}>
                  <FontAwesomeIcon icon={faMoneyCheckEdit} style={{ color: colors.blue_04, paddingRight: '6px' }} sx={{ fontSize: 16 }} />
                  <a className={classes.manageSubscriptionText} href={handleProductDetailsHref(productType)}>
                    Manage Subscription
                  </a>
                  <span className={classes.supplementBoosterText}>
                    {`Renews ${helpers.formatDateString(nextOrderDate, 'long').replace(',', '')}`}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <SeparatorLine classes={classes} />
          </Grid>
        ))}
      {/* Purchased Product Section */}
      {purchasedProducts.products
        .filter((purchasedProduct) => !Object.values({...supplementProductSkus, ...blendSKUs}).some((supplementProductSku) => supplementProductSku === purchasedProduct.productSku))
        .map(({
          productSku, productName, productType, orderDate, mobileOnly, qcStatus, productImage,
        }) => (
          <Grid
            container
            key={productSku + orderDate}
          >
            <Grid item xs={12} md={2}>
              <img className={classes.productImage} src={productImage} alt={productName} />
              <div className={classes.bottomImgPadding} />
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container>
                <Grid item xs={10}>
                  <span className={classes.productNameText}>{productName}</span>
                </Grid>
                <NameSeparatorLine classes={classes} />
                <Grid item xs={12}>
                  <Action
                    classes={classes}
                    productType={productType}
                    mobileOnly={mobileOnly}
                    recipeQuestionnaireCompletedDate={purchasedProducts.recipeQuestionnaireCompletedDate}
                    qcStatus={qcStatus}
                    productName={productName}
                  />
                </Grid>
              </Grid>
            </Grid>
            <SeparatorLine classes={classes} />
          </Grid>
        ))}
    </Grid>
  );
};

PurchasedProducts.propTypes = {
  classes: PropTypes.shape({
    productNameText: PropTypes.string,
    panelDescriptionText: PropTypes.string,
    productDetailsText: PropTypes.string,
    supplementBoosterText: PropTypes.string,
    productNameLine: PropTypes.string,
    productSeparatorLine: PropTypes.string,
    topPadding: PropTypes.string,
    bottomPadding: PropTypes.string,
    bottomImgPadding: PropTypes.string,
  }),
};

PurchasedProducts.defaultProps = {
  classes: null,
};

export default withStyles(styles)(PurchasedProducts);
