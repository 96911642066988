import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faTrophy,
} from '@fortawesome/pro-solid-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const styles = theme => ({
  foodCard: {
    width: 350,
    marginBottom: 10,
    padding: '10px 20px 10px 10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 10,
      marginBottom: 0,
    },
    background: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  foodCardTrophyContainer: {
    // backgroundImage: `-webkit-linear-gradient(310deg, ${colors.green} 35%, ${colors.transparent} 35%)`,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: 'none',
      backgroundColor: colors.white,
      position: 'static',
    },
    width: 0,
    height: 50,
    // padding: 5,
    left: -10,
    top: -10,
  },
  foodCardTrophyTriangle: {
    borderRight: '40px solid transparent',
    borderLeft: `40px solid ${colors.green}`,
    borderBottom: '40px solid transparent',
    height: 0,
    width: 0,
    position: 'absolute',
    left: '0px',
    zIndex: '2',
  },
  foodCardTrophy: {
    color: colors.white,
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      color: colors.green,
      backgroundImage: 'none',
      backgroundColor: colors.white,
      marginRight: 10,
    },
    [theme.breakpoints.up('sm')]: {
      zIndex: '3',
      position: 'absolute',
      left: '5px',
      top: '5px',
    },
  },
  foodName: {
    fontSize: 18,
    margin: 0,
    flex: 3,
  },
  foodWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 3,
  },
  foodArrow: {
    fontSize: 16,
    color: colors.lightBlue,
    flex: 0,
  },
  foodImage: {
    height: 50,
    paddingRight: 5,
    paddingLeft: 10,
  },
});

const FoodListItem = (props) => {
  const {
    foodGroup, food, handleClick, classes, isXsScreen,
  } = props;

  if (isXsScreen) {
    return (
      <div
        role="link"
        className={classes.foodCard}
        onClick={() => handleClick(food.id, `/food/${food.id}`, foodGroup.name, food.name)}
        key={food.id}
      >
        <div className={classes.foodWrapper}>
          {food.trophy
            && (
              <div className={classes.foodCardTrophy}>
                <FontAwesomeIcon icon={faTrophy} />
              </div>
            )}
          <p className={classes.foodName}>{food.name}</p>
        </div>
        <FontAwesomeIcon icon={faArrowRight} className={classes.foodArrow} />
      </div>
    );
  }

  return (
    <div
      role="link"
      className={classes.foodCard}
      onClick={() => handleClick(food.id, `/food/${food.id}`, foodGroup.name, food.name)}
      key={food.id}
    >
      {food.trophy
        && (
          <div className={classes.foodCardTrophyContainer}>
            <div className={classes.foodCardTrophyTriangle} />
            <FontAwesomeIcon icon={faTrophy} className={classes.foodCardTrophy} />
          </div>
        )}
      <img
        className={classes.foodImage}
        src={`https://geno-icons.s3.amazonaws.com/${food.fileName}`}
        alt={`${food.name}`}
      />
      <p className={classes.foodName}>{food.name}</p>
      <FontAwesomeIcon icon={faArrowRight} className={classes.foodArrow} />
    </div>
  );
};

FoodListItem.propTypes = {
  foodGroup: PropTypes.object.isRequired,
  food: PropTypes.object.isRequired,
  isXsScreen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    foodCard: PropTypes.string,
    foodCardTrophyContainer: PropTypes.string,
    foodCardTrophy: PropTypes.string,
    foodCardTrophyTriangle: PropTypes.string,
    foodWrapper: PropTypes.string,
    foodName: PropTypes.string,
    foodArrow: PropTypes.string,
    foodImage: PropTypes.string,
    relativePositioningPlaceholder: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(FoodListItem);
