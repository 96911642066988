import React from 'react';
import { get, round } from 'lodash';
import { Typography } from '@material-ui/core';
import LinearProgressBar from 'components/LinearProgressBar';
import { useSelector } from 'react-redux';
import useStyles from './ProgressBar.styles';

export default function ProgressBar() {
  const classes = useStyles();
  const progress = useSelector(state => state.questionnaire.progress);

  // Divide answer_count by question_count, multiply by 100, and round to zero decimal places to get the percent
  const percent = round(
    parseInt(get(progress, 'progress.answer_count', 0), 10)
    / parseInt(get(progress, 'progress.question_count', 1), 10) * 100, 0,
  );

  return (
    <div className={classes.root} id="progressBar">
      <Typography variant="body1" className={classes.text} gutterBottom>
        {`${percent}% Completed`}
      </Typography>
      <LinearProgressBar variant="determinate" value={percent} />
    </div>
  );
}
