import { combineReducers } from 'redux'
import { get } from 'lodash'
import types from '../actionTypes'
import getStatusReducer from './statusReducer'
import { apiStatuses } from '../../resources/constants'

function errorReducer(state = null, action) {
  switch (action.type) {
    case types.BEGIN_QUESTIONNAIRE_ERROR:
    case types.FORWARD_QUESTIONNAIRE_ERROR:
    case types.BACK_QUESTIONNAIRE_ERROR:
    case types.SAVE_QUESTIONNAIRE_ERROR:
    case types.GET_PROGRESS_ERROR:
      return action.payload
    case types.BEGIN_QUESTIONNAIRE_SUCCESS:
    case types.FORWARD_QUESTIONNAIRE_SUCCESS:
    case types.BACK_QUESTIONNAIRE_SUCCESS:
    case types.SAVE_QUESTIONNAIRE_SUCCESS:
    case types.GET_PROGRESS_SUCCESS:
      return null
    default:
      return state
  }
}

function questionReducer(state = [], action) {
  switch (action.type) {
    case types.BEGIN_QUESTIONNAIRE_SUCCESS:
    case types.FORWARD_QUESTIONNAIRE_SUCCESS:
    case types.BACK_QUESTIONNAIRE_SUCCESS:
      return get(action, 'payload.question', [])
    case types.BEGIN_QUESTIONNAIRE_ERROR:
    case types.FORWARD_QUESTIONNAIRE_ERROR:
    case types.BACK_QUESTIONNAIRE_ERROR:
      return []
    default:
      return state
  }
}

function questionnaireIdReducer(state = -1, action) {
  switch (action.type) {
    case types.BEGIN_QUESTIONNAIRE_SUCCESS:
      return get(action, 'payload.questionnaireId', -1)
    case types.BEGIN_QUESTIONNAIRE_ERROR:
      return -1
    default:
      return state
  }
}

const questionnaireProgressReducer = (state = false, action) => {
  switch (action.type) {
    case types.BEGIN_QUESTIONNAIRE_SUCCESS:
      return action.payload.trackProgress
    default:
      return state
  }
}

function currentQuestionReducer(state = 0, action) {
  switch (action.type) {
    case types.BEGIN_QUESTIONNAIRE_SUCCESS:
    case types.FORWARD_QUESTIONNAIRE_SUCCESS:
    case types.BACK_QUESTIONNAIRE_SUCCESS:
      return get(action, 'payload.question[0].id', 0)
    case types.BEGIN_QUESTIONNAIRE_ERROR:
    case types.FORWARD_QUESTIONNAIRE_ERROR:
    case types.BACK_QUESTIONNAIRE_ERROR:
      return 0
    default:
      return state
  }
}

function questionStatusReducer(state = apiStatuses.IDLE, action) {
  switch (action.type) {
    case types.FORWARD_QUESTIONNAIRE_STARTED:
    case types.BACK_QUESTIONNAIRE_STARTED:
      return apiStatuses.PENDING
    case types.FORWARD_QUESTIONNAIRE_SUCCESS:
    case types.BACK_QUESTIONNAIRE_SUCCESS:
      return apiStatuses.RESOLVED
    case types.FORWARD_QUESTIONNAIRE_ERROR:
    case types.BACK_QUESTIONNAIRE_ERROR:
      return apiStatuses.REJECTED
    default:
      return state
  }
}

function questionnaireCompleteReducer(state = false, action) {
  switch (action.type) {
    case types.BEGIN_QUESTIONNAIRE_SUCCESS:
    case types.FORWARD_QUESTIONNAIRE_SUCCESS:
      return get(action, 'payload.questionnaireComplete', false)
    case types.FORWARD_QUESTIONNAIRE_ERROR:
      return false
    default:
      return state
  }
}

function saveReducer(state = null, action) {
  switch (action.type) {
    case types.SAVE_QUESTIONNAIRE_SUCCESS:
      return get(action, 'payload', null)
    case types.SAVE_QUESTIONNAIRE_ERROR:
      return null
    default:
      return state
  }
}

function progressReducer(state = null, action) {
  switch (action.type) {
    case types.GET_PROGRESS_SUCCESS:
      return get(action, 'payload', null)
    case types.GET_PROGRESS_ERROR:
      return null
    default:
      return state
  }
}

const beginActionTypes = {
  pending: types.BEGIN_QUESTIONNAIRE_STARTED,
  resolved: types.BEGIN_QUESTIONNAIRE_SUCCESS,
  rejected: types.BEGIN_QUESTIONNAIRE_ERROR,
}

const saveActionTypes = {
  pending: types.SAVE_QUESTIONNAIRE_STARTED,
  resolved: types.SAVE_QUESTIONNAIRE_SUCCESS,
  rejected: types.SAVE_QUESTIONNAIRE_ERROR,
}

const progressActionTypes = {
  pending: types.GET_PROGRESS_STARTED,
  resolved: types.GET_PROGRESS_SUCCESS,
  rejected: types.GET_PROGRESS_ERROR,
}

export default combineReducers({
  // Handles begin, next and back API calls
  status: questionStatusReducer,
  beginStatus: getStatusReducer(beginActionTypes),
  question: questionReducer,
  questionnaireId: questionnaireIdReducer,
  trackProgress: questionnaireProgressReducer,
  currentQuestionId: currentQuestionReducer,
  // Handles save API call
  saveStatus: getStatusReducer(saveActionTypes),
  saveResult: saveReducer,
  // Handles progress bar
  progressStatus: getStatusReducer(progressActionTypes),
  progress: progressReducer,
  // Handles questionnaire completion
  questionnaireComplete: questionnaireCompleteReducer,
  // Handles all errors
  error: errorReducer,
})
