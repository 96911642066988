// @flow
import React, { PureComponent } from 'react'
import { Layout, Menu, Icon } from 'antd'
import { images } from 'resources'
import { withRouter } from 'react-router-dom'
import logout from '../../flows/logout'

import styles from './styles.css'

const { Header, Sider, Content } = Layout

type Props = {};
class DashboardContainer extends PureComponent<Props> {
  handleClick = (e) => {
    const { history } = this.props
    console.log('DashboardContainer -> handleClick -> history', history)
    switch (e.key) {
      case 'logout':
        logout()
        break

      case 'home':
        history.push('/')
        break

      case 'account':
        history.push('/account/')
        break

      default:
        break
    }
  }

  render() {
    return (
      <Layout style={{ height: '100%' }}>
        <Header>
          <a href="https://genopalate.com/">
            <img src={images.logo} styleName="logo" />
          </a>
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={['2']}
            style={{ lineHeight: '100px', float: 'right' }}
            onClick={this.handleClick}
          >
            <Menu.Item key="home">
              <Icon type="home" />
              HOME
            </Menu.Item>
            <Menu.Item key="account">
              <Icon type="user" />
              ACCOUNT
            </Menu.Item>
            <Menu.Item key="logout">
              <Icon type="logout" />
              LOGOUT
            </Menu.Item>
          </Menu>
        </Header>
        <Content {...this.props} />
      </Layout>
    )
  }
}

export default withRouter(DashboardContainer)
