import React from 'react'
import useStyles from './StatusMessage.styles'

export default function InputBarcode(props) {
  const { message } = props
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <h3>
          {message}
        </h3>
      </div>
    </div>
  )
}
