import { axios } from 'utils';
import { constants } from 'resources';

export default async function getSubscriptions() {
  try {
    const response = await axios.get(constants.API_GET_SUBSCRIPTIONS);
    return response.data.subscriptions;
  } catch (e) {
    return null;
  }
}
