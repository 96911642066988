import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors, fonts } from 'common/assets/sharedUI';

/* remove unused classNames */
const useStyles = makeStyles((theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  
  wrapper: {
    width: '100%',
    maxWidth: 550,
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    margin: 'auto', // this center the card
    borderRadius: theme.shape.borderRadius * 2, // 4 * 2 = 8
    boxShadow: '0px 3px 6px #00000029',

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  userName: {
    fontFamily: fonts.Swiss721Roman,
  },

  title: {
    fontFamily: fonts.BaskervilleBold,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.6rem'
    }
  },

  listItem: {
    padding: 0,
    cursor: 'pointer'
  },

  legendIcon: {
    minWidth: '23px',
  },

  servingAmount: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '30px',
    padding: '10px 0px',
  },

  inline: {
    display: 'inline',
  },

  text: {
    fontSize: 16,
    fontFamily: fonts.Swiss721MD,
    color: colors.gray_06,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    },
  },

  heavyText: {
    fontFamily: fonts.Swiss721HY,
  },

  impactedGenetic: {
    color: colors.blue_03,
  },

  nutritionHeader: {
    textAlign: 'right',
    fontSize: 18,
    fontFamily: fonts.Swiss721LT,
    color: colors.gray_06,
    margin: '5px 0px 5px 5px',
  },

  nested: {
    paddingLeft: theme.spacing(4),
  },

  blueText: {
    color: colors.blue_03,
  },

  upSellCard: {
    marginTop: 80,
    marginBottom: 80,
    marginLeft: 40,
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 550,
      margin: 'auto',
      padding: 10
    },
  },
  pageBreak: {
    pageBreakBefore: 'always',
  },
  printOnly: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  }
}));

export default useStyles;
