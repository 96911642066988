import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

/**
 * A custom React hook for working with query parameters using react-router-dom.
 *
 * @property {URLSearchParams} queryParams - The current query parameters object.
 * @property {function} getQueryParam - A function to get the value of a specific query parameter.
 * @property {function} setQueryParam - A function to update or add a query parameter and replace the current URL.
 *
 * @returns {{
 *   queryParams: URLSearchParams,
 *   getQueryParam: (param: string) => string | null,
 *   setQueryParam: (param: string, value: string) => void
 * }} An object containing functions to get and set query parameters.
 * 
 * @example
 * // Import the hook in your component
 * import useQueryParams from './useQueryParams';
 *
 * // Use the hook in your component
 * const { queryParams, getQueryParam, setQueryParam } = useQueryParams();
 *
 * // Access the current query parameters
 * console.log(queryParams.get('exampleParam'));
 *
 * // Set a new query parameter
 * setQueryParam('newParam', 'newValue');
 *
 * // Get the value of a specific query parameter
 * const specificParamValue = getQueryParam('specificParam');
 */

const useQueryParams = () => {
  const location = useLocation()
  const history = useHistory()
  const [queryParams, setQueryParams] = useState(
    new URLSearchParams(location.search)
  )

  useEffect(() => {
    setQueryParams(new URLSearchParams(location.search))
  }, [location.search])

  const getQueryParam = (param) => {
    return queryParams.get(param)
  }

  const setQueryParam = (param, value) => {
    const newParams = new URLSearchParams(queryParams)
    newParams.set(param, value)

    history.replace({
      pathname: location.pathname,
      search: newParams.toString(),
    })
  }

  return { queryParams, getQueryParam, setQueryParam }
}

export default useQueryParams
