import { axios } from 'utils';
import { constants } from 'resources';

export default async function updateShippingAddress(address, addressId) {
  return axios.post(constants.API_UPDATE_SHIPPING_ADDRESS, {
    address,
    shippingAddressId: addressId || undefined,
    primary: true,
  });
}
