import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { fonts } from 'common/assets/sharedUI'
import { upperCase } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0, // Remove padding from the root container
  },
  expansionPanel: {
    backgroundColor: 'transparent',
    boxShadow: 'none', // Remove the box shadow
    '&:before': {
      display: 'none', // Remove the default border
    },
    margin: 0, // Remove margin from the ExpansionPanel
  },
  heading: {
    fontFamily: fonts.Swiss721MD,
  },
  summary: {
    padding: 0, // Remove padding from the summary
    minHeight: 40,
  },
  details: {
    padding: 0, // Remove padding from the details
    fontFamily: fonts.Swiss721MD,
  },
}))

const Accordion = ({ title, content, expend = true }) => {
  const classes = useStyles()
  const [expanded, setExpended] = useState(expend)

  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={expanded} className={classes.expansionPanel}>
        <ExpansionPanelSummary
          onClick={() => setExpended((state) => !state)}
          className={classes.summary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel-content'
          id='panel-header'
        >
          <Typography className={classes.heading}>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          {content}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.element,
  expend: PropTypes.bool,
}

export default Accordion
