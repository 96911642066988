import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';
import { fonts } from 'common/assets/sharedUI';

const useStyles = makeStyles(() => createStyles({
  container: {
    marginBottom: '20px',
    padding: 34,
    borderRadius: '3px',
    boxShadow: '0px 3px 6px #00000029',
    minHeight: '312px',
  },
  bgGreen: {
    backgroundColor: colors.green_06,
  },
  bgBlue: {
    backgroundColor: colors.blue_03,
  },
  name: {
    fontFamily: fonts.BaskervilleBold,
    fontSize: '40px',
    lineHeight: '49px',
    textTransform: 'capitalize',
  },
  nutrients: {
    fontFamily: fonts.Swiss721MD,
    fontSize: '65px',
    lineHeight: '65px',
    marginTop: '35px',
    marginRight: '20px',
    paddingRight: '20px',
   },
  description: {
    fontFamily: fonts.Swiss721LT,
    fontSize: '18px',
    lineHeight: '20px',
  },
  description2: {
    fontFamily: fonts.Swiss721MD,
    fontSize: '20px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    marginTop: '45px',
  },
  description3: {
    fontFamily: fonts.BaskervilleBold,
    fontSize: '30px',
    lineHeight: '30px',
  },
  containerSmall: {
    padding: 15,
    borderRadius: '3px',
    boxShadow: '0px 3px 6px #00000029',
    // minHeight: '312px',
  },
  nameSmall: {
    fontFamily: fonts.BaskervilleBold,
    fontSize: '25px',
    lineHeight: '31px',
    textTransform: 'capitalize',
  },
   nutrientsSmall: {
    fontFamily: fonts.Swiss721MD,
    fontSize: '45px',
    lineHeight: '45px',
    marginTop: '8px',
  },
  descriptionSmall: {
    fontFamily: fonts.Swiss721LT,
    fontSize: '14px',
    lineHeight: '14px',
  },
  description2Small: {
    fontFamily: fonts.Swiss721MD,
    fontSize: '12px',
    lineHeight: '12px',
    textTransform: 'uppercase',
    marginTop: '10px',
    marginLeft: '10px',
  },
  description3Small: {
    fontFamily: fonts.BaskervilleBold,
    fontSize: '18px',
    lineHeight: '18px',
    marginLeft: '10px',
  },
  fontDarkestGrey: {
    color: colors.darkestGrey,
  },
  fontWhite: {
    color: colors.white,
  },
  button: {
    backgroundColor: colors.white,
    color: colors.green_07,
    fontFamily: fonts.Swiss721MD,
    marginTop: '20px',
    textTransform: 'capitalize',
    fontSize: '18px',
    height: '34px',
  },
  buttonSmall: {
    backgroundColor: colors.white,
    color: colors.green_07,
    fontFamily: fonts.Swiss721MD,
    marginTop: '20px',
    textTransform: 'capitalize',
    fontSize: '14px',
    height: '23px',
  }
}));

export default useStyles;
