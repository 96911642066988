import React from 'react';
import { Typography } from '@material-ui/core';
import constants from '../resources/constants';

export default function ContactSupport({ className, style, gaEvent }) {
  return (
    <Typography {...className} {...style}>
      Having trouble?
      <Typography style={{ fontWeight: 'bold' }} component="span">
        <a onClick={gaEvent} href={`${constants.CONTACT_SUPPORT_URL}`} target="_blank" rel="noreferrer noopener" style={{ color: 'black', textDecoration: 'none' }}> Contact support</a>
      </Typography>
    </Typography>
  );
}
