// @flow
import React, { useEffect } from 'react'
import { Row, Col, Button } from 'antd'
import ReactGA from 'react-ga4'
import { images } from 'resources'
import './styles.css'

const dnaKitUrl = '/register/dna_kit'
const existingDataUrl = '/register/existing_data'

const { IS_PROD, IS_STAGING } = window.env

const RegisterProductTypePage = () => {
  useEffect(() => {
    if (IS_PROD || IS_STAGING) {
      ReactGA.send({ hitType: "pageview", page: '/register' });
    }
  }, [])

  return (
    <Col align="middle" styleName="parentCol">
      <Row type="flex" justify="space-around" align="middle">
        <img src={images.logo} alt="GenoPalate logo" styleName="logo" />
      </Row>
      <Row type="flex" justify="space-around" align="middle">
        <Col align="middle">
          <div styleName="headerText">Select your product</div>
          <div>If you purchased multiple products, please register them one at a time.</div>
        </Col>
      </Row>
      <Row type="flex" justify="space-around" align="middle" className="flex_1 center">
        <Col type="flex" justify="space-between" align="middle" styleName="imageContainer">
          <div href="https://account.genopalate.com/register/dna_kit">
            <img src={images.dna_kit} alt="DNA Kit" styleName="image" />
          </div>
        </Col>
        <Col type="flex" justify="space-between" align="middle" styleName="imageContainer">
          <div href="https://account.genopalate.com/register/existing_data">
            <img src={images.existing_data} alt="Existing Data" styleName="image" />
          </div>
        </Col>
      </Row>
      <Row type="flex" justify="space-around" align="middle" className="flex_1 center">
        <Col type="flex" justify="space-between" align="middle" styleName="buttContainer"><Button styleName="button" href={dnaKitUrl}>{('DNA Kit').toUpperCase()}</Button></Col>
        <Col type="flex" justify="space-between" align="middle" styleName="buttContainer"><Button styleName="button" href={existingDataUrl}>{('Existing Data').toUpperCase()}</Button></Col>
      </Row>
    </Col>
  )
}

export default RegisterProductTypePage
