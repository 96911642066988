// @flow
import React, { PureComponent } from 'react'
import { Layout, Row, Col } from 'antd'
import ReactGA from 'react-ga4'
import { ForgotPasswordChangeForm } from 'components'
import { images } from 'resources'
import './styles.css'

// const {
//   Header, Footer, Sider, Content,
// } = Layout

const { IS_PROD, IS_STAGING } = window.env

type Props = {}
export default class ForgotPasswordChangePage extends PureComponent<Props> {
  componentDidMount() {
    if (IS_PROD || IS_STAGING) {
      ReactGA.send({ hitType: "pageview", page: '/change_password' });
    }
  }

  render() {
    return (
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        styleName="container"
        className="flex_1 center"
      >
        <Col>
          <Row type="flex" justify="space-around" align="middle">
            <img src={images.logo} styleName="logo" alt="logo" />
          </Row>
          <h1 className="marginB-20">Reset your password</h1>
          <ForgotPasswordChangeForm forgotKey={this.props.match.params.key} />
        </Col>
      </Row>
    )
  }
}
