import { registerGAClickEventEnhanced } from '../../utils/googleanalytics';

/**
 * @param {{ mealType: string, name: string }}
 */
const onRecipeClick = ({ mealType, name }) => {
  registerGAClickEventEnhanced(`Recipe_${mealType}`, 'Open', `Recipe_${name}`);
}

export default {
  onRecipeClick
};
