import React from 'react';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import subYears from 'date-fns/subYears';
import DateFnsUtils from '@date-io/date-fns';
import useStyles from './DateForm.styles';

export default function DateForm(props) {
  const classes = useStyles();
  const {
    id,
    isEighteenOrOlder,
    onDateChange,
    values,
    errors,
    setFieldValue,
  } = props;

  const handleChange = (value) => {
    setFieldValue(`date_${id}`, value);
    onDateChange();
  };

  const getMinDate = () => subYears(new Date(), 105);

  const getMaxDate = () => subYears(new Date(), 18);

  const dateProps = {
    name: `date_${id}`,
    label: '',
    value: values[`date_${id}`],
    openTo: 'year',
    format: 'MM/dd/yyyy',
    onChange: handleChange,
    error: !isEighteenOrOlder ? !isEighteenOrOlder : !!errors[`date_${id}`],
    helperText: !isEighteenOrOlder ? 'You must be eighteen or older to use this product.' : errors[`date_${id}`] && errors[`date_${id}`],
    margin: 'normal',
    inputVariant: 'outlined',
    initialFocusedDate: getMaxDate(),
    size: 'small',
    views: ['year', 'month', 'date'],
    KeyboardButtonProps: {
      'aria-label': 'change date',
    },
    minDate: getMinDate(),
    maxDate: getMaxDate(),
  };

  return (
    <Grid container>
      <Grid container item xs={12} className={classes.questionContainer} justify="center">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker {...dateProps} />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
}

DateForm.propTypes = {
  id: PropTypes.number.isRequired,
  isEighteenOrOlder: PropTypes.bool.isRequired,
  onDateChange: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.any),
  errors: PropTypes.objectOf(PropTypes.any),
  setFieldValue: PropTypes.func.isRequired,
};

DateForm.defaultProps = {
  values: {},
  errors: {},
};
