/* eslint-disable camelcase */
import crustacean from './icons/crustaceans.svg';
import shellfish from './icons/shellfish.svg';
import dairy from './icons/dairy.svg';
import wheat from './icons/wheat.svg';
import egg from './icons/egg.svg';
import fish from './icons/fish.svg';
import peanut from './icons/peanuts.svg';
import pork from './icons/pork.svg';
import redmeat from './icons/redmeat.svg';
import sesame from './icons/sesame.svg';
import soy from './icons/soy.svg';
import treenut from './icons/treenuts.svg';
import vegan from './icons/Vegan.svg';
import vegetarian from './icons/vegetarian.svg';
import filters from './icons/filters.svg';
import lactose from './icons/Lactose.svg';
import gluten from './icons/gluten.svg';
import pescatarian from './icons/pescatarian.svg';
import recoIcon from './icons/reco_icon.png';
import tipsIcon from './icons/tips_icon.png';
import none from './icons/none.svg';
import accountCreation from './icons/account_creation.svg';
import questionnaire from './icons/questionnaire.svg';
import researchActive from './icons/research_active.svg';
import researchInactive from './icons/research_inactive.svg';
import sendKitActive from './icons/send_kit_active.svg';
import sendKitInactive from './icons/send_kit_inactive.svg';
import uploadDnaActive from './icons/upload_DNA_active.svg';
import uploadDnaInactive from './icons/upload_DNA_inactive.svg';
import uncommonfood from './icons/uncommon.svg';
import consult from './icons/consult.svg';
import consultInvert from './icons/consult_invert.svg';
import facebook from './icons/facebook.svg';
import calender from './icons/calender.svg';
import graduation from './icons/graduation.svg';


export default {
  crustacean,
  shellfish,
  dairy,
  wheat,
  egg,
  fish,
  peanut,
  pork,
  redmeat,
  sesame,
  soy,
  treenut,
  vegan,
  vegetarian,
  filters,
  lactose,
  gluten,
  pescatarian,
  recoIcon,
  tipsIcon,
  none,
  accountCreation,
  questionnaire,
  researchActive,
  researchInactive,
  sendKitActive,
  sendKitInactive,
  uploadDnaActive,
  uploadDnaInactive,
  uncommonfood,
  consult,
  consultInvert,
  facebook,
  calender,
  graduation,
};
