import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, withStyles } from '@material-ui/core';
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import { colors } from '../common/assets/sharedUI';
import withCartInitialized from './withCartInitialized';

const styles = {
  cartButton: {
    color: colors.darkestGrey,
  },
  cartCount: {
    backgroundColor: colors.blue_04,
    color: 'white',
    borderRadius: '50%',
    height: 18,
    width: 18,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    position: 'absolute',
    left: 30,
    top: 5,
  },
};

const CartIcon = ({ onClick, classes }) => {
  const cartLineItems = useSelector(state => get(state, 'additionalProducts.cart.cart.lineItems', []));
  return (
    <IconButton
      color="inherit"
      onClick={onClick}
      className={classes.cartButton}
      id="cartButton"
    >
      {cartLineItems.length > 0 && (<div className={classes.cartCount}>{cartLineItems.length}</div>)}
      <FontAwesomeIcon icon={faShoppingCart} />
    </IconButton>
  );
};

CartIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    cartButton: PropTypes.string,
    cartCount: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(withCartInitialized(CartIcon));
