
export const fetchFeatures = async () => {
  try {
    // const { data: featuresList } = await axios.get("/api/features");

    const featureNames = Object.keys(featuresList)
    return featureNames.reduce((result, featureName) => {
      const featureStatus = featuresList[featureName]

      return [...result, [featureName, featureStatus]]
    }, [])
  } catch (e) {
    console.error('Something went wrong', e)
  }

  return []
}

export const FeatureComponentId = {
  GENOBLEND_BANNER: 'genoblend_banner',
}

/**
 * list of feature name to toggle
 * this can be coming from api
 */
const featuresList = {
  [FeatureComponentId.GENOBLEND_BANNER]: true,
}

/**
 * add the email addresses here
 * list of allowed users to show genoblend
 */
export const allowedUsers = [
  'sidrajaved+test@folio3.com',
  'sidrajaved+11@folio3.com',
  'hannah.pekarek@genopalate.com'
]