import { axios } from 'utils';
import { constants } from 'resources';

export default async function setPreference( dietaryPreferenceId, enabled ) {
  try {
    const params = { dietaryPreferenceId: dietaryPreferenceId, enabled: enabled };
    const response = await axios.post(constants.API_DIETARY_PREFERENCE, params);
    return response;
  } catch (e) {
    return null
  }
}
