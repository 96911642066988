import React from 'react';
import PropTypes from 'prop-types';
import icons from 'resources/icons';
import { Recommendation } from 'components/componentsV2';
import { Paper, Typography, Grid } from '@material-ui/core';
import useStyles from './RecommendationPaper.styles';

const RecommendationPaper = (props) => {
  const {
    traitRecommendation, geneticInsightDescription,
  } = props;
  const classes = useStyles();
  return (
    <div className={classes.paperContainer} id="nutrientRecommendationText">
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12} className={classes.titleContainer}>
            <Typography variant="h6" className={classes.title} name="title">Your Recommendation</Typography>
            <img src={icons.recoIcon} className={classes.icon} alt="" />
          </Grid>
          <Grid item xs={12} className={classes.descriptionContainer}>
            <Recommendation traitRecommendation={traitRecommendation} geneticInsightDescription={geneticInsightDescription} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default RecommendationPaper;

RecommendationPaper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  traitRecommendation: PropTypes.any,
  geneticInsightDescription: PropTypes.string.isRequired,
};

RecommendationPaper.defaultProps = {
  traitRecommendation: null,
};
