const userStatuses = {
  GET_TERMS: 'getTerms',
  STATUS_ERROR: 'statusError',
  JUMP_GO_VIDEO_KIT: 'jumpGoVideoKit',
  JUMP_GO_VIDEO_FILE: 'jumpGoVideoFile',
  SHOULD_UPLOAD_DNA: 'shouldUploadDNA',
  BEGIN_QUESTIONNAIRE_KIT: 'beginQuestionnaireKit',
  BEGIN_QUESTIONNAIRE_EXISTING: 'beginQuestionnaireExisting',
  CONTINUE_QUESTIONNAIRE_KIT: 'continueQuestionnaireKit',
  CONTINUE_QUESTIONNAIRE_EXISTING: 'continueQuestionnaireExisting',
  CONSENT_TO_RESEARCH_AND_BIOBANK_EXISTING: 'consentToResearchAndBioBankExisting',
  CONSENT_TO_RESEARCH_AND_BIOBANK_NEW: 'consentToResearchAndBioBankNew',
  CONSENT_TO_RESEARCH_EXISTING: 'consentToResearchExisting',
  CONSENT_TO_RESEARCH_FILE: 'consentToResearchFile',
  CONSENT_TO_RESEARCH_KIT: 'consentToResearchKit',
  GET_DEMOGRAPHIC: 'getDemographic',
  USER_ON_HOLD: 'userOnHold',
  RECIPE_QUESTIONNAIRE: 'recipeQuestionnaire',
  RENDER_REPORT: 'renderReport',
  NEW_BARCODE_REPORT_IN_DEVELOPMENT: 'newBarcodeReportInDevelopment',
  RERUN_REPORT: 'rerunReport',
  REPORT_IN_DEVELOPMENT: 'reportInDevelopment',
  SHOULD_UPLOAD_BARCODE_OLD: 'shouldUploadBarcodeOld',
  SHOULD_UPLOAD_BARCODE_NEW: 'shouldUploadBarcodeNew',
}

module.exports = { userStatuses }
