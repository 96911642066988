import React, { PureComponent } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'

type Props = {}
class Loading extends PureComponent<Props> {
  render() {
    return (
      <Grid
        item
        xs={12}
        style={{
          paddingTop: 200, display: 'flex', justifyContent: 'center', alignItems: 'middle',
        }}
      >
        <CircularProgress />
      </Grid>
    )
  }
}

export default Loading
