import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import clsx from 'clsx';
import { Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { sharedStyles, images } from 'resources';
import { useHistory } from 'react-router-dom';
import { EventCategories, registerGAClickEventEnhanced } from 'utils/googleanalytics';
import { colors } from '../common/assets/sharedUI';
import { closeCart, removeItemFromCart } from '../store/actions/additionalProducts';
import CartSpinner from './CartSpinner';

const styles = {
  cart: {
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    width: 350,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    transform: 'translateX(100%)',
    transition: 'transform 0.15s ease-in-out',
    zIndex: 1303,
    border: `1px solid ${colors.gray_03}`,
  },
  cartOpen: {
    transform: 'translateX(0)',
  },
  cartHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.gray_03}`,
    padding: 10,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: '#000000a6',
    zIndex: 1302,
  },
  cartEmpty: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 20,
  },
  cartEmptyIllustration: {
    width: '130px',
  },
  iconButton: {
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: '#e9e9ea',
    },
  },
  total: {
    fontWeight: 'bold',
    fontSize: 22,
    color: colors.blue_04,
    marginBottom: 10,
  },
  continueShopping: {
    marginTop: 20,
  },
};

const CartLine = withStyles(styles)(({
  lineItem, onRemoveClicked, classes,
}) => {
  const sharedClasses = sharedStyles();
  const remove = useCallback(() => onRemoveClicked(lineItem), [onRemoveClicked, lineItem]);
  return (
    <div style={{
      flexDirection: 'row', display: 'flex', margin: '20px 10px', alignItems: 'center',
    }}
    >
      <img src={lineItem.image} style={{ width: 65, marginRight: 10 }} alt={lineItem.title} />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 3,
        marginRight: 10,
      }}
      >
        <div>
          <Typography className={sharedClasses.h7} style={{ lineHeight: '1.1em' }}>{lineItem.title}</Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography className={sharedClasses.body}>
            {`$${parseFloat(lineItem.price).toFixed(2)}`}
            <Typography component="span" variant="body2">
              {` x${lineItem.quantity}`}
            </Typography>
          </Typography>
          <div className={classes.iconButton} onClick={remove} aria-hidden="true">
            <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: 20 }} />
          </div>
        </div>
      </div>

    </div>
  );
});

const Cart = ({ classes }) => {
  const sharedClasses = sharedStyles();

  const open = useSelector(state => state.additionalProducts.cart.open);
  const cart = useSelector(state => state.additionalProducts.cart.cart);
  const cartLineItems = useSelector(state => get(state, 'additionalProducts.cart.cart.lineItems', []));

  const dispatch = useDispatch();
  const history = useHistory();

  const onCloseClick = useCallback(() => {
    registerGAClickEventEnhanced(EventCategories.Cart, 'Close', 'Close Cart');
    dispatch(closeCart());
  }, [dispatch]);

  const onRemoveClicked = useCallback((lineItem) => {
    registerGAClickEventEnhanced(EventCategories.Cart, 'Remove from Cart', lineItem.title);
    dispatch(removeItemFromCart(lineItem.cartLineId));
  }, [dispatch]);

  const proceedToCheckout = useCallback(() => {
    registerGAClickEventEnhanced(EventCategories.Cart, 'Click', 'Continue to Checkout');
    window.location = cart.checkoutUrl;
  }, [cart]);

  const continueShopping = useCallback(() => {
    dispatch(closeCart());
    history.push('/account/products');
  }, [dispatch, history]);

  const onContinueShoppingClicked = useCallback(() => {
    registerGAClickEventEnhanced(EventCategories.Cart, 'Open', 'Continue shopping');
    continueShopping();
  }, [continueShopping]);

  const onBuyAdditionalProductsClicked = useCallback(() => {
    registerGAClickEventEnhanced(EventCategories.Cart, 'Open', 'Buy Additional Products');
    continueShopping();
  }, [continueShopping]);

  return (
    <React.Fragment>
      <CartSpinner />
      <div className={clsx({ [classes.overlay]: open })} onClick={onCloseClick} />
      <div className={clsx(classes.cart, { [classes.cartOpen]: open })}>
        <div className={classes.cartHeader}>
          <div className={classes.iconButton} onClick={onCloseClick} aria-hidden="true">
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 26 }} />
          </div>
          <Typography className={sharedClasses.h5} style={{ textAlign: 'center', fontWeight: 'bold' }}>Your Cart</Typography>
          <div />
        </div>
        {cart && cartLineItems.length > 0 ? (
          <React.Fragment>
            <div style={{ borderBottom: `1px solid ${colors.gray_03}`, paddingBottom: 20 }}>
              {cartLineItems.map((lineItem) => <CartLine key={lineItem.id} lineItem={lineItem} onRemoveClicked={onRemoveClicked} />)}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography className={clsx(classes.total, sharedClasses.h6)}>Subtotal</Typography>
                <Typography className={clsx(classes.total, sharedClasses.h6)}>
                  {`$${parseFloat(cart.subtotal).toFixed(2)}`}
                </Typography>
              </div>
              <div>
                <Button className={sharedClasses.rebrandedPrimaryButton} style={{ width: '100%', padding: 0 }} onClick={proceedToCheckout}>PROCEED TO CHECKOUT</Button>
              </div>
              <div className={classes.continueShopping}>
                <Button onClick={onContinueShoppingClicked} style={{ textTransform: 'none', fontSize: '1.15em' }}>
                  <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 16, marginRight: 5 }} />
                  <Typography className={sharedClasses.h7}>Continue Shopping</Typography>
                </Button>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className={classes.cartEmpty}>
            <div style={{ textAlign: 'center', marginBottom: 15 }}>
              <img src={images.emptyCart} className={classes.cartEmptyIllustration} alt="Empty shopping cart" />
            </div>
            <div>
              <Typography className={sharedClasses.body} style={{ fontWeight: 700 }}>Your cart is empty</Typography>
            </div>
            <div style={{ marginBottom: 25 }}>
              <Typography className={sharedClasses.body}>You can enhance your personalized nutrition journey by purchasing additional GenoPalate products.</Typography>
            </div>
            <div>
              <Button className={sharedClasses.rebrandedPrimaryButton} style={{ padding: 15 }} onClick={onBuyAdditionalProductsClicked}>BUY ADDITIONAL PRODUCTS</Button>
            </div>
          </div>
        )}

      </div>
    </React.Fragment>
  );
};

Cart.propTypes = {
  classes: PropTypes.shape({
    overlay: PropTypes.string,
    cart: PropTypes.string,
    cartOpen: PropTypes.string,
    cartHeader: PropTypes.string,
    iconButton: PropTypes.string,
    total: PropTypes.string,
    continueShopping: PropTypes.string,
    cartEmpty: PropTypes.string,
    cartEmptyIllustration: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(Cart);