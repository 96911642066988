import images from "./images";

const apiPath = '/api';
const apiVersion = 'v1';
const apiEndpoints = [
  {
    name: 'API_AUTH_LOGIN',
    path: '/auth/login',
  },
  {
    name: 'API_AUTH_REGISTER',
    path: '/auth/register',
  },
  {
    name: 'API_AUTH_FORGOT_PASSWORD',
    path: '/auth/forgot/password',
  },
  {
    name: 'API_AUTH_FORGOT_CHANGE_PASSWORD',
    path: '/auth/forgot/password/change',
  },
  {
    name: 'API_AUTH_ACCOUNT_SHELL',
    path: '/auth/accountShell',
  },
  {
    name: 'API_AUTH_REGISTER_KIT',
    path: '/auth/registerKit',
  },
  {
    name: 'API_USER_PRODUCT',
    path: '/user/product',
  },
  {
    name: 'API_PURCHASED_PRODUCTS',
    path: '/product/purchasedProducts',
  },
  {
    name: 'API_ADDITIONAL_PRODUCTS',
    path: '/product/additionalProducts',
  },
  {
    name: 'API_GET_SUBSCRIPTIONS',
    path: '/subscriptions/subscriptions',
  },
  {
    name: 'API_GET_SUBSCRIPTION_ORDERS',
    path: '/subscriptions/allSubscriptionOrders',
  },
  {
    name: 'API_USER_PRODUCT_V2',
    path: '/user/productV2',
  },
  {
    name: 'API_SET_TERMS_AGREEMENT',
    path: '/user/setTermsAgreement',
  },
  {
    name: 'API_RUN_REPORT',
    path: '/user/runReport',
  },
  {
    name: 'API_ASSIGN_MEMBER',
    path: '/userProduct/assignMember',
  },
  {
    name: 'API_ASSIGN_BARCODE',
    path: '/userProduct/assignBarcode',
  },
  {
    name: 'API_UPLOAD_DNA_RESULT',
    path: '/upload/dnaResult',
  },
  {
    name: 'API_USER_PROFILE',
    path: '/user/profile',
  },
  {
    name: 'API_UPDATE_PROFILE',
    path: '/user/updateProfile',
  },
  {
    name: 'API_USER_UPDATE_PASSWORD',
    path: '/user/update/password',
  },
  {
    name: 'API_VALIDATE_USER_PRODUCT',
    path: '/validate/userProduct',
  },
  {
    name: 'API_USER_TOP_FOODS',
    path: '/user/topFoods',
  },
  {
    name: 'API_GET_FOOD_SCORES',
    path: '/food/foodScores',
  },
  {
    name: 'API_USER_STATUS',
    path: '/user/status',
  },
  {
    name: 'API_SHOW_BANNER',
    path: '/user/showBanner',
  },
  {
    name: 'API_SET_BANNER',
    path: '/user/bannerEvent',
  },
  {
    name: 'API_SHOW_SUPPLEMENT_BANNER',
    path: '/user/showSupplementBanner',
  },
  {
    name: 'API_LOG_FILTER_ACTION',
    path: '/food/logFilterAction',
  },
  {
    name: 'API_GET_TRAIT_RECOMMENDATION',
    path: '/nutrient/recommendation',
  },
  {
    name: 'API_GET_ACTION_ITEMS',
    path: '/userProduct/actionItems',
  },
  {
    name: 'API_BEGIN_QUESTIONNAIRE',
    path: '/questionnaire/begin',
  },
  {
    name: 'API_FORWARD_QUESTIONNAIRE',
    path: '/questionnaire/forward',
  },
  {
    name: 'API_BACK_QUESTIONNAIRE',
    path: '/questionnaire/back',
  },
  {
    name: 'API_SAVE_QUESTIONNAIRE',
    path: '/questionnaire/save',
  },
  {
    name: 'API_GET_PROGRESS_QUESTIONNAIRE',
    path: '/questionnaire/getProgress',
  },
  {
    name: 'API_GET_CONTRACT',
    path: '/consent/getContract',
  },
  {
    name: 'API_GET_ACCOUNT_CONTRACTS',
    path: '/consent/getAccountContracts',
  },
  {
    name: 'API_ADD_CONSENT',
    path: '/consent/addConsent',
  },
  {
    name: 'API_SET_FOOD_VISIBILITY',
    path: '/food/setFoodVisibility',
  },
  {
    name: 'API_DIETARY_PREFERENCE',
    path: '/user/dietaryPreference',
  },
  {
    name: 'API_GET_MACROS',
    path: '/nutrient/macros',
  },
  {
    name: 'API_GET_EER',
    path: '/nutrition/eer',
  },
  {
    name: 'API_GET_FOOD_DETAILS',
    path: '/food/details',
  },
  {
    name: 'API_GET_HEALTHIE_URL',
    path: '/user/getHealthieUrl',
  },
  {
    name: 'API_GET_SHIPPING_ADDRESS',
    path: '/subscriptions/getShippingAddresses',
  },
  {
    name: 'API_UPDATE_SHIPPING_ADDRESS',
    path: '/subscriptions/changeShippingAddress',
  },
  {
    name: 'API_GET_FORMULATION',
    path: '/subscriptions/personalizedSupplement',
  },
  {
    name: 'API_UPDATE_SUBSCRIPTION_BILLING',
    path: '/subscriptions/paymentMethod',
  },
  {
    name: 'API_GET_SUBSCRIPTION_PRODUCTS',
    path: '/product/subscriptionProducts',
  },
  {
    name: 'API_GET_GENOBLEND_SUBSCRIPTION_PRODUCTS',
    path: '/product/subscriptionGenoBlendProducts',
  },
  {
    name: 'API_GET_USER_BLEND_TYPE',
    path: '/product/userBlendType',
  },
  {
    name: 'API_GET_PAYMENT_METHOD',
    path: '/subscriptions/paymentMethod',
  },
  {
    name: 'API_CANCEL_SUBSCRIPTION',
    path: '/subscriptions/cancelSubscription',
  },
  {
    name: 'API_GET_SUBSCRIPTION_QUESTIONNAIRE',
    path: '/subscriptions/getQuestionnaire',
  },
  {
    name: 'API_SAVE_SUBSCRIPTION_QUESTIONNAIRE',
    path: '/subscriptions/saveQuestionnaire',
  },
  {
    name: 'API_GET_BOOSTER_FORMULATIONS',
    path: '/product/boosterFormulations',
  },
  {
    name: 'API_PAUSE_SUBSCRIPTION',
    path: '/subscriptions/pauseSubscription',
  },
  {
    name: 'API_UPDATE_SUBSCRIPTION',
    path: '/subscriptions/updateUserSubscription',
  },
  {
    name: 'API_UPDATE_SUBSCRIPTION_V2',
    path: '/subscriptions/updateUserSubscription',
    version: 'v2'
  },
  {
    name: 'API_AUTH_DELETE_REQUEST_CONFIRM',
    path: '/auth/verifyDelete',
  },
  {
    name: 'API_USER_DELETE_ACCOUNT',
    path: '/user/delete',
  },
];

const generateConfig = () => {
  const config = {
    example: 'example',
    timeout: 10000,
  };

  apiEndpoints.forEach(api => {
    const version = api?.version || apiVersion;
    config[api.name] = `${apiPath}/${version}` + api.path;
  });

  return config;
};

export const REPORT_DELIVERED_STATUS_ID = 4;

const userStatuses = {
  GET_TERMS: 'getTerms',
  STATUS_ERROR: 'statusError',
  SHOULD_UPLOAD_DNA: 'shouldUploadDNA',
  BEGIN_QUESTIONNAIRE_KIT: 'beginQuestionnaireKit',
  BEGIN_QUESTIONNAIRE_EXISTING: 'beginQuestionnaireExisting',
  CONTINUE_QUESTIONNAIRE_KIT: 'continueQuestionnaireKit',
  CONTINUE_QUESTIONNAIRE_EXISTING: 'continueQuestionnaireExisting',
  CONSENT_TO_RESEARCH_AND_BIOBANK_EXISTING: 'consentToResearchAndBioBankExisting',
  CONSENT_TO_RESEARCH_AND_BIOBANK_NEW: 'consentToResearchAndBioBankNew',
  CONSENT_TO_RESEARCH_EXISTING: 'consentToResearchExisting',
  CONSENT_TO_RESEARCH_FILE: 'consentToResearchFile',
  CONSENT_TO_RESEARCH_KIT: 'consentToResearchKit',
  USER_ON_HOLD: 'userOnHold',
  RECIPE_QUESTIONNAIRE: 'recipeQuestionnaire',
  RENDER_REPORT: 'renderReport',
  NEW_BARCODE_REPORT_IN_DEVELOPMENT: 'newBarcodeReportInDevelopment',
  RERUN_REPORT: 'rerunReport',
  REPORT_IN_DEVELOPMENT: 'reportInDevelopment',
  SHOULD_UPLOAD_BARCODE_OLD: 'shouldUploadBarcodeOld',
  SHOULD_UPLOAD_BARCODE_NEW: 'shouldUploadBarcodeNew',
};

export const essentialNutritionDomains = {
  TOTAL_FAT: 'Total Fat',
  TOTAL_CARBOHYDRATES: 'Total Carbohydrates',
  PROTEIN: 'Protein',
};

export const panelNames = {
  ESSENTIAL_NUTRITION: 'Essential Nutrition Report',
  EATING_INSIGHTS: 'Eating Insights Report',
  STRESS_INSIGHTS: 'Stress Insights Report',
};

export const subscriptionStatuses = {
  Pending: 'Pending',
  Active: 'Active',
  Canceled: 'Canceled',
  Expired: 'Expired',
  Paused: 'Paused',
};

export const oneTimeSupplementProductSkus = {
  'VS0000-1T': 'VS0000-1T',
  'VS0001-1T': 'VS0001-1T',
  'VS0002-1T': 'VS0002-1T',
  'VS0003-1T': 'VS0003-1T',
  'VS0004-1T': 'VS0004-1T',
  'VS0005-1T': 'VS0005-1T',
  'VS0006-1T': 'VS0006-1T',
  'VS0007-1T': 'VS0007-1T',
};

export const sixMonthsSupplementProductSkus = {
  'VS0000-6MO': 'VS0000-6MO',
  'VS0001-6MO': 'VS0001-6MO',
  'VS0002-6MO': 'VS0002-6MO',
  'VS0003-6MO': 'VS0003-6MO',
  'VS0004-6MO': 'VS0004-6MO',
  'VS0005-6MO': 'VS0005-6MO',
  'VS0006-6MO': 'VS0006-6MO',
  'VS0007-6MO': 'VS0007-6MO',
};

export const installmentSupplementProductSKUs = {
  'VS0000-1MO': 'VS0000-1MO',
  'VS0001-1MO': 'VS0001-1MO',
  'VS0002-1MO': 'VS0002-1MO',
  'VS0003-1MO': 'VS0003-1MO',
  'VS0004-1MO': 'VS0004-1MO',
  'VS0005-1MO': 'VS0005-1MO',
  'VS0006-1MO': 'VS0006-1MO',
  'VS0007-1MO': 'VS0007-1MO',
};

export const threeMonthsSupplementProductSkus = {
  VS0000: 'VS0000',
  VS0001: 'VS0001',
  VS0002: 'VS0002',
  VS0003: 'VS0003',
  VS0004: 'VS0004',
  VS0005: 'VS0005',
  VS0006: 'VS0006',
  VS0007: 'VS0007',
};

export const supplementProductSkus = {
  ...oneTimeSupplementProductSkus,
  ...sixMonthsSupplementProductSkus,
  ...threeMonthsSupplementProductSkus,
  ...installmentSupplementProductSKUs,
};

// updated one
export const blendSKUsV1 = {
  'NS0001-LPLF-VAN': 'NS0001-LPLF-VAN',
  'NS0002-HPLF-VAN': 'NS0002-HPLF-VAN',
  'NS0004-HPHF-VAN': 'NS0004-HPHF-VAN',
  'NS0003-LPHF-VAN': 'NS0003-LPHF-VAN',
  'NS0001-LPLF-CHOC': 'NS0001-LPLF-CHOC',
  'NS0002-HPLF-CHOC': 'NS0002-HPLF-CHOC',
  'NS0003-LPHF-CHOC': 'NS0003-LPHF-CHOC',
  'NS0004-HPHF-CHOC': 'NS0004-HPHF-CHOC',
  'NS0001-LPLF': 'NS0001-LPLF',
  'NS0002-HPLF': 'NS0002-HPLF',
  'NS0003-LPHF': 'NS0003-LPHF',
  'NS0004-HPHF': 'NS0004-HPHF',
};

export const blendSKUs = {
  'NS0001-LPLF-VAN': 'NS0001-LPLF-VAN',
  'NS0002-HPLF-VAN': 'NS0002-HPLF-VAN',
  'NS0004-HPHF-VAN': 'NS0004-HPHF-VAN',
  'NS0003-LPHF-VAN': 'NS0003-LPHF-VAN',
  'NS0001-LPLF-CHOC': 'NS0001-LPLF-CHOC',
  'NS0002-HPLF-CHOC': 'NS0002-HPLF-CHOC',
  'NS0003-LPHF-CHOC': 'NS0003-LPHF-CHOC',
  'NS0004-HPHF-CHOC': 'NS0004-HPHF-CHOC',
  'NS0001-LPLF': 'NS0001-LPLF',
  'NS0002-HPLF': 'NS0002-HPLF',
  'NS0003-LPHF': 'NS0003-LPHF',
  'NS0004-HPHF': 'NS0004-HPHF',
};

export const baseSupplementProductName = {
  VS0000: 'GenoVit Personalized Supplements with Probiotic',
};

export const supplementProductNames = {
  VS0000: 'GenoVit Personalized Supplements with Probiotic',
  VS0001: 'GenoVit Personalized Supplements with Probiotic + Anti-Stress Booster',
  VS0002: 'GenoVit Personalized Supplements with Probiotic + Craving Control Booster',
  VS0003: 'GenoVit Personalized Supplements with Probiotic + Metabolic Support Booster',
  VS0004: 'GenoVit Personalized Supplements with Probiotic + Anti-Stress and Craving Control Boosters',
  VS0005: 'GenoVit Personalized Supplements with Probiotic + Metabolic Support and Craving Control Boosters',
  VS0006: 'GenoVit Personalized Supplements with Probiotic + Anti-Stress and Metabolic Support Boosters',
  VS0007: 'GenoVit Personalized Supplements with Probiotic + Anti-Stress, Metabolic Support, and Craving Control Boosters',
};

export const blendNames = {
  "NS0001-LPLF": 'GenoBlend Personalized Fiber & Protein Blend',
  "NS0002-HPLF": 'GenoBlend Personalized Fiber & Protein Blend',
  "NS0003-LPHF": 'GenoBlend Personalized Fiber & Protein Blend',
  "NS0004-HPHF": 'GenoBlend Personalized Fiber & Protein Blend',
};

export const productNames = {
  ...blendNames,
  ...baseSupplementProductName,
}

export const boosterNames = {
  Probiotic: 'Probiotic',
  AntiStress: 'Anti-Stress',
  CravingControl: 'Craving Control',
  MetabolicSupport: 'Metabolic Support',
};

export const ProductTypes = {
  DNA: 'DNA',
  Bundle: 'Bundle',
  Supplement: 'Supplement',
  Recipe: 'Recipe',
  Panel: 'Panel',
  Consult: 'Consult',
};

const StateTypes = {
  STATE: 'state',
  TERRITORY: 'territory',
  ARMED_FORCES: 'armed forces',
  DISTRICT: 'district',
};

export const StatesAndTerritories = [{
  code: 'AL',
  name: 'Alabama',
  type: StateTypes.STATE,
},
{
  code: 'AK',
  name: 'Alaska',
  type: StateTypes.STATE,
},
{
  code: 'AZ',
  name: 'Arizona',
  type: StateTypes.STATE,
},
{
  code: 'AR',
  name: 'Arkansas',
  type: StateTypes.STATE,
},
{
  code: 'CA',
  name: 'California',
  type: StateTypes.STATE,
},
{
  code: 'CO',
  name: 'Colorado',
  type: StateTypes.STATE,
},
{
  code: 'CT',
  name: 'Connecticut',
  type: StateTypes.STATE,
},
{
  code: 'DE',
  name: 'Delaware',
  type: StateTypes.STATE,
},
{
  code: 'DC',
  name: 'District of Columbia',
  type: StateTypes.DISTRICT,
},
{
  code: 'FL',
  name: 'Florida',
  type: StateTypes.STATE,
},
{
  code: 'GA',
  name: 'Georgia',
  type: StateTypes.STATE,
},
{
  code: 'HI',
  name: 'Hawaii',
  type: StateTypes.STATE,
},
{
  code: 'ID',
  name: 'Idaho',
  type: StateTypes.STATE,
},
{
  code: 'IL',
  name: 'Illinois',
  type: StateTypes.STATE,
},
{
  code: 'IN',
  name: 'Indiana',
  type: StateTypes.STATE,
},
{
  code: 'IA',
  name: 'Iowa',
  type: StateTypes.STATE,
},
{
  code: 'KS',
  name: 'Kansas',
  type: StateTypes.STATE,
},
{
  code: 'KY',
  name: 'Kentucky',
  type: StateTypes.STATE,
},
{
  code: 'LA',
  name: 'Louisiana',
  type: StateTypes.STATE,
},
{
  code: 'ME',
  name: 'Maine',
  type: StateTypes.STATE,
},
{
  code: 'MD',
  name: 'Maryland',
  type: StateTypes.STATE,
},
{
  code: 'MA',
  name: 'Massachusetts',
  type: StateTypes.STATE,
},
{
  code: 'MI',
  name: 'Michigan',
  type: StateTypes.STATE,
},
{
  code: 'MN',
  name: 'Minnesota',
  type: StateTypes.STATE,
},
{
  code: 'MS',
  name: 'Mississippi',
  type: StateTypes.STATE,
},
{
  code: 'MO',
  name: 'Missouri',
  type: StateTypes.STATE,
},
{
  code: 'MT',
  name: 'Montana',
  type: StateTypes.STATE,
},
{
  code: 'NE',
  name: 'Nebraska',
  type: StateTypes.STATE,
},
{
  code: 'NV',
  name: 'Nevada',
  type: StateTypes.STATE,
},
{
  code: 'NH',
  name: 'New Hampshire',
  type: StateTypes.STATE,
},
{
  code: 'NJ',
  name: 'New Jersey',
  type: StateTypes.STATE,
},
{
  code: 'NM',
  name: 'New Mexico',
  type: StateTypes.STATE,
},
{
  code: 'NY',
  name: 'New York',
  type: StateTypes.STATE,
},
{
  code: 'NC',
  name: 'North Carolina',
  type: StateTypes.STATE,
},
{
  code: 'ND',
  name: 'North Dakota',
  type: StateTypes.STATE,
},
{
  code: 'OH',
  name: 'Ohio',
  type: StateTypes.STATE,
},
{
  code: 'OK',
  name: 'Oklahoma',
  type: StateTypes.STATE,
},
{
  code: 'OR',
  name: 'Oregon',
  type: StateTypes.STATE,
},
{
  code: 'PA',
  name: 'Pennsylvania',
  type: StateTypes.STATE,
},
{
  code: 'RI',
  name: 'Rhode Island',
  type: StateTypes.STATE,
},
{
  code: 'SC',
  name: 'South Carolina',
  type: StateTypes.STATE,
},
{
  code: 'SD',
  name: 'South Dakota',
  type: StateTypes.STATE,
},
{
  code: 'TN',
  name: 'Tennessee',
  type: StateTypes.STATE,
},
{
  code: 'TX',
  name: 'Texas',
  type: StateTypes.STATE,
},
{
  code: 'UT',
  name: 'Utah',
  type: StateTypes.STATE,
},
{
  code: 'VT',
  name: 'Vermont',
  type: StateTypes.STATE,
},
{
  code: 'VA',
  name: 'Virginia',
  type: StateTypes.STATE,
},
{
  code: 'WA',
  name: 'Washington',
  type: StateTypes.STATE,
},
{
  code: 'WV',
  name: 'West Virginia',
  type: StateTypes.STATE,
},
{
  code: 'WI',
  name: 'Wisconsin',
  type: StateTypes.STATE,
},
{
  code: 'WY',
  name: 'Wyoming',
  type: StateTypes.STATE,
},
{
  code: 'AS',
  name: 'American Samoa',
  type: StateTypes.TERRITORY,
},
{
  code: 'GU',
  name: 'Guam',
  type: StateTypes.TERRITORY,
},
{
  code: 'MP',
  name: 'Northern Mariana Islands',
  type: StateTypes.TERRITORY,
},
{
  code: 'PR',
  name: 'Puerto Rico',
  type: StateTypes.TERRITORY,
},
{
  code: 'UM',
  name: 'U.S. Minor Outlying Islands',
  type: StateTypes.TERRITORY,
},
{
  code: 'VI',
  name: 'U.S. Virgin Islands',
  type: StateTypes.TERRITORY,
},
{
  code: 'AA',
  name: 'Armed Forces Americas',
  type: StateTypes.ARMED_FORCES,
},
{
  code: 'AP',
  name: 'Armed Forces Pacific',
  type: StateTypes.ARMED_FORCES,
},
{
  code: 'AE',
  name: 'Armed Forces Europe',
  type: StateTypes.ARMED_FORCES,
},
];

export const AppStoreURL = 'https://apps.apple.com/us/app/genopalate/id1468074158?ls=1';
export const PlayStoreURL = 'http://play.google.com/store/apps/details?id=com.genopalate.mobileapp';

export const PanelResultStatuses = {
  New: 'New',
  FileParsed: 'Genetic file parsed',
  QCPassed: 'Panel QC Passed',
  QCComplete: 'Panel QC Complete',
  QCFailed: 'Panel QC Failed',
  Error: 'Error',
};

export const DomainNames = {
  FATS: 'Fats',
  PROTEIN: 'Protein',
  CARBOHYDRATES: 'Carbohydrates',
  MINERALS: 'Minerals',
  VITAMINS: 'Vitamins',
  SENSITIVITIES: 'Sensitivities',
  SUBSTANCES: 'Substances',
  CRAVINGS: 'Cravings',
  TASTE_PREFERENCES: 'Taste Preference',
  EMOTIONAL_EATING: 'Emotional Eating',
  MEAL_TIMING: 'Meal Timing',
  HUNGER_CUES: 'Hunger Cues',
  MEAL_CONTROL: 'Meal Control',
  SNACKING: 'Snacking',
  MOOD_DRIVEN_EATING: 'Mood-driven Eating',
  SLEEP_RESPONSES: 'Sleep Responses',
  SOCIAL_RESPONSES: 'Social Responses',
  MOOD_BOOSTING_FACTORS: 'Mood-boosting Factors',
  MOOD_AND_INFLAMMATION: 'Mood and Inflammation',
  TRIGGER_RESPONSES: 'Trigger Responses',
  BRAIN_RESPONSES: 'Brain Responses',
};

export const apiStatuses = {
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
};

export const AlertTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
  DEFAULT: 'default',
};

export const internalURLs = {
  SUPPLEMENT_PRODUCT_DETAILS: '/account/products/supplement-subscription',
  BLEND_PRODUCT_DETAILS: '/account/products/blend-subscription',
  CONSULTATIONS_CALENDLY: 'https://calendly.com/frankie-obrien/20min?month=',
  GENOPALATE_UNIVERSITY: 'https://www.genopalate.com/pages/genopalate-university',
  FACEBOOK_FORUM: 'https://www.facebook.com/groups/genopalatemembers',
};

export const bannerTypes = {
  MOBILE_APP: 'mobileApp',
  SUPPLEMENT: 'supplement',
};

export const defaultErrorMessage = 'There was an error processing your request.'; // generic error message to use in UI
export const genericErrorMessage = 'Something went wrong, please try again later.'; // generic error message from API

const totalConfig = {
  ...generateConfig(),
  // ANY UNIVERSAL CONSTANTS HERE
  SLP_CUTOFF_ORDER_ID: 11522,
  SUPPORT_EMAIL: 'hello@genopalate.com',
  CONTACT_SUPPORT_URL: 'https://genopalate.com/pages/contact',
  PRIVACY: 'https://genopalate.com/pages/privacy-policy',
  TERM_OF_SERVICES: 'https://genopalate.com/pages/terms-of-service',
  CONSULTATION_SESSION: 'https://calendly.com/frankie-obrien/20min',
  userStatuses,
  essentialNutritionDomains,
};

export default totalConfig;

export const marketingdata = [
  { id: 1, image: images.pea, title: 'Pea Protein', description: 'A fantastic plant-based source of protein that’s perfect for building muscle and keeping you feeling full. It’s packed with all nine essential amino acids, which are the building blocks your body needs.' },
  { id: 2, image: images.rice, title: 'Brown Rice Protein', description: 'Another excellent plant-based protein source and it’s especially rich in methionine. This pairs perfectly with pea protein, ensuring you get a complete set of amino acids to support your body’s needs.' },
  { id: 3, image: images.citraFiber, title: 'Citra Fiber', description: 'A perfect blend of both insoluble and soluble fibers. This completely natural, plant-based, gluten- and dairy-free fiber source helps with digestion and keeps you feeling satisfied. Plus, it’s 100% upcycled, which means it’s good for you and the planet.' },
  { id: 4, image: images.oatFiber, title: 'Oat Fiber', description: 'This optimal source of insoluble fiber acts as a prebiotic. Prebiotics are excellent for your digestive health, ensuring your gut bacteria stay happy and healthy.' },
  // Add more data here
];

export const marketingDataTitle = 'The ultimate boost for your wellness routine';
export const marketingDataDesc = 'Elevate your wellness journey with GenoBlend, a plant-based protein and fiber supplement formulated to your DNA. Crafted with an optimal 50:50 ratio of pea and rice proteins, GenoBlend delivers a complete amino acid profile for muscle repair and energy. Enriched with citrus and oat fiber, it not only supports digestive health but also helps regulate blood sugar and promotes sustained fullness. Whether you’re adding it to smoothies or milk, this versatile blend is your go-to for an instant wellness boost.';

const paySubscription = 'Pay Every 3-mo in Full';
const paySubscriptionMonthly = 'Pay Monthly';

const optimalFoodsHeader = 'Optimal Foods';
const optimalFoodsSubheading = "We’ve compiled the best food matches for your unique nutritional needs."
const optimalFoodsLink = "How we find your optimal foods →";

const recipeHeader = 'Personalized Recipes';
const recipeSubheading = 'Delicious and nutritious recipes handmade by a GenoPalate Dietitian';
const recipeLink = 'How we create our recipes →';

const nutrientsHeader = 'Personalized Nutrition Label';
const nutrientsSubheading = 'A day in the life of your optimal nutrition.';
const nutrientsLink = 'How we calculate our recommendations →';

const homeHeader = 'Welcome, ';
const homeSubheading = "Discovery can be fun, and we're excited you want to learn more about yourself. Every day you're making decisions about what to eat. Now with insights about your genes, you'll be able to make even smarter decisions. Whether you're in a grocery store, a farmer's market, or your neighborhood restaurant, you now have the power to personalize your nutrition based on your genetic results.";
const homeLink = 'Enjoy Eating for Your Genes!';

const deleteAccountText = "Delete your GenoPalate account and personal data. This includes genetic data, personal information, and other information collected through your use of the GenoPalate service.";
const deleteAccountTextSecondary = "Before deleting your data, we recommend carefully reviewing our comprehensive ";
const deleteAccountPrivacyPolicyText = "Privacy Statement.";
const deleteAccountWarning = "By clicking the button below, you will officially request the deletion of data associated with the following profile:";
const deleteAccountDisclaimer = "Upon submitting your request, an email will be sent to the email address associated with your GenoPalate account. This email will ask you to confirm your deletion request. Once we receive your confirmation, we will proceed with the data deletion process. You will be unable to access your account thereafter. Please note that the fulfillment of your request may take up to 30 days.";

const deleteAccountConfirmationCodeExpired = `We regret to inform you that the confirmation token you are trying to use has expired.\n\nPlease request a new confirmation email to generate a fresh token. If you encounter any further difficulties, do not hesitate to reach out to our support team for assistance. \n\nThank you for your cooperation!`;
const deleteAccountConfirmationCodeInvalid = `We are sorry, but the confirmation token you provided is not valid. \n\nPlease ensure that you are using the correct link from your email. If you continue to experience issues, please contact our support team for assistance. \n\nThank you for your understanding!`;
const deleteAccountSuccess = `Your delete request has been confirmed! \n\nYour information has been permanently removed from our databases, and you will no longer have access to your account. \n\nThank you for being a part of our community!`;
const introductoryHeading = 'INTRODUCTORY OFFER! Get an extra 15% off';
const introductorySubtext = "Use code PLANTPOWER at checkout. This offer is valid for 12 consecutive orders and becomes void if the subscription is canceled.";

const startingAt = 'Starting at';

const deleteAccountConfirmationErrorMessage = `Error: Unable to Delete Genetic Data.\n\nIt looks like we can't process your request to delete your genetic data.This may be due to one of the following:\n\n1. The deletion link you used has expired.\n2. You have already requested to delete your account, and this action cannot be performed twice.\n\nIf you believe this is a mistake or need further assistance, please contact our support team at hello@genopalate.com.`;

export {
  paySubscription,
  paySubscriptionMonthly,
  optimalFoodsHeader,
  optimalFoodsSubheading,
  optimalFoodsLink,
  recipeHeader,
  recipeSubheading,
  recipeLink,
  nutrientsHeader,
  nutrientsSubheading,
  nutrientsLink,
  homeHeader,
  homeSubheading,
  homeLink,
  deleteAccountText, 
  deleteAccountTextSecondary,
  deleteAccountPrivacyPolicyText, 
  deleteAccountWarning,
  deleteAccountDisclaimer,
  deleteAccountConfirmationCodeExpired,
  deleteAccountConfirmationCodeInvalid,
  deleteAccountSuccess,
  introductoryHeading,
  introductorySubtext,
  startingAt,
  deleteAccountConfirmationErrorMessage,
};
