import { axios } from 'utils';
import { constants } from 'resources';

export default async function runReport() {
  try {
    const params = { action: 'GENERATE_REPORT' };
    return axios.post(constants.API_RUN_REPORT, params);
  } catch (e) {
    console.log({ e });
    return null;
  }
}
