import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/assets/sharedUI';

const useStyles = makeStyles((theme) =>
  createStyles({
    banner: {
      backgroundColor: colors.white,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0
      }
    },
    icon: {
      '--fa-primary-color': colors.green_07,
      '--fa-secondary-color': colors.green_09,
    },
    heading: {
        color: colors.gray_06,
        marginBottom: '35px',
    },
    textStyles: {
        color: colors.gray_06,
        fontSize: '20px',
        lineHeight: '24px',
    }
  })
);

export default useStyles;
