import axios from '../utils/axios';
import { history } from '../routes/history';
import { clearLocalStorage } from '../utils/storage';
import { logout as expireServerSession } from '../services/auth';

/**
 * clear local authentication state (localStorage.*, axios.token) (except express cookie)
 */
const clearLocalAuthTokens = () => {
  clearLocalStorage();
  axios.setToken(null);
};

/**
 * notify server of logout (expire express session)
 * & clear local authentication state (localStorage.* including token, axios.token) (except express cookie)
 * @returns {Promise<void>}
 */
const logoutWithoutRedirect = async () => {
  await expireServerSession();
  clearLocalAuthTokens();
};

/**
 * clear all state where session auth may be stored, and redirect to the site root.
 * @returns {Promise<void>}
 */
const logout = async () => {
  await logoutWithoutRedirect();
  history.push('/');
};

export {
  clearLocalAuthTokens as clearToken,
  logout,
  logoutWithoutRedirect,
};
