const _ = require('lodash')

/**
 * Recursively normalizes nested nutrition data into a flat structure using lodash.
 *
 * @param {Object} data - The nested nutrition data to be normalized.
 * @param {string} [parentName=""] - The name of the parent element in the hierarchy.
 *
 * @returns {Array} - An array of normalized nutrition data objects.
 *
 * @typedef {Object} NutritionItem
 * @property {string} title - The normalized title of the nutrition item.
 * @property {number} amountPerServing - The amount per serving.
 * @property {string} unit - The unit of measurement.
 * @property {string} dailyValue - The daily value percentage.
 * @property {boolean} isDailyValueBasedOn2000CalorieDiet - Indicates if the daily value is based on a 2000 calorie diet.
 * @property {boolean} isDailyValueNotEstablished - Indicates if the daily value is not established.
 *
 * @description
 * This function takes a nested nutrition data structure and flattens it into a
 * normalized array of objects. Each object in the array represents a nutrition item
 * with properties such as title, amountPerServing, unit, dailyValue, and flags indicating
 * whether the daily value is based on a 2000 calorie diet or if it's not established.
 * The normalization is performed recursively, considering the hierarchy of the input data.
 *
 * @example
//  * const nutritionData = {
//  *   calories: { name: "Calories", amountPerServing: 160, /* ... */ // }
//  *   totalFat: { name: "Total Fat", amountPerServing: 2.5, /* ... */ }
//  *    ... (other nutrition items)
//  * };
//  *
//  * const normalizedData = normalizeNutritionData(nutritionData);
//  * console.log(normalizedData);
//  */

const normalizeNutritionData = (nutrients) => {
  const customData = []

  for (const nutrientKey in nutrients) {
    if (Object.hasOwnProperty.call(nutrients, nutrientKey)) {
      const nutrient = nutrients[nutrientKey]
      const { name, amountPerServing, unit, dailyValue } = nutrient

      const customItem = {
        title: `${name} ${amountPerServing} ${unit}`,
      }

      if (dailyValue) {
        customItem.title += ` ${dailyValue}`
      }

      if (nutrient.children) {
        customItem.children = convertToCustomData(nutrient.children)
      }

      customData.push(customItem)
    }
  }

  return customData
}

export default {
  normalizeNutritionData,
}
