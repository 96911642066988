import * as React from 'react'
import {
  Route, Redirect,
} from 'react-router-dom'

import { storage } from 'utils'
import { BaseContainer } from 'components'

export default ({ component: Component, additionalProps, ...props }) => {
  const token = storage.getAccessToken()
  return (
    <Route
      {...props}
      render={renderProps => (!token ? (
        <BaseContainer>
          <Component {...renderProps} {...additionalProps} />
        </BaseContainer>
      ) : (
        <Redirect
          to={{
            pathname: '/home',
          }}
        />
      ))
      }
    />
  )
}
