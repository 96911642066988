export const getAccessToken = () => localStorage.getItem('token');
export const setAccessToken = (token) => localStorage.setItem('token', token);

export const getUuidCode = () => JSON.parse(localStorage.getItem('uuidCode'));
export const setUuidCode = (uuidCode) => localStorage.setItem('uuidCode', JSON.stringify(uuidCode));

export const getUserProductV2 = () => JSON.parse(localStorage.getItem('userProductV2'));
export const setUserProductV2 = (userProductV2) => localStorage.setItem('userProductV2', JSON.stringify(userProductV2));

export const getTopFoods = () => JSON.parse(localStorage.getItem('topFoods'));
export const setTopFoods = (topFoods) => localStorage.setItem('topFoods', JSON.stringify(topFoods));

export const getPrintOption = () => JSON.parse(localStorage.getItem('printOption'));

export const clearLocalStorage = () => localStorage.clear();

export const getInitialState = (whiteList) => {
  // iterate through local storage, and extract items whose key start with the storage key
  // check if they've expired. If they have, delete them.
  Object.keys(localStorage).forEach(key => {
    const isInWhiteList = whiteList.find((whiteListed) => whiteListed.key === key);
    if (isInWhiteList) {
      const localSnapShot = JSON.parse(localStorage.getItem(key));
      let timeOfDeath;

      if (key === 'userProductV2' && localSnapShot.product && localSnapShot.product.timeOfDeath) {
        ({ timeOfDeath } = localSnapShot.product);
      }

      if (key === 'topFoods' && localSnapShot && localSnapShot.timeOfDeath) {
        ({ timeOfDeath } = localSnapShot);
      }

      if (localSnapShot && timeOfDeath) {
        const isDead = localSnapShot && timeOfDeath < Date.now();

        if (isDead) {
          localStorage.removeItem(key);
        }
      } else {
        // Remove older versions of localStorage items
        localStorage.removeItem(key);
      }
    }
  });
};

// TODO everything is already exported, why export again under a default object?
export default {
  getAccessToken,
  setAccessToken,
  getUuidCode,
  setUuidCode,
  getUserProductV2,
  setUserProductV2,
  getTopFoods,
  setTopFoods,
  clearLocalStorage,
  getInitialState,
  getPrintOption
};
