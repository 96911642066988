import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  ClickAwayListener,
  Tooltip as MuiTooltip,
  makeStyles,
} from '@material-ui/core'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles(() => ({
  tooltip: {
    maxWidth: 200,
  },
  arrow: {},
}))

const Tooltip = ({ title, children, ...props }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <>
        <MuiTooltip
          title={title}
          interactive
          classes={{ popper: classes.tooltip, arrow: classes.arrow }}
          open={open}
          onOpen={handleTooltipOpen}
          onClick={handleTooltipOpen}
          onTouchMove={handleTooltipOpen}
          onClose={handleTooltipClose}
          disableTouchListener={false}
          {...props}
        >
          {children}
        </MuiTooltip>
      </>
    </ClickAwayListener>
  )
}

Tooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

export default Tooltip
