import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const { IS_PROD } = window.env;

const middleware = [thunk];

 
if (!IS_PROD) {
  const { logger } = require(`redux-logger`);
  middleware.unshift(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(rootReducer, composeEnhancers(
  applyMiddleware(...middleware),
));
