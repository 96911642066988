// @flow
import React from 'react';
import { Button, DialogContent, DialogActions, Typography, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core'; 
import {
  Formik
} from 'formik'
import { object, bool } from 'yup'
import { sharedStyles } from 'resources'
import useStyles from './TermsForm.styles'

export default function TermsForm(props) {
  const { onSubmit } = props
  const sharedClasses = sharedStyles()
  const classes = useStyles()

    return (
      <React.Fragment>
        <Formik
          initialValues={{ checked: false }}
          onSubmit={values => onSubmit(values)}
          validationSchema={object().shape({
            checked: bool().oneOf([true], 'Please accept the terms to proceed').required('Required'),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        name="checked"
                        checked={values.checked} 
                        onChange={value => setFieldValue("checked", !values.checked)} 
                        name="termsAndPrivacyPolicy" 
                      />
                    }
                    label={
                      <Typography>
                        By clicking here you agree to our
                        {' '}
                        <a
                          href="https://www.genopalate.com/privacy"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                        {' '}
                        and
                        {' '}
                        <a
                          href="https://www.genopalate.com/terms-of-service/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Terms of Service
                        </a>
                      </Typography>
                    }
                  />
                  <FormHelperText>{(errors.checked && touched.checked) && errors.checked}</FormHelperText>
                </DialogContent>
                <DialogActions>
                  <div className={classes.buttonContainer}>
                    <Button className={sharedClasses.primaryButton} type="submit" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </div>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  }