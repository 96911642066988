import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import { withStyles, styled } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const styles = theme => ({
  card: {
    height: 45,
    width: 350,
    borderBottom: `1px solid ${colors.gray_03}`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 10,
      marginBottom: 0,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    '&:last-of-type': {
      borderBottomWidth: 0,
    },
  },
  standardPadding: {
    padding: '3px 8px 3px 20px',
  },
  iconPadding: {
    padding: '3px 8px 3px 8px',
  },
  name: {
    fontSize: 18,
    margin: 0,
    flex: 3,
  },
});

const StyledSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" {...props} />
))(() => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: colors.blue_02,
      '& + .MuiSwitch-track': {
        backgroundColor: colors.blue_03,
        opacity: 1,
      },
    },
  },
}));

const ToggleItem = (props) => {
  const {
    id, name, loading, checked, onChange, leftIcon, classes,
  } = props;

  return (
    <div
      className={clsx(classes.card, leftIcon ? classes.iconPadding : classes.standardPadding)}
      key={id}
    >
      { leftIcon && leftIcon}
      <label className={classes.name}>{name}</label>
      { loading
        ? <CircularProgress size={24} color={colors.blue_02} />
        : (
          <StyledSwitch
            checked={checked}
            onChange={onChange}
          />
        )}
    </div>
  );
};

ToggleItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  leftIcon: PropTypes.node,
  classes: PropTypes.shape({
    card: PropTypes.string,
    name: PropTypes.string,
    iconPadding: PropTypes.string,
    standardPadding: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(ToggleItem);
