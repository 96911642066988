import { installmentSupplementProductSKUs, oneTimeSupplementProductSkus, sixMonthsSupplementProductSkus, supplementProductSkus, threeMonthsSupplementProductSkus } from 'resources/constants'

/**
 * Calculates the percentage based on the given part and total.
 * @param {number} initialPrice - The initialPrice value.
 * @param {number} newPrice - The newPrice value (should not be zero).
 * @returns {number} - The calculated percentage
 */
export const calculateDiscountedPrice = (initialPrice, newPrice) =>
  ((initialPrice - newPrice) / initialPrice) * 100

export const SUBSCRIPTION_DURATIONS = {
  THREE: 3,
  SIX: 6,
  ONE_TIME: undefined, //because the duration is undefined
  MONTHLY: 1 //because the billing duration is monthly
}

export const SUBSCRIPTION_PRODUCT = {
  GENOVIT: 'GenoVit',
}

export const SUBSCRIPTION_TYPE = {
  UPDATED: 'UPDATE_SUBSCRIPTION',
  PAUSE: 'PAUSE_SUBSCRIPTION'
}

const parsePlans = (products = []) =>
  products.map((product) => ({
    supplyPlan: product.duration,
    pricePerMonth: product.pricePerMonth,
    planPrice: parseFloat(product.price),
    duration: product.duration,
    durationUnit: product.durationUnit,
  })).sort((a, b) => a.duration - b.duration); // sort the plans because we don't know in which order plans are coming from backend(shopify)

export const getSubscriptionPlans = (subscriptionProducts = []) => {
  const baseSupplementProducts = subscriptionProducts?.filter((subscription) =>
    [supplementProductSkus.VS0000, supplementProductSkus['VS0000-6MO']].includes(
      subscription.sku
    )
  )

  return parsePlans(baseSupplementProducts)
}

/**
 * This function filters the subscription products based on provided SKUs and then parses the plans.
 *
 * @param {Array} subscriptionProducts - An array of subscription products. Default is an empty array.
 * @param {Array} SKUs - An array of SKUs to filter by.
 * @throws {Error} If subscriptionProducts or SKUs is not an array.
 * @returns {Array|null} Returns the parsed plans of the filtered subscription products, or null if parsing fails.
 *
 * @example
 * const subscriptionProducts = [
 *   { sku: 'VS0000-1MO', name: 'Product 1', price: 10 },
 *   { sku: 'VS0000-2MO', name: 'Product 2', price: 20 },
 * ];
 * const plans = getSpecificSubscriptionPlans(subscriptionProducts, ['VS0000-1MO', 'VS0000-2MO']);
 */
export const getSpecificSubscriptionPlans = (subscriptionProducts = [], SKUs) => {
  if (!Array.isArray(subscriptionProducts) || !Array.isArray(SKUs)) {
    throw new Error('subscriptionProducts and SKUs must be arrays');
  }

  const baseSupplementProducts = subscriptionProducts.filter(subscription => SKUs.includes(subscription.sku));

  try {
    return parsePlans(baseSupplementProducts);
  } catch (error) {
    console.error('Failed to parse plans:', error);
    return [];
  }
};

/**
 * Represents a subscription plan.
 * @typedef {Object} SubscriptionPlan
 * @property {string} supplyPlan - The duration of the subscription plan.
 * @property {string} planPrice - The price of the subscription plan.
 * @property {boolean} isActive - Indicates whether the plan is currently active.
 */

/**
 * Array of subscription plans.
 * @type {SubscriptionPlan[]}
 */
export const plans = [
  { supplyPlan: 3, planPrice: '$89/mo' },
  { supplyPlan: 6, planPrice: '$67/mo' },
  // Add more plans as needed.
]

/**
 * Generates subscription information based on the supply plan.
 *
 * @param {string|number} supplyPlan - The supply plan duration.
 * @returns {{
 *   message: string,
 *   boosterPrice: number
 * }} - An object containing the subscription message and the corresponding price.
 */
export const subscriptionInfo = (supplyPlan) => {
  const planInfo = {
    '3': { message: 'Automatically billed & shipped in 3-month installments', boosterPrice: 25 },
    '6': { message: 'Automatically billed & shipped in 6-month installments', boosterPrice: 20 },
    '1': { message: 'Delivered every 3 months, billed monthly', boosterPrice: 25 },
    default: { message: 'One-time payment and shipment of a 3-month supply', boosterPrice: 25 },
  };

  return planInfo[String(supplyPlan)] || planInfo.default;
};

/**
 * Get the supply plan duration based on the supplement product SKU.
 * @param {string} sku - The SKU of the supplement product.
 * @returns {3|6|undefined} - Supply plan duration (3 or 6 months) or undefined if not found.
 */
export const getTheSupplyPlanBasedOnSupplementProductSku = (sku) => {
  if (oneTimeSupplementProductSkus[sku]) return undefined;

  if (threeMonthsSupplementProductSkus[sku]) return 3;

  if (sixMonthsSupplementProductSkus[sku]) return 6;
  
  if (installmentSupplementProductSKUs[sku]) return 1;
  
  return undefined
}


export default {
  plans,
  SUBSCRIPTION_DURATIONS,
  getSubscriptionPlans,
  calculateDiscountedPrice,
  getSpecificSubscriptionPlans,
  subscriptionInfo,
  getTheSupplyPlanBasedOnSupplementProductSku
}
