import { axios } from 'utils';
import { constants } from 'resources';

export default async function getAccountContracts() {
  try {
    return await axios.get(constants.API_GET_ACCOUNT_CONTRACTS);
  } catch (e) {
    console.log({ e });
    return { e };
  }
}
