import React from 'react'
import PropTypes from 'prop-types'

import { Select, MenuItem, Typography, FormControl, FormHelperText } from '@material-ui/core'
import { useStyles } from './DropDownPicker.styles'

/**
 * DropDownPicker Component
 *
 * @component
 *
 * @param {Object} props - Properties of the DropDownPicker component.
 * @param {Array} props.data - Dropdown options.
 * @param {string} props.value - Selected value.
 * @param {string} props.placeholder - Placeholder text.
 * @param {string} props.error - Error text.
 * @param {function} props.handleDropDownChange - Change event handler.
 * @param {Object} props.extraStylesDropDownPicker - Additional styles for the component.
 * @param {Object} props.extraStylesDropDownPickerItem - Additional styles for dropdown items.
 *
 * @returns {JSX.Element} - Rendered DropDownPicker component.
 */

const DropDownPicker = ({ data, value, placeholder, error, handleDropDownChange, extraStylesDropDownPicker, extraStylesDropDownPickerItem }) => {
    const classes = useStyles()
    return (
        <FormControl error={error} fullWidth>
            {error ? <FormHelperText classes={{ error: classes.error }}>{error}</FormHelperText> : null }
            <Select value={value} onChange={handleDropDownChange}
                disableUnderline={true}
                IconComponent={() => () => null}
                classes={{ select: classes.select }}
                className={[classes.flavorDropDownPicker, extraStylesDropDownPicker]}
                displayEmpty
                >
                {placeholder && (
                    <MenuItem value='' className={[classes.dropDownItem, extraStylesDropDownPickerItem]}>
                        <Typography variant='body2' classes={{ body2: classes.placeholder }}>{placeholder}</Typography>
                    </MenuItem>
                )}
                {data?.map((item, index) => (
                    <MenuItem key={index} className={[classes.dropDownItem, extraStylesDropDownPickerItem]} value={item?.value}>{item?.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

DropDownPicker.propTypes = {
    data: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    handleDropDownChange: PropTypes.func.isRequired,
    extraStylesDropDownPicker: PropTypes.object,
    extraStylesDropDownPickerItem: PropTypes.object,
  };
  

export default DropDownPicker