import { getAxiosInstanceWithNoLoginRedirect } from '../../utils/axios';

export default async () => {
  try {
    // todo: make the endpoint idempotent. this throws http 401 if you're not already logged in.
    // We are using axios instance that does not redirect user to login page on 401, this special handling is needed
    // if user clicks on create account button after purchasing the EDNA/Kit kit
    await getAxiosInstanceWithNoLoginRedirect().post('/api/v1/auth/logout');
  } catch (error) {
    if (!error.response || error.response.status !== 401) {
      throw error;
    }
  }
};
