import unionBy from 'lodash/unionBy';
import actionTypes from '../actionTypes';

const initialUserGeneDetailsState = {
  data: [],
  fetching: false,
  error: null,
};

const userGeneDetailsReducer = (state = initialUserGeneDetailsState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_GENE_DETAILS_STARTED:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_USER_GENE_DETAILS_SUCCESS:
      return {
        ...state,
        data: unionBy(state.data, action.data, 'traitId'),
        fetching: false,
      };
    case actionTypes.GET_USER_GENE_DETAILS_ERROR:
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default userGeneDetailsReducer;
