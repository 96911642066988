import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles({
  paperContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    width: '92%',
    border: `1px solid ${colors.darkGrey}`,
    padding: '2rem',
    zIndex: 10,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 'bold',
  },
  icon: {
    width: 40,
    height: 40,
  },
  descriptionContainer: {
    marginTop: 20,
  },
  myBestSourcesContainer: {
    backgroundColor: colors.white,
    padding: 22,
    marginTop: 30,
  },
  carouselContainer: {
    marginTop: 30,
    justifyContent: 'space-around',
    height: 200,
  },
  bestSourcesContainer: {
    borderRadius: 10,
    padding: 15,
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 160,
    margin: '0 auto',
  },
  bestSourcesImage: {
    height: 130,
  },
  btnContainer: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    marginTop: 10,
  },
})

export default useStyles
