import React, {
  useState, useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Loading } from 'components/componentsV2';
import useStyles from './ConsultationLandingPage.styles';
import { colors } from 'common/assets/sharedUI';
import { useQueryParams } from 'hooks';
import { images, sharedStyles } from 'resources';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { internalURLs } from 'resources/constants';
import { EventActions, EventCategories, EventLabels, registerGAClickEventEnhanced } from '../utils/googleanalytics';

const ConsultationLandingPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const sharedClasses = sharedStyles();


  const [loading, setLoading] = useState(false);

  useEffect(() => { 
    handleFirstTimeLoad();
  }, []);

  const handleLinkClicked = () => {
    registerGAClickEventEnhanced(EventCategories.RDConsultation, EventActions.Click, EventLabels.ConsultationLandingScheduleLinkClicked);
     window.open(internalURLs.CONSULTATIONS_CALENDLY, '_blank');
  }

  const handleFirstTimeLoad = () => {
    registerGAClickEventEnhanced(EventCategories.RDConsultation, EventActions.Click, EventLabels.ConsultationLandingViaEmail);
  }


  if (loading) return <Loading />;

  return (
    <>
      <Grid container className={classes.container}>
        <Grid className={classes.imageContainer} lg={6} md={6} sm={12} xs={12}>
          <img src={images.consultation} alt="GenoPalate Logo" className={classes.image} />
        </Grid>
        <Grid className={classes.contentContainer} lg={6} md={6} sm={12} xs={12}>
          <Grid className={classes.starContainer}>
            <StarIcon className={classes.starIcon} />
            <StarIcon className={classes.starIcon} />
            <StarIcon className={classes.starIcon} />
            <StarIcon className={classes.starIcon} />
            <StarIcon className={classes.starIcon} />
          </Grid>
          <Grid>
            <Typography className={[classes.title, classes.mt20]}>Review your results with a registered dietitian</Typography>
            <Typography className={[classes.text, classes.mt20]}>Sign up for a complimentary 20-minute session with a dietitian to decode your genetic nutrition results. We're here to support you every step, with flexibility to fit your schedule.</Typography>

            <Grid container className={classes.bullet}>
              <Grid xs={1} lg={1} className={classes.checkIconContainer}>
                <FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />
              </Grid>
              <Grid xs={11} lg={11}>
                <Typography className={[classes.text, classes.pl10, classes.checkText]}>Review your genetic nutrition reports</Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.bullet}>
              <Grid xs={1} lg={1} className={classes.checkIconContainer}>
                <FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />
              </Grid>
              <Grid xs={11} lg={11}>
                <Typography className={[classes.text, classes.pl10, classes.checkText]}>Create actionable steps</Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.bullet}>
              <Grid xs={1} lg={1} className={classes.checkIconContainer}>
                <FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />
              </Grid>
              <Grid xs={11} lg={11}>
                <Typography className={[classes.text, classes.pl10, classes.checkText]}>Introduce daily wellness opportunities with custom-formula supplements</Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.statistics}>
              <Grid xs={12} lg={3}>
                <Typography className={[classes.title, classes.textCenter]}>67%</Typography>
              </Grid>
              <Grid lg={9} xs={12}>
                <Typography className={classes.text}>of members felt the session was extremely effective at helping them understand their genetic nutrition results.</Typography>
              </Grid>
          </Grid> 
            <Grid container className={[classes.statistics, classes.mt20]}>
              <Grid xs={12} lg={3}>
                <Typography className={[classes.title, classes.textCenter]}>93%</Typography>
              </Grid>
              <Grid lg={9} xs={12}>
                <Typography className={classes.text}>of members felt they will be more successful on their nutrition journey after attending the session.</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Button
                className={classes.scheduleButton}
                type="submit"
                onClick={handleLinkClicked}
                >
                  Schedule Now
              </Button>
              <Button
                className={classes.laterButton}
                type="submit"
                href="/"
              >
                I'll do this later
              </Button>
            </Grid>
            <Typography className={classes.disclaimer}>*Statistics provided are based on surveved GenoPalate members who have undergone an onboarding session with a registered dietitian.</Typography>
          </Grid>
        </Grid>
       
          
          
      </Grid>
       <Grid container className={classes.testimonialSection}>
        
        <Grid item xs={12}>
          <Typography className={classes.testimonialsTitle}>What Our Members Are Saying</Typography>
        </Grid>
        <Grid container xs={12} style={{marginTop: 50}}>
          <Grid container xs={12} md={4} direction="column" justify="center" >
            <Grid className={classes.starContainer}>
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
            </Grid>
            <Grid>
              <Typography className={classes.testimonialText}>"It was a great experience and really helped<br />remind me of where I need to add nutrition to<br />my diet to be more affective!"</Typography>
              <Typography className={classes.testimonialAuthor}>JULIE S.</Typography>
            </Grid>
          </Grid>
          <Grid container xs={12} md={4} direction="column" justify="center" >
            <Grid className={classes.starContainer}>
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
            </Grid>
            <Grid>
              <Typography className={classes.testimonialText}>"Absolutely I firmly believe knowledge is power<br />and health is too important to not know as<br />much as you can about how to take care of<br />yourself."</Typography>
              <Typography className={classes.testimonialAuthor}>AUDRA E.</Typography>
            </Grid>
            
          </Grid>
          <Grid container xs={12} md={4} direction="column" justify="center" >
            <Grid className={classes.starContainer}>
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
              <StarIcon className={classes.starIconSmall} />
            </Grid>
            <Grid>
              <Typography className={classes.testimonialText}>"Yes - I think it is a great way to understand<br />what nutrients the individual person needs and<br />how your body processes foods for overall<br />health.</Typography>
              <Typography className={classes.testimonialAuthor}>CASEY D.</Typography>
            </Grid>
          </Grid>
        </Grid>
          
         </Grid>
    </>
  )


};

export default ConsultationLandingPage;

ConsultationLandingPage.propTypes = {
  detailView: PropTypes.bool.isRequired,
  selected: PropTypes.string.isRequired,
};





