import React, { Fragment } from "react"
import { withRouter } from "react-router-dom"

class ScrollToTop extends React.PureComponent {

  render() {
    const { children } = this.props

    return <Fragment>
      {children}
    </Fragment>
  }
}

const TOP = 0
const LEFT = 0

export const componentDidUpdate = function componentDidUpdate(prevProps) {
  const { location } = this.props

  if (location.pathname !== prevProps.location.pathname) {
    window.scrollTo(LEFT, TOP)
  }
}

ScrollToTop.prototype.componentDidUpdate = componentDidUpdate

export default withRouter(ScrollToTop)