import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogTitle from './DialogTitle';
import MultiSelectQuestion from './MultiSelectQuestion';
import { sharedStyles } from '../../resources';

const Success = ({ handleCloseSuccess, sharedClasses }) => (
  <React.Fragment>
    <DialogTitle>Cancellation complete</DialogTitle>
    <DialogContent>
      <DialogContentText className={sharedClasses.body}>
        Your personalized supplement subscription has been successfully canceled.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button className={sharedClasses.rebrandedPrimaryButton} onClick={handleCloseSuccess}>
        Continue
      </Button>
    </DialogActions>
  </React.Fragment>
)

const Confirmation = ({
  setShowConfirmation,
  handleCancel,
  reasonId,
  cancelQuestionnaireChoices,
  comments,
  sharedClasses
}) => (
  <React.Fragment>
    <DialogContent>
      <DialogContentText className={sharedClasses.body}>
        You did not supply cancel reason. Are you sure you wish to continue?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button className={sharedClasses.rebrandedPrimaryButton} onClick={() => setShowConfirmation(false)}>
        No
      </Button>
      <Button
        className={sharedClasses.rebrandedPrimaryButton}
        onClick={() => handleCancel(
          reasonId ? cancelQuestionnaireChoices[reasonId] : 'No reason provided',
          comments || undefined,
        )}
      >
        Yes
      </Button>
    </DialogActions>
  </React.Fragment>
)

const ReasonForCancelingForm = ({
  choices,
  reasonId,
  setReasonId,
  comments,
  setComments,
  handleContinue,
  handleClose,
  sharedClasses,
  questionText
}) => (
  <React.Fragment>
    <DialogTitle onClose={handleClose}>Reason for Canceling</DialogTitle>
    <DialogContent
      dividers="paper"
      style={{ height: 400 }}
    >
      <MultiSelectQuestion
        questionText={questionText}
        choices={choices}
        updateAnswer={(e) => setReasonId(parseInt(e.target.value, 10))}
        selectedAnswer={reasonId}
        style={{ marginBottom: 10 }}
      />
      <TextField
        id="comments-on-cancel"
        label="Additional comments"
        multiline
        rows={4}
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        inputProps={{ maxLength: 500 }}
        style={{ width: '100%', marginBottom: 10 }}
      />
    </DialogContent>
    <DialogActions>
      <Button className={sharedClasses.rebrandedPrimaryButton} onClick={handleClose}>
        Cancel
      </Button>
      <Button
        className={sharedClasses.rebrandedPrimaryButton}
        onClick={handleContinue}
      >
        Continue
      </Button>
    </DialogActions>
  </React.Fragment>
)

const cancelQuestionnaireChoices = [
  'It\'s too expensive.',
  'I already have more than I need.',
  'I\'m not seeing or feeling the benefits of this product.',
  'My doctor recommended that I no longer use this product.',
  'I\'m experiencing side effects.',
  'I\'m not interested in automatic renewal or a subscription.',
  'I don\'t like the taste and/or smell.',
  'I\'m using or looking into a different product.',
  'Other reason.',
];

const GENO_VIT_QUESTION_TEXT = "What is your reason for canceling your GenoVit subscription?";

const CancelSubscriptionDialog = ({
  open, handleClose, handleCancel, success, handleCloseSuccess, questionText = GENO_VIT_QUESTION_TEXT
}) => {
  const [reasonId, setReasonId] = useState(null);
  const [comments, setComments] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const sharedClasses = sharedStyles();

  const formattedChoiceArray = useMemo(() => cancelQuestionnaireChoices.map((choice, index) => ({ id: index, choiceText: choice })), []);

  const handleContinue = useCallback(() => {
    if (reasonId === null && (comments === null || comments === '')) {
      setShowConfirmation(true);
    } else {
      handleCancel(
        reasonId !== null ? cancelQuestionnaireChoices[reasonId] : 'No reason provided',
        comments || undefined,
      );
    }
  }, [comments, reasonId, handleCancel]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={success ? handleCloseSuccess : handleClose}
        aria-labelledby="cancel-subscription-dialog"
      >
        {success ? (
          <Success handleCloseSuccess={handleCloseSuccess} sharedClasses={sharedClasses} />
        ) : (
          showConfirmation ? (
            <Confirmation
              setShowConfirmation={setShowConfirmation}
              handleCancel={handleCancel}
              reasonId={reasonId}
              cancelQuestionnaireChoices={cancelQuestionnaireChoices}
              comments={comments}
              sharedClasses={sharedClasses}
            />
          ) : (
            <ReasonForCancelingForm
              choices={formattedChoiceArray}
              reasonId={reasonId}
              setReasonId={setReasonId}
              comments={comments}
              setComments={setComments}
              handleContinue={handleContinue}
              handleClose={handleClose}
              sharedClasses={sharedClasses}
              questionText={questionText}
            />
          )
        )}
      </Dialog>
    </div>
  );
};

CancelSubscriptionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
  handleCloseSuccess: PropTypes.func.isRequired,
  questionText: PropTypes.string
};

export default CancelSubscriptionDialog;
