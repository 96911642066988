import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../common/assets/sharedUI';

const useStyles = makeStyles((theme) =>
  createStyles({
    banner: {
      backgroundColor: colors.green_06,
    },
    icon: {
      '--fa-primary-color': colors.green_07,
      '--fa-secondary-color': colors.green_10,
      '--fa-secondary-opacity': 1
    },
    textStyles: {
        color: colors.gray_06
    },

    extraStyles: {
      paddingLeft: 88,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 24 // default
      },
    }
  })
);

export default useStyles;
