import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSubscriptionProducts } from 'store/actions/additionalProducts'

/**
 * fetch or load from redux global state cache ('state.additionalProducts.subscriptionProducts')
 * @param {React.DependencyList} dependencies
 * @returns {{
 *  subscriptionsFetching: boolean,
 *  subscriptions: { subscriptionId: number | string, subscriptionNumber: string, subscriptionName: string, subscriptionItems: string[] | null[], duration: number, durationUnit: DurationUnit, status: SubscriptionStatusNames, nextOrderDate: Date, nextDeliveryDate: Date}[] | null,
 *  subscriptionsError: Error | null
 * }}
 */
const useSubscriptionProducts = () => {
  const dispatch = useDispatch()

  const { data, fetching, error } = useSelector(
    (state) => state.additionalProducts.subscriptionProducts
  )

  useEffect(() => {
    if (!fetching && !data && !error) {
      dispatch(fetchSubscriptionProducts())
    }
  }, [fetching, dispatch, data, error])

  return {
    subscriptionProductsFetching: fetching,
    subscriptionProducts: data,
    subscriptionProductsError: error,
  }
}

export default useSubscriptionProducts
