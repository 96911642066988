import React from 'react'
import PropTypes from 'prop-types'

import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles'
import { colors } from 'common/assets/sharedUI'

const useStyles = makeStyles(() => ({
  customDivider: (props) => ({
    height: `${props.thickness}px`,
    backgroundColor: props.color,
  }),
}))

/**
 * Custom Seprator Component.
 * 
 * @typedef {'horizontal' | 'vertical'} Orientation
 *
 * @param {object} props - The component's props.
 * @param {number} props.thickness - The thickness of the divider in pixels.
 * @param {string} props.color - The color of the divider.
 * @param {Orientation} [props.orientation='horizontal'] - The orientation of the divider. Can be 'horizontal' or 'vertical'. Defaults to 'horizontal'.
 * @returns {JSX.Element} - React element representing the custom divider.
 */
const Seprator = ({
  thickness = 1,
  color = colors.black,
  orientation = 'horizontal',
}) => {
  const classes = useStyles({ thickness, color })

  return <Divider className={classes.customDivider} orientation={orientation} />
}

export default Seprator

Seprator.propTypes = {
  thickness: PropTypes.number,
  color: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
}
