import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { userStatuses } from 'common/resources/constants';
import {
  Loading,
  TermsModal,
  UploadDna,
} from 'components/componentsV2';
import WhatToExpectKit from 'components/WhatToExpectKit';
import StatusMessage from 'components/StatusMessage';
import { useSelector, useDispatch } from 'react-redux';
import { selectStatus } from 'store/actions/status';
import KitRegistrationSuccessPage from 'components/KitRegistrationSuccessPage';
import ReportLanding from './ReportLanding';
import RerunReport from './RerunReport';
import ReportInProgressPage from './ReportInProgressPage';
import JumpGoVideo from 'components/JumpGoVideo';
import { apiStatuses } from 'resources/constants';

const WebAppHomePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const status = useSelector(state => state.status.userStatus);
  const registrationNextStepClicked = useSelector(state => state.registration.registrationNextStep);
  const videoNextStepClicked = useSelector(state => state.registration.videoNextStep);
  const statusLoading = useSelector(state => state.status.status);

  const handleStatus = () => {
    // check if the user status has a questionnaire or consent status
    // and redirect to questionnaire path
    if (statusLoading === apiStatuses.RESOLVED) {
      switch (get(status, 'webDisplay', '')) {
        case userStatuses.BEGIN_QUESTIONNAIRE_KIT:
        case userStatuses.BEGIN_QUESTIONNAIRE_EXISTING:
        case userStatuses.CONTINUE_QUESTIONNAIRE_KIT:
        case userStatuses.CONTINUE_QUESTIONNAIRE_EXISTING:
        case userStatuses.CONSENT_TO_RESEARCH_AND_BIOBANK_EXISTING:
        case userStatuses.CONSENT_TO_RESEARCH_AND_BIOBANK_NEW:
        case userStatuses.CONSENT_TO_RESEARCH_EXISTING:
        case userStatuses.CONSENT_TO_RESEARCH_FILE:
        case userStatuses.CONSENT_TO_RESEARCH_KIT:
          history.push('/questionnaire/onboarding/');
          break;
        case userStatuses.RECIPE_QUESTIONNAIRE:
          history.push('/questionnaire/recipe/');
          break;
        default:
          break;
      }
    }
  }

  // status API functionality
  useEffect(() => {
    handleStatus()
  }, [status]);

  const getScreen = useMemo(() => {
    switch (status.webDisplay) {
      case userStatuses.GET_TERMS:
        return <TermsModal open handleClose={dispatch.bind(this, selectStatus())} />;
      case userStatuses.SHOULD_UPLOAD_BARCODE_OLD:
        return <WhatToExpectKit newVersion={false} skipBarcode={false} />;
      case userStatuses.SHOULD_UPLOAD_BARCODE_NEW:
        return <WhatToExpectKit newVersion skipBarcode={false} />;
      case userStatuses.SHOULD_UPLOAD_DNA:
        return <UploadDna />;
      case userStatuses.RENDER_REPORT:
        return <ReportLanding />;
      case userStatuses.STATUS_ERROR:
        return <StatusMessage message={status.webCopy} />;
      case userStatuses.USER_ON_HOLD:
      case userStatuses.REPORT_IN_DEVELOPMENT:
        return <ReportInProgressPage />;
      case userStatuses.NEW_BARCODE_REPORT_IN_DEVELOPMENT: {
        if (registrationNextStepClicked) {
          return <ReportInProgressPage />;
        }
        return <KitRegistrationSuccessPage />;
      }
      case userStatuses.RERUN_REPORT:
        return <RerunReport />;
      case userStatuses.JUMP_GO_VIDEO_KIT:
        if (videoNextStepClicked) {
          history.push('/questionnaire/onboarding/');
          return null;
        }
        return <JumpGoVideo type='kit' />;
      case userStatuses.JUMP_GO_VIDEO_FILE:
        if (videoNextStepClicked)
          return <UploadDna />;
        return <JumpGoVideo type='DNA' />;
      case userStatuses.BEGIN_QUESTIONNAIRE_KIT:
      case userStatuses.BEGIN_QUESTIONNAIRE_EXISTING:
      case userStatuses.CONTINUE_QUESTIONNAIRE_KIT:
      case userStatuses.CONTINUE_QUESTIONNAIRE_EXISTING:
      case userStatuses.CONSENT_TO_RESEARCH_AND_BIOBANK_EXISTING:
      case userStatuses.CONSENT_TO_RESEARCH_AND_BIOBANK_NEW:
      case userStatuses.CONSENT_TO_RESEARCH_EXISTING:
      case userStatuses.CONSENT_TO_RESEARCH_FILE:
      case userStatuses.CONSENT_TO_RESEARCH_KIT:
      case userStatuses.RECIPE_QUESTIONNAIRE:
        return null;
      default:
        return <StatusMessage message={status.webCopy} />;
    }
  }, [status, registrationNextStepClicked, videoNextStepClicked]);

  if (statusLoading === apiStatuses.PENDING) return <Loading />;

  if (!status) return null;

  return (
    <div>
      {getScreen}
    </div>
  );
};

export default WebAppHomePage;
