import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionOrders } from 'store/actions/subscription';

/**
 * fetch or load from redux global state cache ('state.subscription.subcriptionOrders')
 * @param {React.DependencyList} dependencies
 * @returns {{
 *  subscriptionOrdersFetching: boolean,
 *  subscriptionOrders: { orderId: number | string, name: string, ecommerceOrderNumber: string, ccLastDigits: string | null, orderNumber: string, status: string, trackingNumber: string | null, subscriptionId: number, subscriptionStatus: string }
 *  subscriptionOrdersError: Error | null
 * }}
 */
const useSubscriptionOrders = () => {
  const dispatch = useDispatch();
  const { fetching, data, error } = useSelector(state => state.subscription.subscriptionOrders);

  useEffect(() => {
    if (!fetching && !data && data !== [] && !error) {
      dispatch(getSubscriptionOrders());
    }
  }, [fetching, dispatch, data, error]);

  return {
    subscriptionOrdersFetching: fetching,
    subscriptionOrders: data,
    subscriptionOrdersError: error,
  };
};

export default useSubscriptionOrders;
