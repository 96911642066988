import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    width: '100%',
    marginTop: 10,
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
  recommendationContainer: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  leftArrow: {
    backgroundColor: colors.chartGrey,
    [theme.breakpoints.down('xs')]: {
      minWidth: 50,
      position: 'absolute',
      left: 20,
    },
  },
  rightArrow: {
    backgroundColor: colors.chartGrey,
    [theme.breakpoints.down('xs')]: {
      minWidth: 50,
      position: 'absolute',
      right: 20,
    },
  },
  arrowIcon: {
    display: 'block',
    color: colors.white,
  },
  nutrientBenefitsContainer: {
    width: '100%',
    backgroundColor: colors.darkestGreen,
    padding: 15,
    paddingTop: 100,
    paddingBottom: 100,
    color: colors.white,
    marginTop: -50,
    marginBottom: -50,
  },
  nutrientDescriptionContainer: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  keyBenefitsContainer: {
    backgroundColor: colors.blue,
    padding: 15,
    paddingTop: 100,
    paddingBottom: 100,
    color: colors.white,
    width: '100%',
    marginTop: -50,
    // marginBottom: -50,
  },
  geneContainer: {
    marginTop: 30,
  },
  subtitle: {
    color: colors.white,
    fontWeight: 800,
  },
}));

export default useStyles;
