import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core';
import {
  faExternalLink, faChevronRight, faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faClock,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'common/assets/sharedUI';
import { Label } from 'components/componentsV2';
import { useRecipes } from '../../hooks';
import Analytics from './analytics';

const useStyles = makeStyles({
  pageContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  recipeCardContainer: {
    borderRadius: 8,
  },
  recipeTipWell: {
    backgroundColor: colors.gray_01,
    padding: 20,
    borderRadius: 8,
  },
  sendFeedbackCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  sendFeedbackContent: {
    padding: 20,
  },
  recipesWrapper: {
    width: '100%',
    '@media screen and (min-width: 600px)': {
      width: '75%',
    },
    display: 'flex',
    alignItems: 'stretch',
    flexWrap: 'wrap',
  },
  cardWrapper: {
    marginBottom: '2%',
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  thumbailBackground: {
    backgroundColor: colors.gray_02,
    height: 150,
  },
  recipeTitle: {
    fontSize: 18,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '22px',
    marginTop: '5px',
  },
  metaDataWrapper: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    alignItems: 'center',
  },
  metaData: {
    fontSize: 14,
    color: colors.gray_04,
  },
});

const getMealSortOrder = (meal) => {
  switch (meal.trim().toLowerCase()) {
    case 'breakfast': return 0;
    case 'lunch': return 1;
    case 'dinner': return 2;
    default: return 3;
  }
}

const RecipeCard = ({
  name,
  meal,
  cookTime,
  pdfUrl,
  servings,
  thumbnailUrl,
  ...props
}) => {
  const onClick = useCallback(() => {
    Analytics.onRecipeClick({ mealType: meal, name });
    window.open(pdfUrl, '_blank', 'noreferrer');
  }, [pdfUrl, name, meal]);

  const classes = useStyles();

  return (
    <div className={classes.cardWrapper}>
      <Card className={classes.card}>
        <CardActionArea onClick={onClick} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', height: '100%', justifyContent: 'flex-start' }}>
          <div className={classes.thumbailBackground}>
            <CardMedia component="img" image={thumbnailUrl} height={150} alt={name} />
          </div>
          <CardContent>
            <Label text={meal} />
            <Typography className={classes.recipeTitle}>
              {name} <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: 10 }} />
            </Typography>
            <div className={classes.metaDataWrapper}>
              <Typography className={classes.metaData} style={{ marginRight: 10 }}>
                Serves: <FontAwesomeIcon icon={faUsers} /> {servings}
              </Typography>
              <Typography className={classes.metaData}>
                Cook: <FontAwesomeIcon icon={faClock} /> {cookTime} min
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

const RecipesPage = ({
  ...props
}) => {
  const classes = useStyles();

  const { fetching: fetchingRecipes, recipes, error } = useRecipes();

  if (recipes.length === 0) {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <div style={{ paddingLeft: 20 }}>
            {fetchingRecipes
              ? <p>Loading recipes.</p>
              : error
              ? <p style={{ fontSize: '1rem' }}>Your recipes could not be loaded at this time. Please check back later.</p>
              : <p style={{ fontSize: '1rem' }}><FontAwesomeIcon icon={faClock} size="2x" /> Our team is working hard to create your personalized recipes. We will send you an email when they are ready, or you can check back here!</p>
            }
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={0} justify={'space-between'}>
        <Grid container spacing={2} md={8} xs={12}>
            {recipes
              .sort(({ meal: leftMeal, name: leftName }, { meal: rightMeal, name: rightName }) =>
                getMealSortOrder(leftMeal) - getMealSortOrder(rightMeal) ||
                leftName.localeCompare(rightName))
              .map((recipe, index) => 
                <Grid item xs={12} sm={6} md={4} key={recipe.id}>
                  <RecipeCard key={index} {...recipe} />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4}>
            <div className={classes.cardWrapper}>
              <Card className={classes.sendFeedbackCard}>
                <CardContent className={classes.sendFeedbackContent}>
                  <h2>Send us Your Feedback!</h2>
                  <p>We’d love to know your thoughts on your personalized recipes.</p>
                  <a href="https://www.surveymonkey.com/r/J78YP7V" target="_blank" rel="noreferrer">Give feedback <FontAwesomeIcon icon={faExternalLink} /></a>
                </CardContent>
              </Card>
            </div>
            </Grid>
          </Grid>
          <Grid item md={3} xs={12}>
          <div className={classes.recipeTipWell}>
            <h2>Recipe Tips:</h2>
            <h3>Schedule a Time to Cook</h3>
            <p>Take a look at your calendar. When do you have time to cook? Maybe it's a Saturday or Sunday when you're a bit less busy. Next, set a reminder. "Hey Siri, on Saturday at 5PM remind me to make my GenoPalate recipes!"</p>
            <h3>Shop for the Ingredients</h3>
            <p>It's much easier to make a recipe if you have all the ingredients on hand. This is a simple one, but don't overlook it. When will you go shopping for these ingredients (and your other GenoPalate foods)?</p>
            <h3>Get your Family involved</h3>
            <p>The kitchen is a great place to bond with your family. Ask your kids to help out by prepping ingredients or setting the table.</p>
            <h3>Invite a Friend for Dinner</h3>
            <p>Your friends will be impressed by a meal that is healthy and delicious. It'll also make it easy to decide what's for dinner.</p>
          </div>
      </Grid>
    </Grid>
  );
}

export default RecipesPage;
