import get from 'lodash/get';
import { throwIfErrorResponse, parseCartResponse } from './utils';

export default (shopifyQuery) => async (cartId) => {
  const response = await shopifyQuery(`{
    cart(id: "${cartId}") {
      id
      totalQuantity
      checkoutUrl
      cost {
        totalAmount {
          amount
        }
        subtotalAmount {
          amount
        }
      }
      lines(first: 50) {
        nodes {
          id
          merchandise {
            __typename
            ... on ProductVariant {
              id
              sku
              price {
                amount
              }
              product {
                title
                featuredImage {
                  url
                }
              }
            }
          }
          quantity
          cost {
            totalAmount {
              amount
            }
          }
          attributes {
            key
            value
          }
        }
      }
    }
  }`);

  throwIfErrorResponse(response);

  return parseCartResponse(get(response, 'data.data.cart', {}));
};
