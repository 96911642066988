import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import {
  Grid, Typography, Popover, IconButton, Paper,
} from '@material-ui/core';
import AnswerWrapper from 'components/AnswerWrapper';
import ProgressBar from 'components/ProgressBar';
import useStyles from './QuestionWrapper.styles';
import { registerGAClickEventEnhanced } from '../utils/googleanalytics';

export default function QuestionWrapper(props) {
  // Main container for all questions and answers
  const {
    question,
    questionnaireId,
    trackProgress,
    onClickBackBtn,
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Popover functionality
  // Handle IconButton click to open popover
  const handleClick = (event) => {
    registerGAClickEventEnhanced(`Question-${question[0].id}`, 'Click', 'Info');
    setAnchorEl(event.currentTarget);
  };

  const createMarkup = (children) => ({
    __html: children,
  });

  // Popover properties
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const popOverProps = {
    id,
    open,
    anchorEl,
    onClose: () => {
      setAnchorEl(null);
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  if (question.length === 0) {
    return null;
  }

  const [{
    questionText,
    questionSubtext,
    purpose,
    imageLocation,
    questionGroupName,
  }] = question;

  const answerProps = {
    question,
    questionnaireId,
    onClickBackBtn,
    questionGroupName,
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.center}>
        {trackProgress ? (
          <ProgressBar />
        ) : null}
      </Grid>
      <Grid item xs={12} className={classes.center}>
        {
          imageLocation ? (
            <img className={classes.image} src={imageLocation} alt="" /> // TODO: check how the image size will be handled
          ) : null
        }
      </Grid>
      <Grid item xs={12} className={classes.center}>
        <div className={classes.titleContainer}>
          <Typography variant="h5" gutterBottom>{questionGroupName || questionText}</Typography>
          {
            purpose ? (
              <React.Fragment>
                <IconButton aria-describedby={id} onClick={handleClick}>
                  <InfoIcon className={classes.icon} />
                </IconButton>
                <Popover {...popOverProps}>
                  <Paper className={classes.popoverPaper}>
                    <Typography variant="body1" className={classes.bold}>Why do we ask this?</Typography>
                    <Typography variant="body1" name="purpose">{purpose}</Typography>
                  </Paper>
                </Popover>
              </React.Fragment>
            ) : null
          }
        </div>
        <Typography variant="subtitle1" className={classes.subtext}>
          <div className={classes.subtextContainer} dangerouslySetInnerHTML={createMarkup(questionSubtext)} />
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.center}>
        <AnswerWrapper {...answerProps} />
      </Grid>
    </Grid>
  );
}

QuestionWrapper.propTypes = {
  question: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    questionGroupId: PropTypes.number,
    questionText: PropTypes.string,
    questionSubtext: PropTypes.string,
    questionTypeId: PropTypes.number,
    purpose: PropTypes.string,
    sortOrder: PropTypes.number,
    singleAnswer: PropTypes.bool,
    minAnswerCount: PropTypes.number,
    maxAnswerCount: PropTypes.number,
    mandatory: PropTypes.bool,
    imageLocation: PropTypes.string,
    questionChoices: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      choiceText: PropTypes.string,
      choiceSubtext: PropTypes.string,
      imageLocation: PropTypes.string,
      overlayLocation: PropTypes.string,
      isNoneChoice: PropTypes.bool,
    })),
    answer: PropTypes.shape({
      id: PropTypes.number,
      answerChoices: PropTypes.arrayOf(PropTypes.shape({
        questionChoiceId: PropTypes.number,
      })),
      answerText: PropTypes.arrayOf(PropTypes.any),
      dateResponse: PropTypes.string,
      noneResponse: PropTypes.bool,
      numberResponse: PropTypes.number,
      timeResponse: PropTypes.string,
    }),
  })).isRequired,
  trackProgress: PropTypes.bool.isRequired,
  questionGroupName: PropTypes.string,
  questionnaireId: PropTypes.number.isRequired,
  onClickBackBtn: PropTypes.func.isRequired,
};

QuestionWrapper.defaultProps = {
  questionGroupName: null,
};
