import { combineReducers } from 'redux';
import { get } from 'lodash';
import types from '../actionTypes';
import getStatusReducer from './statusReducer';

function errorReducer(state = null, action) {
  switch (action.type) {
    case types.GET_CONTRACT_ERROR:
    case types.GET_ACCOUNT_CONTRACTS_ERROR:
    case types.ADD_CONSENT_ERROR:
      return action.payload;
    case types.GET_CONTRACT_SUCCESS:
    case types.GET_ACCOUNT_CONTRACTS_SUCCESS:
    case types.ADD_CONSENT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function contractsReducer(state = null, action) {
  switch (action.type) {
    case types.GET_CONTRACT_SUCCESS:
      return get(action, 'payload.contracts', null);
    case types.GET_CONTRACT_ERROR:
      return null;
    default:
      return state;
  }
}

function accountContractsReducer(state = null, action) {
  switch (action.type) {
    case types.GET_ACCOUNT_CONTRACTS_SUCCESS:
      return get(action, 'payload.data.contracts', null);
    case types.GET_ACCOUNT_CONTRACTS_ERROR:
      return null;
    default:
      return state;
  }
}

function addConsentReducer(state = null, action) {
  switch (action.type) {
    case types.ADD_CONSENT_STARTED:
      return get(action, 'payload', null);
    case types.ADD_CONSENT_ERROR:
      return null;
    default:
      return state;
  }
}

const getContractActionTypes = {
  pending: types.GET_CONTRACT_STARTED,
  resolved: types.GET_CONTRACT_SUCCESS,
  rejected: types.GET_CONTRACT_ERROR,
};

const getAccountContractsActionTypes = {
  pending: types.GET_ACCOUNT_CONTRACTS_STARTED,
  resolved: types.GET_ACCOUNT_CONTRACTS_SUCCESS,
  rejected: types.GET_ACCOUNT_CONTRACTS_ERROR,
};

const addConsentActionTypes = {
  pending: types.ADD_CONSENT_STARTED,
  resolved: types.ADD_CONSENT_SUCCESS,
  rejected: types.ADD_CONSENT_ERROR,
};

export default combineReducers({
  getContractStatus: getStatusReducer(getContractActionTypes),
  contracts: contractsReducer,
  getAccountContractsStatus: getStatusReducer(getAccountContractsActionTypes),
  accountContracts: accountContractsReducer,
  addConsentStatus: getStatusReducer(addConsentActionTypes),
  addConsent: addConsentReducer,
  error: errorReducer,
});
