import { nutrition } from 'services'
import types from '../actionTypes'

export const getEERStarted = () => ({ type: types.GET_EER_STARTED })

export const getEERSuccess = payload => ({ type: types.GET_EER_SUCCESS, payload })

export const getEERFailure = payload => ({ type: types.GET_EER_ERROR, payload })

export const getEER = () => async dispatch => {
  dispatch(getEERStarted())
  try {
    const response = await nutrition.getEER()
    dispatch(getEERSuccess(response))
  } catch (error) {
    dispatch(getEERFailure(error))
  }
}
