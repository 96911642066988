export const BARCODE_DIGIT_LENGTH = 9; // digits

const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const TWENTY_MINUTES = ONE_MINUTE * 20;

// create a white list.  key is the redux store key, and lifeSpan is
// the length of time the value should be stored for in milliseconds.
const reduxStorageWhiteList = [
  { key: 'userProductV2', lifeSpan: TWENTY_MINUTES },
  { key: 'topFoods', lifeSpan: TWENTY_MINUTES },
];

export default reduxStorageWhiteList;
