import React from 'react'
import clsx from 'clsx'
import { colors } from 'common/assets/sharedUI'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: props => ({
    borderRadius: '50%',
    width: props.width || 28,
    height: props.height || 28,
    color: colors.darkGrey,
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  }),
  checkedIcon: props => ({
    boxShadow: 'none',
    color: colors.blue,
    width: props.width || 28,
    height: props.height || 28,
    '&:before': {
      display: 'block',
      content: '""',
    },
  }),
})

export default function CustomRadio(props) {
  const classes = useStyles(props)

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<RadioButtonCheckedIcon className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<RadioButtonUncheckedIcon className={classes.icon} />}
      {...props}
    />
  )
}
