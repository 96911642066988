import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 101,
    backgroundColor: colors.white,
    height: 90,
    justifyContent: 'center',
  },
  menuButton: {
    color: colors.darkestGrey,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    flexGrow: 1,
    marginTop: 90,
    width: '100%',
    textTransform: 'uppercase',
    color: colors.blue,
    height: 'auto',
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: 50,
  },
  space: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  button: {
    marginLeft: 30,
    color: colors.darkestGrey,
    fontWeight: 500,
    '&:hover': {
      color: colors.highlightsBlue,
      backgroundColor: colors.transparent,
    },
  },
  selectedButton: {
    marginLeft: 30,
    color: colors.highlightsBlue,
    fontWeight: 800,
    '&:hover': {
      color: colors.highlightsBlue,
      backgroundColor: colors.transparent,
    },
  },
  list: {
    padding: 0,
  },
  listItem: {
    backgroundColor: colors.lightGrey,
  },
  menuImg: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '9px',
    marginLeft: '80px',
  },
  shopButton: {
    backgroundColor: colors.green_07,
    color: colors.white,
    fontSize: '16px',
    textTransform: 'capitalize',
    borderRadius: '3px',
    height: '34px',
    width: '100px',
    '&:hover': {
      backgroundColor: colors.green_07,
      color: colors.white,
    },
  },
  userIcon: {marginRight: '15px'},
  menu: { zIndex: 10000, marginTop: '70px' }
}));

export default useStyles;
