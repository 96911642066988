import React, { PureComponent } from "react"
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

type Props = {}
class TabPanel extends PureComponent<Props> {
  render() {
    if (this.props.active === false)
      return null;
    
    return (
      <Grid item xs={12} style={{paddingTop: 20}}>
          {this.props.children}
      </Grid>
    )
  }
}

export default TabPanel;