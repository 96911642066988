import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InputBarcode from './InputBarcode'
import WhatToExpectKitList from './WhatToExpectKitList'

const WhatToExpectKit = ({ newVersion, skipBarcode }) => {
  const [barcodeReceived, setBarcodeReceived] = useState(false)

  return barcodeReceived
    ? <InputBarcode newVersion={newVersion} skipBarcode={skipBarcode} />
    : <WhatToExpectKitList setBarcodeReceived={() => setBarcodeReceived(true)} />
};

WhatToExpectKit.propTypes = {
  newVersion: PropTypes.bool.isRequired,
  skipBarcode: PropTypes.bool.isRequired,
};

export default WhatToExpectKit;
