import { colors } from 'common/assets/sharedUI'

const styles = theme => ({
  chromosome: {
    margin: '0 auto',
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
    },
  },
  prevalenceContainer: {
    display: 'flex',
    placeItems: 'center',
    flexFlow: 'column',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  chromosomeContainer: {
    display: 'flex',
    placeItems: 'center',
    flexFlow: 'column',
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  container: {
    padding: 20,
  },
  divider: {
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },
  percentageLabel: {
    marginTop: 10,
  },
  chartText: {
    fontSize: 30,
  },
  descriptionLabel: {
    marginTop: 20,
  },
  geneDescriptionLabel: {
    marginBottom: 40,
    marginTop: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  genotypeDescription: {
    marginTop: 20,
  },
  variantTitle: {
    marginBottom: 20,
  },
  variantNumber: {
    fontWeight: 'bold',
    fontSize: '1.75rem',
    marginRight: 10,
  },
  link: {
    color: colors.blue,
  },
})

export default styles
