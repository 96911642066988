import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  Stepper, Step, StepLabel, StepConnector, withStyles, makeStyles,
  StepContent, Typography,
} from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { colors } from 'common/assets/sharedUI'
import clsx from 'clsx'

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
  stepContent: {
    marginTop: 8,
    marginLeft: 12,
    paddingLeft: 20,
    paddingRight: 8,
  },
  imgContainer: {
    width: 35,
    height: 35,
    position: 'relative',
    top: -90,
  },
})

export default function CustomStepper(props) {
  const {
    score, color, steps, stepContent, stepButton, stepImages, orientation, customStyles,
  } = props

  const classes = useStyles()
  const isVertical = orientation === 'vertical'

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 15,
    },
    active: {
      '& $line': {
        backgroundColor: color,
      },
    },
    completed: {
      '& $line': {
        backgroundColor: color,
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: colors.lightGrey,
      borderRadius: 1,
    },
  })(StepConnector)

  const ColorlibVerticalConnector = withStyles({
    lineVertical: {
      borderColor: colors.green,
    },
  })(StepConnector)

  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: colors.lightGrey,
      zIndex: 1,
      color: 'transparent',
      borderRadius: '50%',
      width: isVertical ? 25 : 35,
      height: isVertical ? 25 : 35,
    },
    active: {
      backgroundColor: color,
    },
    completed: {
      backgroundColor: color,
    },
  })

  function CustomStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        <FiberManualRecordIcon />
      </div>
    )
  }

  const stepperProps = {
    style: {
      padding: 10,
    },
    alternativeLabel: !isVertical,
    activeStep: score,
    orientation,
    connector: isVertical
      ? <ColorlibVerticalConnector />
      : <ColorlibConnector />,
  }

  return (
    <Stepper {...stepperProps}>
      {steps.map((label, index) => (
        <Step key={label} expanded>
          <StepLabel StepIconComponent={CustomStepIcon}>
            {customStyles ? (
              <div>
                {
                  get(stepImages, `[${index}].active`, null) && !isVertical ? (
                    <img
                      src={index > score ? stepImages[index].inactive : stepImages[index].active}
                      className={classes.imgContainer}
                      alt=""
                    />
                  ) : null
                }
                <Typography variant="body1" className={classes.bold}>{label}</Typography>
              </div>
            ) : label}
          </StepLabel>
          {stepContent ? (
            isVertical ? (
              <StepContent>
                <Typography variant="body1" style={{ color: index > score ? 'rgba(0, 0, 0, 0.54)' : 'black' }}>{stepContent[index]}</Typography>
                {score === index ? stepButton[index] : null}
              </StepContent>
            ) : (
              <div className={classes.stepContent}>
                <Typography variant="body1" style={{ color: index > score ? 'rgba(0, 0, 0, 0.54)' : 'black' }}>{stepContent[index]}</Typography>
                {score === index ? stepButton[index] : null}
              </div>
            )
          ) : null}
        </Step>
      ))}
    </Stepper>
  )
}

CustomStepper.propTypes = {
  score: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  stepContent: PropTypes.arrayOf(PropTypes.string),
  stepButton: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  stepImages: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  customStyles: PropTypes.bool,
}

CustomStepper.defaultProps = {
  stepContent: [],
  stepButton: {},
  stepImages: {},
  customStyles: false,
}
