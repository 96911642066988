import { Dialog, DialogActions, DialogContent, DialogTitle, Button, makeStyles } from '@material-ui/core';
import { colors, fonts } from 'common/assets/sharedUI';
import React from 'react';

const useStyles = makeStyles({
  container: {
    padding: '20px',
  },
  title: {
    fontFamily: fonts.BaskervilleBold,
    fontSize: '28px',
  },
  subtitle: {
    fontFamily: fonts.Swiss721MD,
    fontSize: '18px',
  },
  text: {
    fontFamily: fonts.Swiss721LT,
    fontSize: '16px',
  },
  cancelButton: {
    backgroundColor: colors.gray_03,
    color: colors.white,
    textTransform: 'capitalize',
    fontSize: '16px',
  },
  deleteButton: {
    backgroundColor: colors.red,
    color: colors.white,  
    textTransform: 'capitalize',
    fontSize: '16px',
  },
});
    
const DeleteAccountDisclaimer = ({ handleDeleteAccount, open, handleCancel }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleCancel} className={classes.container}>
      <DialogTitle disableTypography='true'
        className={classes.title}
      >Before You Go</DialogTitle>
      <DialogContent
        disableTypography='true'
        className={classes.subtitle}>
        GenoVit & GenoBlend Subscription Cancelation Notice
      </DialogContent>
      <DialogContent
        disableTypography='true'
        className={classes.text}>
        Deleting your account and DNA data will cancel all active subscriptions immediately. This action is irreversible and will result in the loss of access to personalized insights and services. If you are currently being billed monthly for your GenoVit subscription, you will continue to be charged until all payments from past shipments have been received. Once past deliveries have been paid in full, your payment method will not be charged again.
      </DialogContent>
      <DialogContent
        disableTypography='true'
        className={classes.text}>
          Please consider these implications before proceeding. For assistance, contact our support team at hello@genopalate.com.
      </DialogContent>
            <DialogContent
>     <DialogActions style={{marginBottom: '20px'}}>
        <Button onClick={handleCancel}
          variant="contained"
          className={classes.cancelButton}
        >Cancel</Button>
        <Button onClick={handleDeleteAccount}
          variant="contained"
          color="error"
          className={classes.deleteButton}>
          Delete My Account & Data</Button>
        </DialogActions>
        </DialogContent>
 
    </Dialog>
  );
};

export default DeleteAccountDisclaimer;
