import React, { useCallback } from 'react';

import {
  Box,
  Container,
  Grid,
  Button,
  Typography,
  makeStyles,
  FormControl,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { Accordion, Seprator } from 'components/shared';
import { colors } from 'common/assets/sharedUI';
import { registerThirdPartyMarketPlaceKit } from 'store/actions/registration';
import { registerGAClickEventEnhanced } from 'utils/googleanalytics';
import { images } from 'resources';

import {
  EventActions,
  EventCategories,
  EventLabels,
} from '../../utils/googleanalytics';
import { fonts } from '../../common/assets/sharedUI';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },

  container: {
    marginTop: theme.spacing(3),
  },

  navbar: {
    position: 'relative',
    marginBottom: 30,
  },

  logo: {
    width: 120,
    margin: 'auto',
    display: 'block',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: 'unset',
      width: 100,
    },
  },

  links: {
    position: 'absolute',
    display: 'flex',
    gap: 20,
    top: 10,
    right: 0,
  },

  image: {
    width: '100%',
    height: 'auto',
  },
  formContainer: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    maxWidth: '400px',
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },

  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },

  description: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  registerButton: {
    backgroundColor: colors.green_07,
    color: colors.white,
    fontSize: '16px',
    textTransform: 'capitalize',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: colors.green_07,
      color: colors.white,
    },
  },

  kitFAQContainer: {
    backgroundColor: '#FAFAFA',
    marginTop: '5rem',
    padding: '1.5rem',
  },

  heading: {
    fontFamily: fonts.Baskerville,
    color: colors.green_07,
  },

  errorText: {
    padding: 4,
    margin: 4,
    borderStyle: 'solid',
    borderRadius: 4,
    backgroundColor: 'rgba(180,0,0,.15)',
    color: colors.red,
    textAlign: 'center',
  },

  successText: {
    padding: 4,
    margin: 4,
    borderStyle: 'solid',
    borderRadius: 4,
    backgroundColor: 'rgba(0,180,0,.15)',
    color: colors.green_07,
    textAlign: 'center',
  },
}));

const RegisterTMKit = () => {
  const classes = useStyles();
  const registerKit = useSelector((state) => state.registerKit);

  return (
    <>
      <Container className={clsx(classes.container)}>
        <Box className={classes.navbar}>
          <img
            src={images.logoNoTagline}
            alt='GenoPalate logo'
            className={classes.logo}
          />

          <Box className={classes.links}>
            <Link to='/login'>
              <Typography>Sign In</Typography>
            </Link>
            <Link to='/register'>
              <Typography>Register Account</Typography>
            </Link>
          </Box>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <img
              src='https://ucarecdn.com/c6054489-1e8e-406f-bd22-028e51181e54/-/format/auto/-/preview/1500x1500/-/quality/lighter/dnakit-hands-video-dna-diet-test.jpg'
              alt='Placeholder'
              className={classes.image}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Box>
              <RegisterTMKitForm />
              {registerKit.error ? (
                <Grid item xs={12} className={classes.errorText}>
                  <span>{registerKit.error}</span>
                </Grid>
              ) : null}

              {registerKit.status === 'resolved' && registerKit.message ? (
                <Grid item xs={12} className={classes.successText}>
                  <span>{registerKit.message}</span>
                </Grid>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Grid container spacing={4} className={classes.kitFAQContainer}>
        <Grid item xs={12}>
          <Container maxWidth='md'>
            <KitFAQ />
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterTMKit;

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email').required('This field is required'),
  kitBarCode: Yup.string().required('This field is required'),
  shippingZipCode: Yup.string()
    .matches(/^\d{5}(-\d{4})?$/, 'Must be a valid US ZIP code')
    .required('This field is required'),
});

const RegisterTMKitForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSubmit = useCallback(async (values) => {
    registerGAClickEventEnhanced(
      EventCategories.Registration,
      EventActions.RegisterKitClicked,
      EventLabels.RegisterThirdPartyDNAkit,
    );
    await dispatch(registerThirdPartyMarketPlaceKit(values));
  }, []);

  return (
    <Box>
      <Typography variant='h4' className={clsx(classes.heading)}>
        Register Your DNA Kit
      </Typography>
      <Typography variant='body1' className={classes.description}>
        Congrats on taking the next step in your personalized nutrition journey!
        If you purchased your DNA Collection Kit from a third-party marketplace,
        please enter your information below to receive your order and product
        numbers.
      </Typography>

      <Formik
        initialValues={{
          email: '',
          kitBarCode: '',
          shippingZipCode: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          errors,
          isSubmitting,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <FormControl
                className={clsx(classes.margin)}
                variant='outlined'
                fullWidth
              >
                <OutlinedInput
                  id='email'
                  name='email'
                  placeholder='Email Address'
                  aria-describedby='user-email-address'
                  inputProps={{
                    'aria-label': 'email address',
                  }}
                  labelWidth={0}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                <FormHelperText error>
                  {touched.email && errors.email}
                </FormHelperText>
              </FormControl>
              <FormControl
                className={clsx(classes.margin)}
                variant='outlined'
                fullWidth
              >
                <OutlinedInput
                  id='kit-barcode'
                  name='kitBarCode'
                  placeholder='Kit Barcode'
                  aria-describedby='kit-barcode'
                  inputProps={{
                    'aria-label': 'kit barcode',
                  }}
                  value={values.kitBarCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.kitBarCode && Boolean(errors.kitBarCode)}
                  helperText={touched.kitBarCode && errors.kitBarCode}
                  labelWidth={0}
                />
                <FormHelperText error>
                  {touched.kitBarCode && errors.kitBarCode}
                </FormHelperText>
              </FormControl>
              <FormControl
                className={clsx(classes.margin)}
                variant='outlined'
                fullWidth
              >
                <OutlinedInput
                  id='shipping-zip-code'
                  name='shippingZipCode'
                  placeholder='Shipping Zip Code'
                  aria-describedby='shipping-zip-code'
                  inputProps={{
                    'aria-label': 'Shipping Zip Code',
                  }}
                  value={values.shippingZipCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.shippingZipCode && Boolean(errors.shippingZipCode)
                  }
                  helperText={touched.shippingZipCode && errors.shippingZipCode}
                  labelWidth={0}
                />
                <FormHelperText error>
                  {touched.shippingZipCode && errors.shippingZipCode}
                </FormHelperText>
              </FormControl>

              <Button
                type='submit'
                variant='contained'
                size='large'
                disabled={isSubmitting}
                className={clsx(classes.margin, classes.registerButton)}
              >
                {isSubmitting ? 'Please wait...' : 'Request Registration Codes'}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export const KitFAQ = () => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant='h4' align='center' className={clsx(classes.heading)}>
        Frequently Asked Questions
      </Typography>

      <Accordion
        expend={false}
        title='Where can I find my barcode?'
        content={
          <Typography variant='subtitle2'>
            You will find your barcode located on the back of your at-home
            collection kit.
          </Typography>
        }
      />

      <br />
      <Seprator color='#0000001f' />
      <br />

      <Accordion
        expend={false}
        title='How do I complete my DNA Kit registration?'
        content={
          <Typography variant='subtitle2'>
            Completing your DNA Kit registration is a straightforward process.
            Once you fill out the form provided above, our team will generate
            your unique GenoPalate order number and product code, which are
            essential for completing your account setup. You will receive these
            details via email within a timeframe of 24-48 hours. With your
            GenoPalate order number and product code in hand, you can easily
            complete your account setup and embark on your personalized
            nutrition journey.
          </Typography>
        }
      />
    </Box>
  );
};
