/**
 * Custom React hook to fetch user blend types and associated nutrition data.
 * This hook utilizes Redux for state management and performs initial data fetching
 * upon hook invocation.
 *
 * @returns {{
 *   userBlend: object | null, // User's blend data
 *   blendNutrition: object,     // Associated nutrition data for the blend
 * }}
 *
 * @typedef {Object} UserBlendTypeData
 * @property {object | null} userBlendSKU - User's blend SKU (for base product).
 * @property {object} blendNutrition - Associated nutrition data for the blend.
 *
 * @typedef {Object} ReduxState
 * @property {boolean} fetching - Indicates if data is currently being fetched.
 * @property {object | null} data - Fetched data, may contain userBlend and nutrition.
 * @property {Error | null} error - An error object if the fetching process encounters an error.
 *
 * @typedef {import('react').Dispatch<any>} Dispatch - Redux dispatch function.
 *
 * @typedef {Object} AdditionalProductsState
 * @property {ReduxState} userGenoBlendType - State for user blend types and nutrition.
 *
 * @typedef {Object} AdditionalProductsActions
 * @property {() => void} fetchUserBlendTypes - Redux action to fetch user blend types.
 * @property {() => void} fetchSubscriptionGenoBlendProducts - Redux action to fetch subscription GenoBlend products.
 *
 * @typedef {Object} HookDependencies
 * @property {boolean} fetching - Indicates if data is currently being fetched.
 * @property {ReduxState} data - Fetched data, may contain userBlend and nutrition.
 * @property {Error} error - An error object if the fetching process encounters an error.
 *
 * @typedef {Object} HookParams
 * @property {HookDependencies} HookDependencies - Dependencies needed by the hook.
 * @property {Dispatch} Dispatch - Redux dispatch function.
 * @property {AdditionalProductsActions} AdditionalProductsActions - Redux actions for additional products.
 * @property {function} useEffect - React useEffect function.
 * @property {function} useSelector - React Redux useSelector function.
 * @property {function} useDispatch - React Redux useDispatch function.
 *
 * @typedef {function(): UserBlendTypeData} useUserBlendType - Custom hook to fetch user blend types and nutrition.
 *
 * @type {useUserBlendType}
 */

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserBlendTypes } from '../store/actions/additionalProducts'
import { genoBlendHelper } from '../utils'

const useUserBlendType = () => {
  const dispatch = useDispatch()

  /**
   * Redux state for user blend types and nutrition.
   * @type {ReduxState}
   */
  const { fetching, data, error } = useSelector(
    (state) => state.additionalProducts.userGenoBlendType
  )

  //TODO: fix the data
  const { userBlend, nutrition } = data || {}
  const {
    servingSize = 1,
    servingSizeScoop = '1 scoop',
    servingsPerContainer = 14,
  } = nutrition || {}

  /**
   * useEffect to fetch user blend types if data is not available and no error occurred.
   */
  useEffect(() => {
    if (!fetching && !data && !error) {
      dispatch(fetchUserBlendTypes())
    }
  }, [fetching, dispatch, data, error])

  /**
   * Return userBlend and nutrition.
   * @type {UserBlendTypeData}
   */

  return {
    userBlendSKU: userBlend,
    blendNutrition: nutrition?.facts,
    servingSize,
    servingSizeScoop,
    servingsPerContainer,
  }
}

export default useUserBlendType
