import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Paper, Typography,
} from '@material-ui/core'
import { sharedStyles } from 'resources'
import useStyles from './RecipeQuestionnaireIntro.styles'

export default function RecipeQuestionnaireIntro(props) {
  const { onClick } = props
  const sharedClasses = sharedStyles()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Paper className={classes.root}>
        <React.Fragment>
          <Typography variant="h5" className={classes.bold}>One more thing</Typography>
          <Typography variant="body1" className={classes.description}>Before you get to your results, we need you to answer a few recipe related questions so our awesome team can personalize your recipes.</Typography>
          <Button className={sharedClasses.primaryButton} onClick={onClick}>Continue</Button>
        </React.Fragment>
      </Paper>
    </div>
  )
}

RecipeQuestionnaireIntro.propTypes = {
  onClick: PropTypes.func.isRequired,
}
