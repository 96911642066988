import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  WelcomeMessage,
  ToggleItem,
} from 'components/componentsV2';
import {
  faChevronLeft,
} from '@fortawesome/pro-solid-svg-icons';
import { Link, useRouteMatch } from 'react-router-dom';
import { Grid, Icon } from '@material-ui/core';
import { getPreferences, setPreference } from 'store/actions/preferences';
import { getIcon, sortFilter } from 'utils/dataTools';
import ReactGA from 'react-ga4';
import { apiStatuses } from 'resources/constants';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import styles from './PreferenceSettingsPage.styles';

const { IS_PROD, IS_STAGING } = window.env;

const ToggleWrapper = (props) => {
  const {
    setting, onToggle, displayReverse, children,
  } = props;
  const setPreferenceStatus = useSelector(state => state.preferences.setPreferenceStatus);
  const [loading, setLoading] = useState(false);

  const onChange = useCallback(
    () => {
      setLoading(true);
      onToggle(setting.id, setting.displayName, !setting.enabled);
    }, [setting, onToggle],
  );

  useEffect(() => {
    if (setPreferenceStatus === apiStatuses.RESOLVED) setLoading(false);
  }, [setPreferenceStatus]);

  return (
    <ToggleItem
      id={setting.id}
      name={setting.displayName}
      loading={loading}
      checked={displayReverse ? !setting.enabled : setting.enabled}
      onChange={onChange}
      leftIcon={children}
    />
  );
};

const PreferenceSettingsPage = (props) => {
  const { classes, type } = props; // type is "diets" or "allergies"
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const dietaryPreferences = useSelector(state => state.preferences.dietaryPreferences);
  const allergyPreferences = useSelector(state => state.preferences.allergies);
  const currentPreferences = type === 'allergies'
    ? sortFilter(allergyPreferences)
    : sortFilter(dietaryPreferences);

  useEffect(() => {
    if (type === 'diets' && (!dietaryPreferences || dietaryPreferences.length === 0)) dispatch(getPreferences());
    if (type === 'allergies' && (!allergyPreferences || allergyPreferences.length === 0)) dispatch(getPreferences());
  }, [type, dietaryPreferences, allergyPreferences, dispatch]);

  const onToggle = useCallback(
    (id, name, enabled) => {
      registerGAClickEventEnhanced(
        EventCategories.FoodTools,
        enabled === true ? EventActions.On : EventActions.Off,
        EventLabels.Setting(name),
      );
      dispatch(setPreference(id, enabled));
    },
    [dispatch, type],
  );

  useEffect(() => {
    if (IS_PROD || IS_STAGING) {
      ReactGA.send({ hitType: "pageview", page: match.url });
    }
  }, [match]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.pageWrapper}>
          <Link to="/food/settings">
            <div className={classes.backLink}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <label className={classes.backLinkText}>Back to Settings</label>
            </div>
          </Link>
          <WelcomeMessage title={type === 'allergies' ? 'Allergies and Preferences' : 'Diet Types'} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <p className={classes.pageDescription}>
          {type === 'diets'
            ? 'Turn on the toggle for the diet type you follow. This will adjust the foods in your list.'
            : 'Turn off the toggle for any categories you don\'t want to see on your optimal foods list.'}
        </p>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.settingsWrapper}>
          {currentPreferences.map((setting) => (
            <ToggleWrapper setting={setting} onToggle={onToggle} displayReverse={type === 'allergies'}>
              <Icon className={classes.icon}>
                <img src={getIcon(setting.displayName)} className={classes.img} alt="" />
              </Icon>
            </ToggleWrapper>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

ToggleWrapper.propTypes = {
  setting: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
    dietaryPreference: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
  }).isRequired,
  onToggle: PropTypes.func.isRequired,
  displayReverse: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

PreferenceSettingsPage.propTypes = {
  type: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    pageWrapper: PropTypes.string.isRequired,
    pageDescription: PropTypes.string.isRequired,
    backLink: PropTypes.string.isRequired,
    backLinkText: PropTypes.string.isRequired,
    settingsWrapper: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(PreferenceSettingsPage);
