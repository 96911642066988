import axios from 'axios';
import { getAccessToken } from './storage';
import serviceErrorHandler from './serviceErrorHandler';

export const setInstanceDefaultAuthorization = (instance, bearer) => {
  // eslint-disable-next-line no-param-reassign
  instance.defaults.headers.common.Authorization = `Bearer ${bearer}`;
};

/**
 * get axios client instance with auth and basic error handling set up
 */
export const getAxiosInstance = (() => {
  let instance;
  return () => {
    instance = instance || axios.create({
      timeout: 10000000,
    });
    setInstanceToken(instance);

    instance.interceptors.response.use(response => response, serviceErrorHandler);
    return instance;
  };
})();

function setInstanceToken(axiosInstance) {
  axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
  axiosInstance.setToken = (bearer) => setInstanceDefaultAuthorization(axiosInstance, bearer);
  const token = getAccessToken();
  axiosInstance.setToken(token);
}

export const getAxiosInstanceWithNoLoginRedirect = (() => {
  let anotherInstance;
  return () => {
    anotherInstance = anotherInstance || axios.create({
      timeout: 10000000,
    });
    setInstanceToken(anotherInstance);
    return anotherInstance;
  };
})();

/**
 * axios instance with auth token and basic error handling set up
 * @deprecated no import side effects, use getAxiosInstance
 * @see getAxiosInstance
 */
export default getAxiosInstance();
