import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import CustomRadio from 'components/CustomRadio';
import { useStyles } from './styles';
import { paySubscription, paySubscriptionMonthly } from 'resources/constants';
import { SUBSCRIPTION_DURATIONS } from 'utils/subscriptionHelpers';

/**
 * `InstallmentButtons` is a React component that renders a set of radio buttons for selecting a subscription plan.
 *
 * @component
 * @param {Object} props - The properties that define the component's behavior and display.
 * @param {string} props.subscriptionPlan - The selected subscription plan.
 * @param {string} props.installmentPlan - The selected installment plan.
 * @param {string} props.supplyPlanID - The ID of the supply plan.
 * @param {Function} props.setSupplyPlanID - The function to set the supply plan ID.
 * @param {boolean} props.disabled - A flag indicating whether the component is disabled.
 *
 * @example
 * <InstallmentButtons subscriptionPlan="premium" installmentPlan="monthly" supplyPlanID="123" setSupplyPlanID={setSupplyPlanID} disabled={false} />
 *
 * @returns {React.Element} The rendered `InstallmentButtons` component.
 */
const InstallmentButtons = ({
  subscriptionPlan,
  installmentPlan,
  supplyPlanID,
  setSupplyPlanID,
  disabled,
}) => {
  const { container, radioButtonsWrapper, installmentButtonText } = useStyles();
  const customRadio = <CustomRadio height={'14px'} width={'14px'} />;

  const renderPrice = (price, pricePerMonth) =>
    `$${Math.floor(price)} ($${Math.floor(pricePerMonth)}/mo)`;

  const handleChange = useCallback(
    (value) => {
      setSupplyPlanID(value);
    },
    [setSupplyPlanID]
  );

  const renderFormControlLabel = (value, label, style = {}) => (
    <FormControlLabel
      value={value}
      style={style}
      control={customRadio}
      label={<Typography className={installmentButtonText}>{label}</Typography>}
      onChange={() => handleChange(value)}
    />
  );

  return (
    <Grid container className={container}>
      <Grid item sm={8}>
        <FormControl disabled={disabled}>
          <RadioGroup className={radioButtonsWrapper} value={+supplyPlanID}>
            {renderFormControlLabel(
              SUBSCRIPTION_DURATIONS.THREE,
              `${paySubscription} ${renderPrice(
                subscriptionPlan.planPrice,
                subscriptionPlan.pricePerMonth
              )}`
            )}
            {renderFormControlLabel(
              SUBSCRIPTION_DURATIONS.MONTHLY,
              `${paySubscriptionMonthly} $${Math.floor(
                +installmentPlan.pricePerMonth
              )}/mo`,
              { marginTop: '-7px' }
            )}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

InstallmentButtons.propTypes = {
  subscriptionPlan: PropTypes.shape({
    planPrice: PropTypes.number.isRequired,
    pricePerMonth: PropTypes.number.isRequired,
  }).isRequired,
  installmentPlan: PropTypes.shape({
    pricePerMonth: PropTypes.number.isRequired,
  }).isRequired,
};

export default InstallmentButtons;
