import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './CounterComponent.styles'

const CounterComponent = ({
  quantity,
  setQuantity,
  min = 1,
  max = 25,
  label,
  disabled,
  counterContainerClass
}) => {
  const classes = useStyles()

  const handleIncrement = () => {
    quantity < 25 && setQuantity(quantity + 1)
  }

  const handleDecrement = () => {
    quantity !== 1 && setQuantity(quantity - 1)
  }

  const handleQuantityChange = (e) => {
    const { value } = e.target
    // Remove non-numeric characters and ensure the entered value is within the range [1, 25]
    const newValue = Math.max(
      min,
      Math.min(max, parseInt(value.replace(/\D/g, ''), 10) || 1)
    )
    setQuantity(newValue)
  }

  return (
    <Box display='flex' flexDirection='row' alignItems='center'>
      {label ? (
        <Typography variant='body1' className={classes.counterLabel}>
          {label}
        </Typography>
      ) : null}

      <Box className={[classes.counterContainer, counterContainerClass]}>
        <Button
          disabled={disabled}
          color='primary'
          onClick={handleDecrement}
          className={classes.counterButton}
        >
          &#8211;
        </Button>

        <input
          disabled={disabled}
          type='text'
          value={quantity}
          onChange={handleQuantityChange}
          pattern={`[${min}-${max}]`}
          className={classes.counterText}
        />

        <Button
          disabled={disabled}
          color='primary'
          onClick={handleIncrement}
          className={classes.counterButton}
        >
          &#43;
        </Button>
      </Box>
    </Box>
  )
}

export default CounterComponent
