import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import CustomRadio from '../CustomRadio';
import { sharedStyles } from '../../resources';

const MultiSelectChoice = ({ label, value, sharedClasses}) => (
  <FormControlLabel
    value={value}
    control={(
      <CustomRadio />
    )}
    classes={{
      label: sharedClasses.body
    }}
    label={label}
  />
)

MultiSelectChoice.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

const MultiSelectQuestion = ({
  questionText, questionId, choices, updateAnswer, selectedAnswer,
}) => {
  const sharedClasses = sharedStyles();
  return (
    <FormControl component="fieldset" key={questionText}>
      <FormLabel component="legend" classes={{ root: sharedClasses.h7 }}>{questionText}</FormLabel>
      <RadioGroup
        aria-label={questionText}
        name={questionId ? `radiogroup_${questionId}` : 'radiogroup'}
        value={selectedAnswer}
        onChange={updateAnswer}
      >
        {choices.map((choice) => <MultiSelectChoice key={choice.id} label={choice.choiceText} value={choice.id} sharedClasses={sharedClasses} />)}
      </RadioGroup>
    </FormControl>
  )
}

MultiSelectQuestion.propTypes = {
  questionText: PropTypes.string.isRequired,
  questionId: PropTypes.number,
  choices: PropTypes.arrayOf(PropTypes.shape({
    choiceText: PropTypes.string.isRequired,
    choiceId: PropTypes.number.isRequired,
  })).isRequired,
  updateAnswer: PropTypes.func.isRequired,
  selectedAnswer: PropTypes.number,
};

MultiSelectQuestion.defaultProps = {
  selectedAnswer: null,
  questionId: null,
};

export default MultiSelectQuestion;
