/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table, TableBody, TableCell, TableRow, Typography,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import { colors, fonts } from 'common/assets/sharedUI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons';
import helpers from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionOrders } from '../store/actions/subscription';

const TableNameCell = styled(TableCell)(() => ({
  fontWeight: '600',
  fontFamily: fonts.paragraph2,
  fontSize: '16px',
  lineHeight: '24px',
}));

const TableDataCell = styled(TableCell)(() => ({
  fontWeight: '500',
  fontFamily: fonts.paragraph2,
  paddingTop: '-4px',
  fontSize: '16px',
  lineHeight: '24px',
}));

const TableRowItem = styled(TableRow)(() => ({
  borderBottom: '1px solid',
  borderBottomColor: 'rgba(0, 0, 0, 0.1)',
}));

const OrderHistoryMessage = styled(Typography)(() => ({
  fontFamily: fonts.paragraph2,
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
}));

const StatusText = styled(Typography)(() => ({
  color: colors?.darkGrey,
  marginRight: '8px'
}));

const OrderHistory = () => {
  const [hasBeenCheckedThisRender, setHasBeenCheckedThisRender] = useState(false);
  const dispatch = useDispatch();

  const { fetching, data: subscriptionOrders, error: subscriptionOrdersError } = useSelector(state => state.subscription.subscriptionOrders);

  useEffect(() => {
    // Handles fetching data on initial page render.
    if (!fetching && !subscriptionOrdersError && !subscriptionOrders) {
      dispatch(getSubscriptionOrders());
      setHasBeenCheckedThisRender(true);
    // Handles fetching data on re-render when existing data exists.
    } else if (!hasBeenCheckedThisRender) {
      dispatch(getSubscriptionOrders());
      setHasBeenCheckedThisRender(true);
    }
  }, [fetching, dispatch, subscriptionOrders, subscriptionOrdersError, hasBeenCheckedThisRender]);

  if (subscriptionOrdersError) {
    return (
      <div style={{ maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}>
        <Typography variant="h5">An error occurred while loading your order history. Please refresh the page and try again.</Typography>
      </div>
    );
  }

  if (fetching) {
    return (
      <OrderHistoryMessage>Loading...</OrderHistoryMessage>
    );
  }

  if (!subscriptionOrders || !subscriptionOrders.length) {
    return (
      <OrderHistoryMessage>You have not ordered a GenoVit personalized supplement subscription. There is no order history to display.</OrderHistoryMessage>
    );
  }

  return (
    <Table aria-label="customized table">
      {/* Supplement Subscription Section */}
      <TableBody>
        {subscriptionOrders.sort(
          (a, b) => Date.parse(b.orderDate) - Date.parse(a.orderDate),
        ).map((order) => (
          <TableRowItem key={order.ecommerceOrderNumber + order.status}>
            <TableNameCell>
              {order.name}
              <br style={{ marginBottom: 10 }} />
              <Grid container>
                  <StatusText>{'Subscription Status: '}</StatusText>
                  <Typography style={{ color: order.subscriptionStatus === 'Active' ? colors.green_05 : colors.red, fontWeight: 'bold', marginRight: '40px' }}>{order.subscriptionStatus === 'To Be Canceled' ? 'Canceled' : order.subscriptionStatus}</Typography>
                  <StatusText>{'Order Status: '}</StatusText>
                  <Typography style={{color: colors.green_05, fontWeight: 'bold' }}>{order.status}</Typography>
              </Grid>
            </TableNameCell>
            <TableDataCell>
              {`Order #${order.ecommerceOrderNumber}`}
            </TableDataCell>
            <TableDataCell>
              {helpers.formatDateString(order.orderDate, 'short')}
            </TableDataCell>
            <TableDataCell>
              <FontAwesomeIcon icon={faCreditCard} style={{ color: colors.blue_04, paddingRight: '6px' }} sx={{ fontSize: 16 }} />
              {`Ending with ${order.ccLastDigits}`}
            </TableDataCell>
          </TableRowItem>
        ))}
      </TableBody>
    </Table>
  );
};

export default OrderHistory;
