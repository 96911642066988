import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  pieChartContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

export default useStyles
