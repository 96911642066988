/* eslint-disable import/prefer-default-export */
import { genetics } from 'services';
import actionTypes from '../actionTypes';

export const getUserGeneDetails = (traitIds) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_USER_GENE_DETAILS_STARTED });
  try {
    const userGeneDetails = await genetics.getUserGeneDetails(traitIds);
    dispatch({ type: actionTypes.GET_USER_GENE_DETAILS_SUCCESS, data: userGeneDetails });
  } catch (error) {
    dispatch({ type: actionTypes.GET_USER_GENE_DETAILS_ERROR, error });
  }
};
