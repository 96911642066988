import React, { useCallback, useState } from 'react';
import { images } from 'resources';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, Container, Button, Hidden, Drawer, Divider, CssBaseline, IconButton, List, ListItem, ListItemText, Menu, MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {
  Link, useHistory,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome, faCarrot, faCog, faStore, faSignOutAlt, faGlasses, faDna, faChevronDown, faUser,
} from '@fortawesome/free-solid-svg-icons';
import { faHatChef } from '@fortawesome/pro-solid-svg-icons';
import { PanelResultStatuses } from 'resources/constants';
import { selectExpandedCategory } from '../store/actions/food';
import { clearStore } from '../store/actions/user';
import { openCart } from '../store/actions/additionalProducts';
import * as flows from '../flows';
import useStyles from './WebAppToolbar.styles';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../utils/googleanalytics';
import CartIcon from './CartIcon';
import { useRecipes } from '../hooks';
import { colors } from 'common/assets/sharedUI';

const paths = {
  home: '/home',
  nutrients: '/nutrients',
  foods: '/food',
  insights: '/insights',
  recipes: '/recipes',
  shop: '/shop',
  account: '/account',
};

const tabs = {
  home: 'home',
  nutrients: 'nutrients',
  foods: 'food',
  insights: 'insights',
  recipes: 'recipes',
  shop: 'shop',
  account: 'account',
};

const ButtonAppBar = ({ topMargin, navProps }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);

  // global state
  const status = useSelector(state => state.status.userStatus);
  const panels = useSelector(state => state.status.panels);

  const webDisplay = (status ? status.webDisplay : '');

  const [anchorEl, setAnchorEl] = useState(false);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(false);

  // panelsPassedQC.length will always be at least 1 (ENR) if the report is being rendered.
  // Anything more than that and we assume there's another panel to display on the insights page
  const displayMyInsights = panels.filter(({ status }) => status === PanelResultStatuses.QCComplete).length > 1;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onClick = useCallback((e, target, eventName) => {
    e.preventDefault();
    handleClose();
    registerGAClickEventEnhanced(EventCategories.Navbar, EventActions.Open, eventName);
    history.push(target);
  }, [history, navProps]);

  const onLogoClicked = useCallback(() => {
    handleClose();
    registerGAClickEventEnhanced(EventCategories.Navbar, EventActions.Open, EventLabels.Home);
  }, []);
  const handleHomeClick = useCallback((event) => onClick(event, paths.home, EventLabels.Home), [onClick]);
  const handleNutrientsClick = useCallback((event) => onClick(event, paths.nutrients, EventLabels.MyNutrients), [onClick]);
  const handleFoodsClick = useCallback((event) => {
    onClick(event, paths.foods, EventLabels.MyFoods);
    dispatch(selectExpandedCategory(8)); // sets initial expanded food group id
  }, [dispatch, onClick]);

  const handleInsightsClick = useCallback((event) => onClick(event, paths.insights, EventLabels.MyInsights), [onClick]);
  const handleAccountClick = useCallback((event) => onClick(event, paths.account, EventLabels.MyAccount), [onClick]);
  const handleShopClick = useCallback((event) => onClick(event, paths.shop, EventLabels.Shop), [onClick]);
  const handleResources = useCallback(() => {
    window.location.href = "https://www.genopalate.com/pages/genopalate-university";
}, []);
  const handleHelp = useCallback(() => {
    window.location.href = "https://www.genopalate.com/pages/contact";
}, []);

  const onCartClicked = useCallback(() => {
    dispatch(openCart());
    registerGAClickEventEnhanced(EventCategories.AddOns, EventActions.Open, EventLabels.CartOpen);
  }, [dispatch]);

  const onLogout = useCallback(() => {
    registerGAClickEventEnhanced(EventCategories.Navbar, EventActions.Open, EventLabels.Logout);
    flows.logout();
    dispatch(clearStore()); // todo: move clearStore call into the logout flow
  }, [dispatch]);

  const { userHasPurchasedRecipes: showRecipes } = useRecipes();

  const handleRecipesClick = useCallback((event) => onClick(event, paths.recipes, EventLabels.Navbar), [onClick]);

  const drawer = (
    <div>
      <List className={classes.list} style={{ marginTop: topMargin }}>
        <ListItem button component="a" key="home" onClick={handleHomeClick} className={classes.listItem} name="mobileHome">
          <FontAwesomeIcon icon={faHome} style={{ marginRight: 5 }} />
          <ListItemText primary="home" />
        </ListItem>
        <ListItem disabled={webDisplay !== 'renderReport'} button component="a" key="myNutrients" onClick={handleNutrientsClick} name="mobileNutrients">
          <FontAwesomeIcon icon={faDna} style={{ marginRight: 5 }} />
          <ListItemText primary="nutrition label" />
          <Divider />
        </ListItem>
        <ListItem disabled={webDisplay !== 'renderReport'} button component="a" key="myFoods" className={classes.listItem} onClick={handleFoodsClick} name="mobileFood">
          <FontAwesomeIcon icon={faCarrot} style={{ marginRight: 5 }} />
          <ListItemText primary="foods" />
          <Divider />
        </ListItem>
        {displayMyInsights && (
          <ListItem disabled={webDisplay !== 'renderReport'} button component="a" key="myInsights" className={classes.listItem} onClick={handleInsightsClick} name="mobileInsights">
            <FontAwesomeIcon icon={faGlasses} style={{ marginRight: 5 }} />
            <ListItemText primary="insights" />
            <Divider />
          </ListItem>
        )}
        {showRecipes
          && (
            <ListItem disabled={webDisplay !== 'renderReport'} button component="a" className={classes.listItem} onClick={handleRecipesClick} name="mobileRecipes">
              <FontAwesomeIcon icon={faHatChef} style={{ marginRight: 5 }} />
              <ListItemText primary="recipes" />
              <Divider />
            </ListItem>
          )}
        <ListItem button component="a" onClick={handleShopClick} key="shop" name="mobileShop">
          <FontAwesomeIcon icon={faStore} style={{ marginRight: 5 }} />
          <ListItemText primary="shop" />
          <Divider />
        </ListItem>
        <ListItem button component="a" onClick={handleAccountClick} key="myAccount" name="mobileAccount">
          <FontAwesomeIcon icon={faCog} style={{ marginRight: 5 }} />
          <ListItemText primary="account" />
          <Divider />
        </ListItem>
        <ListItem button to="/" className={classes.listItem} onClick={onLogout} key="logout" name="mobileLogout">
          <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 5 }} />
          <ListItemText primary="logout" />
        </ListItem>

      </List>
    </div>
  );


  const renderMenu = (
    <Menu
      id="user-account-options"
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      keepMounted
      elevation={1}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className={classes.menu}
      >
      <MenuItem onClick={handleAccountClick}>Account</MenuItem>
      <MenuItem onClick={handleResources}>Resources</MenuItem>
      <MenuItem onClick={handleHelp}>Help</MenuItem>
      <MenuItem onClick={onLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" elevation={0} className={classes.appBar} style={{ top: topMargin }}>
        <Container maxWidth="lg">
          <Toolbar className={classes.space}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
              id="mobileMenuButton"
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Link
              to="/home"
              onClick={onLogoClicked}
            >
              <img alt="Genopalate logo" src={images.logo} className={classes.logo} />
            </Link>
            <Hidden smDown>
              <Button
                name={tabs.home}
                size="large"
                onClick={handleHomeClick}
                className={navProps.tab === tabs.home ? classes.selectedButton : classes.button}
                id="tabNavigationHome"
              >
                Home
              </Button>
              <Button
                name={tabs.nutrients}
                size="large"
                onClick={handleNutrientsClick}
                className={navProps.tab === tabs.nutrients ? classes.selectedButton : classes.button}
                disabled={webDisplay !== 'renderReport'}
                id="tabNavigationNutrients"
              >
                Nutrition Label
              </Button>
              <Button
                name={tabs.foods}
                size="large"
                onClick={handleFoodsClick}
                className={navProps.tab === tabs.foods ? classes.selectedButton : classes.button}
                disabled={webDisplay !== 'renderReport'}
                id="tabNavigationFoods"
              >
                Foods
              </Button>
              {displayMyInsights && (
                <Button
                  name={tabs.insights}
                  size="large"
                  onClick={handleInsightsClick}
                  className={navProps.tab === tabs.insights ? classes.selectedButton : classes.button}
                  /* panelsPassedQC.length will always be at least 1 (ENR) if the report is being rendered.
                  Anything more than that and we assume there's another panel to display on the insights page */
                  disabled={webDisplay !== 'renderReport'}
                  id="tabNavigationInsights"
                >
                  Insights
                </Button>
              )}
              {showRecipes
                && (
                  <Button
                    name={tabs.recipes}
                    size="large"
                    onClick={handleRecipesClick}
                    className={navProps.tab === tabs.recipes ? classes.selectedButton : classes.button}
                    disabled={webDisplay !== 'renderReport'}
                    id="tabNavigationRecipes"
                  >
                    Recipes
                  </Button>
                )}
            </Hidden>
            <div
              className={classes.iconContainer}
            >
              <Hidden smDown>
                <Button
                  name={tabs.shop}
                  onClick={handleShopClick}
                  className={classes.shopButton}
                  id="tabNavigationShop"
                  >
                  Shop →
                </Button>
              </Hidden>
              <CartIcon onClick={onCartClicked} />
              <Hidden smDown>
                <IconButton
                  aria-controls="user-account-options"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="inherit"
                  >
                  <FontAwesomeIcon icon={faUser} color={Boolean(anchorEl) ? colors.highlightsBlue : colors.darkestGrey} className={classes.userIcon}/>
                  <FontAwesomeIcon icon={faChevronDown} color={colors.darkestGrey} fontSize={15}/>
                </IconButton>
              </Hidden>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClick={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <Hidden smDown>
        {renderMenu}
      </Hidden>
    </div>
  );
};

ButtonAppBar.propTypes = {
  topMargin: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  navProps: PropTypes.any.isRequired,
};

export default ButtonAppBar;
