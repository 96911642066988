import { getAxiosInstance } from '../utils/axios';

const listRecipes = async () => {
  const response = await getAxiosInstance()
    .get('/api/v1/recipe');

  return response.data;
}

export default {
  listRecipes,
}
