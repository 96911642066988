// eslint-disable-next-line max-len
export default function Product({
  id, name, type,
}) {
  return {
    id,
    name,
    type,
  }
}
