import React, { useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './AdditionalProducts.styles'
import AdditionalProduct from 'components/SupplementSubscription/AdditionalProduct'
import { sharedStyles } from '../../resources';
import { useSelector } from 'react-redux';
import { colors } from 'common/assets/sharedUI';

const AdditionalProducts = () => {
    const { data: products } = useSelector((state) => state.additionalProducts.products);

    const availableProducts = useMemo(() => {
        if (Array.isArray(products) && products.length > 0) {
            return products.sort((a, b) => a.sortOrder - b.sortOrder);
        }
        return [];
    }, [products]);
    const sharedClasses = sharedStyles();
    const classes = useStyles()
    return (
        <Grid item xs={12} style={{ display: availableProducts.length === 0 ? 'none' : 'initial' }}>
            <Typography className={sharedClasses.h5} style={{ color: colors.blue_05, marginBottom: 8, marginTop: 80 }}>
                Additional Products
            </Typography>
            <hr className={classes.horizontalRule} />
            <Grid container>
                {
                    Array.isArray(availableProducts) && availableProducts.map(product => (
                        <Grid className={classes.additionalProductWrapper} item xs={12} md={3} key={product.name}>
                            <AdditionalProduct
                                name={product.name}
                                image={product.image}
                                price={product.price}
                                description={product.metafieldDescription}
                                id={product.id}
                            />
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    )
}

export default AdditionalProducts