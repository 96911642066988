import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { colors, fonts } from 'common/assets/sharedUI';

const styles = () => ({
  title: {
    fontSize: 25,
    fontWeight: 600,
    color: colors.blue_05,
    marginBottom: 40,
    fontFamily: fonts.heading1,
  },
  wrapper: {
    marginBottom: 40,
  },
});

const SectionWrapper = (props) => {
  const {
    title, classes, children,
  } = props;

  return (
    <div className={classes.wrapper}>
      <Typography variant="h3" className={classes.title}>{title}</Typography>
      {children}
    </div>
  );
};

SectionWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    title: PropTypes.string,
    wrapper: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(SectionWrapper);
