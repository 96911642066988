import { axios } from 'utils';
import { constants } from 'resources';
/**
 * Pauses a subscription.
 *
 * @param {string} subscriptionId - The unique identifier for the subscription to be paused.
 * @param {string} nextChargeDate - The date when the next charge for the subscription is scheduled.
 * @param {Array<{ name: string, value: string }>} properties - Additional properties or configuration for pausing the subscription.
 * @returns {Promise} A Promise that resolves with the result of the pause operation.
 *
 * @throws {Error} If the pause operation fails or encounters an error.
 *
 * @example
 * const result = await pauseSubscription('123456', '2023-01-01', { reason: 'Vacation' });
 * console.log('Subscription paused successfully:', result);
 */

export default async function pauseSubscription(subscriptionId, nextChargeDate, properties) {
  return axios.post(constants.API_PAUSE_SUBSCRIPTION, { subscriptionId, nextChargeDate, properties });
}
