import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Button, withStyles } from '@material-ui/core';
import { colors } from 'common/assets/sharedUI';
import back from 'services/questionnaire/back';

const styles = {
  link: {
    color: colors.gray_05,
    fontSize: '1.15em',
    '&:hover': {
      background: 'none',
      color: colors.blue_04,
    },
    textTransform: 'none',
  },
  icon: {
    marginRight: 3,
  },
};

const BackButton = ({ classes, href, text }) => {
  const history = useHistory();
  const onBackClicked = useCallback(() => (href ? history.push(href) : history.goBack()), [history, href]);

  return (
    <Button onClick={onBackClicked} className={classes.link}>
      <FontAwesomeIcon icon={faArrowLeft} className={classes.icon} />
      {text}
    </Button>
  );
};

BackButton.propTypes = {
  classes: PropTypes.shape({
    link: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  href: PropTypes.string,
  text: PropTypes.string,
};

BackButton.defaultProps = {
  href: undefined,
  text: 'Back',
};

export default withStyles(styles)(BackButton);