import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { findIndex, startCase, uniqBy } from 'lodash';
import {
  Card, CardContent, Fab, Grid, Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import { getTraitRecommendations } from 'store/actions/domain';
import {
  GeneSummary, KeyBenefit, NutrientDescription, RecommendationBlock,
} from 'components/componentsV2';
import GeneView from 'components/GeneView';
import RecommendationPaper from 'components/RecommendationPaper';
import TipsPaper from 'components/TipsPaper';
import { cleanRecommendation, getDomainName, getIntake } from 'utils/dataTools';
import { DomainNames } from 'resources/constants';
import useStyles from './NutrientDetailView.styles';
import { registerGAClickEventEnhanced } from '../utils/googleanalytics';
import actionTypes from '../store/actionTypes';

/**
 * @param {{ name: string, icon: string, description: string }[]} benefits
 */
const NutrientBenefits = ({ benefits }) => uniqBy(benefits, 'description')
  .slice(0, 4)
  .map(({ icon, name, description }, _index, ary) => (
    <KeyBenefit
      key={`${icon}_${name}`}
      icon={icon}
      label={description}
      componentWidth={12 / ary.length}
    />
  ));

const Arrow = ({
  onFabClick, arrowStyles, url, label, children,
}) => (
  <Fab className={arrowStyles} onClick={e => onFabClick(e, url, label)}>
    {children}
  </Fab>
);

Arrow.propTypes = {
  onFabClick: PropTypes.func.isRequired,
  arrowStyles: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const BenefitsWrapper = ({ subtitleStyles, label, children }) => (
  <Grid xs={12} item md={6}>
    <div>
      <Typography variant="h6" align="center" className={subtitleStyles}>{label || ''}</Typography>
      <Grid container>
        {children}
      </Grid>
    </div>
  </Grid>
);

BenefitsWrapper.propTypes = {
  subtitleStyles: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const NutrientDetailView = ({
  selectedDomain,
  essentialNutritionDetails,
  geneDetails,
  id,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fetching: traitRecommendationFetching, data: traitRecommendation, error: traitRecommendationError } = useSelector(state => state.domain.traitRecommendation);

  const selectedTraitDetails = useMemo(() =>
    selectedDomain.traits.find(t => t.traitId === parseInt(id, 10)), [selectedDomain, id]
  );

  const traitENDetails = useMemo(() =>
    essentialNutritionDetails.find(end => end.id === parseInt(id, 10)), [essentialNutritionDetails, id]
  );

  const sensitivitiesOrSubstances = useMemo(() => !!(
    [DomainNames.SENSITIVITIES.toLowerCase(), DomainNames.SUBSTANCES.toLowerCase()].includes(selectedDomain.name.toLowerCase())
  ), [selectedDomain.name]);

  useEffect(() => {
    if (selectedTraitDetails.geneticInsightId && !sensitivitiesOrSubstances) {
      if (
        (!traitRecommendation || traitRecommendation.id !== parseInt(selectedTraitDetails.geneticInsightId, 10))
        && (!traitRecommendationFetching && !traitRecommendationError)
      ) {
        dispatch(getTraitRecommendations([selectedTraitDetails.geneticInsightId]));
      }
    } else {
      dispatch({ type: actionTypes.GET_TRAIT_RECOMMENDATION_SUCCESS, data: null });
    }
  }, [
    traitRecommendation,
    traitRecommendationFetching,
    selectedTraitDetails.geneticInsightId,
    dispatch,
    traitRecommendationError,
    sensitivitiesOrSubstances,
  ]);

  const nextUrl = useMemo(() => {
    const curIndex = findIndex(selectedDomain.traits, (t) => t.traitId === parseInt(id, 10));
    const nextIndex = selectedDomain.traits.length - 1 === curIndex ? 0 : curIndex + 1;
    const nextTraitId = selectedDomain.traits[nextIndex].traitId;
    return `/nutrients/${selectedDomain.name.toLowerCase()}/${nextTraitId}`;
  }, [id, selectedDomain.name, selectedDomain.traits]);

  const previousUrl = useMemo(() => {
    const curIndex = findIndex(selectedDomain.traits, (t) => t.traitId === parseInt(id, 10));
    const prevIndex = curIndex === 0 ? selectedDomain.traits.length - 1 : curIndex - 1;
    const prevTraitId = selectedDomain.traits[prevIndex].traitId;
    return `/nutrients/${selectedDomain.name.toLowerCase()}/${prevTraitId}`;
  }, [id, selectedDomain.name, selectedDomain.traits]);

  const onFabClick = useCallback((e, route, direction) => {
    registerGAClickEventEnhanced(`Nutrient-${startCase(title)}`, 'Click', `${direction} Arrow`);
    e.preventDefault();
    history.push(route);
  }, [title, history]);

  if (!traitENDetails) return null;

  const getUnitSubtitle = formattedRecommendation => {
    if (!formattedRecommendation || formattedRecommendation === 'No recommendation') { return ''; }
    return formattedRecommendation.includes('%') ? 'of your daily calories' : 'per day';
  };

  // filter out healthBenefits to only use the ones specific to the trait we're using
  // then loop through the genes and populate
  const nutrientBenefits = (traitENDetails.healthBenefits || []);
  const traitGenes = geneDetails.find(gd => gd.traitId === parseInt(id, 10));
  const genes = !!traitGenes && !!traitGenes.genes && traitGenes.genes.reduce((acc, { markers }) => {
    markers.forEach(marker => {
      if (marker.variant && !!marker.healthOutcome && !acc.find(({ name }) => name === marker.healthOutcome.name)) {
        acc.push({ name: marker.healthOutcome.name, description: marker.healthOutcome.description, icon: marker.healthOutcome.icon });
      }
    });

    return acc;
  }, []);
  const title = getDomainName(selectedTraitDetails.traitName);
  const subtitle = getUnitSubtitle(selectedTraitDetails.formattedRecommendation) || '';
  const generalIntake = selectedTraitDetails.formattedAverage || '';

  return (
    <Card className={classes.container}>
      <CardContent>
        <div className={classes.title} id="nutrientName">
          <Typography variant="h4">{title}</Typography>
        </div>
        <hr />
        <Grid container>
          <Grid item xs={12} className={classes.recommendationContainer}>
            <Arrow
              arrowStyles={classes.leftArrow}
              url={previousUrl}
              label="Left"
              onFabClick={onFabClick}
            >
              <ArrowBackIcon className={classes.arrowIcon} />
            </Arrow>
            <RecommendationBlock
              intake={
                getIntake(
                  selectedTraitDetails.geneticInsightName || 'Genetic Result Not Read',
                  cleanRecommendation(selectedTraitDetails.formattedRecommendation),
                  sensitivitiesOrSubstances,
                  selectedTraitDetails.traitName
                )
              }
              subtitle={subtitle}
              generalIntake={generalIntake}
              sensitivitiesOrSubstances={sensitivitiesOrSubstances}
            />
            <Arrow
              arrowStyles={classes.rightArrow}
              url={nextUrl}
              label="Right"
              onFabClick={onFabClick}
            >
              <ArrowForwardIcon className={classes.arrowIcon} />
            </Arrow>
          </Grid>
          <RecommendationPaper
            traitRecommendation={traitRecommendation}
            geneticInsightDescription={selectedTraitDetails.geneticInsightDescription}
          />
          <div className={classes.nutrientBenefitsContainer}>
            <Grid container>
              <Grid item xs={12} md={6} className={classes.nutrientDescriptionContainer} id="nutrientDescription">
                <NutrientDescription
                  name={selectedTraitDetails.traitName}
                  description={selectedTraitDetails.traitDescription}
                  additionalDescription={selectedTraitDetails.traitAdditionalDescription}
                />
              </Grid>
              {!sensitivitiesOrSubstances && nutrientBenefits.length > 0 && (
                <BenefitsWrapper label="Nutrient Benefits" subtitleStyles={classes.subtitle}>
                  {!!traitENDetails && !!traitENDetails.healthBenefits && <NutrientBenefits benefits={traitENDetails.healthBenefits} />}
                </BenefitsWrapper>
              )}
            </Grid>
          </div>
          <TipsPaper
            sensitivitiesOrSubstances={sensitivitiesOrSubstances}
            traitData={selectedTraitDetails}
            enDetails={traitENDetails}
          />
          <div className={classes.keyBenefitsContainer} id="genesSummary">
            <Grid container>
              <Grid item xs={12} md={6} className={classes.recommendationContainer}>
                <GeneSummary totalVariants={selectedTraitDetails.totalVariants} />
              </Grid>
              {!sensitivitiesOrSubstances && genes.length > 0 && (
                <BenefitsWrapper label="Key Benefits" subtitleStyles={classes.subtitle}>
                  <NutrientBenefits benefits={genes} />
                </BenefitsWrapper>
              )}
            </Grid>
          </div>
          {!!traitGenes && (
            <Grid item xs={12} className={classes.geneContainer} id="genesList">
              <GeneView traitName={selectedTraitDetails.traitName} domainName={selectedDomain.name} geneDetails={traitGenes} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

NutrientDetailView.propTypes = {
  selectedDomain: PropTypes.shape({
    name: PropTypes.string,
    traits: PropTypes.arrayOf(PropTypes.shape({
      traitId: PropTypes.number,
      geneticInsightId: PropTypes.number,
      traitName: PropTypes.string,
      formattedAverage: PropTypes.string,
      formattedRecommendation: PropTypes.string,
      geneticInsightName: PropTypes.string,
      geneticInsightDescription: PropTypes.string,
      totalVariants: PropTypes.number,
      traitDescription: PropTypes.string,
      traitAdditionalDescription: PropTypes.string,
    })),
  }).isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
    title: PropTypes.string,
    leftArrow: PropTypes.string,
    rightArrow: PropTypes.string,
    arrowIcon: PropTypes.string,
    nutrientBenefitsContainer: PropTypes.string,
    nutrientDescriptionContainer: PropTypes.string,
    keyBenefitsContainer: PropTypes.string,
    recommendationContainer: PropTypes.string,
    subtitle: PropTypes.string,
    geneContainer: PropTypes.string,
  }).isRequired,
  essentialNutritionDetails: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  geneDetails: PropTypes.arrayOf(PropTypes.shape({
    traitId: PropTypes.number,
    genes: PropTypes.arrayOf(PropTypes.shape({
      markers: PropTypes.arrayOf(PropTypes.shape({
        healthOutcome: PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
          icon: PropTypes.string,
        }),
        variant: PropTypes.bool,
      })),
    })),
  })).isRequired,
  id: PropTypes.number.isRequired,
};

export default NutrientDetailView;
