import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  Button, Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';
import { updatePassword, resetUpdatePasswordState } from '../../store/actions/user';
import { passwordRequirements } from '../../utils';
import { sharedStyles } from '../../resources';
import AccountFormikInput from './AccountFormikInput';
import {
  registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels,
} from '../../utils/googleanalytics';

const useStyles = makeStyles(theme => ({
  checkIcon: {
    color: colors.primaryGreen,
    fontSize: '5rem',
    paddingBottom: 20,
  },
  successContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  successText: {
    paddingBottom: 20,
  },
  errorText: {
    textAlign: 'center',
    color: colors.red,
  },
  form: {
    maxWidth: 440,
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const UpdatePasswordForm = () => {
  const classes = useStyles();
  const sharedClasses = sharedStyles();

  const { updatingPassword } = useSelector(state => ({
    updatingPassword: state.user.updatingPassword,
  }));

  const dispatch = useDispatch();

  // Resets state on component unmount so user can change password after navigating back here
  useEffect(() => {
    dispatch(resetUpdatePasswordState());
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('You must enter your current password'),
    newPassword: passwordRequirements,
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
      .required('You are required to confirm your password'),
  });

  return (
    <Formik
      initialValues={{ currentPassword: '', newPassword: '', confirmPassword: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        if (values && values.currentPassword && values.newPassword) {
          registerGAClickEventEnhanced(EventCategories.AccountPassword, EventActions.Update, EventLabels.UpdatePassword);
          dispatch(updatePassword(values.currentPassword, values.newPassword));
          resetForm();
        }
      }}
    >
      {({
        handleSubmit, submitCount, isValid, dirty,
      }) => (
        <Form onSubmit={handleSubmit} className={classes.form}>
          <Grid item xs={12}>
            <AccountFormikInput
              name="currentPassword"
              label="Current Password"
              submitCount={submitCount}
              type="password"
              placeholder="Enter current password"
            />
          </Grid>
          <Grid item xs={12}>
            <AccountFormikInput
              name="newPassword"
              label="New Password"
              submitCount={submitCount}
              type="password"
              placeholder="Enter new password"
              helperText="Must be at least 8 characters and contain 1 number and 1 special character"
            />
          </Grid>
          <Grid item xs={12}>
            <AccountFormikInput
              name="confirmPassword"
              label="Confirm New Password"
              submitCount={submitCount}
              type="password"
              placeholder="Confirm new password"
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 20, paddingBottom: 20,
            }}
          >
            <Button
              disabled={updatingPassword || !dirty || !isValid}
              className={sharedClasses.rebrandedPrimaryButton}
              type="submit"
            >
              Change →
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UpdatePasswordForm;
