import { axios } from 'utils'
import { constants } from 'resources'

export default async function getContract(name) {
  try {
    const params = {
      names: name,
    }
    const { data } = await axios.post(constants.API_GET_CONTRACT, params)
    return data
  } catch (e) {
    console.log({ e })
    return { e }
  }
}
