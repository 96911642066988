import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    marginBottom: 15,
  },
  icon: {
    fontSize: 72,
  },
  title: {
    fontWeight: 800,
  },
})

export default useStyles
