import { createStyles, makeStyles } from '@material-ui/core/styles';
import { colors } from 'common/assets/sharedUI';

const useStyles = makeStyles((theme) => createStyles({
  banner: {
    width: '100%',
    left: 0,
    zIndex: 1301,
    fontSize: '1.25rem',
    height: 50,
  },
  bannerContainer: {
    padding: '0 2.5vw 0 2.5vw !important',
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row',
  },
  flex: {
    display: 'flex',
  },
  firstColumn: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  bannerTxt: {
    marginTop: 6,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  imgPhoneContainer: {
    overflow: 'hidden',
    maxWidth: 60,
    maxHeight: 53,
  },
  imgPhone: {
    width: 70,
    height: 100,
  },
  secondColumn: {
    marginTop: 3,
  },
  imgGooglePlay: {
    width: 152,
    height: 59,
  },
  closeButton: {
    color: colors.white,
    width: 64,
  },
}));

export default useStyles;
