import React, {
  useEffect, useState, useMemo, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Grid, Tabs, Tab, ListItemSecondaryAction, Typography, Hidden, Box, Button, Card, CardContent, CardActions, withStyles, useMediaQuery, FormControl, RadioGroup, FormControlLabel } from '@material-ui/core';
import startCase from 'lodash/startCase';
import difference from 'lodash/difference';
import kebabCase from 'lodash/kebabCase';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import {
  WelcomeMessage, Loading, TabPanel, RecommendationBlock,
} from 'components/componentsV2';
import NutrientDetailView from 'components/NutrientDetailView';
import {
  getDomainName, getIntake, cleanRecommendation, getIntakeInCalories
} from 'utils/dataTools';
import { selectActionItems } from 'store/actions/actionItems';
import { getEssentialNutrition, getEssentialNutritionDetails } from 'store/actions/domain';
import { getUserGeneDetails } from 'store/actions/genetics';
import { registerGAClickEventEnhanced, EventCategories, EventActions, EventLabels } from 'utils/googleanalytics';
import useStyles from './NutrientPage.styles';
import { NutrientList }  from 'components/Nutrient'
import { colors } from 'common/assets/sharedUI';
import { helpers, storage } from 'utils';
import { get, upperCase } from 'lodash';
import { Seprator, Accordion, Tooltip, CustomDialog } from 'components/shared';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faFingerprint, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { faPrint } from '@fortawesome/pro-duotone-svg-icons';
import { useAdditionalProducts, useNutrients, useQueryParams } from 'hooks';
import { images } from 'resources';
import CustomRadio from 'components/CustomRadio';
import { isSensitivityOrSubstance } from 'hooks/useNutrients';
import { internalURLs } from 'resources/constants';
import { formatMacroMixData, formatMicroMixData, formatSensitivitiesAndSubstanceData } from 'utils/nutrients';
import { genopalateClickIdKey } from 'services/shopify/utils';
import { NutrientListItem } from 'components/Nutrient/NutrientListItem';

/**
 * TODO: this page will be refactored
 * 
 */
const NutrientPage = (props) => {
  const { selected, detailView } = props;
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const printRef = useRef();
  const isLargeScreen = useMediaQuery('(min-width:960px)');

  const { data: products } = useAdditionalProducts();

  // const { fetching: geneticInsightsFetching, data: geneticInsights, geneticInsights: insights , error: geneticInsightsError } = useSelector(state => state.domain.essentialNutrition);
  const { macroNutrients, microNutrients, sensitivitiesOrSubstances, geneticInsightsFetching, geneticInsights, insights, geneticInsightsError } = useNutrients();
  const { fetching: geneDetailsFetching, data: geneDetails, error: geneDetailsError } = useSelector(state => state.genetics);
  const { fetching: traitsFetching, data: allTraits, error: traitsError } = useSelector(state => state.trait);
  const { fetching: enDetailsFetching, data: enDetails, error: enDetailsError } = useSelector(state => state.domain.essentialNutritionDetails);
  const domainActionItems = useSelector(state => state.actionItems.domainActionItems);
  const { userProfile } = useSelector((state) => state.profile)

  /* using product name because Consultation product id is different on different env (qa, staging, prod) */
  const productName = '1-on-1 Consultation with a Registered Dietitian';
  const product = useMemo(() => products && products.find(({ name }) => name === productName), [products?.length]);

  const userName = useMemo(() => get(userProfile, "data.name", ""), [userProfile]);

  const macroMix = useMemo(() => formatMacroMixData(macroNutrients), [macroNutrients.length]);
  const microMix = useMemo(() => formatMicroMixData(microNutrients), [microNutrients.length]);
  const sensitivitiesOrSubstancesMix = useMemo(() => formatSensitivitiesAndSubstanceData(sensitivitiesOrSubstances), [sensitivitiesOrSubstances.length]);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false)
  const [printing, setPrinting] = useState(false);
  const [enablePrint, setEnablePrint] = useState(storage.getPrintOption() !== null ? storage.getPrintOption() : true);
  console.log('print', storage.getPrintOption());
  const onBeforeGetContentResolve = useRef(null);

  const { queryParams } = useQueryParams()
  const amount = queryParams.get('amount');

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      setPrinting(false);
    },
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        setPrinting(true);
        onBeforeGetContentResolve.current = resolve;
        registerGAClickEventEnhanced(EventCategories.Nutrients, EventActions.NutrientsResultsPrint, EventActions.NutrientsResultsPrint);
      });
    },
  });


  useEffect(() => {
    if (printing && onBeforeGetContentResolve.current) {
      onBeforeGetContentResolve.current(); // Resolves the Promise in `onBeforeGetContent`
    }
  }, [printing, onBeforeGetContentResolve]);

  // useEffect(() => {
  //   if ((!geneticInsightsFetching && !geneticInsightsError) && (!geneticInsights || !allTraits)) {
  //     dispatch(getEssentialNutrition());
  //   }
  // }, [geneticInsightsFetching, dispatch, geneticInsights, geneticInsightsError, allTraits]);

  const selectedDomain = useMemo(() => (geneticInsights ? geneticInsights.find(insight => insight.name.toLowerCase() === selected) : null), [geneticInsights, selected]);

  // check if we have EN Details fetched for all the traits for a domain
  const selectedDomainTraitIds = useMemo(() => (selectedDomain && selectedDomain.traits ? selectedDomain.traits.map(t => t.traitId) : []), [selectedDomain]);
  const fetchedENDetailsTraitIds = enDetails.map(end => end.id);
  const unfetchedTraitDetails = difference(selectedDomainTraitIds, fetchedENDetailsTraitIds);

  // same thing for user gene details
  const fetchedGeneDetailsTraitIds = geneDetails.map(gd => gd.traitId);
  const unfetchedGeneDetails = difference(selectedDomainTraitIds, fetchedGeneDetailsTraitIds);

  useEffect(() => {
    if (unfetchedTraitDetails.length && !enDetailsFetching) {
      dispatch(getEssentialNutritionDetails(selectedDomainTraitIds));
    }
  }, [unfetchedTraitDetails, enDetailsFetching, selectedDomainTraitIds, dispatch]);

  useEffect(() => {
    if (unfetchedGeneDetails.length && !geneDetailsFetching) {
      dispatch(getUserGeneDetails(selectedDomainTraitIds));
    }
  }, [unfetchedGeneDetails, geneDetailsFetching, selectedDomainTraitIds, dispatch]);

  useEffect(() => {
    setLoading(true);

    if (!domainActionItems) {
      dispatch(selectActionItems());
    }
    setLoading(false);
  }, [match.params.id, detailView, dispatch, domainActionItems]);

  const onTabClick = useCallback((e, route) => {
    e.preventDefault();
    history.push(route);
  }, [history]);

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getRecommendationBlockURL = (domainName, traitId) => `/nutrients/${domainName.toLowerCase()}/${traitId}`;

  const handleRecommendationBlockChange = (value, traitName) => {
    registerGAClickEventEnhanced(EventCategories.Nutrients, EventActions.Open, EventLabels.Trait(traitName));
    history.push(value);
  };

  const getUnitSubtitle = formattedRecommendation => {
    if (!formattedRecommendation || formattedRecommendation === 'No recommendation') { return ''; }
    return formattedRecommendation.includes('%') ? 'of your daily calories' : 'per day';
  };

  if (loading || geneticInsightsFetching || geneticInsightsError || traitsFetching || !geneticInsights || !allTraits) return <Loading />;

  // TODO: should be in a separate component or page
  if (detailView) {
    return (
      <NutrientDetailView
        selectedDomain={selectedDomain}
        essentialNutritionDetails={enDetails}
        geneDetails={geneDetails}
        id={match.params.id}
      />
    )
  }

  const handleCustomBlendClick = (title) => {
    const params = new URLSearchParams({
      [genopalateClickIdKey]: kebabCase(title)
    });

    registerGAClickEventEnhanced(
      EventCategories.Nutrients,
      EventActions.NutrientsGenoBlendCallout,
      EventLabels.NutrientUpSell(title));
    history.push(`${internalURLs.BLEND_PRODUCT_DETAILS}?${params.toString()}`);
  }

  const handleGenoVitClick = (title) => {
    const params = new URLSearchParams({
      [genopalateClickIdKey]: kebabCase(title)
    });

    registerGAClickEventEnhanced(
      EventCategories.Nutrients,
      EventActions.NutrientsGenoVitCallout,
      EventLabels.NutrientUpSell(title));

    history.push(`${internalURLs.SUPPLEMENT_PRODUCT_DETAILS}?${params.toString()}`);
  }

  const handleConsultClick = (title) => {
    const params = new URLSearchParams({
      [genopalateClickIdKey]: kebabCase(title)
    });

    registerGAClickEventEnhanced(
      EventCategories.Nutrients,
      EventActions.NutrientsConsult,
      EventLabels.NutrientUpSell(title));
      
    const newUrl = product?.id ? `/account/products/product-details/${product.id}?${params.toString()}` : '/account/products/product-details/not-found'
    history.push(newUrl);
  }


  return (
    <>
      {/* check the bg color and change it */}
      <Grid container style={{ padding: 3, backgroundColor: '#eee' }}>
        <Hidden mdDown>
          {/* set width with important here because need to override the grid width (lg) */}
          <Grid item style={{ backgroundColor: colors.white, padding: 30, paddingTop: 'inherit', width: 300 }}>
            <Box marginY={10}>
              <Typography variant='h5' className={classes.title}>Conversions</Typography>
              <Seprator color={colors.gray_02} />
              {/* <Accordion title='Percentage Values' content={<NutrientConversionPercentValue />} />
              <Seprator color={colors.gray_02} /> */}
              <Accordion title='Nutrients Amounts' content={<NutrientConversionAmount />} />
              <Seprator color={colors.gray_02} />
            </Box>
          </Grid>
        </Hidden>

        <Grid item xs={12} md={6} lg={6} style={{ paddingTop: isLargeScreen ? 0 : 20 }}>

          {/* Nutrition Card */}
          <Box marginY={isLargeScreen ? 0 : 2} marginX={isLargeScreen ? 0 : 1} style={{ marginBottom: 60 }}>
            <Box style={{display: 'flex', marginRight: 30, marginBottom: 35, marginTop: 30, justifyContent: 'flex-end'}}>
              <Hidden mdUp>
                <Box mb={3} maxWidth={650} textAlign='right'>
                  <Button variant='outlined' style={{ borderWidth: 3, borderColor: colors.green_07, color: colors.green_07, borderRadius: 6, backgroundColor: colors.white }} onClick={handleOpen}>
                    Conversions →
                  </Button>
                </Box>
              </Hidden>

            {enablePrint ? 
              <Box style={{
                cursor: 'pointer',
                marginLeft: 20,
                marginTop: 10
              }} onClick={handlePrint}>
                <FontAwesomeIcon
                  icon={faPrint}
                  size="xl"
                  color={colors.darkGrey}
                />
              </Box>
            : null }
            </Box>

            <div ref={printRef}>
              <img className={classes.printOnly} src={images.logo} alt='report cover' style={{ height: 50, margin: 20 }} />
              <NutrientListCard
                data={[macroMix, microMix]}
                showDailyCalories={true}
                title='Nutrition Recommendations'
                legendText="You have genetic variants impacting your needs. Click on a nutrient to see more details."
                listHeader={
                  <Box display='flex' justifyContent='space-between'>
                    <Typography>Your Needs</Typography>
                    <Typography>Compared To Average*</Typography>
                  </Box>
                }
                footer={
                  <Box marginY={2}>
                    <Typography>
                      * The Compared To Average column tells you how your nutrient needs
                      compare to the general RDA standard amounts. Your calorie and
                      nutrient recommendations are all tailored to your age and gender.
                    </Typography>
                  </Box>
                }
              />
              <div className={classes.pageBreak} />
              <br />
              <img className={classes.printOnly} src={images.logo} alt='report cover' style={{ height: 50, margin: 20 }} />
              <NutrientListCard
                data={[sensitivitiesOrSubstancesMix]}
                showDailyCalories={false}
                title='Sensitivity Results'
                legendText="You have genetic variants impacting your metabolism or sensitivity. Click on a trait to see more details."
                listHeader={
                  <Box display='flex' justifyContent='space-between'>
                    <Typography>Trait</Typography>
                    <Typography style={{ marginRight: 15 }}>Your Result</Typography>
                  </Box>
                }
              />
            </div>
          </Box>
        </Grid>


        <Grid item xs={12} md={6} lg={3} style={{ borderLeft: `1px solid ${colors.grey}` }}>
          {/* <Box my={isLargeScreen ? 10 : 0} mr={isLargeScreen ? 0 : 3} ml={isLargeScreen ? 5 : 3}> */}
          <Box className={classes.upSellCard}>
          {/* Up sell section */}

            <Box>
              <Typography variant='h4' className={classes.title}>The Next Step To <br /> A Healthier You</Typography>
                <br /> <br />
              <Typography>While we recommend a food-first approach, we understand it can be hard to hit all your unique needs every day. To address this, we developed personalized vitamin and supplement formulations designed specifically for you. Plus, you can sign up for a free consultation with one of our registered dietitians to help you start eating healthier.</Typography>
            </Box>

            <br /> <br />
            
            <UpSellCard 
              title='Personalized Fiber & Protein' 
              subtitle='Macro Support'
              image={images.customBlend}
              buttonTitle='See Custom Blend'
              handleClick={handleCustomBlendClick}
            />

            <br />

            
            <UpSellCard 
              title='Personalized Vitamins' 
              subtitle='Micro Support'
              image={images.genovitUpsell}
              buttonTitle='See Custom Formula'
              handleClick={handleGenoVitClick}
            />
            
            <br />

            <UpSellCard 
              title='1-on-1 Consultation' 
              subtitle='Dietitian Support'
              image={images.dietitianSupport}
              buttonTitle='Sign Up Now'
              handleClick={handleConsultClick}
            />
          
          </Box>
        </Grid>
      </Grid>

      <CustomDialog 
        open={open} 
        divider={true} 
        title='Conversions' 
        handleClose={handleClose} 
        content={
        <>
          <Typography className={classes.heading}>Percentage Values</Typography>
          <NutrientConversionPercentValue />
          <br /><br />
            <Typography className={classes.heading}>Nutrient Amounts</Typography>
          <NutrientConversionAmount />
        </>
        }
      />
    </>
  )

  return (
    <Grid container spacing={0}>
     <NutrientListCard geneticInsights={geneticInsights} />
      <WelcomeMessage title="Nutrients" />
      <Grid item xs={12}>
        <Tabs value={selected} aria-label="navigation" variant="scrollable" scrollButtons="on" classes={{ indicator: classes.indicator }}>
          {geneticInsights.map(({ name }) => (
            <Tab
              label={startCase(name)}
              value={name.toLowerCase()}
              className={selected === name.toLowerCase() ? classes.activeTab : classes.defaultTab}
              onClick={e => onTabClick(e, `/nutrients/${name.toLowerCase()}`, `${name} clicked on My Nutrients`)}
            />
          ))}
        </Tabs>
      </Grid>
      {detailView ? (
        <Grid item xs={12}>
          <NutrientDetailView
            selectedDomain={selectedDomain}
            essentialNutritionDetails={enDetails}
            geneDetails={geneDetails}
            id={match.params.id}
          />
        </Grid>
      ) : (
        <div style={{ width: '100%' }}>
          <TabPanel active>
            <Grid container spacing={4}>
              {selectedDomain.traits.map(trait => {
                const sensitivitiesOrSubstances = (!!(selectedDomain.name.toLowerCase() === 'sensitivities' || selectedDomain.name.toLowerCase() === 'substances'));
                const title = getDomainName(trait.traitName);
                const geneticInsightName = trait.geneticInsightName || 'Genetic Result Not Read';
                const recText = cleanRecommendation(trait.formattedRecommendation) || '';
                const subtitle = getUnitSubtitle(trait.formattedRecommendation);
                const generalIntake = trait.formattedAverage;
                return (
                  <Grid item xs={12} sm={4}>
                    <RecommendationBlock
                      title={title}
                      subtitle={subtitle}
                      intake={getIntake(geneticInsightName, recText, sensitivitiesOrSubstances)}
                      sensitivitiesOrSubstances={sensitivitiesOrSubstances}
                      generalIntake={generalIntake}
                      onClick={() => handleRecommendationBlockChange(getRecommendationBlockURL(selectedDomain.name, trait.traitId), title)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </TabPanel>
        </div>
      )}
    </Grid>
  );
};

export default NutrientPage;

NutrientPage.propTypes = {
  detailView: PropTypes.bool.isRequired,
  selected: PropTypes.string.isRequired,
};

// TODO: put this in separate component or file
// Nutrient List Card

const NutrientListCard = ({ data, showDailyCalories, title, listHeader, legendText, footer = null }) => {
  const classes = useStyles();

  const { userProfile } = useSelector((state) => state.profile)
  const eer = useSelector(state => state.nutrition.eer)

  const userName = useMemo(() => get(userProfile, "data.name", ""), [userProfile]);
  const formattedEER = Math.round(parseFloat(eer))

  return (
      <div className={classes.wrapper}>
        <div>
          <Typography variant='subtitle1' className={classes.userName}>{`${helpers.possessive((userName)).toUpperCase()}`}</Typography>
          <Typography variant='h4' className={classes.title} >{title}</Typography>
        </div>
        <Seprator color={colors.separatorColor} />

        <ListItem disableGutters alignItems="flex-start">
          <ListItemIcon className={classes.legendIcon}>
            <FontAwesomeIcon
                icon={faFingerprint}
                size="xl"
                color={colors.blue_03}
              />
          </ListItemIcon>
          <ListItemText primary={legendText} style={{fontSize: 14, marginLeft: 10 }} />
        </ListItem>

      <div>
        <ListItem disableGutters>
          {showDailyCalories ? <ListItemText
            primary={
              <React.Fragment>
                <Typography variant='h5'>Daily Calories</Typography>
              </React.Fragment>
            }
          />:null}

            {/* <ListItemSecondaryAction> */}
           {showDailyCalories ? <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography component='span' variant='h5'>
                {helpers.formatNumber(formattedEER || 0, {})}
              </Typography>


            <Tooltip arrow title={'We took into account your sex, age, height, weight, weight goals, and exercise frequency when calculating your personalized calorie recommendation.'}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="xl"
                color={colors.gray_03}
                style={{ marginLeft: 8 }}
              />
            </Tooltip>
            </Box>
            :null}
            {/* </ListItemSecondaryAction> */}
        </ListItem>
      </div>

      {listHeader ? listHeader : null}

      <Seprator color={colors.separatorColor} />
    
        
        <NutrientList>

         {data.map((arr, index) => (
            <React.Fragment key={index}>
              {arr.map((trait, idx) => {
                if(!trait?.traitName) return null
                return (
                <>
                  <NutrientListItem key={trait.traitId} trait={trait} isLastTrait={false} />
                  {idx !== arr.length - 1 && <Seprator color={colors.separatorColor} thickness={1} /> }
                </>
              )
              })}
              {index !== data.length && <Seprator color={colors.separatorColor} thickness={10} />}
            </React.Fragment>
          ))}
        </NutrientList>

        {footer}
      </div>     
  )
}

const ColorButton = withStyles((theme) => ({
  root: {
    color: colors.white,
    backgroundColor: colors.green_07,
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
      color: colors.white,
      backgroundColor: colors.green_05,
      boxShadow: 'none',
    },
  },
}))(Button);


const UpSellCard = ({ title, subtitle, image, buttonTitle, handleClick }) => {
  const classes = useStyles();
  const history = useHistory();

  
  
  return (
    <Card>
      <CardContent>
        <Typography
          className={classes.subtitle}
          style={{ color: colors.black }}
          color='textSecondary'
          gutterBottom
        >
          {upperCase(subtitle)}
        </Typography>
        <Typography variant='h5' component='h2' className={classes.title}>
          {title}
        </Typography>

        <img
          src={image}
          alt={title}
          style={{ objectFit: 'cover', width:'16rem', height:'6.5rem' }}
        />
      </CardContent>

      <CardActions style={{ padding: 16, paddingTop: 0 }}>
        <ColorButton size='small' variant="contained" onClick={() => handleClick(title)}>
          {buttonTitle} →
        </ColorButton>
      </CardActions>
    </Card>
  )
}




const NutrientConversionAmount = () => {
  const { queryParams, setQueryParam } = useQueryParams()
  const value = queryParams.get('amount')

  const handleChange = (event) => {
    const value = event.target.value;
    registerGAClickEventEnhanced(EventCategories.Nutrients, EventActions.NutrientsUnitsToggled, EventLabels.NutrientConversion(value));
    setQueryParam('amount', value);
  }

  return (
    <>
    <FormControl component='fieldset'>
      <RadioGroup
        aria-label='Nutrient Amount'
        name='nutrientAmount'
        value={value || 'grams'}
        onChange={handleChange}
      >
        <FormControlLabel
          value='grams'
          control={<CustomRadio width='14px' height='14px' />}
          label='Grams'
        />

        <FormControlLabel
          value='calories'
          control={<CustomRadio width='14px' height='14px' />}
          label='Calories'
        />
      </RadioGroup>
    </FormControl>
    </>
  )
}


const NutrientConversionPercentValue = () => {
  const { queryParams, setQueryParam } = useQueryParams()
  const value = queryParams.get('percent')

  const handleChange = (event) => {
    const value = event.target.value
    registerGAClickEventEnhanced(
      EventCategories.NutrientConversionPercent,
      EventActions.Click,
      EventLabels.NutrientConversion(value)
    )
    setQueryParam('percent', value)
  }

  return (
    <>
      <FormControl component='fieldset'>
        <RadioGroup
          aria-label='percentValue'
          name='nutrientPercentValue'
          value={value || 'average'}
          onChange={handleChange}
        >
          <FormControlLabel
            value='average'
            control={<CustomRadio width='14px' height='14px' />}
            label='% Compared To Average'
          />

          <FormControlLabel
            value='percent_calories'
            control={<CustomRadio width='14px' height='14px' />}
            label='% Daily Calories'
          />
        </RadioGroup>
      </FormControl>
    </>
  )
}