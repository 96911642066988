import types from '../actionTypes';
import { apiStatuses } from '../../resources/constants';

const defaultState = {
  status: apiStatuses.IDLE,
  isRegistration: false,
  error: null,
};

function registrationTMKit(state = defaultState, action) {
  switch (action.type) {
    case types.REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_STARTED:
      return {
        ...state,
        status: apiStatuses.PENDING,
        isRegistration: true,
        error: null,
      };
    case types.REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_SUCCESS:
      return {
        ...state,
        isRegistration: false,
        status: apiStatuses.RESOLVED,
        message: action.payload.message
      };
    case types.REGISTER_THIRD_PARTY_MARKET_PLACE_KIT_ERROR:
      return {
        ...state,
        isRegistration: false,
        status: apiStatuses.REJECTED,
        error: action.error,
      };
    default:
      return state;
  }
}

export default registrationTMKit;
