import { useSelector } from 'react-redux'


/**
 * Custom hook for feature toggling using Redux.
 * @returns {[isEnabled: function]} An array containing functions for checking if a feature is enabled
 */
export const useFeatureToggle = () => {
  const { enabledFeatures: activeFeatures } = useSelector(
    (state) => state.featureFlag
  )


  const isEnabled = (featureName) => {
    return activeFeatures.includes(featureName);
  }

  return [isEnabled]
}

export default useFeatureToggle;
