import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { blendSKUs } from 'resources/constants';
import { getSubscriptions } from 'store/actions/subscription';
import { appConstants } from 'utils/constants/appConstants';

/**
 * fetch or load from redux global state cache ('state.subscription.subcriptions')
 * @param {React.DependencyList} dependencies
 * @returns {{
 *  subscriptionsFetching: boolean,
 *  subscriptions: { subscriptionId: number | string, subscriptionNumber: string, subscriptionName: string, subscriptionItems: string[] | null[], duration: number, durationUnit: DurationUnit, status: SubscriptionStatusNames, nextOrderDate: Date, nextDeliveryDate: Date}[] | null,
 *  subscriptionsError: Error | null
 * }}
 */
const useSubscriptions = () => {
  const dispatch = useDispatch();
  const { fetching, data, error } = useSelector(state => state.subscription.subscriptions);

  useEffect(() => {
    if (!fetching && !data && !error) {
      dispatch(getSubscriptions());
    }
  }, [fetching, dispatch, data, error]);

  const subscriptions = data?.map(subscription =>  {
    // can be handle from backend in future
    const productType = blendSKUs[subscription.productSku] ? appConstants.genoBlend : appConstants.genoVit;
    return {
      ...subscription,
      productType
    }
  })

  return {
    subscriptionsFetching: fetching,
    subscriptions: subscriptions,
    subscriptionsError: error,
  };
};

export default useSubscriptions;
