import { axios } from 'utils';
import { constants } from 'resources';

export default async function getShippingAddress() {
  try {
    const { data } = await axios.get(constants.API_GET_SHIPPING_ADDRESS);
    return data;
  } catch (e) {
    return { e };
  }
}
