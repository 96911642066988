import assignMember from './assignMember'
import assignBarcode from './assignBarcode'
import uploadDnaResult from './uploadDnaResult'
import actionItems from './actionItems'

export default {
  assignMember,
  assignBarcode,
  uploadDnaResult,
  actionItems,
}
