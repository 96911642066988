import { getAxiosInstance } from '../utils/axios';
import { setAccessToken, setUuidCode, clearLocalStorage } from '../utils/storage';

/**
 * only sets local auth state - localStorage.token, axios.token, localStorage.uuidCode
 * @param token
 * @param userId
 * @param uuidCode
 */
export const login = (token, userId, uuidCode) => {
  // force a clean localStorage state
  clearLocalStorage();

  // set login state for localStorage
  setAccessToken(token);
  setUuidCode(uuidCode || null); // null is fine, just can't be undefined here

  // set login state for axios instance
  getAxiosInstance().setToken(token);
};

export default login;
